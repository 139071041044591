.tank-cleaning__video-block {
	padding: 8.5rem 0 2rem;

	@media all and (max-width: 992px) {
		padding: 6rem 0 0;
	}

	@media all and (max-width: 576px) {
		padding: 4rem 0 0;
	}

	&-inner {
		max-width: 100rem;
		margin: 0 auto;
	}
}

.content__video {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}