@import "colors";

.wrapper--search-page {
  background: $t-bg-light-gray;
}

.content-block__search-form {
  position: relative;
  margin-top: 5.5rem;
  width: 100%;

  @media all and (max-width: 768px) {
    margin-top: 4rem;
  }
  @media all and (max-width: 576px) {
    margin-top: 2.5rem;
  }
  @media all and (max-width: 576px) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }

  .search-form__input-search {
    width: 100%;
    padding: 2.1rem 8rem 1.7rem 3.1rem;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.7rem;
    color: $text-black;
    border: none;
    border-radius: .3rem;
    background: white;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.05);

    @media all and (max-width: 768px) {
      padding: 1.9rem 8rem 1.7rem 2.5rem;
    }
    @media all and (max-width: 576px) {
      font-size: 1.6rem;
      line-height: 1.9rem;
      padding: 1.9rem 6rem 1.7rem 1.6rem;
    }

    &::placeholder {
      color: rgba(0, 0, 0, .6);
    }

    &:focus {
      outline: none;
    }
  }

  .search-form__button-search {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    width: 7rem;
    position: absolute;
    border: none;
    background: transparent url('../img/search-icon.svg') center center/auto no-repeat;
    font-size: 0;
    cursor: pointer;

    @media all and (max-width: 576px) {
      width: 5rem;
      background-size: 2rem;
    }
  }
}

.search-result {

  .bread-crumbs {
    margin-top: 1.3rem;
    @media all and (max-width: 576px) {
      margin-top: 1rem;
    }
  }

  &__item {
    margin-bottom: 4.5rem;

    @media all and (max-width: 576px) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.7rem;
    border-bottom: 1px solid $text-black;

    &:hover {
      color: $t-green;
      border-bottom-color: $t-green;
    }

    @media all and (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 3rem;
    }
    @media all and (max-width: 576px) {
      margin-bottom: 3rem;
    }
  }

  .title__link {
    &:hover {
      color: inherit;
    }
  }

  &__preview {
    margin-top: 1.3rem;

    @media all and (max-width: 576px) {
     margin-top: 1rem;
    }
  }
}


