@import "colors";

.datepicker {
  border-radius: .4rem;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.07);
  border: none;

  &.active {
    transform: translateY(-1rem);
  }

  .datepicker--pointer {
    display: none;
  }
}
.datepicker--days-names {
  display: none;
}

.datepicker--button {
  color: $t-green;
}

.datepicker--cell {
  color: #0C1116;

  &:hover, &.-selected- {
    background: #F0F3F5;
    border: .1rem solid #C8CFD6;
    color: #0C1116;
    &.-focus- {
      background: #F0F3F5;

    }
  }

  &.-weekend- {
    color: $t-green;
  }

  &.-current-{
    background: $t-green;
    border: .1rem solid $t-green;
    color: white;

    &.-selected- {
      background: $t-green;
      border: .1rem solid $t-green;
      color: white;
    }
  }
  &.-other-month- {
    color: transparent;
  }
}
