@import "../colors";

.tank-cleaning {
  &__header {
    display: flex;
    min-height: 70.3rem;
    flex-direction: column;
    align-items: center;
    padding: 0 0 2.5rem;
    background: url('../img/bg-tank-cleaning.jpg') center center/cover no-repeat;

    @media all and (max-width: 480px) {
      background: url('../img/bg-tank-cleaning-m.jpg') center center/cover no-repeat;
    }

    .tank-cleaning__order {
      margin-top: 0;

      .container {
        @media all and (max-width: 576px) {
          padding:  0 1rem;
        }
      }
    }

    .tank-cleaning__order-inner {
      max-width: 109.6rem;
      margin: 0 auto;
      padding: 3.7rem 2rem 5rem;
      min-height: 31.4rem;
      border-radius: .4rem;

      @media all and (max-width: 576px) {
        padding: 2.6rem 3rem 3rem;
      }
    }

    .tank-cleaning__order-title {
      line-height: 4rem;

      @media all and (max-width: 576px) {
        line-height: unset;
      }
    }

    .tank-cleaning__order-form {
      margin-top: 2.7rem;
    }
  }

  &__header-title {
    max-width: 125rem;
    padding: 0 1rem;
    margin: 7.5rem auto 0;
    text-align: center;
    font-family: Oswald, 'Sans Serif', serif;
    font-weight: 500;
    font-size: 5.8rem;
    line-height: 6rem;
    color: white;

    @media all and (max-width: 992px) {
      font-size: 4.6rem;
      line-height: 5rem;
    }

    @media all and (max-width: 768px) {
      margin-top: 5rem;
      font-size: 4rem;
      line-height: 4.6rem;
    }

    @media all and (max-width: 576px) {
      font-size: 3.6rem;
      line-height: 4.2rem;
    }

    @media all and (max-width: 375px) {
      margin-top: 3rem;
      font-size: 3.2rem;
      line-height: 4rem;
    }

    &.title--without-desc {
      margin-bottom: 6.5rem;

      @media all and (max-width: 768px) {
        margin-bottom: 5rem;
      }

      @media all and (max-width: 576px) {
        margin-bottom: 3.5rem;
      }

    }

  }

  &__header-desc {
    max-width: 75.9rem;
    margin: 4.2rem 0 5.6rem;
    padding: 0 1rem;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.1rem;
    text-align: center;
    color: white;

    @media all and (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }


    @media all and (max-width: 375px) {
      max-width: 27.7rem;
      margin: 3rem auto;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }

  &__header-list {
    padding: 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3.6rem auto 5.1rem;
    list-style: none;
    max-width: 74rem;

    @media all and (max-width: 768px) {
      align-items: flex-start;
      margin: 3.6rem auto;
    }

    &-item + &-item {
      margin-top: .9rem;

      @media all and (max-width: 768px) {
       margin-top: 1.5rem;
      }
    }

    &-item {
      display: inline-block;
      position: relative;
      padding-left: 3rem;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 3.1rem;
      color: white;
      text-align: center;

      @media all and (max-width: 768px) {
        padding-left: 2.6rem;
        width: 100%;
        text-align: left;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 1.3rem;
        display: block;
        width: 2rem;
        height: .3rem;
        background: #00BB51;

        @media all and (max-width: 768px) {
          top: 1.2rem;
          width: 1.6rem;
          height: .2rem;
        }
      }

      @media all and (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
      @media all and (max-width: 375px) {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}