@import "colors";

.wrapper--quality-page {
  .content-block {
    &.block--quality {

      @media all and (max-width: 576px) {
        padding-bottom: 4.2rem;
      }
    }
  }
}

.content-quality {
   p {
    margin-bottom: 2.7rem;
   &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    font-weight: 900;
    font-size: 3.2rem;
    line-height: 4.5rem;
    margin-bottom: 2.4rem;

     @media all and (max-width: 576px) {
      font-size: 2.4rem;
      line-height: 3.4rem;
    }
  }

  &__list {
    margin-top: 3.2rem;

    @media all and (max-width: 576px) {
      margin-top: 2rem;
    }

    .list__item {
      position: relative;
      margin-bottom: 2.5rem;
      padding-left: 3.5rem;
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 3.5rem;

      @media all and (max-width: 992px) {
        font-size: 1.9rem;
        line-height: 3rem;
      }
      @media all and (max-width: 576px) {
        padding-left: 3.3rem;
      }

      &::before {
        content: '';
        display: block;
        width: 2rem;
        position: absolute;
        left: 0;
        top: 1.5rem;
        border-bottom: .2rem solid $text-black;

        @media all and (max-width: 576px) {
          width: 1.7rem;
        }
      }
    }
  }
}
