@import "colors";
.main-wrapper {
    &__content {
        width: 100%;
        text-align: center;
        // @media all and (max-width:480px) {
        //     padding-bottom: 12.3rem;
        // }


        &-failed-num {
            display: block;
            font-family: "Oswald", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 30rem;
            line-height: 44.5rem;
            height: 40rem;

            @media all and (max-width:480px) {
                font-weight: 500;
                font-size: 17rem;
                line-height: 25.2rem;
                height: auto;
            }
        }

        &-failed-text {
            display: block;
            font-family: "Lato",sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 3rem;
            line-height: 164.5%;
            @media all and (max-width:480px) {
                font-size: 2.2rem;
            }
        }

        &-failed-link {
            margin-top: 3.8rem;
            display: inline-block;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 1.8rem;
            line-height: 3rem;
            color: white !important;
            background: $t-green;
            border-radius: 3px;
            padding: 1.6rem 2rem;

            @media all and (max-width:480px) {
                margin-top: 2.9rem;
                padding: 1.4rem 2rem;
            }

            &:hover {
                background: #0CA24D;
            }
        }

    }
}