@import '../colors';

.tank-cleaning__steps {
  padding-bottom: 10.6rem;
  background: #f7f7f7;

  @media all and (max-width: 576px) {
    padding-bottom: 5rem;
  }
}

.tabs-process {
  padding: 5.3rem 10.5rem 6.5rem;
  background: white;

  @media all and (max-width: 1260px) {
    padding: 4rem 3.5rem 3.5rem;
  }
  @media all and (max-width: 576px) {
    padding: 3rem 2rem 3.5rem;
  }

  &__tab-panel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tab-btn + &__tab-btn {
    margin-left: 1.4rem;

    @media all and (max-width: 576px) {
      margin-left: 1rem;
    }
  }

  &__tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: .2rem;
    width: 4.4rem;
    height: 4.4rem;
    font-weight: 800;
    font-size: 2.1rem;
    line-height: 3rem;
    color: $t-green;
    border: 2px solid $t-green;
    border-radius: 50%;
    transition: .2s;

    @media all and (max-width: 576px) {
      width: 3.4rem;
      height: 3.4rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    &:hover {
      cursor: pointer;
      background: #E4F1EA;
    }

    &.tab-btn--active {
      color: white;
      background: $t-green;
    }
  }

  &__tab {
    display: none;
    margin-top: 2.6rem;

    @media all and (max-width: 576px) {
      margin-top: 2rem;
    }

    &.tab--active {
      display: block;
    }
  }


  &__step-title {
    display: block;
    max-width: 83.6rem;
    margin: 0 auto;
    font-weight: 800;
    font-size: 4rem;
    line-height: 4.8rem;
    text-align: center;

    @media all and (max-width: 992px) {
      font-size: 3rem;
      line-height: 3.6rem;
    }

    @media all and (max-width: 576px) {
      font-size: 2.4rem;
      line-height: 3rem;
    }
    @media all and (max-width: 375px) {
      max-width: 24rem;
    }
  }

  &__step-desc {
    max-width: 83.6rem;
    margin: 2.4rem auto 0;
    font-size: 2.2rem;
    line-height: 3.7rem;
    text-align: center;

    @media all and (max-width: 992px) {
      font-size: 2rem;
      line-height: 2.6rem;
      margin-top: 1.6rem;
    }

    @media all and (max-width: 576px) {
      font-size: 1.8rem;
      line-height: 3rem;
    }
  }

  &__step-content {
    margin-top: 4.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width: 992px) {
      flex-direction: column;
    }
    @media all and (max-width: 576px) {
      margin-top: 2.3rem;
    }
  }

  &__step-img-wrapper {
    max-width: 46.72%;
    margin-right: 7.6rem;
    min-width: 44rem;

    @media all and (max-width: 1260px) {
      margin-right: 4rem;
    }

    @media all and (max-width: 992px) {
      margin-right: 0;
      max-width: unset;
      min-width: unset;
    }

    @media all and (max-width: 375px) {
      width: calc(100% + 4rem);
    }
  }

  &__step-img {
    vertical-align: top;
    width: 100%;
  }

  &__step-adv {
    flex: 1;

    @media all and (max-width: 992px) {
      margin-top: 2.5rem;
    }
  }

  &__step-adv-item + &__step-adv-item {
    margin-top: 3rem;

    @media all and (max-width: 576px) {
      margin-top: 2.6rem;
    }
  }

  &__step-adv-item {
    min-height: 4.4rem;
    position: relative;
    padding-left: 6.4rem;
    font-size: 2rem;
    line-height: 3rem;

    @media all and (max-width: 576px) {
      padding-left: 0;
      padding-top: 5.4rem;
      text-align: center;
    }

    &::before {
      content: '';
      position: absolute;
      top: .5rem;
      left: 0;
      display: block;
      width: 4.4rem;
      height: 4.4rem;
      border-radius: 50%;
      background: #E4F1EA url('../img/message-send.svg') center center/16px no-repeat;

      @media all and (max-width: 576px) {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__btn-wrapper {
    margin-top: 5.6rem;
    text-align: center;

    @media all and (max-width: 576px) {
      margin-top: 3.6rem;
    }
  }

  &__order-button {
    padding-left: 3.9rem;
    padding-right: 3.9rem;

    @media all and (max-width: 576px) {
      max-width: 24rem;
      white-space: normal;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: center;
      padding-left: 2.9rem;
      padding-right: 2.9rem;
    }
  }
}