@import "../colors";

.tank-cleaning {

  &__tech {
    margin-top: 6.3rem;

    @media all and (max-width: 768px) {
      margin-top: 4rem;
    }
    @media all and (max-width: 375px) {
      margin-top: 3.1rem;
    }

    &-inner {
      background: $t-bg-gray;
      padding: 8.2rem 2.8rem 10.6rem;

      @media all and (max-width: 640px) {
        padding: 5rem 2.8rem 7rem;
      }

      @media all and (max-width: 375px) {
        padding: 3rem 2.8rem 5.6rem;
      }
    }

    &-list {
      max-width: 107rem;
      margin: 7.6rem auto 0;
      display: grid;
      grid-template: auto / repeat(2, 1fr);
      grid-column-gap: 5.2rem;
      grid-row-gap: 6.4rem;

      @media all and (max-width: 900px) {
        grid-column-gap: 3rem;
        grid-row-gap: 5rem;
      }
      @media all and (max-width: 640px) {
        margin-top: 4rem;
        grid-template: auto / 1fr;
      }
      @media all and (max-width: 576px) {
        grid-row-gap: 3.8rem;
      }
      @media all and (max-width: 375px) {
        margin-top: 2.1rem;
      }

      &-item {
        position: relative;
        padding-left: 8rem;
        font-weight: 700;
        font-size: 2.1rem;
        line-height: 3rem;

        @media all and (max-width: 900px) {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }

        @media all and (max-width: 375px) {
          padding-left: unset;
          padding-top: 5rem;
          text-align: center;
        }

        &::before {
          content: '';
          display: block;
          width: 5.4rem;
          height: 5.4rem;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
          background: $t-green url('../img/check-tech.svg') center center / auto no-repeat;

          @media all and (max-width: 375px) {
            width: 4.4rem;
            height: 4.4rem;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

  }
}