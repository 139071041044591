@import "colors";

.sidebar__widget {
  margin-bottom: 5.9rem;

  @media all and (max-width: 768px) {
    max-width: 30rem;
    margin-bottom: 4rem;
  }

  &.widget--news {
    @media all and (max-width: 576px) {
      display: none;
    }
  }

  &.widget--news-filter {
    position: relative;
    padding: 4.1rem 3.4rem 2.1rem;
    background: #F7F7F7;
    border-radius: .4rem;

    @media all and (max-width: 1200px) {
      padding: 3rem 2rem 1rem;
    }
    @media all and (max-width: 768px) {
      padding: 1.4rem 2rem 1.4rem;
      width: 100%;
      max-width: 60rem;
    }

    .widget__show-filter-button {
      display: none;
      position: relative;
      width: 100%;
      background: transparent;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.8rem;
      text-align: left;
      border-radius: 0;
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &::before {
        content: '';
        width: 0;
        height: 0;
        border: .5rem solid transparent;
        border-color: #a5a5a5 transparent transparent;
        position: absolute;
        top: 50%;
        right: 0;

      }

      &.active {
        &::before {
          top: calc(50% - .5rem);
          transform: rotate(180deg);
        }
      }


      @media all and (max-width: 768px) {
        display: inline-block;
      }
    }

    .form-news-filter {

      @media all and (max-width: 768px) {
        margin-top: 2.2rem;
        display: none;
      }
    }

    .form__input-wrapper {
      &.wrapper--search {
        &::before {
          content: '';
          display: block;
          width: 1.7rem;
          height: 1.7rem;
          position: absolute;
          top: calc(50% - .85rem);
          right: 2rem;
          background: url('../img/filter-search.svg') center center/auto no-repeat;
        }
      }

      .select {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      .select-styled {
        border-radius: .4rem;
        border: none;
        box-shadow: 0 .1rem .1rem rgba(0, 62, 98, 0.14), 0 0 .1rem rgba(0, 62, 98, 0.5);

        &:hover {
          box-shadow: 0 .1rem .1rem rgba(4, 36, 53, 0.24), 0 0 .1rem #053049;
        }
      }

      &.input-wrapper--inline {
        margin-top: 3.6rem;
        display: flex;
        justify-content: space-between;

        @media all and (max-width: 480px) {
           margin-top: 2.1rem;
          margin-bottom: 2.1rem;
        }

        @media all and (max-width: 375px) {
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }

        .form__select-wrapper {
          flex: 1;
          max-width: 15.4rem;

          @media all and (max-width: 1200px) {
            max-width: 48%;
          }
          @media all and (max-width: 375px) {
            flex: unset;
            max-width: 100%;
            width: 100%;
            margin-bottom: 1.1rem;
          }

          &:last-child {
            @media all and (max-width: 375px) {
              margin-bottom: 0;
            }
          }
        }

        .select + .select {
          @media all and (max-width: 375px) {
            margin-top: 1.1rem;
          }
        }

        .select-styled {
          font-size: 1.5rem;
          line-height: 2rem;
          border-radius: .4rem;
          border: none;
          box-shadow: 0 .1rem .1rem rgba(46, 46, 46, 0.14), 0 0 .1rem rgba(46, 46, 46, 0.5);

          &:hover {
            box-shadow: 0 .1rem .1rem rgba(46, 46, 46, 0.24), 0 0 .1rem #053049;
          }
        }
      }
    }

    .form__button-wrapper {
      display: none;
      margin: 3.6rem 0 2rem;

      @media all and (max-width: 768px) {
        margin: 3.1rem 0 1.7rem;
      }
    }

    .form__clear-filter-button {
      position: relative;
      background: transparent;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      padding: .2rem 0 0 3.3rem;
      width: 15.8rem;
      height: 3.9rem;
      border: .1rem solid $t-green;
      border-radius: .4rem;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 2.2rem;
      color: $t-green;
      cursor: pointer;
      transition: .15s;

      &:hover {
        border: .1rem solid $t-green;
      }
      &:focus {
        outline: none;
      }

      &::before {
        content: '';
        display: block;
        width: 1.7rem;
        height: 1.7rem;
        position: absolute;
        top: calc(50% - .75rem);
        left: 1.3rem;
        background: url('../img/clear-filter.svg') center center/auto no-repeat;
      }
    }

    .form__input {
      box-shadow: 0 .1rem .1rem rgba(145, 145, 145, 0.2), 0 0 .1rem rgba(145, 145, 145, 0.2);
      border: .1rem solid transparent;
      transition: .15s;

      &::placeholder {
        color: #939393;
      }

      &.input--search {
        padding-right: 4rem;

        &:focus {
          border: .1rem solid #929292;
          box-shadow: 0 .3rem .5rem rgba(137, 146, 157, 0.2), 0 0 .4rem rgba(137, 146, 157, 0.2);
        }
      }
    }
  }

  &.widget--gallery {
    @media all and (max-width: 576px) {
      max-width: unset;
      width: 100%;
      margin-bottom: 0;
    }

    .photo__img {
      width: 100%;
    }
  }

  &.widget--project-info {
    font-size: 1.8rem;
    line-height: 3rem;
    margin-bottom: 5rem;

    @media all and (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.5rem;
      margin-bottom: 3rem;
    }

    .widget__title {
      font-family: 'Lato', 'Sans Serif';
      font-weight: 900;
      font-size: 2rem;
      line-height: 3.3rem;
      margin-top: 5rem;

      @media all and (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 3rem;
        margin-top: 2rem;
      }

      &:first-child {
        margin-top: 0;
      }
    }
    .project-info__text {
      margin-top: 1.1rem;
    }
    .project-info__website {
      font-weight: 600;
      border-bottom: .1rem solid $text-black;

      &:hover {
        color: $t-green;
        border-bottom-color: $t-green;
      }
    }
  }

  &.widget--document {
    margin: 0 0 1.7rem;

    @media all and (max-width: 768px) {
      margin: 0 0 1.9rem;
      min-width: 100%;
    }

    .widget__icon-document {
      margin-right: 1.5rem;
    }
    .widget__info-document {
      margin-bottom: .3rem;
    }
    .info-document__title {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    .info-document__size {
      margin-top: .6rem;
      border-bottom: none;
    }
  }

  &.widget--contacts-info {
    max-width: 22.7rem;

    @media all and (max-width: 768px) {
      margin-right: auto;
    }
  }

  &.widget--links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &.widget--photo {
    position: sticky;
    top: 2rem;

    .widget__gallery-link {
      &:last-child {
        .widget__gallery-photo {
          margin-bottom: 0;
        }
      }
    }

    .widget__gallery-photo {
      &.selected {
        outline: .3rem solid $t-green;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .widget__title {
    margin-bottom: 1rem;
    font-family: 'Oswald', 'Sans Serif';
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 4.9rem;
  }

  .widget__text {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }

  .widget__email-link {
    display: inline-block;
    font-size: 2.2rem;
    line-height: 2.5rem;
    margin-top: .5rem;
    margin-bottom: 3.4rem;
    border-bottom: 1px solid $t-link-black;

    @media all and (max-width: 576px) {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 2.5rem;
    }

    &:hover {
      color: $t-green;
      border-bottom: 1px solid $t-green;
    }
  }

  .widget__phone-link {
    font-size: 2.2rem;
    line-height: 3.7rem;
    @media all and (max-width: 576px) {
      font-size: 2rem;
      line-height: 3.3rem;
    }
  }


  .widget__photo {
    margin-top: 1rem;

    .photo__img {
      vertical-align: top;
      max-width: 100%;
    }

    .photo__caption {
      margin-top: 1.2rem;
      font-size: 1.4rem;
      line-height: 2.3rem;
      color: #29394B;
    }
  }

  .widget__gallery-photo {
    vertical-align: top;
    margin-bottom: 2rem;
    max-width: 100%;
  }

  .widget__link {
    display: inline-block;
    border-bottom: 1px solid $t-link-black;
    font-weight: 600;
    margin-bottom: 2.3rem;
    line-height: 1.9rem;

    &:hover {
      color: $t-green;
      border-bottom-color: $t-green;
    }


    &.link--current {
      color: $text-black;
      border-bottom: 1px solid $text-black;

      &:hover {
        color: $t-green;
        border-bottom-color: $t-green;
      }
    }
  }

  .widget__news-link {
    display: inline-block;
    margin-top: 1.2rem;
    position: relative;
    font-size: 1.6rem;
    line-height: 2rem;
    border-bottom: 1px solid $t-link-black;
    transition: .2s;

    &::after {
      content: '';
      display: block;
      width: 2.6rem;
      height: 2.6rem;
      position: absolute;
      top: calc(50% - 1.3rem);
      right: -3.6rem;
      border-radius: 50%;
      background: $t-green url('../img/arrow-all-sidebar.svg') center center no-repeat;

    }

    &:hover {
      color: $t-green;
      border-bottom: 1px solid $t-green;
    }
  }

  .widget__date {
    display: block;
    margin-top: 1.6rem;
    font-size: 1.3rem;
    line-height: 1.9rem;
    color: $title-black;
    opacity: .5;
  }

  .widget__news-title {
    display: inline-block;
    margin-top: .5rem;
    font-weight: bold;
    font-size: 1.9rem;
    line-height: 2.5rem;

    a {
      transition: .2s;
    }

    &:hover {
      a {
        color: $t-green;
      }
      .underline {
        border-bottom: 1px solid $t-green;
      }
    }
  }

  .widget__news-desc {
    margin: 1.4rem 0 3rem;
    font-size: 1.4rem;
    line-height: 2.3rem;
    color: #29394B;
  }
}
