.services__header {
  display: flex;
  min-height: 70.3rem;
  flex-direction: column;
  align-items: center;
  padding: 0 0 2.5rem;

  &--energetics {
    min-height: 67.4rem;
    background: url('../img/bg-energetics.jpg') center center/cover no-repeat;

    @media all and (max-width: 375px) {
      background: url('../img/bg-energetics-m.jpg') center center/cover no-repeat;
    }
  }

  .services__order {
    margin-top: 0;

    .container {
      @media all and (max-width: 576px) {
        padding: 0 1rem;
      }
    }
  }

  .services__order-inner {
    max-width: 109.6rem;
    margin: 0 auto;
    padding: 3.7rem 2rem 5rem;
    min-height: 31.4rem;
    border-radius: .4rem;

    @media all and (max-width: 576px) {
      padding: 2.6rem 3rem 3rem;
    }
  }

  .services__order-title {
    line-height: 4rem;

    @media all and (max-width: 576px) {
      line-height: unset;
    }
  }

  .services__order-form {
    margin-top: 2.7rem;
  }


  &-title {
    max-width: 125rem;
    padding: 0 1rem;
    margin: 7.5rem auto 0;
    text-align: center;
    font-family: Oswald, 'Sans Serif', serif;
    font-weight: 500;
    font-size: 5.8rem;
    line-height: 6rem;
    color: white;

    @media all and (max-width: 992px) {
      font-size: 4.6rem;
      line-height: 5rem;
    }

    @media all and (max-width: 768px) {
      margin-top: 5rem;
      font-size: 4rem;
      line-height: 4.6rem;
    }

    @media all and (max-width: 576px) {
      font-size: 3.6rem;
      line-height: 4.2rem;
    }

    @media all and (max-width: 375px) {
      margin-top: 3rem;
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  &-desc {
    max-width: 75.9rem;
    margin: 4.2rem 0 5.6rem;
    padding: 0 1rem;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.1rem;
    text-align: center;
    color: white;

    @media all and (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }


    @media all and (max-width: 375px) {
      max-width: 27.7rem;
      margin: 3rem auto;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }


}