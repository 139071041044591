.detail-work {
  padding: 3.4rem 0 5rem;
  background: #F7F7F7;

  @media all and (max-width: 576px) {
    padding: 3.4rem 0 2.7rem;
  }

  &__desc {
    margin: 3.8rem auto 0;
    max-width: 100rem;
    font-size: 2.2rem;
    line-height: 3.5rem;
    text-align: center;

    @media all and (max-width: 576px) {
      margin-top: 2rem;
      padding: 0 1rem;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }

    p {
      @media all and (max-width: 576px) {
        margin-top: 1.5rem;
      }
    }
  }

  &__grid {
    margin-top: 3.8rem;
    display: flex;
    justify-content: center;

    @media all and (max-width: 768px) {
      flex-direction: column;
      max-width: 64rem;
      margin: 3rem auto 0;
    }

     @media all and (max-width: 576px) {
      margin-top: 2.3rem;
      padding: 0 1rem;
    }
  }

  &__grid-item + &__grid-item {
    margin-left: 2rem;

    @media all and (max-width: 768px) {
      margin-left: 0;
      margin-top: 2rem;
    }
    @media all and (max-width: 576px) {
      margin-top: 1rem;
    }
  }

  &__grid-item {
    padding: 1rem 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 10.4rem;
    font-size: 2.2rem;
    line-height: 3.5rem;
    text-align: center;
    background: white;
    box-shadow: 0 4rem 3rem -3rem rgba(31, 31, 31, 0.09);

    @media all and (max-width: 576px) {
      min-height: 10rem;
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }

  &__grid-item-value {
    display: block;
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 3.5rem;

    @media all and (max-width: 768px) {
      margin-bottom: .3rem;
    }
  }
}