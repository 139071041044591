@import "../colors";

.tank-cleaning__advantages {
  padding: 6rem 0 12.5rem;
  background: #f7f7f7;

  @media all and (max-width: 768px) {
    padding: 4rem 0 6rem;
  }
  @media all and (max-width: 576px) {
    padding: 3rem 0 6rem;
  }

  .tank-cleaning__block-title {
    @media all and (max-width: 576px) {
      text-align: left;
      padding: 0 2rem;
    }
  }

  &-grid {
    margin-top: 6.3rem;
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 2.1rem;

    @media all and (max-width: 768px) {
      margin-top: 4rem;
      column-gap: 2rem;
    }

    @media all and (max-width: 576px) {
      margin-top: 2.3rem;
      grid-template: auto / 1fr;
      row-gap: 1.1rem;
    }
  }

  &-item {
    margin: 0 auto;
    width: 100%;
    max-width: 61rem;
    padding: 3.8rem 0 4.1rem;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.5rem;
    background: white;
    box-shadow: 0 3rem 3rem -2rem rgba(31,31,31,.09);

    @media all and (max-width: 768px) {
      padding: 2.2rem 0 1.8rem;
      font-size: 2rem;
      line-height: 3.2rem;
    }
    @media all and (max-width: 576px) {
      font-size: 1.8rem;
      line-height: 3rem;
    }

    &:last-child:nth-of-type(odd) {
     grid-column: span 2;

      @media all and (max-width: 576px) {
        grid-column: unset;
      }
    }
  }

  &-desc {
    padding: 0 5.3rem;
    border-left: .3rem solid $t-green;

    @media all and (max-width: 768px) {
      padding: 0 3rem 0 2.7rem;
    }
  }
}