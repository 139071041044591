@import "colors";

.bread-crumbs {
  margin-top: 4.2rem;
  color: $t-text-gray;

  @media all and (max-width: 576px) {
    margin-top: 3.5rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    &:last-of-type {
      .bread-crumbs__link {
        margin-right: 0;
      }
    }
  }

  &__link {
    position: relative;
    color: $t-text-gray;
    font-size: 1.5rem;
    line-height: 2.3rem;
    border-bottom: 1px solid $t-text-gray;
    margin-right: 2rem;

    @media all and (max-width: 576px) {
      font-size: 1.4rem;
    }


    &:hover {
      color: $t-link-black;
      border-bottom: 1px solid $t-link-black;
    }

    &::after {
      content: '/';
      bottom: -.5rem;
      right: -1.5rem;
      position: absolute;
      text-decoration: none;
    }
  }
}

.search-result {
  .bread-crumbs__item:last-child {
    .bread-crumbs__link {
      &::after {
        display: none;
      }
    }
  }
}
