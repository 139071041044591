@import "colors";

.content-block {
  &.block--documentation {

    @media all and (max-width: 480px) {
      padding-bottom: 1.5rem;
    }
  }
}

.content-documentation__documents-list {
  border-right: 1px solid #EBEDEF;

  @media all and (max-width: 768px) {
    border: none;
  }

  .documents-list__item {
    width: calc(100% + 4rem);
    margin-left: -4rem;
    padding: 3.4rem 2rem 3.6rem 4rem;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #EBEDEF;


    @media all and (max-width: 768px) {
      padding: 3.5rem 2rem 3.8rem;
      margin-left: -2rem;
    }

    @media all and (max-width: 480px) {
      flex-direction: column;
    }

    &:first-of-type {
      padding-top: 2rem;
    }

    .item__documents__img {
      max-width: 16.2rem;
      flex-basis: 16.2rem;
      vertical-align: top;
      margin-right: 4rem;

      @media all and (max-width: 992px) {
        margin-right: 2rem;
      }
    }

    .item__documents-info {
      flex: 1;

      @media all and (max-width: 480px) {
        margin-top: 3rem;
      }
    }

    .item__documents-title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.4rem;

      @media all and (max-width: 768px) {
        font-size: 2rem;
        line-height: 2.6rem;
      }
    }

    .item__documents-text {
      margin-top: 2.1rem;
      max-width: 60.8rem;
      font-size: 1.6rem;
      line-height: 2.8rem;

      @media all and (max-width: 768px) {
        line-height: 2.5rem;
      }
    }

    .content__widget.widget--document {
      .info-document__title {
        font-weight: 600;
        font-size: 1.6rem;
      }

    }
  }
}
