
.tanc-cleaning__building-features {
  padding: 10.2rem 0;

  @media all and (max-width: 992px) {
    padding: 6rem 0;
  }

  @media all and (max-width: 576px) {
    padding: 2.5rem 0 4.5rem;
  }
}

.building-features {
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }

  .tank-cleaning__block-title {
    text-align: left;
  }

  &__photo {
    margin-left: 3rem;
    width: 50rem;
    order: 2;

    @media all and (max-width: 992px) {
      width: unset;
      max-width: 64rem;
      margin-left: 0;
      order: unset;
    }

    img {
      width: 100%;
      vertical-align: top;

      @media all and (max-width: 992px) {
        height: 50rem;
        object-fit: cover;
      }

      @media all and (max-width: 768px) {
        height: unset;
      }
    }

  }

  &__info {
    max-width: 71.6rem;
    order: 1;

    @media all and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2.5rem;
      order: unset;
      max-width: 64rem;
      text-align: center;
    }

    @media all and (max-width: 576px) {
      align-items: flex-start;
      text-align: left;
    }

    @media all and (max-width: 375px) {
      padding: 0 1.3rem;
    }

    .tank-cleaning__block-title {
      @media all and (max-width: 992px) {
        text-align: center;
      }
      @media all and (max-width: 576px) {
        text-align: left;
      }
    }
  }

  &__desc {
    margin-top: 4rem;
    font-size: 1.8rem;
    line-height: 3rem;

    @media all and (max-width: 576px) {
      margin-top: 2rem;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }

  &__button {
    margin-top: 3rem;
    padding-right: 4.1rem;
    padding-left: 4.1rem;

    @media all and (max-width: 576px) {
      margin-top: 2.1rem;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }

  &__modal-content {
    display: none;
  }
}