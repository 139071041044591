
.main-grid__social-share {

  .ya-share2__item + .ya-share2__item{
     margin-left: .9rem;

    @media all and (max-width: 576px) {
      margin-left: .6rem;
    }
  }

  .ya-share2__badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;

    @media all and (max-width: 576px) {
      width: 3.5rem;
      height: 3.5rem;
    }

    .ya-share2__icon {
      height: 2.8rem;
      width: 2.8rem;
      background-position: center center;
      background-repeat: no-repeat;

      @media all and (max-width: 576px) {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
  }

  .ya-share2__item_service_vkontakte {
    .ya-share2__badge {
      .ya-share2__icon {
        margin-top: .1rem;
      }
    }
  }

  .ya-share2__item_service_odnoklassniki {
    .ya-share2__badge {
      .ya-share2__icon {
        height: 3rem;
        width: 2.8rem;
      }
    }
  }
}
