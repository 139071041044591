.main-wrapper__content {
  width: 100%;
  text-align: center;
}
.main-wrapper__content-failed-num {
  display: block;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30rem;
  line-height: 44.5rem;
  height: 40rem;
}
@media all and (max-width: 480px) {
  .main-wrapper__content-failed-num {
    font-weight: 500;
    font-size: 17rem;
    line-height: 25.2rem;
    height: auto;
  }
}
.main-wrapper__content-failed-text {
  display: block;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 164.5%;
}
@media all and (max-width: 480px) {
  .main-wrapper__content-failed-text {
    font-size: 2.2rem;
  }
}
.main-wrapper__content-failed-link {
  margin-top: 3.8rem;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 3rem;
  color: white !important;
  background: #00923F;
  border-radius: 3px;
  padding: 1.6rem 2rem;
}
@media all and (max-width: 480px) {
  .main-wrapper__content-failed-link {
    margin-top: 2.9rem;
    padding: 1.4rem 2rem;
  }
}
.main-wrapper__content-failed-link:hover {
  background: #0CA24D;
}
.wrapper--about-page .bread-crumbs {
  margin-top: 0;
}
.wrapper--about-page .bread-crumbs .bread-crumbs__link {
  color: white;
  border-bottom: 1px solid white;
}

.business-direction__title {
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 3.8rem;
  line-height: 6.6rem;
}
@media all and (max-width: 768px) {
  .business-direction__title {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
}

.business-direction__grid {
  margin-top: 3.4rem;
  display: grid;
  grid-template: auto/repeat(2, 1fr);
  grid-gap: 3.5rem;
}
@media all and (max-width: 768px) {
  .business-direction__grid {
    grid-gap: 2rem;
  }
}
@media all and (max-width: 768px) {
  .business-direction__grid {
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 375px) {
  .business-direction__grid {
    grid-gap: 1.8rem;
  }
}

.business-direction__item {
  padding: 3.7rem 4.1rem 4.7rem;
  color: white;
  border-radius: 0.3rem;
  box-shadow: 0 8rem 10rem rgba(0, 0, 0, 0.08);
}
@media all and (max-width: 768px) {
  .business-direction__item {
    padding: 3rem;
  }
}
@media all and (max-width: 375px) {
  .business-direction__item {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    padding: 1.5rem 2rem;
  }
}
.business-direction__item.item--project {
  background: linear-gradient(199.48deg, #00A346 -0.33%, #00873A 103.3%);
}
.business-direction__item.item--building {
  background: linear-gradient(196.5deg, #007CC7 -6.23%, #0072B8 101.4%);
}
.business-direction__item.item--with-img {
  padding: 0 0 2.7rem 0;
  color: #000000;
}
.business-direction__item.item--with-img .item__title {
  margin-top: 2.1rem;
  padding: 0 4rem;
}
@media all and (max-width: 768px) {
  .business-direction__item.item--with-img .item__title {
    padding: 0 3rem;
  }
}
@media all and (max-width: 576px) {
  .business-direction__item.item--with-img .item__title {
    padding: 0 2rem;
    margin-top: 1.4rem;
  }
}
.business-direction__item.item--with-img .item__desc {
  margin-top: 1.7rem;
  padding: 0 4rem;
}
@media all and (max-width: 768px) {
  .business-direction__item.item--with-img .item__desc {
    padding: 0 3rem;
  }
}
@media all and (max-width: 576px) {
  .business-direction__item.item--with-img .item__desc {
    padding: 0 2rem;
  }
}
.business-direction__item .item__img {
  vertical-align: top;
  width: 100%;
  min-height: 18rem;
  object-fit: cover;
}
.business-direction__item .item__title {
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4rem;
}
@media all and (max-width: 768px) {
  .business-direction__item .item__title {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
.business-direction__item .item__desc {
  margin-top: 2rem;
}
.additional-info {
  padding: 4.3rem 0 5.4rem;
  background: #00923F;
  color: white;
}
@media all and (max-width: 576px) {
  .additional-info {
    padding: 1.9rem 0 3.1rem;
  }
}
.additional-info__content {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 576px) {
  .additional-info__content {
    flex-direction: column;
  }
}
.additional-info__text-info {
  max-width: 69.2rem;
  margin-right: 2rem;
}
@media all and (max-width: 576px) {
  .additional-info__text-info {
    margin-right: unset;
  }
}
.additional-info__title {
  display: inline-block;
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 3.8rem;
  line-height: 6.6rem;
}
@media all and (max-width: 768px) {
  .additional-info__title {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
}
.additional-info__desc {
  margin-top: 1.6rem;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 768px) {
  .additional-info__desc {
    margin-top: 2.2rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.additional-info__consult-button {
  position: relative;
  margin-top: 3.8rem;
  padding: 0 4.6rem 0 2rem;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #00923F;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.1);
}
.additional-info__consult-button::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  right: 2rem;
  background: url("../img/arrow-add-info.svg") center center/contain no-repeat;
}
.additional-info__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4.6rem 0 2rem;
  background: white;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #00923F;
  height: 5.6rem;
  margin-top: 3.8rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.1);
}
.additional-info__link:hover {
  color: #00923F;
}
@media all and (max-width: 768px) {
  .additional-info__link {
    margin-top: 2.5rem;
  }
}
.additional-info__link.link-career {
  width: 16.7rem;
  color: #007D36;
}
.additional-info__link::after {
  content: "";
  display: block;
  width: 1rem;
  height: 1.5rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  right: 2rem;
  background: url("../img/arrow-add-info.svg") center center/contain no-repeat;
}
.additional-info__buttons-block {
  display: flex;
  align-items: flex-end;
}
@media all and (max-width: 992px) {
  .additional-info__buttons-block {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 640px) {
  .additional-info__buttons-block {
    flex-direction: column;
    align-items: flex-start;
  }
}
.additional-info__resume-block {
  display: flex;
  margin-left: 2rem;
  align-items: center;
}
@media all and (max-width: 992px) {
  .additional-info__resume-block {
    margin-top: 1.8rem;
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  .additional-info__resume-block {
    flex-direction: column;
  }
}
.additional-info__resume-button.button {
  height: 5.6rem;
  border: 0.2rem solid white;
  background: transparent;
  font-weight: 600;
  color: white;
  width: 21.2rem;
}
.additional-info__resume-formats {
  display: inline;
  margin: 0 0 0 2rem;
  width: 18rem;
}
@media all and (max-width: 640px) {
  .additional-info__resume-formats {
    margin: 1rem 0 0 0;
    width: 19rem;
  }
}
.additional-info__contacts {
  width: 28.8rem;
}
@media all and (max-width: 576px) {
  .additional-info__contacts {
    margin-top: 2.5rem;
  }
}
.additional-info__contacts .contacts__phone-link {
  display: block;
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4.9rem;
}
@media all and (max-width: 768px) {
  .additional-info__contacts .contacts__phone-link {
    font-size: 2rem;
    line-height: 3.5rem;
  }
}
.additional-info__contacts .contacts__text {
  max-width: 22rem;
  margin-top: 0.7em;
  line-height: 2.8rem;
}
@media all and (max-width: 768px) {
  .additional-info__contacts .contacts__text {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
@media all and (max-width: 576px) {
  .additional-info__contacts .contacts__text {
    display: none;
  }
}
.additional-info__subscribe {
  margin-top: 4rem;
}
@media all and (max-width: 420px) {
  .additional-info__subscribe {
    margin-top: 3rem;
  }
}
.additional-info__subscribe .subscribe__form {
  max-width: 56.5rem;
}
.additional-info__subscribe .subscribe__form .form__input {
  padding: 1.4rem 16rem 1.4rem 3rem;
  background: rgba(8, 183, 84, 0.7);
  font-family: "Lato", "Sans Serif";
  color: white;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3rem;
  height: 5.5rem;
  border: none;
}
@media all and (max-width: 420px) {
  .additional-info__subscribe .subscribe__form .form__input {
    width: 100%;
    margin-left: 0;
    padding: 1.4rem 2rem 1.4rem;
  }
}
.additional-info__subscribe .subscribe__form .form__input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  opacity: 1;
}
.additional-info__subscribe .subscribe__form .form__button-subscribe {
  width: 15rem;
  height: 5.5rem;
  background: white;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #00923F;
  border-radius: 0.3rem;
}
@media all and (max-width: 420px) {
  .additional-info__subscribe .subscribe__form .form__button-subscribe {
    position: relative;
    margin-top: 1rem;
  }
}
@charset "UTF-8";
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 100;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: rgb(30, 30, 30);
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 101;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 102;
}

.fancybox-slide::before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 104;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

/* Fix IE11 */
.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right);
}

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 103;
}

.fancybox-caption--separate {
  margin-top: -50px;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}
/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
}
/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: 0.5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.datepicker--cells {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  z-index: 1;
}

.datepicker--cell.-focus- {
  background: #f0f0f0;
}

.datepicker--cell.-current- {
  color: #4EB5E6;
}

.datepicker--cell.-current-.-focus- {
  color: #4a4a4a;
}

.datepicker--cell.-current-.-in-range- {
  color: #4EB5E6;
}

.datepicker--cell.-in-range- {
  background: rgba(92, 196, 239, 0.1);
  color: #4a4a4a;
  border-radius: 0;
}

.datepicker--cell.-in-range-.-focus- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell.-disabled- {
  cursor: default;
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}

.datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}

.datepicker--cell.-range-from- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 4px 0 0 4px;
}

.datepicker--cell.-range-to- {
  border: 1px solid rgba(92, 196, 239, 0.5);
  background-color: rgba(92, 196, 239, 0.1);
  border-radius: 0 4px 4px 0;
}

.datepicker--cell.-selected-, .datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #5cc4ef;
}

.datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px;
}

.datepicker--cell.-selected- {
  border: none;
}

.datepicker--cell.-selected-.-focus- {
  background: #45bced;
}

.datepicker--cell:empty {
  cursor: default;
}

.datepicker--days-names {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}

.datepicker--day-name {
  color: #FF9A19;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}

.-only-timepicker- .datepicker--content, .datepicker--body, .datepicker-inline .datepicker--pointer {
  display: none;
}

.datepicker--cell-day {
  width: 14.28571%;
}

.datepicker--cells-months {
  height: 170px;
}

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

.datepicker--cells-years, .datepicker--years {
  height: 170px;
}

.datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}

.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}

@media print {
  .datepickers-container {
    display: none;
  }
}
.datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  z-index: 100;
}

.datepicker.-from-top- {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.datepicker.-from-right- {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}

.datepicker.-from-bottom- {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

.datepicker.-from-left- {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}

.datepicker.active {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
}

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.datepicker--content {
  box-sizing: content-box;
  padding: 4px;
}

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
}

.datepicker--nav-action:hover, .datepicker--nav-title:hover {
  background: #f0f0f0;
}

.-top-center- .datepicker--pointer, .-top-left- .datepicker--pointer, .-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.-right-bottom- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-top- .datepicker--pointer {
  right: calc(100% - 4px);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.-bottom-center- .datepicker--pointer, .-bottom-left- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.-left-bottom- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-top- .datepicker--pointer {
  left: calc(100% - 4px);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.-bottom-left- .datepicker--pointer, .-top-left- .datepicker--pointer {
  left: 10px;
}

.-bottom-right- .datepicker--pointer, .-top-right- .datepicker--pointer {
  right: 10px;
}

.-bottom-center- .datepicker--pointer, .-top-center- .datepicker--pointer {
  left: calc(50% - 5px);
}

.-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
  top: 10px;
}

.-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
  bottom: 10px;
}

.-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
  top: calc(50% - 5px);
}

.datepicker--body.active {
  display: block;
}

.datepicker--nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px;
}

.-only-timepicker- .datepicker--nav {
  display: none;
}

.datepicker--nav-action, .datepicker--nav-title {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker--nav-action.-disabled- {
  visibility: hidden;
}

.datepicker--nav-action svg {
  width: 32px;
  height: 32px;
}

.datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px;
}

.datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px;
}

.datepicker--buttons, .datepicker--time {
  border-top: 1px solid #efefef;
  padding: 4px;
}

.datepicker--nav-title i {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 5px;
}

.datepicker--nav-title.-disabled- {
  cursor: default;
  background: 0 0;
}

.datepicker--buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.datepicker--button {
  color: #4EB5E6;
  cursor: pointer;
  border-radius: 4px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
}

.datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}

.datepicker--time {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.datepicker--time.-am-pm- .datepicker--time-sliders {
  -webkit-flex: 0 1 138px;
  -ms-flex: 0 1 138px;
  flex: 0 1 138px;
  max-width: 138px;
}

.-only-timepicker- .datepicker--time {
  border-top: none;
}

.datepicker--time-sliders {
  -webkit-flex: 0 1 153px;
  -ms-flex: 0 1 153px;
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}

.datepicker--time-label {
  display: none;
  font-size: 12px;
}

.datepicker--time-current {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.datepicker--time-current-hours, .datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}

.datepicker--time-current-hours:after, .datepicker--time-current-minutes:after {
  content: "";
  background: #f0f0f0;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0;
}

.datepicker--time-current-hours.-focus-:after, .datepicker--time-current-minutes.-focus-:after {
  opacity: 1;
}

.datepicker--time-current-ampm {
  text-transform: uppercase;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}

.datepicker--time-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
}

.datepicker--time-row:first-child {
  margin-bottom: 4px;
}

.datepicker--time-row input[type=range] {
  background: 0 0;
  cursor: pointer;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}

.datepicker--time-row input[type=range]::-ms-tooltip {
  display: none;
}

.datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:hover::-moz-range-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:hover::-ms-thumb {
  border-color: #b8b8b8;
}

.datepicker--time-row input[type=range]:focus {
  outline: 0;
}

.datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type=range]:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}

.datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
  margin-top: -6px;
}

.datepicker--time-row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  transition: background 0.2s;
}

.datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: 0 0;
}

.datepicker--time-row input[type=range]::-moz-range-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: 0 0;
}

.datepicker--time-row input[type=range]::-ms-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: 0 0;
}

.datepicker--time-row input[type=range]::-ms-fill-lower {
  background: 0 0;
}

.datepicker--time-row input[type=range]::-ms-fill-upper {
  background: 0 0;
}

.datepicker--time-row span {
  padding: 0 12px;
}

.datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em;
}

.datepicker--time-icon:after, .datepicker--time-icon:before {
  content: "";
  background: currentColor;
  position: absolute;
}

.datepicker--time-icon:after {
  height: 0.4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.datepicker--time-icon:before {
  width: 0.4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px);
}

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}

.-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}

.-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}

.-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}

.-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #ccc;
}

.-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}

.datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
  background: 0 0;
  border: none;
}

.iti {
  position: relative;
  display: inline-block;
}

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.iti__country {
  padding: 5px 10px;
  outline: none;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/flags/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../img/flags/flags@2x.png");
  }
}
.iti__flag.iti__np {
  background-color: transparent;
}

.iti__flag {
  background-image: url("../img/flags/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../img/flags/flags@2x.png");
  }
}
html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
}

body.no-scroll {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: sticky;
}

button:focus {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: #000000;
}
a:hover {
  color: #000000;
}

ul {
  list-style: none;
}

.main-wrapper.wrapper--single-page {
  padding-top: 0.01rem;
}

.container {
  width: 131rem;
  margin: 0 auto;
  padding: 0 3rem;
}
@media all and (max-width: 1310px) {
  .container {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  .container {
    padding: 0 2rem;
  }
}
.container--tank-cleaning, .container--services {
  width: 131rem;
  margin: 0 auto;
}
@media all and (max-width: 1310px) {
  .container--tank-cleaning, .container--services {
    width: 100%;
  }
}
@media all and (max-width: 375px) {
  .container--tank-cleaning, .container--services {
    padding: 0 1rem;
  }
}
.container--tank-cleaning-wide {
  width: 146rem;
  margin: 0 auto;
}
@media all and (max-width: 1460px) {
  .container--tank-cleaning-wide {
    width: 100%;
  }
}
@media all and (max-width: 375px) {
  .container--tank-cleaning-wide {
    padding: 0 1rem;
  }
}

.container-tank-cleaning {
  padding: 0;
  width: 144rem;
  margin: 0 auto;
}
@media all and (max-width: 1440px) {
  .container-tank-cleaning {
    width: 100%;
  }
}

.container .responsive-img {
  width: 100%;
}

.text-break {
  display: block;
}

.text-nowrap {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  border-bottom: 1px solid #09121C;
}
.underline.underline--white {
  border-bottom: 1px solid white;
}
.underline.underline--gray {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}
.underline.underline--blue {
  border-bottom: 1px solid #017DC3;
}
.underline.underline--dashed {
  border-bottom: 1px dashed #818181;
}

.no-style-link {
  color: inherit;
}
.no-style-link:hover {
  color: inherit;
}

.button {
  background: white;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3rem;
  height: 5.6rem;
  border: none;
  border-radius: 0.3rem;
  white-space: nowrap;
  cursor: pointer;
  color: #00923F;
  transition: 0.2s;
}
.button:focus {
  outline: none;
}
.button:disabled {
  color: #00923F;
}
.button.button--invert {
  background: #00923F;
  color: white;
}
.button.button--invert:hover {
  background: #0CA24D;
}
.button.button--invert:disabled {
  color: rgba(255, 255, 255, 0.5);
}
.button.button--invert:disabled:hover {
  background: #00923F;
}

.form__input-outer {
  position: relative;
}
.form__input-outer .iti {
  width: 100%;
}
.form__input-outer.field--invalid::after {
  content: "!";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.8rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2.4rem;
  line-height: 4rem;
  color: white;
  background: #C71F1F;
  border-radius: 0 0.4rem 0.4rem 0;
}
.form__input-outer.field--invalid .field--requared {
  border: 0.2rem solid #C71F1F;
}
.form__input-outer.field--invalid .field--requared + .calendar-icon {
  border: 0.1rem solid #C71F1F;
}
.form__input-outer.field--invalid + .form__error-msg {
  display: inline-block;
}
.form__input-outer.field--invalid + .select-styled {
  border: 0.1rem solid #C71F1F;
}

.form__input-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}
@media all and (max-width: 576px) {
  .form__input-wrapper {
    margin-bottom: 1.6rem;
  }
}
.form__input-wrapper.input-wrapper--radio, .form__input-wrapper.input-wrapper--checkbox {
  display: flex;
  justify-content: space-between;
  border-radius: 0.4rem;
  border: 0.1rem solid #C8CFD6;
  align-self: end;
}
.form__input-wrapper.input-wrapper--radio-gender {
  position: relative;
}
@media all and (max-width: 576px) {
  .form__input-wrapper.input-wrapper--radio-gender.field--invalid {
    margin-bottom: 2rem;
  }
}
.form__input-wrapper.input-wrapper--radio-gender.field--invalid .form__error-msg {
  display: block;
}
.form__input-wrapper.input-wrapper--radio-gender .form__error-msg {
  position: absolute;
  top: 5.9rem;
}
@media all and (max-width: 576px) {
  .form__input-wrapper.input-wrapper--radio-gender .form__error-msg {
    top: 4.5rem;
  }
}
.form__input-wrapper.input-wrapper--marital-status {
  margin-bottom: 4rem;
}
@media all and (max-width: 768px) {
  .form__input-wrapper.input-wrapper--marital-status {
    display: none;
  }
}
.form__input-wrapper.input-wrapper--marital-status-select {
  display: none;
}
@media all and (max-width: 768px) {
  .form__input-wrapper.input-wrapper--marital-status-select {
    display: block;
  }
}
@media all and (max-width: 480px) {
  .form__input-wrapper.input-wrapper--driver-category {
    flex-wrap: wrap;
    justify-content: flex-start;
    border: none;
    border-top: 0.1rem solid #c8cfd6;
    border-left: 0.1rem solid #c8cfd6;
  }
}
@media all and (max-width: 480px) {
  .form__input-wrapper.input-wrapper--driver-category .form__label.label--radio {
    flex: unset;
    flex-basis: 25%;
    border-bottom: 0.1rem solid #c8cfd6;
  }
}
@media all and (max-width: 480px) {
  .form__input-wrapper.input-wrapper--driver-category .form__label.label--radio:first-of-type {
    border-radius: 0.4rem 0 0 0;
  }
}
@media all and (max-width: 480px) {
  .form__input-wrapper.input-wrapper--driver-category .form__label.label--radio:last-of-type {
    border-right: 0.1rem solid #c8cfd6;
    border-radius: 0 0 0.4rem 0;
  }
}
@media all and (max-width: 480px) {
  .form__input-wrapper.input-wrapper--driver-category .form__label.label--radio:nth-of-type(4) {
    border-radius: 0 0.4rem 0 0;
  }
}
@media all and (max-width: 480px) {
  .form__input-wrapper.input-wrapper--driver-category .form__label.label--radio:nth-of-type(8) {
    border-radius: 0 0 0.4rem 0;
  }
}
@media all and (max-width: 480px) {
  .form__input-wrapper.input-wrapper--driver-category .form__label.label--radio:nth-of-type(9) {
    border-radius: 0 0 0 0.4rem;
  }
}
.form__input-wrapper.input-wrapper--inline {
  max-width: 56.4rem;
  display: flex;
}
.form__input-wrapper.input-wrapper--inline .select:first-of-type .select-styled {
  border-radius: 0.4rem 0 0 0.4rem;
}
.form__input-wrapper.input-wrapper--inline .select:last-of-type .select-styled {
  border-radius: 0 0.4rem 0.4rem 0;
}
.form__input-wrapper .calendar-icon {
  display: block;
  position: absolute;
  width: 3.7rem;
  height: 100%;
  top: 0;
  left: 0;
  background: #F3F3F3 url("../img/calendar.svg") center center/auto no-repeat;
  border-radius: 0.4rem 0 0 0.4rem;
  border: 0.1rem solid #D4D4D4;
}

.form__label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  .form__label-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media all and (max-width: 640px) {
  .form__label-wrapper .form__label.label--checkbox {
    margin: 0.6rem 0 1.5rem;
  }
}

.form__input-inline {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-row-gap: 2rem;
}
.form__input-inline.input-inline--col6 {
  grid-template: auto/repeat(6, 1fr);
}
@media all and (max-width: 900px) {
  .form__input-inline.input-inline--col6 {
    grid-template: auto/repeat(3, 1fr);
  }
}
@media all and (max-width: 576px) {
  .form__input-inline.input-inline--col6 {
    grid-template: auto/1fr;
    grid-gap: 0;
  }
}
.form__input-inline.input-inline--col4 {
  grid-template: auto/repeat(4, 1fr);
}
@media all and (max-width: 900px) {
  .form__input-inline.input-inline--col4 {
    grid-template: auto/repeat(2, 1fr);
  }
}
@media all and (max-width: 480px) {
  .form__input-inline.input-inline--col4 {
    grid-template: auto/1fr;
  }
}
.form__input-inline.input-inline--col3 {
  grid-template: auto/repeat(3, 1fr);
}
@media all and (max-width: 576px) {
  .form__input-inline.input-inline--col3 {
    grid-template: auto/1fr;
    grid-gap: 0;
  }
}
.form__input-inline.input-inline--col2 {
  grid-template: auto/repeat(2, 1fr);
}
@media all and (max-width: 720px) {
  .form__input-inline.input-inline--col2 {
    grid-template: auto/1fr;
    grid-gap: 0;
  }
}
.form__input-inline.input-inline--gap0 {
  grid-column-gap: unset;
}
.form__input-inline.input-inline--gap1 {
  grid-column-gap: 1.5rem;
}
.form__input-inline.input-inline--gap3 {
  grid-column-gap: 3.6rem;
}

.form__input-section {
  position: relative;
  margin-bottom: 4.1rem;
  background: #efefef;
  padding: 2.5rem 2.9rem 1.4rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #C8CFD6;
}
@media all and (max-width: 576px) {
  .form__input-section {
    margin-bottom: 2.1rem;
    padding: 1.5rem 2rem 0.5rem;
  }
}
.form__input-section.input-section--programms {
  padding: 3.5rem 4.8rem;
}
@media all and (max-width: 576px) {
  .form__input-section.input-section--programms {
    padding: 2rem;
  }
}
@media all and (max-width: 576px) {
  .form__input-section.input-section--programms {
    padding: 2rem;
  }
}
.form__input-section.input-section--expirience {
  padding: 0 4.3rem 0 0;
  margin-bottom: 0.5rem;
}
@media all and (max-width: 900px) {
  .form__input-section.input-section--expirience {
    padding: 0;
  }
}
@media all and (max-width: 576px) {
  .form__input-section.input-section--expirience {
    padding: 1.5rem 2rem 0.5rem;
  }
}
.form__input-section.input-section--expirience .input-inline--col4 {
  grid-template: auto/26rem repeat(3, 1fr);
}
@media all and (max-width: 900px) {
  .form__input-section.input-section--expirience .input-inline--col4 {
    grid-template: auto/repeat(3, 1fr);
  }
}
@media all and (max-width: 576px) {
  .form__input-section.input-section--expirience .input-inline--col4 {
    grid-template: auto/1fr;
    grid-gap: 0;
  }
}
@media all and (max-width: 576px) {
  .form__input-section.input-section--expirience .section--period .form__input-outer.field--invalid {
    margin-bottom: 0.8rem;
  }
}
.form__input-section.input-section--expirience .section--period .form__error-msg {
  position: absolute;
  top: 4.3rem;
}
@media all and (max-width: 576px) {
  .form__input-section.input-section--expirience .section--period .form__error-msg {
    top: 6.8rem;
  }
}
.form__input-section.input-section--relatives, .form__input-section.input-section--leave {
  padding: 0 4.3rem 0 1.5rem;
  margin-bottom: 0.5rem;
}
@media all and (max-width: 900px) {
  .form__input-section.input-section--relatives, .form__input-section.input-section--leave {
    padding: 0 1.5rem;
  }
}
@media all and (max-width: 576px) {
  .form__input-section.input-section--relatives, .form__input-section.input-section--leave {
    padding: 1.5rem 2rem 0.5rem;
  }
}
.form__input-section.clone-section {
  margin-top: 1.5rem;
}

.form__label {
  position: relative;
  display: inline-block;
  margin-bottom: 0.4rem;
  font-size: 1.5rem;
  line-height: 2.2rem;
}
.form__label.label--checkbox {
  font-size: 1.3rem;
  line-height: 1.9rem;
  padding-left: 3rem;
}
.form__label.label--checkbox::before {
  content: "";
  position: absolute;
  top: -0.2rem;
  left: 0;
  display: block;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 0.3rem;
  border: 1px solid #CDCDCD;
  box-sizing: border-box;
  background: white;
}
@media all and (max-width: 480px) {
  .form__label.label--checkbox::before {
    top: 0;
    width: 1.9rem;
    height: 1.9rem;
  }
}
.form__label.label--radio {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 1.5rem 0;
  border: 0.1rem solid transparent;
  border-right: 0.1rem solid #D4D4D4;
  text-align: center;
  color: #949494;
  background: white;
}
@media all and (max-width: 576px) {
  .form__label.label--radio {
    padding: 1rem 0;
  }
}
.form__label.label--radio:last-of-type {
  border-right: transparent;
  border-radius: 0 0.4rem 0.4rem 0;
}
.form__label.label--radio:first-of-type {
  border-radius: 0.4rem 0 0 0.4rem;
}
.form__label.label--expirience {
  display: none;
}
@media all and (max-width: 576px) {
  .form__label.label--expirience {
    display: block;
  }
}
.form__label.label--upload {
  margin-top: 3.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #00923F;
  font-size: 1.6rem;
  line-height: 3rem;
  padding: 1.5rem 2.5rem;
  height: 5.6rem;
  border: none;
  border-radius: 0.3rem;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
}
@media all and (max-width: 480px) {
  .form__label.label--upload {
    margin-top: 3.2rem;
  }
}
@media all and (max-width: 375px) {
  .form__label.label--upload {
    width: 100%;
  }
}
.form__label.label--upload-resume {
  margin-top: 0;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  min-width: 18rem;
  background: transparent;
  border: 0.2rem dashed #AAB5BE;
  color: #3F4C59;
}
.form__label .label__asterisk {
  color: #ff2929;
}

.form__error-msg {
  display: none;
  position: relative;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #C71F1F;
  top: -0.5rem;
}
.form__error-msg--privacy {
  position: absolute;
  top: 50%;
  left: -1.8rem;
  transform: translate(-100%, -50%);
  width: 20.8rem;
  padding: 0.9rem 1.3rem;
  text-align: left;
  color: white;
  border-radius: 0.2rem;
  background: #C71F1F;
}
@media all and (max-width: 768px) {
  .form__error-msg--privacy {
    top: unset;
    left: -0.5rem;
    bottom: -1.6rem;
    transform: translate(0, 100%);
  }
}
.form__error-msg--privacy::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -0.4rem;
  transform: translateY(-50%) rotate(45deg);
  display: block;
  width: 0.9rem;
  height: 0.9rem;
  background: #C71F1F;
}
@media all and (max-width: 768px) {
  .form__error-msg--privacy::before {
    right: unset;
    left: 1rem;
    top: 0;
  }
}

.form__input, .form__textarea {
  width: 100%;
  padding: 1.6rem 1.8rem 1.2rem;
  border: 0.1rem solid #D5D5D5;
  background: white;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #000000;
  border-radius: 0.4rem;
}
@media all and (max-width: 576px) {
  .form__input, .form__textarea {
    padding: 0.9rem 1.4rem 0.9rem;
  }
}
.form__input:focus, .form__textarea:focus {
  outline: none;
}
.form__input::placeholder, .form__textarea::placeholder {
  color: #929292;
}
.form__input:disabled, .form__textarea:disabled {
  background: #efefef;
  color: #D5D5D5;
}
.form__input.field--span3, .form__textarea.field--span3 {
  grid-column: span 3;
}
.form__input[type=number]::-webkit-outer-spin-button, .form__input[type=number]::-webkit-inner-spin-button, .form__textarea[type=number]::-webkit-outer-spin-button, .form__textarea[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}
.form__input.field--date, .form__textarea.field--date {
  padding-left: 4.5rem;
}
.form__input.field--phone, .form__textarea.field--phone {
  color: #929292;
}
.form__input.field--phone:focus, .form__textarea.field--phone:focus {
  color: #000000 !important;
}

.form__textarea {
  min-height: 8.9rem;
  resize: none;
  vertical-align: top;
}
@media all and (max-width: 576px) {
  .form__textarea {
    min-height: 7.9rem;
  }
}

.form__checkbox {
  display: none;
}
.form__checkbox.checkbox--privacy + .label--checkbox::before {
  border: 0.2rem solid #c71f1f;
}

.form__checkbox:checked + .label--checkbox::before {
  background: #00923F url("../img/checkbox.svg") center center/auto no-repeat;
  border: none;
}

.form__input-radio, .form__input-checkbox {
  display: none;
}

.form__input-radio:checked + .label--radio, .form__input-checkbox:checked + .label--radio {
  background: #d6f3e2;
  color: #000000;
  border: 0.1rem solid #00923F;
}
@media all and (max-width: 480px) {
  .form__input-radio:checked + .label--radio, .form__input-checkbox:checked + .label--radio {
    border-bottom: 0.1rem solid #00923F !important;
  }
}

.form__upload-wrapper {
  margin-top: 3.6rem;
  display: grid;
  grid-template: auto/18rem 1fr;
  grid-column-gap: 2.9rem;
  grid-row-gap: 2.1rem;
}
@media all and (max-width: 576px) {
  .form__upload-wrapper {
    margin-top: 2.4rem;
    grid-template: auto/1fr;
  }
}
.form__upload-wrapper .form__error-msg {
  top: unset;
}
@media all and (max-width: 480px) {
  .form__upload-wrapper .form__error-msg {
    width: 100%;
    text-align: center;
  }
}

.form__file-upload {
  display: none;
}
.form__file-upload.field--invalid + .label--upload-resume {
  border-color: #C71F1F;
}

.form__file-list {
  margin-top: 0.8rem;
  display: none;
  grid-column: span 2;
}
@media all and (max-width: 576px) {
  .form__file-list {
    margin-top: 0;
    order: 2;
    grid-column: unset;
  }
}
.form__file-list-item {
  position: relative;
  display: flex;
  height: 5.4rem;
  align-items: center;
  padding: 0 3.8rem 0 4.6rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #3F4C59;
  background: white;
  border: 0.1rem dashed #AAB5BE;
  border-right: transparent;
  border-radius: 0.3rem;
}
.form__file-list-item::before {
  content: "";
  display: block;
  width: 1.7rem;
  height: 2.2rem;
  position: absolute;
  top: calc(50% - 1.1rem);
  left: 1.8rem;
  background: url("../img/upload-file.svg") center center/auto no-repeat;
}
.form__file-list-del {
  display: block;
  position: absolute;
  right: 0;
  top: -0.1rem;
  width: 3.8rem;
  height: calc(100% + 0.2rem);
  border: none;
  font-size: 0;
  background: #C71F1F url("../img/del-file-button.svg") center center/auto no-repeat;
  border-radius: 0 0.3rem 0.3rem 0;
  cursor: pointer;
}

@media all and (max-width: 576px) {
  .form__file-upload-wrapper {
    text-align: center;
    order: 3;
  }
}

.form__file-upload-desc {
  font-size: 1.5rem;
  line-height: 2.5rem;
}
@media all and (max-width: 576px) {
  .form__file-upload-desc {
    order: 1;
  }
}

.form__add-button {
  display: block;
  background: transparent;
  color: #00923F;
  border: none;
  border-bottom: 0.1rem dashed #00923F;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 2rem;
}
.form__add-button.add-button--job, .form__add-button.add-button--relative, .form__add-button.add-button--leave {
  margin-left: auto;
  margin-bottom: 2rem;
}

.form__language-section {
  margin-bottom: 4rem;
}
@media all and (max-width: 576px) {
  .form__language-section {
    margin-bottom: 3.2rem;
  }
}
@media all and (max-width: 480px) {
  .form__language-section .select:first-of-type {
    flex: 0.67;
  }
}

.form__checkbox-section {
  display: flex;
  flex-direction: column;
}
.form__checkbox-section .checkbox-section__title {
  display: block;
  padding-bottom: 0.7rem;
  border-bottom: 0.1rem solid #C8CFD6;
  margin-bottom: 1.4rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #000000;
}
.form__checkbox-section .label--checkbox {
  padding-left: 2.6rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #000000;
}
.form__checkbox-section .label--checkbox:before {
  top: 0.1rem;
  width: 1.9rem;
  height: 1.9rem;
}
.form__checkbox-section .label--checkbox:last-of-type {
  margin-top: auto;
}
.form__checkbox-section .form__textarea {
  margin-top: 1.1rem;
}

.form__privacy-link {
  border-bottom: 0.6px solid #000000;
  cursor: pointer;
  transition: 0.2s;
}
.form__privacy-link:hover {
  color: #00923F;
  border-bottom: 0.6px solid #00923F;
}

.grecaptcha-badge {
  display: none;
}
.bread-crumbs {
  margin-top: 4.2rem;
  color: #808D94;
}
@media all and (max-width: 576px) {
  .bread-crumbs {
    margin-top: 3.5rem;
  }
}
.bread-crumbs__list {
  display: flex;
  flex-wrap: wrap;
}
.bread-crumbs__item:last-of-type .bread-crumbs__link {
  margin-right: 0;
}
.bread-crumbs__link {
  position: relative;
  color: #808D94;
  font-size: 1.5rem;
  line-height: 2.3rem;
  border-bottom: 1px solid #808D94;
  margin-right: 2rem;
}
@media all and (max-width: 576px) {
  .bread-crumbs__link {
    font-size: 1.4rem;
  }
}
.bread-crumbs__link:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.bread-crumbs__link::after {
  content: "/";
  bottom: -0.5rem;
  right: -1.5rem;
  position: absolute;
  text-decoration: none;
}

.search-result .bread-crumbs__item:last-child .bread-crumbs__link::after {
  display: none;
}
.wrapper--career-page {
  background: #F7F7F7;
}

.content-block.block--single-page.block--career {
  padding: 1rem 0 7.7rem;
}
@media all and (max-width: 768px) {
  .content-block.block--single-page.block--career {
    padding-bottom: 2rem;
  }
}
@media all and (max-width: 576px) {
  .content-block.block--single-page.block--career {
    padding-bottom: 0.4rem;
  }
}

.career-content__desc {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.career-content__vacancy-list {
  margin-top: 2.9rem;
}
@media all and (max-width: 576px) {
  .career-content__vacancy-list {
    margin-top: 2rem;
  }
}
@media all and (max-width: 375px) {
  .career-content__vacancy-list {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}

.dropdawn-menu__vacancy-item {
  position: relative;
  margin-bottom: 1.2rem;
  background: white;
  box-shadow: 0 0.1rem 0.1rem rgba(41, 41, 41, 0.16);
}
.dropdawn-menu__vacancy-item.item--active {
  box-shadow: 0 2rem 5rem rgba(41, 41, 41, 0.05);
}
.dropdawn-menu__vacancy-item.item--active::after {
  transform: rotate(180deg);
}
.dropdawn-menu__vacancy-item:hover {
  box-shadow: 0 2rem 5rem rgba(0, 55, 84, 0.05);
}
.dropdawn-menu__vacancy-item:last-child {
  margin-bottom: 0;
}
.dropdawn-menu__vacancy-item::after {
  content: "";
  display: block;
  width: 1.7rem;
  height: 1.1rem;
  position: absolute;
  top: 3.6rem;
  right: 3.6rem;
  background: url("../img/arrow-dropdown-vacancy.svg") center center/auto no-repeat;
  transition: 0.15s;
}
.dropdawn-menu__vacancy-item .vacancy-item__header {
  padding: 2.7rem 6rem 2rem 3.8rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
@media all and (max-width: 576px) {
  .dropdawn-menu__vacancy-item .vacancy-item__header {
    padding: 1.7rem 5.5rem 1.5rem 2rem;
  }
}
.dropdawn-menu__vacancy-item .vacancy-item__header .vacancy-item__name {
  font-weight: 600;
  color: #00923F;
}
.dropdawn-menu__vacancy-item .vacancy-item__header .vacancy-item__city {
  display: inline-block;
  margin-top: 0.7rem;
  font-size: 1.4rem;
  line-height: 2.3rem;
  color: rgba(0, 0, 0, 0.5);
}
@media all and (max-width: 576px) {
  .dropdawn-menu__vacancy-item .vacancy-item__header .vacancy-item__city {
    margin-top: 0.5rem;
  }
}
.dropdawn-menu__vacancy-item .vacancy-item__header .vacancy-item__salary {
  display: inline-block;
  margin-top: 1.1rem;
  font-weight: 500;
}
@media all and (max-width: 576px) {
  .dropdawn-menu__vacancy-item .vacancy-item__header .vacancy-item__salary {
    margin-top: 0.7rem;
  }
}
.dropdawn-menu__vacancy-item .vacancy-item__vacancy-info {
  display: none;
  padding: 3.1rem 3.8rem;
  border-top: 1px solid #e9e9e9;
  font-size: 1.6rem;
  line-height: 2.7rem;
}
@media all and (max-width: 576px) {
  .dropdawn-menu__vacancy-item .vacancy-item__vacancy-info {
    line-height: 2.5rem;
    padding: 2.5rem 2.3rem 1.5rem;
  }
}
.dropdawn-menu__vacancy-item .vacancy-item__vacancy-info .vacancy-info__title {
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 576px) {
  .dropdawn-menu__vacancy-item .vacancy-item__vacancy-info .vacancy-info__title {
    font-size: 1.6rem;
    line-height: 2.7rem;
  }
}
.dropdawn-menu__vacancy-item .vacancy-item__vacancy-info .vacancy-info__add-info {
  margin-top: 4.4rem;
}
@media all and (max-width: 576px) {
  .dropdawn-menu__vacancy-item .vacancy-item__vacancy-info .vacancy-info__add-info {
    margin-top: 3rem;
  }
}
.dropdawn-menu__vacancy-item .vacancy-item__vacancy-info .vacancy-info__list {
  margin: 1.7rem 0 3.3rem;
}
@media all and (max-width: 576px) {
  .dropdawn-menu__vacancy-item .vacancy-item__vacancy-info .vacancy-info__list {
    margin: 1rem 0 3rem;
  }
}
.dropdawn-menu__vacancy-item .vacancy-item__vacancy-info .vacancy-info__list .list__item {
  position: relative;
  padding-left: 3rem;
  margin-bottom: 0.8rem;
}
.dropdawn-menu__vacancy-item .vacancy-item__vacancy-info .vacancy-info__list .list__item::before {
  content: "";
  display: block;
  width: 1.5rem;
  border-bottom: 0.2rem solid #000000;
  position: absolute;
  top: 1.2rem;
  left: 0;
}

.welder-flex-class {
  display: flex;
}

.career-welder {
  width: 100%;
  height: 100%;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}
.career-welder .welder-content {
  width: 55.7rem;
  height: 27.4rem;
  background-color: white;
  margin: auto;
  color: black;
  position: relative;
  padding: 3rem 4.5rem 4rem 4.5rem;
}
.career-welder .welder-content .welder-exit {
  position: absolute;
  right: 2rem;
  top: 2rem;
  font-size: 2.4rem;
  font-weight: 300;
  color: #89929d;
  cursor: pointer;
}
.career-welder .welder-content h1 {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 4rem;
  margin-bottom: 1.9rem;
}
.career-welder .welder-content h1 + span {
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 2.8rem;
  margin-bottom: 2.7rem;
  display: inline-block;
}
.career-welder .welder-content span + div {
  display: flex;
  justify-content: space-between;
}
.career-welder .welder-content span + div > a {
  width: 20.6rem;
  height: 5.4rem;
  background: #00923f;
  border-radius: 3px;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 2.4rem;
  border: none;
  cursor: pointer;
  padding-top: 1.5rem;
}
.career-welder .welder-content div > a + a {
  width: 20.6rem;
  height: 5.4rem;
  border-radius: 3px;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 2.4rem;
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1.5px solid #00923f;
  border-radius: 3px;
}

@media screen and (max-width: 557px) {
  .career-welder .welder-content {
    width: 98%;
    height: auto;
    padding: 2rem 3.5rem 3rem 3.5rem;
  }
  .career-welder .welder-content .welder-exit {
    right: 1rem;
    top: 1rem;
    font-size: 1.6rem;
  }
  .career-welder .welder-content span + div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .career-welder .welder-content span + div > a {
    margin-bottom: 1rem;
  }
}

.wrapper--comment-page {
  background: #F7F8F9;
}

.comment-form {
  max-width: 59rem;
}
.comment-form .form__textarea {
  min-height: 14.9rem;
}
@media all and (max-width: 576px) {
  .comment-form .form__textarea {
    max-height: 10.4rem;
  }
}
.comment-form .form__submit-button-wrapper {
  margin-bottom: 0;
}
#map {
  height: 52rem;
}
@media all and (max-width: 768px) {
  #map {
    height: 45rem;
  }
}
@media all and (max-width: 576px) {
  #map {
    height: 35rem;
  }
}
@media all and (max-width: 375px) {
  #map {
    height: 22.5rem;
  }
}

.ymaps-block {
  position: relative;
  margin-top: 4rem;
}
.ymaps-block__float-contacts {
  width: 45.7rem;
  height: 34.6rem;
  padding: 11.5rem 1.2rem 3.3rem;
  position: absolute;
  top: calc(50% - 17.3rem);
  right: calc(50% - 62.7rem);
  font-size: 1.8rem;
  line-height: 3rem;
  background: white;
  border-radius: 0.6rem;
  box-shadow: 0 3.5rem 5rem rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 1310px) {
  .ymaps-block__float-contacts {
    right: 3rem;
  }
}
@media all and (max-width: 768px) {
  .ymaps-block__float-contacts {
    font-size: 1.6rem;
    line-height: 2.5rem;
    width: 39.7rem;
    height: 30.6rem;
    top: calc(50% - 15.3rem);
  }
}
@media all and (max-width: 640px) {
  .ymaps-block__float-contacts {
    position: relative;
    min-height: 32rem;
    top: 0;
    right: 0;
    width: 100%;
    height: unset;
    padding: 10.4rem 1.2rem 2rem;
  }
}
@media all and (max-width: 375px) {
  .ymaps-block__float-contacts {
    min-height: 33.9rem;
  }
}
.ymaps-block__float-contacts .float-contacts__offices {
  position: absolute;
  top: 1.4rem;
  left: 1.2rem;
  right: 1.2rem;
}
@media all and (max-width: 640px) {
  .ymaps-block__float-contacts .float-contacts__offices {
    top: 2.1rem;
  }
}
.ymaps-block__float-contacts .float-contacts__offices-list {
  position: relative;
  width: 100%;
  max-height: 31.8rem;
  overflow-y: auto;
  margin-bottom: 4rem;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.2);
  background: white;
  z-index: 5;
}
@media all and (max-width: 768px) {
  .ymaps-block__float-contacts .float-contacts__offices-list {
    max-height: 27.8rem;
  }
}
@media all and (max-width: 640px) {
  .ymaps-block__float-contacts .float-contacts__offices-list {
    max-height: 28.3rem;
  }
}
@media all and (max-width: 375px) {
  .ymaps-block__float-contacts .float-contacts__offices-list {
    max-height: 30.5rem;
  }
}
.ymaps-block__float-contacts .float-contacts__offices-list::-webkit-scrollbar-track {
  width: 1rem;
}
.ymaps-block__float-contacts .float-contacts__offices-list::-webkit-scrollbar {
  margin-right: 0.5rem;
  width: 0.4rem;
}
.ymaps-block__float-contacts .float-contacts__offices-list::-webkit-scrollbar-thumb {
  width: 0.4rem;
  background: #C3C8CA;
  border-radius: 0.2rem;
}
.ymaps-block__float-contacts .float-contacts__offices-list.list--active::after {
  transform: rotate(180deg);
  right: 2.3rem;
  top: 2.35rem;
}
@media all and (max-width: 576px) {
  .ymaps-block__float-contacts .float-contacts__offices-list.list--active::after {
    top: 2.1rem;
    right: 2rem;
  }
}
.ymaps-block__float-contacts .float-contacts__offices-list.list--active .offices-list__item {
  padding: 1.3rem 2.8rem 1.1rem;
  border-top: 1px solid #EBEDEF;
  display: block;
}
.ymaps-block__float-contacts .float-contacts__offices-list.list--active .offices-list__item.item--current {
  padding: 1.3rem 2.8rem 1.1rem;
}
.ymaps-block__float-contacts .float-contacts__offices-list.list--active .offices-list__item:first-child {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 1.9rem 4rem 1.6rem 2.8rem;
}
@media all and (max-width: 576px) {
  .ymaps-block__float-contacts .float-contacts__offices-list.list--active .offices-list__item:first-child {
    padding: 1.5rem 4rem 1.3rem 1.7rem;
  }
}
.ymaps-block__float-contacts .float-contacts__offices-list::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 0.65rem solid transparent;
  border-top: 0.65rem solid #000000;
  position: absolute;
  top: 3rem;
  right: 2.6rem;
  transition: 0.15s;
  z-index: 2;
}
@media all and (max-width: 576px) {
  .ymaps-block__float-contacts .float-contacts__offices-list::after {
    top: 2.6rem;
    right: 1.9rem;
    border: 0.4rem solid transparent;
    border-top: 0.4rem solid #000000;
  }
}
.ymaps-block__float-contacts .float-contacts__offices-list .offices-list__item {
  font-weight: 600;
  cursor: pointer;
  display: none;
}
.ymaps-block__float-contacts .float-contacts__offices-list .offices-list__item.item--current {
  display: block;
  padding: 1.9rem 4rem 1.6rem 2.8rem;
}
@media all and (max-width: 576px) {
  .ymaps-block__float-contacts .float-contacts__offices-list .offices-list__item.item--current {
    padding: 1.5rem 4rem 1.3rem 1.7rem;
  }
}

.office-item__title {
  display: none;
}

.office-item__contact {
  margin-top: 1.2rem;
  display: flex;
}
@media all and (max-width: 640px) {
  .office-item__contact {
    margin-top: 1.9rem;
  }
}
.office-item__contact:first-of-type {
  margin-top: 0;
}
.office-item__contact .contact__title {
  flex-basis: 11.3rem;
  padding-left: 2.8rem;
  margin-right: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
}
@media all and (max-width: 375px) {
  .office-item__contact .contact__title {
    flex-basis: 10.3rem;
    padding-left: 1.8rem;
  }
}
.office-item__contact .contact__value {
  flex: 1;
}
.office-item__contact .contact__value .phone-link {
  white-space: nowrap;
}
.office-item__contact .contact__value .email__link {
  border-bottom: 1px solid #000000;
}

.branch-offices__grid {
  margin-top: 6.4rem;
  display: grid;
  grid-template: auto/repeat(3, 1fr);
  grid-column-gap: 6.4rem;
  grid-row-gap: 4.9rem;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 1200px) {
  .branch-offices__grid {
    grid-column-gap: 4.4rem;
  }
}
@media all and (max-width: 992px) {
  .branch-offices__grid {
    grid-template: auto/repeat(2, 1fr);
  }
}
@media all and (max-width: 768px) {
  .branch-offices__grid {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 576px) {
  .branch-offices__grid {
    grid-template: auto/1fr;
  }
}
.branch-offices__grid .office-item__title {
  display: inline-block;
  font-weight: 900;
  font-size: 2.8rem;
  line-height: 3.9rem;
  margin-bottom: 3rem;
}
@media all and (max-width: 992px) {
  .branch-offices__grid .office-item__title {
    font-size: 2.1rem;
    line-height: 2.9rem;
  }
}
@media all and (max-width: 768px) {
  .branch-offices__grid .office-item__title {
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 640px) {
  .branch-offices__grid .office-item__contact {
    margin-top: 1.4rem;
  }
}
.branch-offices__grid .office-item__contact:first-of-type {
  margin-top: 0;
}
.branch-offices__grid .office-item__contact .contact__title {
  flex-basis: 8.5rem;
  padding-left: 0;
  margin-right: 0.9rem;
}
@charset "UTF-8";
.content-block {
  padding: 4.5rem 0 5.4rem;
}
@media all and (max-width: 576px) {
  .content-block {
    margin-top: 0.5rem;
    padding: 2.2rem 0 4rem;
  }
}
.content-block.block--single-page {
  padding: 1rem 0 6.4rem;
}
@media all and (max-width: 576px) {
  .content-block.block--single-page {
    padding: 0 0 5.4rem;
  }
}
.content-block.block--tank-cleaning, .content-block.block--tank-diagnostic, .content-block.block--tank-repair, .content-block.block--tank-anticor, .content-block.block--tank-building, .content-block.block--energetics {
  padding: 2.2rem 0 0;
}
@media all and (max-width: 576px) {
  .content-block.block--tank-cleaning, .content-block.block--tank-diagnostic, .content-block.block--tank-repair, .content-block.block--tank-anticor, .content-block.block--tank-building, .content-block.block--energetics {
    padding: 1.5rem 0 0;
  }
}
@media all and (min-width: 768px) {
  .content-block.block--tank-diagnostic .tank-cleaning__reviews, .content-block.block--tank-repair .tank-cleaning__reviews, .content-block.block--tank-building .tank-cleaning__reviews, .content-block.block--tank-anticor .tank-cleaning__reviews {
    padding-bottom: 9rem;
  }
}
@media all and (min-width: 768px) {
  .content-block.block--tank-diagnostic .tank-cleaning__about, .content-block.block--tank-repair .tank-cleaning__about, .content-block.block--tank-building .tank-cleaning__about, .content-block.block--tank-anticor .tank-cleaning__about {
    padding-bottom: 3rem;
  }
}
.content-block.block--tank-diagnostic .tank-cleaning__order-block, .content-block.block--tank-repair .tank-cleaning__order-block, .content-block.block--tank-building .tank-cleaning__order-block, .content-block.block--tank-anticor .tank-cleaning__order-block {
  padding-top: 4rem;
}
@media all and (min-width: 768px) {
  .content-block.block--tank-diagnostic .tank-cleaning__order-block, .content-block.block--tank-repair .tank-cleaning__order-block, .content-block.block--tank-building .tank-cleaning__order-block, .content-block.block--tank-anticor .tank-cleaning__order-block {
    padding-top: 7rem;
  }
}
.content-block.block--tank-cleaning .tank-cleaning__header {
  min-height: 82.3rem;
}
.content-block.block--tank-diagnostic .tank-cleaning__header {
  min-height: 67.4rem;
}
.content-block.block--tank-repair .tank-cleaning__header {
  min-height: 61.4rem;
}
.content-block.block--tank-anticor .tank-cleaning__header {
  min-height: 68.4rem;
}
.content-block.block--tank-building .tank-cleaning__header {
  min-height: 85.9rem;
}
.content-block.block--tank-building .tank-cleaning__header-list {
  max-width: unset;
}
.content-block.block--tank-building .tank-cleaning__expirience {
  padding-bottom: 10rem;
}
@media all and (max-width: 992px) {
  .content-block.block--tank-building .tank-cleaning__expirience {
    padding-bottom: 7rem;
  }
}
@media all and (max-width: 576px) {
  .content-block.block--tank-building .tank-cleaning__expirience {
    padding-bottom: 4rem;
  }
}
@media all and (max-width: 375px) {
  .content-block.block--tank-anticor .tank-cleaning__order-title {
    max-width: 21rem;
  }
}
.content-block.block--tank-anticor .tank-cleaning__stats {
  padding-bottom: 5rem;
}
@media all and (max-width: 576px) {
  .content-block.block--tank-anticor .tank-cleaning__stats {
    padding: 5rem 0 3rem;
  }
}
.content-block.block--energetics .tank-cleaning__header {
  min-height: 68.4rem;
}
@media all and (max-width: 576px) {
  .content-block.block--projects {
    padding: 0 0 3.1rem;
  }
}
@media all and (max-width: 768px) {
  .content-block.block--comment {
    padding: 0 0 1.2rem;
  }
}
.content-block.block--about {
  padding: 3.1rem 0 8.1rem;
}
@media all and (max-width: 768px) {
  .content-block.block--about {
    padding: 2rem 0 5rem;
  }
}
@media all and (max-width: 576px) {
  .content-block.block--about {
    padding: 1.4rem 0 3.1rem;
  }
}
.content-block.block--about .content-block__header {
  max-width: 144rem;
  padding-top: 6.1rem;
  margin: 0 auto;
  height: 43.1rem;
  background: url("../img/about.jpg") center center/cover no-repeat;
}
@media all and (max-width: 992px) {
  .content-block.block--about .content-block__header {
    height: 34rem;
  }
}
@media all and (max-width: 768px) {
  .content-block.block--about .content-block__header {
    height: 28rem;
  }
}
@media all and (max-width: 576px) {
  .content-block.block--about .content-block__header {
    padding-top: 2.1rem;
    height: 20rem;
  }
}
@media all and (max-width: 375px) {
  .content-block.block--about .content-block__header {
    height: 15.7rem;
  }
}
.content-block.block--about .content-block__title {
  margin-top: 0.9rem;
  color: white;
}
.content-block .content-block__header-img {
  display: block;
  width: 144rem;
  margin: 4rem auto 0;
}
@media all and (max-width: 1440px) {
  .content-block .content-block__header-img {
    width: 100%;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__header-img {
    margin-top: 2.9rem;
  }
}
.content-block .content-block__header-img .header-img__img {
  vertical-align: top;
  min-height: 18rem;
  max-height: 39rem;
  width: 100%;
  object-fit: cover;
}
@media all and (max-width: 992px) {
  .content-block .content-block__header-img .header-img__img {
    max-height: 30rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__header-img .header-img__img {
    max-height: 24rem;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__header-img .header-img__img {
    max-height: 20rem;
  }
}
.content-block .content-block__main-grid {
  display: grid;
  grid-template: auto/1fr 28.8rem;
  grid-column-gap: 8.5rem;
}
@media all and (max-width: 992px) {
  .content-block .content-block__main-grid {
    grid-template: auto/1fr 24rem;
    grid-column-gap: 4rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid {
    grid-row-gap: 4rem;
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid {
    grid-row-gap: 3rem;
  }
}
.content-block .content-block__main-grid.main-grid--news-list {
  margin-top: 8.8rem;
}
@media all and (max-width: 992px) {
  .content-block .content-block__main-grid.main-grid--news-list {
    margin-top: 4.5rem;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--news-list {
    margin-top: 2.9rem;
  }
}
.content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right {
  grid-template: auto/1fr 39.7rem;
  grid-column-gap: 8.3rem;
}
@media all and (max-width: 1200px) {
  .content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right {
    grid-template: auto/1fr 34rem;
    grid-column-gap: 3.3rem;
  }
}
@media all and (max-width: 992px) {
  .content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right {
    grid-template: auto/1fr 30rem;
    grid-column-gap: 2.5rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right {
    grid-template: auto/1fr;
    grid-column-gap: 2.5rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right .main-grid__sidebar {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}
@media all and (max-width: 375px) {
  .content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right .main-grid__sidebar {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right .main-grid__content {
    grid-column-start: 1;
    grid-row-start: 2;
  }
}
.content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right .main-grid__content .pagination-block {
  margin: 9rem 0 2.2rem;
}
@media all and (max-width: 992px) {
  .content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right .main-grid__content .pagination-block {
    margin: 6rem 0 0;
  }
}
@media all and (max-width: 480px) {
  .content-block .content-block__main-grid.main-grid--news-list.main-grid--sidebar-right .main-grid__content .pagination-block {
    margin: 3.9rem 0 0;
  }
}
.content-block .content-block__main-grid.main-grid--news, .content-block .content-block__main-grid.main-grid--projects, .content-block .content-block__main-grid.main-grid--reviews-single {
  margin-top: 3.7rem;
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--news, .content-block .content-block__main-grid.main-grid--projects, .content-block .content-block__main-grid.main-grid--reviews-single {
    margin-top: 3.2rem;
  }
}
.content-block .content-block__main-grid.main-grid--news {
  grid-template: auto/calc(100% - 37.3rem) 28.5rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--news {
    grid-template: auto/100%;
  }
}
@media all and (max-width: 480px) {
  .content-block .content-block__main-grid.main-grid--news {
    margin-top: 2rem;
  }
}
.content-block .content-block__main-grid.main-grid--about {
  margin-top: 4.5rem;
  grid-row-gap: 5rem;
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--about {
    margin-top: 3.1rem;
  }
}
@media all and (max-width: 992px) {
  .content-block .content-block__main-grid.main-grid--about.main-grid--sidebar-left {
    grid-template: auto/26rem 1fr;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--about.main-grid--sidebar-left {
    grid-template: auto/1fr;
    grid-row-gap: 1.5rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--about.main-grid--sidebar-left .main-grid__content {
    order: 2;
  }
}
.content-block .content-block__main-grid.main-grid--about.main-grid--sidebar-left .main-grid__sidebar {
  margin-top: 2rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--about.main-grid--sidebar-left .main-grid__sidebar {
    margin: 0 auto 0 0;
    order: 1;
  }
}
.content-block .content-block__main-grid.main-grid--about.main-grid--sidebar-left .main-grid__business-direction {
  grid-column-start: span 2;
  order: 3;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--about.main-grid--sidebar-left .main-grid__business-direction {
    grid-column-start: span 1;
  }
}
.content-block .content-block__main-grid.main-grid--quality {
  margin-top: 8.8rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--quality {
    margin-top: 5rem;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--quality {
    margin-top: 3.7rem;
    grid-row-gap: 1.4rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--quality .main-grid__sidebar {
    display: none;
  }
}
.content-block .content-block__main-grid.main-grid--vendors, .content-block .content-block__main-grid.main-grid--career, .content-block .content-block__main-grid.main-grid--resume, .content-block .content-block__main-grid.main-grid--comment {
  margin-top: 4.5rem;
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--comment {
    margin-top: 3.2rem;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--vendors {
    margin-top: 2.8rem;
  }
}
.content-block .content-block__main-grid.main-grid--resume {
  grid-template: auto/87.7rem 1fr;
}
@media all and (max-width: 992px) {
  .content-block .content-block__main-grid.main-grid--resume {
    grid-template: auto/1fr;
  }
}
.content-block .content-block__main-grid.main-grid--search {
  margin-top: 5.2rem;
  grid-row-gap: 0;
}
@media all and (max-width: 1024px) {
  .content-block .content-block__main-grid.main-grid--search {
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--search {
    margin-top: 2.4rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--projects .main-grid__sidebar, .content-block .content-block__main-grid.main-grid--reviews-single .main-grid__sidebar {
    display: none;
  }
}
.content-block .content-block__main-grid.main-grid--projects .main-grid__sidebar.sidebar--projects, .content-block .content-block__main-grid.main-grid--projects .main-grid__sidebar.sidebar--reviews-single, .content-block .content-block__main-grid.main-grid--reviews-single .main-grid__sidebar.sidebar--projects, .content-block .content-block__main-grid.main-grid--reviews-single .main-grid__sidebar.sidebar--reviews-single {
  padding-top: 6rem;
}
.content-block .content-block__main-grid.main-grid--services {
  margin-top: 5.2rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--services {
    margin-top: 2.3rem;
  }
}
@media all and (max-width: 992px) {
  .content-block .content-block__main-grid.main-grid--services.main-grid--sidebar-left {
    grid-template: auto/26rem 1fr;
    grid-column-gap: 1rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--services.main-grid--sidebar-left {
    grid-template: auto/1fr;
  }
}
.content-block .content-block__main-grid.main-grid--gallery {
  margin-top: 4.1rem;
  color: white;
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--gallery {
    margin-top: 2rem;
  }
}
.content-block .content-block__main-grid.main-grid--gallery.main-grid--sidebar-left {
  grid-template: auto/15.5rem 1fr;
  grid-column-gap: 4.5rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--gallery.main-grid--sidebar-left {
    grid-template: auto/1fr;
  }
}
.content-block .content-block__main-grid.main-grid--gallery.main-grid--sidebar-left .main-grid__content {
  max-width: 100%;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--gallery.main-grid--sidebar-left .main-grid__sidebar {
    display: none;
  }
}
.content-block .content-block__main-grid.main-grid--reviews {
  margin-top: 3.8rem;
  grid-template: auto/1fr 29rem;
  grid-column-gap: 3.1rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--reviews {
    margin-top: 5rem;
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid.main-grid--reviews {
    margin-top: 3.7rem;
  }
}
.content-block .content-block__main-grid.main-grid--reviews .main-grid__sidebar {
  margin-top: 2.7rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--reviews .main-grid__sidebar {
    display: none;
  }
}
.content-block .content-block__main-grid.main-grid--reviews .main-grid__sidebar .sidebar__widget.widget--links {
  margin-top: 2rem;
}
.content-block .content-block__main-grid.main-grid--documentation {
  margin-top: 7.1rem;
  grid-template: auto/1fr 29rem;
  grid-column-gap: 3.1rem;
}
@media all and (max-width: 992px) {
  .content-block .content-block__main-grid.main-grid--documentation {
    grid-template: auto/1fr 25rem;
    grid-column-gap: 1.8rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--documentation {
    margin-top: 5rem;
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 480px) {
  .content-block .content-block__main-grid.main-grid--documentation {
    margin-top: 3.3rem;
  }
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--documentation .main-grid__sidebar {
    display: none;
  }
}
.content-block .content-block__main-grid.main-grid--documentation .main-grid__sidebar .sidebar__widget.widget--links {
  margin-top: 2rem;
}
.content-block .content-block__main-grid.main-grid--sidebar-left {
  grid-template: auto/34rem 1fr;
  grid-column-gap: 3.2rem;
}
.content-block .content-block__main-grid.main-grid--sidebar-left .main-grid__content {
  max-width: 82.3rem;
  order: 2;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--sidebar-left .main-grid__content {
    order: 1;
  }
}
.content-block .content-block__main-grid.main-grid--sidebar-left .main-grid__sidebar {
  order: 1;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid.main-grid--sidebar-left .main-grid__sidebar {
    order: 2;
  }
}
.content-block .content-block__main-grid .main-grid__content {
  font-size: 1.8rem;
  line-height: 3rem;
}
.content-block .content-block__main-grid .main-grid__content .content-block__article h3 {
  margin-bottom: 2.6rem;
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 3.8rem;
  line-height: 6.6rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid .main-grid__content .content-block__article h3 {
    font-size: 3.2rem;
    line-height: 4rem;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid .main-grid__content .content-block__article h3 {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
}
.content-block .content-block__main-grid .main-grid__content .content-block__article h4 {
  margin-bottom: 2.6rem;
  font-weight: 800;
  font-size: 2.3rem;
  line-height: 3rem;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid .main-grid__content .content-block__article h4 {
    font-size: 2.1rem;
    line-height: 2.6rem;
  }
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid .main-grid__content .content-block__article h4 {
    margin-bottom: 2rem;
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
}
.content-block .content-block__main-grid .main-grid__content .content-block__article ul {
  margin: 2.5rem 0;
}
.content-block .content-block__main-grid .main-grid__content .content-block__article ul li {
  list-style: none;
  position: relative;
  padding-left: 1.9rem;
}
.content-block .content-block__main-grid .main-grid__content .content-block__article ul li::before {
  content: "•";
  display: inline-block;
  position: absolute;
  top: 0.2rem;
  left: 0;
  font-size: 1.2rem;
  line-height: 3rem;
  margin-right: 1rem;
}
.content-block .content-block__main-grid .main-grid__content .content-block__article ul li ul {
  margin: 1.8rem 0;
}
.content-block .content-block__main-grid .main-grid__content .content-block__article ul li ul li:before {
  display: none;
}
.content-block .content-block__main-grid .main-grid__content .content-block__article ul li + li {
  margin-top: 1.5rem;
}
.content-block .content-block__main-grid .main-grid__content .content-block__article p {
  margin-bottom: 2.7rem;
}
@media all and (max-width: 576px) {
  .content-block .content-block__main-grid .main-grid__content .content-block__article p {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.content-block .content-block__main-grid .main-grid__content .content-block__article p:last-child {
  margin-bottom: 0;
}
.content-block .content-block__main-grid .main-grid__content .content__video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.content-block .content-block__main-grid .main-grid__content .content__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid .main-grid__sidebar {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
.content-block .content-block__main-grid .main-grid__services-types {
  grid-column-start: span 2;
  order: 3;
}
@media all and (max-width: 768px) {
  .content-block .content-block__main-grid .main-grid__services-types {
    order: 1;
    grid-column-start: unset;
  }
}

.main-grid__social-share {
  margin-top: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media all and (max-width: 768px) {
  .main-grid__social-share {
    margin-top: 1.5rem;
  }
}
@media all and (max-width: 576px) {
  .main-grid__social-share {
    margin-top: 2.3rem;
  }
}
.main-grid__social-share .social-share__desc {
  margin-right: 1.9rem;
  font-weight: 700;
}
@media all and (max-width: 576px) {
  .main-grid__social-share .social-share__desc {
    margin-right: 0.9rem;
  }
}
.main-grid__social-share .social-share__social-icon {
  display: flex;
}
.main-grid__social-share .social-share__social-icon .social-icon__icon {
  position: relative;
  margin-right: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  background: rgba(65, 75, 87, 0.7);
  border-radius: 50%;
}
@media all and (max-width: 576px) {
  .main-grid__social-share .social-share__social-icon .social-icon__icon {
    height: 3.5rem;
    width: 3.5rem;
    margin-right: 0.8rem;
  }
}
.main-grid__social-share .social-share__social-icon .social-icon__icon.icon--rt {
  background: #FFFFFF;
}
.main-grid__social-share .social-share__social-icon .social-icon__icon.icon--tg {
  background: #FFFFFF;
}
.main-grid__social-share .social-share__social-icon .social-icon__icon.icon--yt {
  background: #FFFFFF;
}
.main-grid__social-share .social-share__social-icon .social-icon__icon.icon--vk {
  background: #FFFFFF;
}
.main-grid__social-share .social-share__social-icon .social-icon__icon.icon--fb {
  background: #3B5998;
}
.main-grid__social-share .social-share__social-icon .social-icon__icon.icon--tw {
  background: #1DA1F2;
}
.main-grid__social-share .social-share__social-icon .social-icon__icon.icon--ok {
  background: #EE8208;
}
.main-grid__social-share .social-share__social-icon .social-icon__icon.icon--ok .icon__img {
  max-width: 1.8rem;
}
.main-grid__social-share .social-share__social-icon .icon__img {
  max-width: 1.6rem;
  max-height: 2rem;
}
@media all and (max-width: 576px) {
  .main-grid__social-share .social-share__social-icon .icon__img {
    max-width: 1.4rem;
    max-height: 1.8rem;
  }
}
.main-grid__social-share .social-share__social-icon .social-icon__link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  font-size: 0;
  opacity: 0;
  border-radius: 50%;
}

.content__widget.widget--document, .sidebar__widget.widget--document {
  margin-top: 2.6rem;
  display: flex;
  align-items: flex-start;
  font-weight: 500;
}
@media all and (max-width: 576px) {
  .content__widget.widget--document, .sidebar__widget.widget--document {
    align-items: flex-start;
    margin-top: 1.5rem;
  }
}
.content__widget.widget--document .widget__icon-document, .sidebar__widget.widget--document .widget__icon-document {
  margin-right: 1.9rem;
  max-width: 4rem;
  min-width: 3.5rem;
}
.content__widget.widget--document .widget__icon-document .icon-document__icon, .sidebar__widget.widget--document .widget__icon-document .icon-document__icon {
  vertical-align: top;
  max-width: 100%;
}
.content__widget.widget--document .widget__info-document, .sidebar__widget.widget--document .widget__info-document {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content__widget.widget--document .info-document__title, .sidebar__widget.widget--document .info-document__title {
  font-weight: 500;
  display: inline-block;
  font-size: 1.8rem;
  line-height: 2rem;
}
@media all and (max-width: 576px) {
  .content__widget.widget--document .info-document__title, .sidebar__widget.widget--document .info-document__title {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.content__widget.widget--document .info-document__link:hover, .sidebar__widget.widget--document .info-document__link:hover {
  color: #00923F;
}
.content__widget.widget--document .info-document__link:hover .underline, .sidebar__widget.widget--document .info-document__link:hover .underline {
  border-bottom: 1px solid #00923F;
}
.content__widget.widget--document .info-document__size, .sidebar__widget.widget--document .info-document__size {
  margin-top: 0.9rem;
  display: inline-block;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #ABABAB;
}
@media all and (max-width: 576px) {
  .content__widget.widget--document .info-document__size, .sidebar__widget.widget--document .info-document__size {
    margin-top: 0.5rem;
    font-size: 1.4rem;
    line-height: 2.1rem;
    border-bottom: none;
  }
}
.datepicker {
  border-radius: 0.4rem;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.07);
  border: none;
}
.datepicker.active {
  transform: translateY(-1rem);
}
.datepicker .datepicker--pointer {
  display: none;
}

.datepicker--days-names {
  display: none;
}

.datepicker--button {
  color: #00923F;
}

.datepicker--cell {
  color: #0C1116;
}
.datepicker--cell:hover, .datepicker--cell.-selected- {
  background: #F0F3F5;
  border: 0.1rem solid #C8CFD6;
  color: #0C1116;
}
.datepicker--cell:hover.-focus-, .datepicker--cell.-selected-.-focus- {
  background: #F0F3F5;
}
.datepicker--cell.-weekend- {
  color: #00923F;
}
.datepicker--cell.-current- {
  background: #00923F;
  border: 0.1rem solid #00923F;
  color: white;
}
.datepicker--cell.-current-.-selected- {
  background: #00923F;
  border: 0.1rem solid #00923F;
  color: white;
}
.datepicker--cell.-other-month- {
  color: transparent;
}
@media all and (max-width: 480px) {
  .content-block.block--documentation {
    padding-bottom: 1.5rem;
  }
}

.content-documentation__documents-list {
  border-right: 1px solid #EBEDEF;
}
@media all and (max-width: 768px) {
  .content-documentation__documents-list {
    border: none;
  }
}
.content-documentation__documents-list .documents-list__item {
  width: calc(100% + 4rem);
  margin-left: -4rem;
  padding: 3.4rem 2rem 3.6rem 4rem;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #EBEDEF;
}
@media all and (max-width: 768px) {
  .content-documentation__documents-list .documents-list__item {
    padding: 3.5rem 2rem 3.8rem;
    margin-left: -2rem;
  }
}
@media all and (max-width: 480px) {
  .content-documentation__documents-list .documents-list__item {
    flex-direction: column;
  }
}
.content-documentation__documents-list .documents-list__item:first-of-type {
  padding-top: 2rem;
}
.content-documentation__documents-list .documents-list__item .item__documents__img {
  max-width: 16.2rem;
  flex-basis: 16.2rem;
  vertical-align: top;
  margin-right: 4rem;
}
@media all and (max-width: 992px) {
  .content-documentation__documents-list .documents-list__item .item__documents__img {
    margin-right: 2rem;
  }
}
.content-documentation__documents-list .documents-list__item .item__documents-info {
  flex: 1;
}
@media all and (max-width: 480px) {
  .content-documentation__documents-list .documents-list__item .item__documents-info {
    margin-top: 3rem;
  }
}
.content-documentation__documents-list .documents-list__item .item__documents-title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.4rem;
}
@media all and (max-width: 768px) {
  .content-documentation__documents-list .documents-list__item .item__documents-title {
    font-size: 2rem;
    line-height: 2.6rem;
  }
}
.content-documentation__documents-list .documents-list__item .item__documents-text {
  margin-top: 2.1rem;
  max-width: 60.8rem;
  font-size: 1.6rem;
  line-height: 2.8rem;
}
@media all and (max-width: 768px) {
  .content-documentation__documents-list .documents-list__item .item__documents-text {
    line-height: 2.5rem;
  }
}
.content-documentation__documents-list .documents-list__item .content__widget.widget--document .info-document__title {
  font-weight: 600;
  font-size: 1.6rem;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
.footer {
  background: #F7F7F7;
  padding: 5.9rem 0 3.2rem;
}
@media all and (max-width: 576px) {
  .footer {
    padding: 2.7rem 0;
  }
}

.footer__footer-content {
  display: flex;
}
@media all and (max-width: 992px) {
  .footer__footer-content {
    flex-direction: column;
  }
}

.footer-content__subscribe-block {
  display: none;
  color: #000000;
  max-width: 44.3rem;
}
@media all and (max-width: 992px) {
  .footer-content__subscribe-block {
    display: block;
  }
}

.footer-content__nav-grid {
  display: grid;
  grid-template: auto/repeat(3, 1fr);
  grid-column-gap: 9rem;
}
@media all and (max-width: 1200px) {
  .footer-content__nav-grid {
    grid-column-gap: 4rem;
  }
}
@media all and (max-width: 992px) {
  .footer-content__nav-grid {
    margin-top: 4rem;
    grid-template: auto/2fr 2fr 1fr;
  }
}
@media all and (max-width: 768px) {
  .footer-content__nav-grid {
    grid-template: auto/1fr 1fr;
  }
}
@media all and (max-width: 576px) {
  .footer-content__nav-grid {
    grid-template: auto/1fr;
  }
}

@media all and (max-width: 768px) {
  .nav-grid__menu-section:nth-of-type(2) {
    grid-row: span 2;
  }
}
@media all and (max-width: 576px) {
  .nav-grid__menu-section:nth-of-type(2) {
    margin-top: 3.7rem;
  }
}

@media all and (max-width: 768px) {
  .nav-grid__menu-section:nth-of-type(3) {
    margin-top: 3rem;
  }
}
@media all and (max-width: 576px) {
  .nav-grid__menu-section:nth-of-type(3) {
    margin-top: 3.7rem;
  }
}

.footer-menu__list .list__item {
  margin-top: 1.8rem;
}
.footer-menu__list .list__item:first-child {
  margin-top: 0;
}
.footer-menu__list .list__item .item__link.link--top {
  display: inline-block;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  opacity: 1;
}
.footer-menu__list .list__item .item__link.link--top:hover {
  opacity: 1;
}
.footer-menu__list .list__item .item__link {
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #000000;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #000000;
  opacity: 0.6;
  transition: 0.15s;
}
.footer-menu__list .list__item .item__link:hover {
  opacity: 0.9;
  color: #00923F;
  border-bottom: 1px solid #00923F;
}

.footer-content__contacts-section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 35.44%;
  color: #000000;
}
@media all and (max-width: 1200px) {
  .footer-content__contacts-section {
    min-width: 25%;
  }
}
@media all and (max-width: 992px) {
  .footer-content__contacts-section {
    align-items: center;
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .footer-content__contacts-section {
    align-items: flex-start;
    text-align: left;
  }
}

.contacts-section__subscribe {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 500;
  color: #000000;
}
@media all and (max-width: 992px) {
  .contacts-section__subscribe {
    display: none;
  }
}

.subscribe__label {
  display: inline-block;
  margin-bottom: 1.9rem;
}

.subscribe__form {
  position: relative;
}
.subscribe__form .form__input {
  font-family: "Oswald", "Sans Serif";
  padding: 1rem 5rem 1.4rem 2.2rem;
  width: 100%;
  background: transparent;
  border: 0.1rem solid #D0D0D0;
  border-radius: 0.3rem;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 3.6rem;
  color: #000000;
}
@media all and (max-width: 1200px) {
  .subscribe__form .form__input {
    padding: 1rem 4.5rem 1.4rem 1.8rem;
  }
}
@media all and (max-width: 576px) {
  .subscribe__form .form__input {
    font-size: 2.2rem;
    line-height: 3.3rem;
  }
}
@media all and (max-width: 375px) {
  .subscribe__form .form__input {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.subscribe__form .form__input::placeholder {
  color: #969696;
  opacity: 0.7;
}
.subscribe__form .form__input:focus {
  outline: none;
}
.subscribe__form .form__button-subscribe {
  position: absolute;
  bottom: 0;
  right: 0;
  background: transparent url("../img/subscribe-arrow.svg") center center no-repeat;
  background-size: auto;
  border: none;
  font-size: 0;
  width: 7.5rem;
  height: 6rem;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  .subscribe__form .form__button-subscribe {
    width: 5.5rem;
  }
}

.contacts-section__contacts-block {
  margin-top: 3.3rem;
  margin-bottom: 2rem;
}
@media all and (max-width: 992px) {
  .contacts-section__contacts-block {
    margin-top: 5.2rem;
  }
}
.contacts-section__contacts-block .contacts-block__phone {
  font-family: "Oswald", "Sans Serif";
  display: block;
  font-size: 3.2rem;
  line-height: 4.7rem;
}
.contacts-section__contacts-block .contacts-block__email, .contacts-section__contacts-block .contacts-block__address {
  display: block;
  margin-top: 1rem;
}
@media all and (max-width: 576px) {
  .contacts-section__contacts-block .contacts-block__email, .contacts-section__contacts-block .contacts-block__address {
    margin-top: 1.5rem;
  }
}
@media all and (max-width: 1200px) {
  .contacts-section__contacts-block .address__street {
    display: block;
  }
}
@media all and (max-width: 992px) {
  .contacts-section__contacts-block .address__street {
    display: inline;
  }
}
@media all and (max-width: 576px) {
  .contacts-section__contacts-block .address__street {
    display: block;
  }
}

.contacts-section__comment-link {
  margin-top: auto;
  flex: 0 0 1;
}
@media all and (max-width: 992px) {
  .contacts-section__comment-link {
    margin-top: 2rem;
  }
}
@media all and (max-width: 576px) {
  .contacts-section__comment-link {
    margin-top: 4.4rem;
  }
}
.contacts-section__comment-link .comment-link__link {
  display: inline-block;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-left: 2.6rem;
}
.contacts-section__comment-link .comment-link__link:hover {
  color: #00923F;
}
.contacts-section__comment-link .comment-link__link:hover .underline {
  border-bottom: 0.1rem solid #00923F;
}
.contacts-section__comment-link .comment-link__link::before {
  content: "";
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 0;
  left: -2.6rem;
  background: url("../img/pencil.svg") center center/auto no-repeat;
}

.contacts-section__social-icon {
  margin-top: 3.4rem;
  display: flex;
}
@media all and (max-width: 992px) {
  .contacts-section__social-icon {
    margin-top: 3.8rem;
  }
}
.contacts-section__social-icon .social-icon__icon {
  position: relative;
  margin-right: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  transition: 0.15s;
}
.contacts-section__social-icon .social-icon__icon.icon--vk {
  background: #5181B8;
}
.contacts-section__social-icon .social-icon__icon.icon--fb {
  background: #3B5998;
}
.contacts-section__social-icon .social-icon__icon.icon--tw {
  background: #1DA1F2;
}
.contacts-section__social-icon .social-icon__icon.icon--ok {
  background: #EE8208;
}
.contacts-section__social-icon .icon__img {
  max-width: 4.6rem;
  max-height: 3rem;
}
.contacts-section__social-icon .social-icon__link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  font-size: 0;
  opacity: 0;
  border-radius: 50%;
}

.footer__copyright {
  margin-top: 7rem;
  display: flex;
  align-items: flex-end;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: rgba(0, 0, 0, 0.6);
}
@media all and (max-width: 992px) {
  .footer__copyright {
    margin-top: 5.1rem;
    justify-content: center;
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .footer__copyright {
    margin-top: 3.8rem;
    text-align: left;
  }
}

.copyright__text {
  display: flex;
  flex-direction: column;
  width: 49%;
}
@media all and (max-width: 992px) {
  .copyright__text {
    max-width: 40rem;
    width: 100%;
  }
}
@media all and (max-width: 576px) {
  .copyright__text {
    max-width: unset;
  }
}

.google-recaptcha {
  max-width: 40rem;
  margin-top: 1.5rem;
}
.google-recaptcha__link {
  color: inherit;
  font-weight: 700;
  border-bottom: 0.1rem solid #636363;
}
.google-recaptcha__link:hover {
  color: inherit;
}

.copyright__find-bug {
  width: 51%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media all and (max-width: 992px) {
  .copyright__find-bug {
    display: none;
  }
}
.copyright__find-bug .find-bug__ctrl-key, .copyright__find-bug .find-bug__enter-key {
  padding: 0.5rem 0.8rem 2.1rem 2.6rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  border: 0.1rem solid #D0D0D0;
  border-radius: 0.3rem;
  margin-right: 1rem;
}
.copyright__find-bug .find-bug__info {
  margin-left: 1rem;
  color: #000000;
}
.copyright__find-bug .find-bug__info .info__title {
  display: block;
  font-weight: 700;
  opacity: 0.8;
}
.copyright__find-bug .find-bug__info .info__desc {
  opacity: 0.6;
}

.footer--gallery {
  background: #121212;
}
.footer--gallery .footer-menu__list .list__item .item__link {
  color: white;
  border-bottom: 0.1rem solid white;
}
.footer--gallery .footer-content__contacts-section {
  color: white;
}
.footer--gallery .subscribe__label {
  color: white;
}
.footer--gallery .subscribe__form .form__input {
  background: #4D4D4D;
  color: white;
  border: none;
}
.footer--gallery .subscribe__form .form__input::placeholder {
  color: #999999;
}
.footer--gallery .subscribe__form .form__button-subscribe {
  background: url(../img/subscribe-arrow-white.svg) center center no-repeat;
}
.footer--gallery .contacts-section__comment-link .comment-link__link {
  color: rgba(255, 255, 255, 0.6);
}
.footer--gallery .contacts-section__comment-link .comment-link__link:hover {
  opacity: 1;
  color: inherit;
}
.footer--gallery .contacts-section__comment-link .comment-link__link:hover .underline {
  border-bottom: 0.1rem solid white;
}
.footer--gallery .contacts-section__comment-link .comment-link__link::before {
  background: url(../img/pencil-white.svg) center center no-repeat;
}
.footer--gallery .contacts-section__social-icon .social-icon__icon {
  background: #4D4D4D;
}
.footer--gallery .footer__copyright {
  color: rgba(179, 192, 198, 0.8);
}
.footer--gallery .copyright__find-bug .find-bug__ctrl-key, .footer--gallery .copyright__find-bug .find-bug__enter-key {
  color: #B3C0C6;
  border: 0.1rem solid #3F4D53;
}
.footer--gallery .copyright__find-bug .find-bug__info {
  color: rgba(179, 192, 198, 0.6);
}
.footer--gallery .copyright__find-bug .find-bug__info .info__title {
  color: white;
}
.wrapper--gallery-page {
  background: #262626;
}
.wrapper--gallery-page .main-screen__header {
  margin-top: 3.1rem;
}
.wrapper--gallery-page .bread-crumbs .bread-crumbs__link {
  color: #C7C7C7;
  border-bottom: 1px solid #C7C7C7;
}
.wrapper--gallery-page .bread-crumbs .bread-crumbs__link:hover {
  color: #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.wrapper--gallery-page .page-title {
  color: white;
}
.wrapper--gallery-page .sorting-block__drop-tag-panel {
  overflow-x: scroll;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
@media all and (max-width: 1200px) {
  .wrapper--gallery-page .sorting-block__drop-tag-panel {
    overflow: unset;
    background: #5E5E5E;
    border-bottom: none;
  }
}
.wrapper--gallery-page .sorting-block__drop-tag-panel::after {
  background: url("../img/drop-down-panel-white.svg") center center/auto no-repeat;
}
.wrapper--gallery-page .sorting-block__drop-tag-panel .drop-tag-panel__tag {
  color: white;
}
.wrapper--gallery-page .sorting-block__drop-tag-panel .drop-tag-panel__tag:hover {
  color: #00923F;
}
.wrapper--gallery-page .sorting-block__drop-tag-panel .drop-tag-panel__tag.tag--active {
  color: #00923F;
}
@media all and (max-width: 1200px) {
  .wrapper--gallery-page .sorting-block__drop-tag-panel .drop-tag-panel__tag.tag--active {
    color: white;
  }
}
.wrapper--gallery-page .sorting-block__drop-tag-panel .drop-tag-panel__tag.tag--active::after {
  background: #00923F;
}

.content-block.block--single-page.block--gallery {
  padding: 1rem 0 8.4rem;
}
@media all and (max-width: 768px) {
  .content-block.block--single-page.block--gallery {
    padding-bottom: 5rem;
  }
}
@media all and (max-width: 576px) {
  .content-block.block--single-page.block--gallery {
    padding-bottom: 3.2rem;
  }
}

.content__gallery .gallery__photo {
  max-width: 100%;
  vertical-align: top;
  margin-bottom: 2.9rem;
}
@media all and (max-width: 768px) {
  .content__gallery .gallery__photo {
    width: 100%;
  }
}
@media all and (max-width: 576px) {
  .content__gallery .gallery__photo {
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 375px) {
  .content__gallery .gallery__photo {
    max-width: unset;
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.content__gallery .gallery__photo:last-child {
  margin-bottom: 0;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
.geography-block {
  background: #121212;
  overflow: hidden;
  padding: 4rem 0 4.9rem;
}
@media all and (max-width: 576px) {
  .geography-block {
    padding: 2rem 0 1.9rem;
  }
}
@media all and (max-width: 375px) {
  .geography-block .block-title {
    font-size: 2.6rem;
    line-height: 3.5rem;
  }
}
.geography-block.block--geography-tc .geography-block__geography-map {
  padding: 0 0 9rem;
}
@media all and (max-width: 576px) {
  .geography-block.block--geography-tc .geography-block__geography-map {
    padding: 0 0 8rem;
  }
}
.geography-block.block--geography-tc .geography-map__info {
  width: 100%;
  display: block;
  align-items: unset;
  justify-content: unset;
  text-align: center;
  padding-top: 8.2rem;
}
@media all and (max-width: 480px) {
  .geography-block.block--geography-tc .geography-map__info {
    padding-top: 4rem;
  }
}
@media all and (max-width: 375px) {
  .geography-block.block--geography-tc .geography-map__info .info__title {
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.5rem;
  }
}
.geography-block .geography-block__geography-map {
  position: relative;
}
@media all and (max-width: 992px) {
  .geography-block .geography-block__geography-map {
    padding-top: 11.7rem;
  }
}
.geography-block .geography-map__img {
  display: block;
}
@media all and (max-width: 768px) {
  .geography-block .geography-map__img {
    display: none;
  }
}
.geography-block .geography-map__mobile-img {
  display: none;
}
@media all and (max-width: 768px) {
  .geography-block .geography-map__mobile-img {
    display: block;
    margin-bottom: 2.4rem;
  }
}
@media all and (max-width: 576px) {
  .geography-block .geography-map__mobile-img {
    width: calc(100% + 6rem);
    margin-left: -3rem;
    margin-bottom: 1.4rem;
  }
}
.geography-block .geography-map__info {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.5rem 0 6.9rem;
  height: 100%;
  width: 100%;
}
@media all and (max-width: 576px) {
  .geography-block .geography-map__info {
    padding: 2.1rem 0 2.4rem;
  }
}
.geography-block .geography-map__info .info__title {
  color: white;
}
.geography-block .geography-map__info .info__title.title--center {
  text-align: center;
}
.geography-block .geography-map__info .info__desc {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: white;
  width: 16.2rem;
}
@media all and (max-width: 576px) {
  .geography-block .geography-map__info .info__desc {
    display: none;
  }
}
.geography-block .geography-map__info .info__desc-euro {
  font-weight: 700;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
.main-screen__header, .single-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.2rem;
  color: white;
  width: calc(100% + 0.8rem);
  margin-left: -0.8rem;
}
@media all and (max-width: 375px) {
  .main-screen__header, .single-page__header {
    height: 5.8rem;
  }
}

.single-page__header {
  margin-top: 3.1rem;
  color: #000000;
}
@media all and (max-width: 375px) {
  .single-page__header {
    margin-top: 1.4rem;
  }
}
.single-page__header .right-block__first-line .first-line__menu-caller .menu-caller__burger {
  background: #000000;
}
.single-page__header .right-block__first-line .first-line__menu-caller .menu-caller__burger::before, .single-page__header .right-block__first-line .first-line__menu-caller .menu-caller__burger::after {
  background: #000000;
}
.single-page__header .right-block__first-line .first-line__contact .contact__phone-link {
  color: #000000;
}
.single-page__header .right-block__first-line .search__form .form__search-caller {
  background: url(../img/search-icon.svg) center center/contain no-repeat;
}
@media all and (max-width: 375px) {
  .single-page__header .right-block__first-line .search__form .form__search-caller {
    background: url(../img/search-icon-m.svg) center center/contain no-repeat;
  }
}
.single-page__header .header-menu__navigation .navigation__item .item__link {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.single-page__header .header-menu__navigation .navigation__item .item__link:hover {
  color: #00923F;
  border-bottom: 1px solid #00923F;
}
.single-page__header .header-menu__navigation .navigation__item .item__link.link--language {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.single-page__header .header-menu__navigation .navigation__item .item__link.link--language:hover {
  color: #00923F;
  border-bottom-color: #00923F;
}
.single-page__header .header-menu__navigation .navigation__item .item__submenu .item__link {
  border-bottom: none;
}
.single-page__header .header-menu__navigation .navigation__item.item--has-submenu::after {
  background: url(../img/menu-arrow-down-black.svg) center center/cover no-repeat;
}

.header__left-block {
  position: relative;
  display: flex;
  align-items: center;
}
.header__left-block .header__home-link {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
}
.header__left-block .left-block__logo {
  width: 7.2rem;
  margin-right: 0.8rem;
}
@media all and (max-width: 375px) {
  .header__left-block .left-block__logo {
    width: 5.8rem;
  }
}
.header__left-block .left-block__info {
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin-bottom: 0.3rem;
}
@media all and (max-width: 375px) {
  .header__left-block .left-block__info {
    max-width: 11.5rem;
    margin-bottom: 0.4rem;
  }
}
.header__left-block .info__title {
  font-family: "Oswald", "Sans Serif";
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.4rem;
}
@media all and (max-width: 375px) {
  .header__left-block .info__title {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}
.header__left-block .info__desc {
  margin-top: 0.3rem;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
}
@media all and (max-width: 375px) {
  .header__left-block .info__desc {
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: 300;
  }
}

.header__right-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.right-block__first-line {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-block__first-line .first-line__contact {
  font-family: "Oswald", "Sans Serif";
  font-size: 2.8rem;
  line-height: 4.4rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
@media all and (max-width: 576px) {
  .right-block__first-line .first-line__contact {
    display: none;
  }
}
.right-block__first-line .first-line__contact .contact__phone-link {
  color: white;
}
.right-block__first-line .first-line__contact .contact__phone-link:hover {
  color: inherit;
}
.right-block__first-line .search__bg-substrate {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #02141C;
  opacity: 0;
  transition: 0.7s;
}
.right-block__first-line .search__bg-substrate.bg-substrate--active {
  display: block;
  opacity: 0.3;
  z-index: 6;
}
.right-block__first-line .first-line__search {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 1.8rem 0 2.8rem;
}
@media all and (max-width: 375px) {
  .right-block__first-line .first-line__search {
    margin: 0 1.3rem 0 0;
  }
}
.right-block__first-line .search__form {
  position: relative;
}
.right-block__first-line .search__form .form__search-caller {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.4rem;
  height: 2.4rem;
  background: url(../img/search-icon-white.svg) center center no-repeat;
  background-size: contain;
  z-index: 2;
  cursor: pointer;
}
@media all and (max-width: 375px) {
  .right-block__first-line .search__form .form__search-caller {
    background: url(../img/search-icon-white-m.svg) center center no-repeat;
    background-size: contain;
  }
}
.right-block__first-line .search__form .form__search-caller.search-caller--active {
  background: url(../img/search-icon.svg) center center no-repeat;
  z-index: 8;
}
@media all and (max-width: 576px) {
  .right-block__first-line .search__form .form__search-caller.search-caller--active {
    top: -1.5rem;
    left: 4rem;
  }
}
@media all and (max-width: 375px) {
  .right-block__first-line .search__form .form__search-caller.search-caller--active {
    top: 0.3rem;
    left: 3.5rem;
    background: url(../img/search-icon-m.svg) center center no-repeat;
    background-size: contain;
  }
}
.right-block__first-line .search__form .form__input-search {
  position: absolute;
  width: 0;
  padding: 1.5rem 0 1.3rem;
  top: calc(50% - 2.9rem);
  right: -1.5rem;
  font-size: 1.8rem;
  line-height: 3rem;
  background: transparent;
  border: none;
  border-radius: 0.3rem;
  z-index: 1;
  transition: 0.2s;
}
@media all and (max-width: 576px) {
  .right-block__first-line .search__form .form__input-search {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    transform: translateY(-5.8rem);
  }
}
.right-block__first-line .search__form .form__input-search.input-search--active {
  background: white;
  padding: 1.5rem 4.4rem 1.3rem 2.6rem;
  width: 48.2rem;
  z-index: 7;
}
@media all and (max-width: 576px) {
  .right-block__first-line .search__form .form__input-search.input-search--active {
    width: 100%;
    padding: 1.5rem 4.4rem 1.3rem 2rem;
    transform: translateY(0);
  }
}
.right-block__first-line .search__form .form__input-search::placeholder {
  color: #979EA2;
}
.right-block__first-line .search__form .form__input-search:focus, .right-block__first-line .search__form .form__input-search:active {
  outline: none;
}
.right-block__first-line .search__form .form__button-search {
  border: none;
  width: 0;
  height: 0;
  font-size: 0;
  opacity: 0;
}
.right-block__first-line .first-line__menu-caller {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media all and (max-width: 375px) {
  .right-block__first-line .first-line__menu-caller {
    width: 2rem;
    height: 2rem;
  }
}
.right-block__first-line .first-line__menu-caller .menu-caller__burger {
  position: relative;
  display: block;
  width: 2.4rem;
  height: 0.3rem;
  background: white;
}
@media all and (max-width: 375px) {
  .right-block__first-line .first-line__menu-caller .menu-caller__burger {
    width: 1.8rem;
  }
}
.right-block__first-line .first-line__menu-caller .menu-caller__burger::before, .right-block__first-line .first-line__menu-caller .menu-caller__burger::after {
  content: "";
  display: block;
  width: 2.4rem;
  height: 0.3rem;
  position: absolute;
  left: 0;
  background: white;
}
@media all and (max-width: 375px) {
  .right-block__first-line .first-line__menu-caller .menu-caller__burger::before, .right-block__first-line .first-line__menu-caller .menu-caller__burger::after {
    width: 1.8rem;
  }
}
.right-block__first-line .first-line__menu-caller .menu-caller__burger::before {
  top: -0.9rem;
}
@media all and (max-width: 375px) {
  .right-block__first-line .first-line__menu-caller .menu-caller__burger::before {
    top: -0.7rem;
  }
}
.right-block__first-line .first-line__menu-caller .menu-caller__burger::after {
  bottom: -0.9rem;
}
@media all and (max-width: 375px) {
  .right-block__first-line .first-line__menu-caller .menu-caller__burger::after {
    bottom: -0.7rem;
  }
}

.right-block__second-line {
  display: flex;
  margin-top: 0.7rem;
  justify-content: flex-end;
}

.header-menu__navigation .navigation__item {
  display: inline-block;
  margin-left: 2.7rem;
  padding-bottom: 1.5rem;
}
@media all and (max-width: 1140px) {
  .header-menu__navigation .navigation__item {
    margin-left: 2.2rem;
  }
}
@media all and (max-width: 1024px) {
  .header-menu__navigation .navigation__item {
    display: none;
  }
}
.header-menu__navigation .navigation__item:first-child {
  margin-left: 0;
}
.header-menu__navigation .navigation__item:last-child {
  margin-left: 7.7rem;
}
@media all and (max-width: 1100px) {
  .header-menu__navigation .navigation__item:last-child {
    margin-left: 4rem;
  }
}
@media all and (max-width: 1024px) {
  .header-menu__navigation .navigation__item:last-child {
    display: inline-block;
  }
}
@media all and (max-width: 375px) {
  .header-menu__navigation .navigation__item:last-child {
    padding-bottom: 0;
    margin-left: 5rem;
  }
}
.header-menu__navigation .navigation__item.item--has-submenu {
  position: relative;
  padding-right: 1.8rem;
}
.header-menu__navigation .navigation__item.item--has-submenu::after {
  content: "";
  display: block;
  width: 1rem;
  height: 0.7rem;
  position: absolute;
  top: 1rem;
  right: 0;
  background: url("../img/menu-arrow-down.svg") center center no-repeat;
  background-size: cover;
}
.header-menu__navigation .navigation__item.item--has-submenu:hover .item__submenu {
  display: block;
}
.header-menu__navigation .navigation__item .item__link {
  color: white;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  border-bottom: 1px solid white;
}
.header-menu__navigation .navigation__item .item__link:hover {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.header-menu__navigation .navigation__item .item__link.link--language {
  border: none;
  background: transparent;
  color: white;
  font-size: 1.6rem;
  line-height: 2rem;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.header-menu__navigation .navigation__item .item__link.link--language.link--hidden {
  display: none;
}
.header-menu__navigation .navigation__item .item__submenu {
  display: none;
  width: 29.6rem;
  padding: 0.3rem 1.5rem 2.9rem 2.7rem;
  position: absolute;
  left: -3rem;
  top: 4rem;
  background: white;
  border-radius: 0.3rem;
  box-shadow: 0 3.5rem 5rem rgba(0, 0, 0, 0.07);
  z-index: 12;
}
.header-menu__navigation .navigation__item .item__submenu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: -1.2rem;
  left: 4.5rem;
  border: 0.65rem solid transparent;
  border-bottom: 0.65rem solid white;
}
.header-menu__navigation .navigation__item .item__submenu .submenu__item {
  position: relative;
  padding-right: 2rem;
}
.header-menu__navigation .navigation__item .item__submenu .submenu__item.item--has-submenu::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  border: 0.5rem solid transparent;
  border-left: 0.65rem solid #000000;
}
.header-menu__navigation .navigation__item .item__submenu .submenu__item.item--has-submenu:hover .item__sub-submenu {
  display: block;
}
.header-menu__navigation .navigation__item .item__submenu .submenu__item .item__sub-submenu {
  display: none;
  width: 29.2rem;
  padding: 0.3rem 3.5rem 2.9rem 2.9rem;
  position: absolute;
  left: 100%;
  top: -3rem;
  background: white;
  border-radius: 0.3rem;
  box-shadow: 0 3.5rem 5rem rgba(0, 0, 0, 0.07);
  z-index: 3;
}
.header-menu__navigation .navigation__item .item__submenu .item__link {
  margin-top: 2.3rem;
  display: block;
  font-weight: normal;
  color: #000000;
  text-decoration: underline;
}
.header-menu__navigation .navigation__item .item__submenu .item__link:hover {
  color: #00923F;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
.main-screen {
  position: relative;
  padding-top: 3.1rem;
  height: 100vh;
  background: url("../img/bg-main-screen.jpg") center center no-repeat;
  background-size: cover;
}
@media all and (max-width: 576px) {
  .main-screen {
    height: 45rem;
  }
}
@media all and (max-width: 375px) {
  .main-screen {
    padding-top: 1.4rem;
    height: 36.6rem;
    background: url("../img/bg-main-screen-m.jpg") center center no-repeat;
    background-size: cover;
  }
}
@media all and (max-height: 768px) {
  .main-screen {
    height: unset;
  }
}
.main-screen .container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main-screen__main-title {
  flex: 1 0 auto;
  margin-top: 10rem;
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 10rem;
  line-height: 9.5rem;
  color: white;
  max-width: 95rem;
}
@media all and (max-height: 900px) {
  .main-screen__main-title {
    margin-top: 5rem;
    font-size: 8rem;
    line-height: 9rem;
    max-width: 72rem;
  }
}
@media all and (max-width: 1200px) {
  .main-screen__main-title {
    font-size: 8rem;
    max-width: 72rem;
  }
}
@media all and (max-width: 992px) {
  .main-screen__main-title {
    margin-top: 6rem;
    font-size: 6rem;
    line-height: 7rem;
    max-width: 53rem;
  }
}
@media all and (max-width: 768px) {
  .main-screen__main-title {
    font-size: 6rem;
    line-height: 7rem;
    max-width: 53rem;
  }
}
@media all and (max-width: 576px) {
  .main-screen__main-title {
    margin-bottom: 6rem;
    font-size: 3.9rem;
    line-height: 4.8rem;
    max-width: 36rem;
  }
}
@media all and (max-width: 375px) {
  .main-screen__main-title {
    margin-top: 11.5rem;
    font-size: 3.2rem;
    line-height: 3.5rem;
    max-width: 27.5rem;
  }
}

.main-screen__add-info-block {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4rem;
}
@media all and (max-width: 576px) {
  .main-screen__add-info-block {
    display: none;
  }
}
@media all and (max-height: 768px) {
  .main-screen__add-info-block {
    margin-top: 5rem;
  }
}

.add-info-block__scroll-down {
  max-width: 9rem;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media all and (max-width: 1024px) {
  .add-info-block__scroll-down {
    display: none;
  }
}
@media all and (max-height: 768px) {
  .add-info-block__scroll-down {
    display: none;
  }
}
.add-info-block__scroll-down .scroll-down__mouse {
  position: relative;
  display: block;
  width: 3.8rem;
  height: 5.3rem;
  border: 0.25rem solid white;
  border-radius: 5rem;
}
.add-info-block__scroll-down .scroll-down__mouse:before {
  content: "";
  display: block;
  width: 0.25rem;
  height: 1rem;
  position: absolute;
  top: 0.7rem;
  left: calc(50% - 0.1rem);
  background: white;
  animation: 2s wheel infinite;
}
@keyframes wheel {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
.add-info-block__scroll-down .scroll-down__img {
  width: 3.8rem;
}
.add-info-block__scroll-down .scroll-down__text {
  margin-top: 1.1rem;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: white;
}

.add-info-block__item {
  position: relative;
  max-width: 26.6rem;
}
@media all and (max-width: 768px) {
  .add-info-block__item {
    max-width: 22.6rem;
  }
}
.add-info-block__item::before {
  content: "";
  display: block;
  width: 6.5rem;
  height: 14.8rem;
  position: absolute;
  left: 0;
  top: 0;
  background: #00923F;
  z-index: 1;
}
@media all and (max-width: 768px) {
  .add-info-block__item::before {
    width: 5.5rem;
  }
}
.add-info-block__item .item__title {
  position: absolute;
  top: 6.4rem;
  right: calc(100% - 3rem);
  font-family: "Oswald", "Sans Serif";
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.3rem;
  text-transform: uppercase;
  text-decoration: underline;
  color: white;
  z-index: 2;
}
.add-info-block__item .item__img {
  height: 14.8rem;
  width: 100%;
  object-fit: cover;
}
.add-info-block__item .item__desc {
  padding: 0 3rem;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 1.8rem;
  color: white;
}

.item--articles {
  margin-left: 5.2rem;
}
.item--articles:before {
  background: #0084BC;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
.modal-menu {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #F7F7F7;
  z-index: 10;
  color: #000000;
  transform: translateX(100%);
}
.modal-menu.modal-menu--active {
  opacity: 1;
  transform: translateX(0);
}
.modal-menu-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-menu__header {
  position: relative;
  margin-top: 3.1rem;
}
@media all and (max-width: 375px) {
  .modal-menu__header {
    margin-top: 1.4rem;
  }
}

.menu__close-button {
  position: absolute;
  top: 4.3rem;
  right: 9.5rem;
  width: 2rem;
  height: 2rem;
  background: none;
  cursor: pointer;
  border: none;
  font-size: 0;
  z-index: 2;
}
@media all and (max-width: 992px) {
  .menu__close-button {
    top: 2rem;
    right: 2rem;
  }
}
.menu__close-button::before, .menu__close-button::after {
  content: "";
  display: block;
  width: 2.6rem;
  height: 0.3rem;
  background: #000000;
  position: absolute;
  top: calc(50% - 0.1rem);
  left: 0;
  transform: rotate(45deg);
}
.menu__close-button::after {
  transform: rotate(-45deg);
}

.modal-menu__header {
  display: flex;
  align-items: center;
}
.modal-menu__header .header__logo {
  width: 7.2rem;
  margin-right: 0.8rem;
}
@media all and (max-width: 375px) {
  .modal-menu__header .header__logo {
    width: 5.8rem;
  }
}
.modal-menu__header .header__info {
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin-bottom: 0.8rem;
}
@media all and (max-width: 375px) {
  .modal-menu__header .header__info {
    max-width: 11.5rem;
    margin-bottom: 1.1rem;
  }
}
.modal-menu__header .info__title {
  font-family: "Oswald", "Sans Serif";
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.4rem;
}
@media all and (max-width: 375px) {
  .modal-menu__header .info__title {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}
.modal-menu__header .info__desc {
  margin-top: 0.4rem;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5rem;
}
@media all and (max-width: 375px) {
  .modal-menu__header .info__desc {
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: 300;
  }
}

.modal-menu__menu-content .menu-content__grid {
  margin-top: 6rem;
  margin-bottom: 8.1rem;
  display: grid;
  grid-template: auto/repeat(4, 1fr);
  grid-gap: 7.8rem;
}
@media all and (max-width: 1140px) {
  .modal-menu__menu-content .menu-content__grid {
    display: block;
    column-count: 2;
    column-gap: 5rem;
    max-width: 62rem;
    margin: 0 auto 3rem;
  }
}
@media all and (max-width: 576px) {
  .modal-menu__menu-content .menu-content__grid {
    column-count: 1;
  }
}
.modal-menu__menu-content .grid__menu-section {
  display: none;
}
@media all and (max-width: 1140px) {
  .modal-menu__menu-content .grid__menu-section {
    margin-top: 4.3rem;
    display: inline-block;
  }
}
@media all and (max-width: 576px) {
  .modal-menu__menu-content .grid__menu-section {
    display: block;
  }
}
.modal-menu__menu-content .grid__menu-section .menu-section__list .list__item {
  margin-top: 1.7rem;
}
.modal-menu__menu-content .grid__menu-section .menu-section__list .list__item .item__link.link--top {
  display: inline-block;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  color: #000000;
}
.modal-menu__menu-content .grid__menu-section .menu-section__list .list__item .item__link {
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #3F4C59;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #3F4C59;
  opacity: 0.9;
  transition: 0.15s;
}
.modal-menu__menu-content .grid__menu-section .menu-section__list .list__item .item__link:hover {
  color: #00923F;
  border-bottom: 1px solid #00923F;
}

.modal-menu__menu-footer {
  margin: auto 0 6.9rem;
  height: 13rem;
  display: flex;
  align-items: center;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  transition: 0.1s;
  opacity: 0;
}
@media all and (max-width: 767px) {
  .modal-menu__menu-footer {
    display: none;
  }
}

.menu-footer__contacts-block {
  display: flex;
  justify-content: flex-start;
}
.menu-footer__contacts-block .contacts-block__elem + .contacts-block__elem {
  margin-left: 6rem;
}
@media all and (max-width: 992px) {
  .menu-footer__contacts-block .contacts-block__elem + .contacts-block__elem {
    margin-left: 3rem;
  }
}
.menu-footer__contacts-block .elem__title {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 2.8rem;
}
.menu-footer__contacts-block .elem__phone, .menu-footer__contacts-block .elem__email, .menu-footer__contacts-block .elem__address {
  font-family: "Oswald", "Sans Serif";
  margin-top: 1rem;
  display: block;
  font-size: 2rem;
  letter-spacing: 0.01em;
}
@media all and (max-width: 992px) {
  .menu-footer__contacts-block .elem__phone, .menu-footer__contacts-block .elem__email, .menu-footer__contacts-block .elem__address {
    font-size: 1.8rem;
  }
}
.modal {
  overflow: unset;
  display: none;
  padding: 3rem 3rem 4.1rem 4.1rem;
}
@media all and (max-width: 576px) {
  .modal {
    padding: 3rem 2rem;
  }
}
@media all and (max-width: 375px) {
  .modal {
    padding: 2rem;
    vertical-align: top;
  }
}
.modal__title {
  display: inline-block;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 4rem;
  color: #000000;
}
@media all and (max-width: 576px) {
  .modal__title {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 375px) {
  .modal__title {
    max-width: 24.3rem;
    margin: 0 auto;
  }
}
.modal__content {
  margin-top: 1.9rem;
}
.modal__content-list-item + .modal__content-list-item {
  margin-top: 1rem;
}
.modal__content-list-item {
  position: relative;
  padding-left: 1.5rem;
}
.modal__content-list-item::before {
  content: "";
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  left: 0;
  background: #000;
}
.modal__content-title {
  font-weight: 700;
  margin: 3rem 0 1.7rem;
  display: inline-block;
  font-size: 1.8rem;
  line-height: 3.1rem;
}
.modal__content-requirements {
  display: none;
}
.modal__download-photo-button {
  display: block;
  padding: 1.5rem 2.5rem;
  margin-top: 3.6rem;
}
.modal__all-requirements {
  position: relative;
  margin-top: 2.6rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #00923F;
  background: transparent;
  border: none;
  border-bottom: 0.1rem dashed #00923F;
  cursor: pointer;
}
.modal__all-requirements::before {
  content: "";
  display: block;
  width: 1rem;
  height: 0.6rem;
  background: url("../img/show-more-arrow.svg") center center/auto no-repeat;
  position: absolute;
  top: 0.8rem;
  right: -1.75rem;
}
.modal__all-requirements.active::before {
  transform: rotate(180deg);
}
.modal .fancybox-close-small {
  width: 3rem;
  height: 3rem;
  top: 1.7rem;
  right: 1.7rem;
  padding: 0;
  opacity: 1;
}
@media all and (max-width: 576px) {
  .modal .fancybox-close-small {
    top: 1.25rem;
    right: 1.25rem;
  }
}
.modal .fancybox-close-small path {
  fill: #89929D;
}
.modal--typo {
  width: 62.5rem;
  padding: 3rem 4.5rem 4.5rem;
}
@media all and (max-width: 576px) {
  .modal--typo {
    padding: 2rem;
  }
}
.modal--typo .modal__typo-form {
  margin-top: 4rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
@media all and (max-width: 576px) {
  .modal--typo .modal__typo-form {
    margin-top: 3rem;
  }
}
.modal--typo .form__sent-text-wrapper {
  margin: 1.5rem 0 2rem 0;
  background: #F7F8F9;
  padding: 2rem 2rem 3rem;
}
@media all and (max-width: 576px) {
  .modal--typo .form__sent-text-wrapper {
    margin: 0.5rem 0 1.5rem 0;
    padding: 2rem;
  }
}
.modal--typo .form__send-text {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.5rem;
}
.modal--typo .form__send-text .typo {
  color: #C71F1F;
}
.modal--typo .typo-form__submit-button-wrapper {
  margin-top: 4.5rem;
}
@media all and (max-width: 576px) {
  .modal--typo .typo-form__submit-button-wrapper {
    margin-top: 3rem;
  }
}
.modal--typo .typo-form__submit-button {
  min-width: 18rem;
}
@media all and (max-width: 375px) {
  .modal--typo .typo-form__submit-button {
    width: 100%;
  }
}
.modal--attach-resume {
  width: 62rem;
  padding: 3rem 4.5rem 2.5rem;
}
@media all and (max-width: 576px) {
  .modal--attach-resume {
    padding: 2rem 2rem 0;
  }
}
.modal--attach-resume .resume-form {
  margin-top: 4rem;
}
@media all and (max-width: 576px) {
  .modal--attach-resume .resume-form {
    margin-top: 3rem;
  }
}
.modal--attach-resume .input-wrapper--phone {
  max-width: 26.7rem;
}
@media all and (max-width: 576px) {
  .modal--attach-resume .input-wrapper--phone {
    max-width: unset;
  }
}
.modal--attach-resume .form__upload-wrapper {
  margin-top: 4.5rem;
}
@media all and (max-width: 576px) {
  .modal--attach-resume .form__upload-wrapper {
    margin-top: 3rem;
  }
}
.modal--attach-resume .form__submit-button-wrapper {
  margin: 4rem 0 2rem;
}
@media all and (max-width: 576px) {
  .modal--attach-resume .form__submit-button-wrapper {
    margin: 2rem 0 1rem;
  }
}
.modal--attach-resume .form__submit-button {
  font-size: 1.6rem;
  line-height: 2.7rem;
}
.modal--attach-resume .form__label.label--upload-resume {
  border-color: #00923F;
  color: #00923F;
}
.modal--thanks {
  vertical-align: middle;
  width: unset;
  padding: 4rem 6rem 4.4rem;
  border-radius: 0.4rem;
}
@media all and (max-width: 576px) {
  .modal--thanks {
    padding: 3.6rem 3rem 4.3rem;
  }
}
@media all and (max-width: 375px) {
  .modal--thanks {
    width: 100%;
  }
}
.modal--thanks .modal__content {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal--thanks .modal__thanks-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 5.6rem;
  height: 5.6rem;
  background: #D6F3E2;
  border-radius: 50%;
}
.modal--thanks .modal__thanks-img {
  vertical-align: top;
  max-width: 100%;
}
.modal--thanks .modal__thank-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal--thanks .modal__thank-text.modal__thank-text {
  display: none;
}
.modal--thanks .modal__thank-text.modal__thank-text.thank-text--subscribe .modal__desc {
  display: none;
}
.modal--thanks .modal__title {
  margin: 2rem 0 1.4rem;
  font-weight: 700;
  display: block;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .modal--thanks .modal__title {
    font-size: 1.9rem;
    line-height: 3rem;
  }
}
.modal--thanks .modal__desc {
  width: 25.5rem;
  margin: 0 auto;
  font-size: 1.6rem;
  line-height: 2.5rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .modal--thanks .modal__desc {
    max-width: 24.1rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}
.modal--photo {
  width: 55.7rem;
  vertical-align: top;
  margin-top: 26.7rem;
}
@media all and (max-width: 576px) {
  .modal--photo {
    margin-top: 12.1rem;
  }
}
@media all and (max-width: 375px) {
  .modal--photo .modal__title {
    padding-right: 5rem;
  }
}
.modal--photo .form__error-msg {
  top: 0;
}
.modal--privacy {
  max-width: 90rem;
  padding: 4.7rem 9.2rem 1.6rem 5.6rem;
}
@media all and (max-width: 768px) {
  .modal--privacy {
    padding: 3.1rem 6rem 1.6rem 3.1rem;
  }
}
@media all and (max-width: 480px) {
  .modal--privacy {
    padding: 3.1rem 2rem 1.6rem 2rem;
  }
}
.modal--privacy .modal__title {
  font-size: 3.2rem;
  line-height: 5.1rem;
}
@media all and (max-width: 768px) {
  .modal--privacy .modal__title {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }
}
@media all and (max-width: 576px) {
  .modal--privacy .modal__title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}
.modal--privacy .modal__content {
  margin-top: 2.5rem;
}
.modal--privacy .modal__content.content--privacy {
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 768px) {
  .modal--privacy .modal__content.content--privacy {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.modal--privacy .modal__content.content--privacy p + p {
  margin-top: 3rem;
}
@media all and (max-width: 480px) {
  .modal--privacy .modal__content.content--privacy p + p {
    margin-top: 2.5rem;
  }
}
.modal--privacy .modal__privacy-text {
  margin-top: 2.5rem;
  color: #000000;
}
.modal--privacy .modal__privacy-text p + p {
  margin-top: 3rem;
}
.modal--privacy .fancybox-close-small {
  top: 2.1rem;
  right: 2.1rem;
  width: 4rem;
  height: 4rem;
  padding: 0;
}
@media all and (max-width: 768px) {
  .modal--privacy .fancybox-close-small {
    top: 1.1rem;
    right: 1.1rem;
    width: 3.6rem;
    height: 3.6rem;
  }
}
.modal--privacy .fancybox-close-small path {
  fill: #000;
}
.modal--order .modal__title {
  display: block;
  text-align: center;
}
.modal--consult, .modal--order {
  vertical-align: middle;
  max-width: 44rem;
  padding: 4.1rem 4.5rem;
  border-radius: 0.4rem;
}
@media all and (max-width: 576px) {
  .modal--consult, .modal--order {
    padding: 2rem 2rem 0.9rem;
  }
}
.modal--consult .modal__desc, .modal--order .modal__desc {
  display: block;
  margin-top: 1.3rem;
  text-align: center;
  color: #3F4C59;
}
.modal--consult .consult-form, .modal--order .consult-form {
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  .modal--consult .consult-form, .modal--order .consult-form {
    margin-top: 2.5rem;
  }
}
.modal--consult .consult-form__submit-button-wrapper, .modal--order .consult-form__submit-button-wrapper {
  margin: 3.7rem 0 2.9rem;
}
@media all and (max-width: 576px) {
  .modal--consult .consult-form__submit-button-wrapper, .modal--order .consult-form__submit-button-wrapper {
    margin: 3.1rem 0 2rem;
  }
}
.modal--consult .consult-form .form__submit-button, .modal--order .consult-form .form__submit-button {
  font-size: 1.6rem;
  line-height: 2.7rem;
  width: 100%;
}
.modal--step, .modal--read-more {
  padding: 0;
  max-width: 125rem;
  border-radius: 0.4rem;
}
.modal--step .modal-step-content, .modal--read-more .modal-step-content {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 992px) {
  .modal--step .modal-step-content, .modal--read-more .modal-step-content {
    flex-direction: column;
  }
}
.modal--step .modal-step-content__text, .modal--read-more .modal-step-content__text {
  font-size: 1.9rem;
  line-height: 3rem;
  padding: 4.8rem 5.8rem 5.2rem;
}
@media all and (max-width: 576px) {
  .modal--step .modal-step-content__text, .modal--read-more .modal-step-content__text {
    padding: 4.5rem 2rem 3.4rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.modal--step .modal-step-content__text p + p, .modal--read-more .modal-step-content__text p + p {
  margin-top: 1.6rem;
}
@media all and (max-width: 576px) {
  .modal--step .modal-step-content__text p + p, .modal--read-more .modal-step-content__text p + p {
    margin-top: 1.2rem;
  }
}
.modal--step .modal-step-content__text h4, .modal--read-more .modal-step-content__text h4 {
  margin: 4.9rem 0 2.6rem;
  font-weight: 900;
  font-size: 2.8rem;
  line-height: 3.5rem;
}
@media all and (max-width: 576px) {
  .modal--step .modal-step-content__text h4, .modal--read-more .modal-step-content__text h4 {
    margin: 3.5rem 0 2rem;
    font-size: 2.4rem;
    line-height: 3rem;
  }
}
.modal--step .modal-step-content__feedback, .modal--read-more .modal-step-content__feedback {
  flex: 0 0 46.6rem;
  border-radius: 0 0.4rem 0.4rem 0;
  padding: 4.3rem 5.8rem 2.5rem;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .modal--step .modal-step-content__feedback, .modal--read-more .modal-step-content__feedback {
    flex: unset;
  }
}
@media all and (max-width: 576px) {
  .modal--step .modal-step-content__feedback, .modal--read-more .modal-step-content__feedback {
    padding: 3.2rem 3rem 4rem;
  }
}
.modal--step .modal-step-content__title, .modal--read-more .modal-step-content__title {
  display: block;
  width: 100%;
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 4rem;
}
@media all and (max-width: 992px) {
  .modal--step .modal-step-content__title, .modal--read-more .modal-step-content__title {
    text-align: center;
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
}
@media all and (max-width: 768px) {
  .modal--step .modal-step-content__title, .modal--read-more .modal-step-content__title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 576px) {
  .modal--step .modal-step-content__title, .modal--read-more .modal-step-content__title {
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: left;
  }
}
.modal--step .modal-step-content__form, .modal--read-more .modal-step-content__form {
  margin: 2.7rem auto 0;
  max-width: 46rem;
}
.modal--step .modal-step-content__form .form__input, .modal--read-more .modal-step-content__form .form__input {
  height: 6.4rem;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 576px) {
  .modal--step .modal-step-content__form .form__input, .modal--read-more .modal-step-content__form .form__input {
    font-size: 1.6rem;
    line-height: 2.8rem;
    height: 5.4rem;
  }
}
.modal--step .modal-step-content__form .form__input-outer.field--invalid .field--requared, .modal--read-more .modal-step-content__form .form__input-outer.field--invalid .field--requared {
  background: rgba(199, 31, 31, 0.03);
  border: 0.2rem solid #c71f1f;
}
.modal--step .modal-step-content__form .form__input-outer.field--invalid .field--requared::placeholder, .modal--read-more .modal-step-content__form .form__input-outer.field--invalid .field--requared::placeholder {
  color: rgba(199, 31, 31, 0.6);
}
.modal--step .modal-step-content__form .form__input-outer::after, .modal--read-more .modal-step-content__form .form__input-outer::after {
  display: none;
}
.modal--step .modal-step-content .consult-form__submit-button-wrapper, .modal--read-more .modal-step-content .consult-form__submit-button-wrapper {
  text-align: center;
  margin-bottom: 2rem;
}
.modal--step .modal-step-content .form__submit-button, .modal--read-more .modal-step-content .form__submit-button {
  width: 100%;
  max-width: 40.6rem;
}
@media all and (min-width: 1300px) {
  .modal--step .fancybox-close-small, .modal--read-more .fancybox-close-small {
    top: 0.4rem;
    right: -3.2rem;
  }
}
@media all and (min-width: 1360px) {
  .modal--step .fancybox-close-small path, .modal--read-more .fancybox-close-small path {
    fill: #ffffff;
  }
}
.modal__scroll-top {
  position: sticky;
  bottom: 1.6rem;
  height: 5rem;
  text-align: right;
}
.modal__scroll-top-button {
  position: relative;
  right: -7.6rem;
  bottom: 0;
  width: 5rem;
  height: 5rem;
  border: 0.2rem solid #000;
  border-radius: 50%;
  font-size: 0;
  background: white url("../img/scroll-top-arrow.svg") center center/auto no-repeat;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  .modal__scroll-top-button {
    right: -4.4rem;
    height: 4.2rem;
    width: 4.2rem;
  }
}
@media all and (max-width: 480px) {
  .modal__scroll-top-button {
    right: -0.9rem;
  }
}

.fancybox-container .fancybox-slide {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.fancybox-container .fancybox-slide::-webkit-scrollbar {
  display: none;
}
.fancybox-container .fancybox-bg {
  background: rgba(2, 20, 28, 0.3);
}
.fancybox-container .fancybox-slide--html {
  padding: 1rem;
}

.fancybox-content .fancybox-close-small {
  color: #000000;
}

.fancybox-navigation .fancybox-button {
  display: block;
  padding: 0;
  opacity: 1;
  background: transparent;
  width: 4.8rem;
  height: 4.8rem;
  top: 50%;
  transform: translateY(-50%);
}
@media all and (max-width: 576px) {
  .fancybox-navigation .fancybox-button {
    width: 3.5rem;
    height: 3.5rem;
  }
}
.fancybox-navigation .fancybox-button:disabled {
  opacity: 0.4;
}
.fancybox-navigation .fancybox-button.fancybox-button--arrow_left {
  left: 1rem;
}
.fancybox-navigation .fancybox-button.fancybox-button--arrow_left div {
  transform: rotate(180deg);
}
.fancybox-navigation .fancybox-button.fancybox-button--arrow_right {
  right: 1rem;
}
.fancybox-navigation .fancybox-button div {
  width: 4.8rem;
  height: 4.8rem;
  padding: 0;
  background: #00923F url(../img/arrow-next.svg) center center/auto no-repeat;
  border-radius: 50%;
}
@media all and (max-width: 576px) {
  .fancybox-navigation .fancybox-button div {
    width: 3.5rem;
    height: 3.5rem;
    background: #00923F url(../img/arrow-next.svg) center center/25% no-repeat;
  }
}
.fancybox-navigation .fancybox-button div svg {
  display: none;
}

.error-widget {
  display: none;
  width: 35.3rem;
  height: 10rem;
  position: fixed;
  right: 3.2rem;
  bottom: 3.2rem;
  padding: 2.5rem 7rem 2.5rem 3.4rem;
  background: white;
  font-size: 1.5rem;
  line-height: 2.5rem;
  border-left: 0.3rem solid #cc1212;
  box-shadow: 0 2rem 5rem rgba(0, 55, 84, 0.07);
  z-index: 10;
}
.error-widget__close {
  position: absolute;
  top: 2.1rem;
  right: 2.1rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
.error-widget__close::before, .error-widget__close::after {
  content: "";
  display: block;
  width: 2.1rem;
  height: 0.2rem;
  background: #89929D;
  position: absolute;
  top: calc(50% - 0.1rem);
  left: calc(50% - 1rem);
}
.error-widget__close::before {
  transform: rotate(45deg);
}
.error-widget__close::after {
  transform: rotate(-45deg);
}
.news-gallery-slider {
  max-width: calc(100% - 10rem);
  margin: 5.8rem auto 0;
}
@media all and (max-width: 992px) {
  .news-gallery-slider {
    max-width: 100%;
    margin-top: 4rem;
  }
}
@media all and (max-width: 576px) {
  .news-gallery-slider {
    max-width: 100%;
    margin-top: 2rem;
  }
}
.news-gallery-slider__item {
  padding: 0 1rem;
  border-radius: 0.5rem;
}
@media all and (max-width: 768px) {
  .news-gallery-slider__item {
    padding: 0 1rem;
  }
}
.news-gallery-slider__item:focus {
  outline: none;
}
.news-gallery-slider__item-img {
  vertical-align: top;
  width: 100%;
  height: 20rem;
  object-fit: cover;
  border-radius: 0.5rem;
}
@media all and (max-width: 1160px) {
  .news-gallery-slider__item-img {
    height: 20rem;
  }
}
.news-gallery-slider__item-img:focus {
  outline: none;
}
.news-gallery-slider__item-zoom-link:focus {
  outline: none;
}
.news-gallery-slider .slick-prev, .news-gallery-slider .slick-next {
  width: 4.4rem;
  height: 6.4rem;
  transform: translate(0, -50%);
  z-index: 2;
}
@media all and (max-width: 576px) {
  .news-gallery-slider .slick-prev, .news-gallery-slider .slick-next {
    height: 5.4rem;
  }
}
.news-gallery-slider .slick-prev:hover::before, .news-gallery-slider .slick-next:hover::before {
  background: #309C5F url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
}
.news-gallery-slider .slick-prev::before, .news-gallery-slider .slick-next::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #00923f url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
  border-radius: 0.3rem;
  opacity: 1;
}
.news-gallery-slider .slick-prev.slick-disabled::before, .news-gallery-slider .slick-next.slick-disabled::before {
  background: #309C5F url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
}
.news-gallery-slider .slick-prev {
  left: -5.8rem;
}
@media all and (max-width: 992px) {
  .news-gallery-slider .slick-prev {
    left: 0;
  }
}
.news-gallery-slider .slick-prev::before {
  transform: rotate(180deg);
}
.news-gallery-slider .slick-next {
  right: -5.8rem;
}
@media all and (max-width: 992px) {
  .news-gallery-slider .slick-next {
    right: 0;
  }
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
.news-block {
  padding: 4.5rem 0 5.4rem;
}

.news-block__all-news {
  margin-top: 6.5rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .news-block__all-news {
    margin-top: 4.5rem;
  }
}
.news-block__all-news .all-news__link {
  display: inline-block;
  padding-right: 6rem;
  position: relative;
  font-weight: 700;
  font-size: 2rem;
  line-height: 3.2rem;
  color: #09121C;
  transition: 0.2s;
}
.news-block__all-news .all-news__link:hover {
  color: #00923F;
}
.news-block__all-news .all-news__link::after {
  content: "";
  position: absolute;
  right: 0;
  top: calc(50% - 2.5rem);
  display: block;
  width: 5rem;
  height: 5rem;
  background: #00923F url("../img/arrow-next.svg") center center no-repeat;
  background-size: auto;
  border-radius: 50%;
}

.content-section__news-list .news-list__item + .news-list__item {
  margin-top: 5.1rem;
}
@media all and (max-width: 480px) {
  .content-section__news-list .news-list__item + .news-list__item {
    margin-top: 3.5rem;
  }
}
.content-section__news-list .news-list__item {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media all and (max-width: 480px) {
  .content-section__news-list .news-list__item {
    padding-top: 2.6rem;
  }
}
.content-section__news-list .news-list__item.item--no-photo .news-list__item-photo {
  display: none;
}
.content-section__news-list .news-list__item-info {
  flex: 1;
}
.content-section__news-list .news-list__item-info .item-info__meta {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #727272;
}
@media all and (max-width: 992px) {
  .content-section__news-list .news-list__item-info .item-info__meta {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
@media all and (max-width: 480px) {
  .content-section__news-list .news-list__item-info .item-info__meta {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.content-section__news-list .news-list__item-info .item-info__meta-date {
  display: inline-block;
  margin-left: 1.5rem;
  font-weight: 400;
}
.content-section__news-list .news-list__item-info .item-info__meta-tag {
  color: #00923F;
}
.content-section__news-list .news-list__item-info .item-info__title {
  max-width: 60rem;
  margin-top: 0.8rem;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3rem;
  color: #000000;
}
@media all and (max-width: 992px) {
  .content-section__news-list .news-list__item-info .item-info__title {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 480px) {
  .content-section__news-list .news-list__item-info .item-info__title {
    margin-top: 0;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.content-section__news-list .news-list__item-info .item-info__title-link:hover {
  color: #000000;
}
.content-section__news-list .news-list__item-info .item-info__title-link:hover .underline {
  border-bottom: 2px solid rgba(9, 18, 28, 0.5);
}
.content-section__news-list .news-list__item-info .item-info__title-link .underline {
  border-bottom: 2px solid transparent;
  transition: 0.1s;
}
.content-section__news-list .news-list__item-info .item-info__desc {
  margin-top: 0.7rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #747474;
}
@media all and (max-width: 992px) {
  .content-section__news-list .news-list__item-info .item-info__desc {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
.content-section__news-list .news-list__item-photo {
  flex-basis: 16rem;
  width: 16rem;
  height: 16.9rem;
  margin-left: 5.3rem;
}
@media all and (max-width: 1200px) {
  .content-section__news-list .news-list__item-photo {
    margin-left: 3rem;
  }
}
@media all and (max-width: 992px) {
  .content-section__news-list .news-list__item-photo {
    flex-basis: 12rem;
    width: 12rem;
    height: 12.7rem;
    margin-left: 2rem;
  }
}
@media all and (max-width: 480px) {
  .content-section__news-list .news-list__item-photo {
    flex-basis: 7.3rem;
    width: 7.3rem;
    height: 7.7rem;
  }
}
.content-section__news-list .news-list__item-photo .item-photo__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media all and (max-width: 375px) {
  .content-section__no-result {
    padding: 0 1rem;
  }
}
.content-section__no-result .no-result_text {
  font-size: 2.2rem;
  line-height: 3rem;
}
.content-section__no-result .no-result_text-query {
  font-weight: 700;
}
.content-section__no-result .no-result__reasons {
  margin-top: 2.6rem;
}
.content-section__no-result .no-result__reasons-item {
  position: relative;
  font-size: 1.6rem;
  line-height: 2.8rem;
  padding-left: 2.1rem;
}
@media all and (max-width: 375px) {
  .content-section__no-result .no-result__reasons-item {
    margin-bottom: 1.4rem;
  }
}
.content-section__no-result .no-result__reasons-item:last-child {
  margin-bottom: 0;
}
.content-section__no-result .no-result__reasons-item::before {
  content: "";
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 1.1rem;
  left: 0;
  border-radius: 50%;
  background: #000;
}

.content-section__news-article .news-article__meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-section__news-article .news-article__date {
  margin-right: 2rem;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #000000;
  opacity: 0.5;
}
@media all and (max-width: 375px) {
  .content-section__news-article .news-article__date {
    margin-right: 1.5rem;
  }
}
.content-section__news-article .news-article__tag {
  display: inline-block;
  padding: 1.1rem 1.1rem 0.9rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: #00923F;
  color: white;
}
@media all and (max-width: 480px) {
  .content-section__news-article .news-article__tag {
    padding: 0;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-transform: none;
    background: transparent;
    color: #00923F;
  }
}
.content-section__news-article .news-article__text {
  margin-top: 3.9rem;
}
@media all and (max-width: 480px) {
  .content-section__news-article .news-article__text {
    margin-top: 2.8rem;
  }
}
.content-section__news-article .news-article__text p {
  margin-bottom: 2.7rem;
}
.content-section__news-article .news-article__text p:last-child {
  margin-bottom: 0;
}
.pagination-block {
  margin-top: 6.7rem;
}
@media all and (max-width: 576px) {
  .pagination-block {
    margin-top: 4.6rem;
  }
}

.pagination-block__pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-list__item .item--pagination-link {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 50%;
  color: #000000;
}
@media all and (max-width: 576px) {
  .pagination-list__item .item--pagination-link {
    width: 3.8rem;
    height: 3.8rem;
  }
}
.pagination-list__item .item--pagination-link:hover {
  color: #000000;
}
@media all and (max-width: 576px) {
  .pagination-list__item.item--prev, .pagination-list__item.item--next {
    display: none;
  }
}
.pagination-list__item.item--prev .item--pagination-link, .pagination-list__item.item--next .item--pagination-link {
  margin: 0 2rem;
  width: 10rem;
  border-radius: 3rem;
  background: #F3F4F6;
  transition: 0.15s;
}
.pagination-list__item.item--current-page .item--pagination-link {
  background: #00923F;
  color: white;
}
.pagination-list__item.item--current-page .item--pagination-link:hover {
  color: white;
}
.pagination-list__item:hover .item--pagination-link {
  color: #00923F;
}

@media all and (max-width: 992px) {
  .pagination-block.pagination--in-content .pagination-list__item.item--prev, .pagination-block.pagination--in-content .pagination-list__item.item--next {
    display: none;
  }
}
@media all and (max-width: 768px) {
  .pagination-block.pagination--in-content .pagination-list__item.item--prev, .pagination-block.pagination--in-content .pagination-list__item.item--next {
    display: block;
  }
}
@media all and (max-width: 576px) {
  .pagination-block.pagination--in-content .pagination-list__item.item--prev, .pagination-block.pagination--in-content .pagination-list__item.item--next {
    display: none;
  }
}

.pagination-block.pagination--white-btn .pagination-list__item.item--prev .item--pagination-link, .pagination-block.pagination--white-btn .pagination-list__item.item--next .item--pagination-link {
  background: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
.wrapper--projects-page {
  background: #F7F7F7;
}

.our-projects-block {
  position: relative;
  padding: 7.9rem 0 2rem;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  .our-projects-block {
    padding: 2.2rem 0 2rem;
  }
}
@media all and (max-width: 576px) {
  .our-projects-block {
    padding: 2.2rem 0 2rem;
  }
}
.our-projects-block .our-projects-block__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.our-projects-block .our-projects-block__header .our-projects-block__all-projects-link {
  color: #000000;
  font-weight: 700;
}
@media all and (max-width: 767px) {
  .our-projects-block .our-projects-block__header .our-projects-block__all-projects-link {
    display: none;
  }
}
.our-projects-block .our-projects-block__title {
  position: relative;
  z-index: 2;
}

.our-projects-block__sort-projects {
  position: relative;
  z-index: 2;
}

.sort-projects__tag-panel {
  margin-top: 2.4rem;
}
@media all and (max-width: 992px) {
  .sort-projects__tag-panel {
    max-width: 83rem;
    display: flex;
    justify-content: space-between;
  }
}
@media all and (max-width: 767px) {
  .sort-projects__tag-panel {
    display: none;
  }
}
.sort-projects__tag-panel .tag-panel__tag-projects {
  display: inline-flex;
  align-items: center;
  height: 3.5rem;
  padding: 0 1.3rem 0 1.1rem;
  background: #D1D1D1;
  margin-right: 1.3rem;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
  border: none;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
}
@media all and (max-width: 992px) {
  .sort-projects__tag-panel .tag-panel__tag-projects {
    font-size: 1.2rem;
    margin-right: 0;
  }
}
.sort-projects__tag-panel .tag-panel__tag-projects:hover {
  background: #C1C1C1;
}
.sort-projects__tag-panel .tag-panel__tag-projects:disabled {
  color: rgba(55, 55, 55, 0.4);
}
.sort-projects__tag-panel .tag-panel__tag-projects:disabled:hover {
  background: #D1D1D1;
}
.sort-projects__tag-panel .tag-panel__tag-projects.tag-projects--active {
  color: white;
  background: #141414;
}

.sort-projects__slider-projects {
  margin-top: 1rem;
}
.sort-projects__slider-projects .slick-prev, .sort-projects__slider-projects .slick-next {
  width: 5.6rem;
  height: 5.6rem;
  top: 17rem;
  transition: 0.2s;
  z-index: 2;
}
.sort-projects__slider-projects .slick-prev:hover:before, .sort-projects__slider-projects .slick-next:hover:before {
  background: #DEDEDE url("../img/slider-arrow.svg") center center no-repeat;
}
.sort-projects__slider-projects .slick-prev::before, .sort-projects__slider-projects .slick-next::before {
  content: "";
  display: block;
  width: 5.6rem;
  height: 5.6rem;
  background: #E8E8E8 url("../img/slider-arrow.svg") center center no-repeat;
  border-radius: 50%;
  opacity: 1;
}
.sort-projects__slider-projects .slick-prev.slick-disabled, .sort-projects__slider-projects .slick-next.slick-disabled {
  opacity: 0;
}
.sort-projects__slider-projects .slick-prev {
  left: -8.6rem;
}
@media all and (max-width: 1440px) {
  .sort-projects__slider-projects .slick-prev {
    left: -2.8rem;
  }
}
.sort-projects__slider-projects .slick-prev:before {
  transform: rotate(180deg);
}
.sort-projects__slider-projects .slick-next {
  right: -8.6rem;
}
@media all and (max-width: 1440px) {
  .sort-projects__slider-projects .slick-next {
    right: -2.8rem;
  }
}
@media all and (max-width: 1366px) {
  .sort-projects__slider-projects .slick-list {
    margin-left: 0;
  }
}
@media all and (max-width: 1024px) {
  .sort-projects__slider-projects .slick-list {
    margin-right: 0;
    margin-left: -1.5rem;
  }
}
@media all and (max-width: 767px) {
  .sort-projects__slider-projects .slick-list {
    margin-top: 3.2rem;
  }
}
@media all and (max-width: 640px) {
  .sort-projects__slider-projects .slick-list {
    margin-left: 0;
  }
}
@media all and (max-width: 576px) {
  .sort-projects__slider-projects .slick-list {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}
.sort-projects__slider-projects .slick-slide:focus {
  outline: none;
}
.sort-projects__slider-projects .slider-projects__slick-slide {
  width: 29rem;
  margin: 0 1.5rem;
  font-weight: 700;
  color: #000000;
}
@media all and (max-width: 1024px) {
  .sort-projects__slider-projects .slider-projects__slick-slide {
    width: unset;
    margin-right: 0;
  }
}
@media all and (max-width: 640px) {
  .sort-projects__slider-projects .slider-projects__slick-slide {
    width: unset;
    margin: 0;
  }
}
.sort-projects__slider-projects .slider-projects__slick-slide *:focus {
  outline: none;
}
.sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__img {
  width: 100%;
  height: 34rem;
  object-fit: cover;
  outline-offset: -1rem;
  transition: 0.25s;
}
@media all and (max-width: 992px) {
  .sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__img {
    height: 30rem;
  }
}
@media all and (max-width: 375px) {
  .sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__img {
    height: 21.2rem;
  }
}
.sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__meta {
  display: flex;
  margin-top: 2.2rem;
  align-items: center;
}
@media all and (max-width: 576px) {
  .sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__meta {
    padding: 0 2rem;
  }
}
.sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__meta .meta__date-project {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #84888D;
}
.sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__meta .meta__tag-project {
  margin-right: 2.2rem;
  display: inline-block;
  padding: 0.8rem 1.2rem;
  background: #00923F;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: white;
}
.sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__title {
  font-weight: 700;
  margin-top: 2.1rem;
  font-size: 2.2rem;
  line-height: 2.9rem;
}
@media all and (max-width: 640px) {
  .sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__title {
    font-size: 2.4rem;
  }
}
@media all and (max-width: 576px) {
  .sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__title {
    padding: 0 2rem;
  }
}
.sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__title .title_link {
  transition: 0.15s;
}
.sort-projects__slider-projects .slider-projects__slick-slide .slick-slide__title .title_link:hover {
  color: #00923F;
}
.sort-projects__slider-projects .slider-projects__slick-slide.last-project {
  width: 61rem;
}
@media all and (max-width: 1024px) {
  .sort-projects__slider-projects .slider-projects__slick-slide.last-project {
    width: unset;
  }
}
.sort-projects__slider-projects .slider-projects__slick-slide.last-project .slick-slide__title {
  margin-top: 1.8rem;
  font-size: 3.4rem;
  line-height: 4.1rem;
}
@media all and (max-width: 1024px) {
  .sort-projects__slider-projects .slider-projects__slick-slide.last-project .slick-slide__title {
    margin-top: 2.1rem;
    font-size: 2.2rem;
    line-height: 2.9rem;
  }
}
@media all and (max-width: 640px) {
  .sort-projects__slider-projects .slider-projects__slick-slide.last-project .slick-slide__title {
    font-size: 2.4rem;
  }
}
.sort-projects__slider-projects .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.3rem;
  background: rgba(57, 77, 100, 0.6);
  border-radius: 2rem;
  transition: 0.25s;
}
@media all and (max-width: 768px) {
  .sort-projects__slider-projects .slick-dots {
    bottom: -5rem;
    background: transparent;
    height: 1rem;
  }
}
@media all and (max-width: 576px) {
  .sort-projects__slider-projects .slick-dots {
    bottom: -4.7rem;
  }
}
.sort-projects__slider-projects .slick-dots li {
  display: block;
  width: 30rem;
  height: 100%;
  margin: 0;
}
@media all and (max-width: 768px) {
  .sort-projects__slider-projects .slick-dots li {
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
  }
}
.sort-projects__slider-projects .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
  transition: 0.25s;
  border-radius: 2rem;
}
.sort-projects__slider-projects .slick-dots li button::before {
  display: none;
}
@media all and (max-width: 768px) {
  .sort-projects__slider-projects .slick-dots li button {
    height: 1rem;
    background: #6C7381;
    border-radius: 50%;
  }
}
.sort-projects__slider-projects .slick-dots .slick-active button {
  background: #5A6F88;
}
@media all and (max-width: 768px) {
  .sort-projects__slider-projects .slick-dots .slick-active button {
    background: #00923F;
  }
}

.our-projects-block__link-wrapper {
  margin-top: 10rem;
  text-align: center;
  display: none;
}
@media all and (max-width: 767px) {
  .our-projects-block__link-wrapper {
    display: block;
  }
}

.our-projects-block__all-projects-link {
  display: inline-block;
  position: relative;
  border-radius: 0;
  color: #000000;
  padding-right: 6.2rem;
}
.our-projects-block__all-projects-link:hover {
  color: #000000;
}
.our-projects-block__all-projects-link:hover::after {
  box-shadow: 0 0 0 0.8rem rgba(0, 160, 69, 0.5);
}
.our-projects-block__all-projects-link::after {
  content: "";
  position: absolute;
  top: calc(50% - 2.3rem);
  right: 0;
  display: block;
  width: 4.6rem;
  height: 4.6rem;
  background: #00A045 url("../img/arrow-next.svg") center center no-repeat;
  background-size: auto;
  border-radius: 50%;
  box-shadow: 0 0 0 0.5rem rgba(0, 160, 69, 0.5);
  cursor: pointer;
  transition: 0.25s;
}
.our-projects-block__all-projects-link:focus {
  outline: none;
}

.content-section__projects-article .projects-article__sidebar-content {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  .content-section__projects-article .projects-article__sidebar-content {
    margin-top: 2.4rem;
  }
}
@media all and (max-width: 576px) {
  .content-section__projects-article .projects-article__sidebar-content {
    justify-content: flex-start;
    flex-direction: column;
  }
}
.content-section__projects-article .projects-article__sidebar-content .sidebar__widget {
  max-width: 28.8rem;
  margin-right: 2rem;
  margin-bottom: 3rem;
}
.content-section__projects-article .projects-article__sidebar-content .sidebar__widget .project-info__text {
  margin-bottom: 0;
}
.content-section__projects-article .projects-article__meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-section__projects-article .projects-article__date {
  margin-right: 2rem;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #000000;
  opacity: 0.5;
}
.content-section__projects-article .projects-article__text {
  margin-top: 3.7rem;
}
@media all and (max-width: 576px) {
  .content-section__projects-article .projects-article__text {
    margin-top: 2.7rem;
  }
}
.content-section__projects-article .projects-article__text p {
  margin-bottom: 2.7rem;
}
.content-section__projects-article .projects-article__text p:last-child {
  margin-bottom: 0;
}
.content-section__projects-article .projects-article__text h3 {
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 4.5rem;
  margin-bottom: 2.4rem;
}
@media all and (max-width: 576px) {
  .content-section__projects-article .projects-article__text h3 {
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
}
.content-section__projects-article .projects-article__photo {
  margin: 3.1rem 0 4.5rem;
}
@media all and (max-width: 576px) {
  .content-section__projects-article .projects-article__photo {
    margin: 1.5rem 0 3rem;
  }
}
.content-section__projects-article .projects-article__photo .photo__img-wrapper {
  position: relative;
}
@media all and (max-width: 375px) {
  .content-section__projects-article .projects-article__photo .photo__img-wrapper {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.content-section__projects-article .projects-article__photo .photo__img-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.content-section__projects-article .projects-article__photo .photo__gallery-link {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0;
  opacity: 0;
  z-index: 4;
}
.content-section__projects-article .projects-article__photo .photo__gallery-link.link--launcher {
  display: block;
}
.content-section__projects-article .projects-article__photo .photo__gallery-rem {
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 3.1rem;
  text-transform: uppercase;
  color: white;
  position: absolute;
  right: 2.2rem;
  bottom: 0.8rem;
  z-index: 3;
}
.content-section__projects-article .projects-article__photo .photo__gallery-rem::before {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: calc(50% - 1.2rem);
  left: -3rem;
  background: url("../img/gallery-icon.svg") center center/auto no-repeat;
}
.content-section__projects-article .projects-article__photo .photo__img {
  display: none;
  vertical-align: top;
  width: 100%;
}
.content-section__projects-article .projects-article__photo .photo__img.img--launcher {
  display: unset;
}
.content-section__projects-article .projects-article__photo .photo__caption {
  margin-top: 1.45rem;
  max-width: 44rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #808D94;
}
@media all and (max-width: 576px) {
  .content-section__projects-article .projects-article__photo .photo__caption {
    margin-top: 1.1rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
@media all and (max-width: 576px) {
  .wrapper--quality-page .content-block.block--quality {
    padding-bottom: 4.2rem;
  }
}

.content-quality p {
  margin-bottom: 2.7rem;
}
.content-quality p:last-child {
  margin-bottom: 0;
}
.content-quality h3 {
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 4.5rem;
  margin-bottom: 2.4rem;
}
@media all and (max-width: 576px) {
  .content-quality h3 {
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
}
.content-quality__list {
  margin-top: 3.2rem;
}
@media all and (max-width: 576px) {
  .content-quality__list {
    margin-top: 2rem;
  }
}
.content-quality__list .list__item {
  position: relative;
  margin-bottom: 2.5rem;
  padding-left: 3.5rem;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3.5rem;
}
@media all and (max-width: 992px) {
  .content-quality__list .list__item {
    font-size: 1.9rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 576px) {
  .content-quality__list .list__item {
    padding-left: 3.3rem;
  }
}
.content-quality__list .list__item::before {
  content: "";
  display: block;
  width: 2rem;
  position: absolute;
  left: 0;
  top: 1.5rem;
  border-bottom: 0.2rem solid #000000;
}
@media all and (max-width: 576px) {
  .content-quality__list .list__item::before {
    width: 1.7rem;
  }
}
.wrapper--resume-page {
  background: #F7F7F7;
}

.content-block.block--single-page.block--resume {
  padding: 1rem 0 7.7rem;
}
@media all and (max-width: 768px) {
  .content-block.block--single-page.block--resume {
    padding-bottom: 2rem;
  }
}
@media all and (max-width: 576px) {
  .content-block.block--single-page.block--resume {
    padding-bottom: 0.4rem;
  }
}

.resume-content__desc {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.resume-content__desc + .resume-content__desc {
  margin-top: 2.6rem;
}

.resume-form {
  margin-top: 5.6rem;
}
@media all and (max-width: 576px) {
  .resume-form {
    margin-top: 3rem;
  }
}
.resume-form__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media all and (max-width: 576px) {
  .resume-form__header {
    flex-direction: column;
  }
}
.resume-form__employee-photo {
  position: relative;
  flex-basis: 24.3rem;
  min-height: 30rem;
  margin-right: 2.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.4rem 1.6rem 1.5rem;
  border-radius: 0.6rem;
  border: 2px dashed #CBCBCB;
  overflow: hidden;
}
@media all and (max-width: 576px) {
  .resume-form__employee-photo {
    flex-basis: unset;
    width: 28rem;
    margin: 0 auto;
  }
}
.resume-form__employee-photo-wrapper {
  width: 16.5rem;
  height: 16.5rem;
  border-radius: 50%;
  overflow: hidden;
  border: 0.3rem solid #BEBEBE;
  text-align: center;
}
.resume-form__employee-photo-img {
  margin-top: 1.8rem;
}
.resume-form__employee-photo-button {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-top: auto;
  width: 100%;
  z-index: 3;
}
.resume-form__photo-uploaded {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}
.resume-form__photo-uploaded-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.resume-form__employee-full-name {
  flex: 1;
}
@media all and (max-width: 576px) {
  .resume-form__employee-full-name {
    margin-top: 2rem;
    width: 100%;
  }
}

.form__submit-button-wrapper {
  margin-top: 3.6rem;
  margin-bottom: 3rem;
}
@media all and (max-width: 576px) {
  .form__submit-button-wrapper {
    margin: 2.5rem 0 2.5rem;
    text-align: center;
  }
}

.form__submit-button {
  width: 18rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
@media all and (max-width: 375px) {
  .form__submit-button {
    width: 100%;
  }
}

.section__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11.6rem;
  text-align: center;
}
@media all and (max-width: 900px) {
  .section__title {
    height: 7.6rem;
  }
}
@media all and (max-width: 576px) {
  .section__title {
    height: unset;
    text-align: left;
    justify-content: flex-start;
  }
}

.section__subtitle {
  position: relative;
  display: flex;
  align-items: center;
  height: 5.5rem;
  border-bottom: 0.1rem solid #C8CFD6;
  border-top: 0.1rem solid #C8CFD6;
}
@media all and (max-width: 900px) {
  .section__subtitle {
    display: none;
  }
}
.section__subtitle::before {
  content: "";
  display: block;
  width: 4.3rem;
  height: 100%;
  position: absolute;
  top: -0.1rem;
  right: -4.3rem;
  border-top: 0.1rem solid #C8CFD6;
  border-bottom: 0.1rem solid #C8CFD6;
}
.section__subtitle-text {
  flex: 1;
  text-align: center;
}

.section__input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10.8rem;
  padding: 1rem 0.8rem;
}
@media all and (max-width: 900px) {
  .section__input {
    min-height: unset;
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 576px) {
  .section__input {
    flex-direction: column;
    padding: 0;
    margin-bottom: 1.5rem;
  }
}
.section__input.clone-section {
  padding: 0 0.8rem 1rem;
  min-height: 9.9rem;
}
.section__input .form__input-wrapper + .form__input-wrapper {
  margin-left: 1.6rem;
}
@media all and (max-width: 576px) {
  .section__input .form__input-wrapper + .form__input-wrapper {
    margin-left: 0;
    margin-top: 1.5rem;
  }
}
.section__input .form__input-wrapper {
  margin-bottom: 0;
}
.section__input .form__input-wrapper .calendar-icon {
  width: 3.1rem;
}
.section__input .form__input-wrapper .form__input {
  height: 4.1rem;
  font-size: 1.3rem;
  padding: 1.5rem 1rem 1.4rem;
}
.section__input .form__input-wrapper .form__input.field--date {
  padding: 1.5rem 1rem 1.4rem 3.5rem;
}
.section__input .form__input-wrapper .form__textarea {
  font-size: 1.4rem;
  padding: 1rem;
}

.input-inline__section {
  font-size: 1.5rem;
  line-height: 2.5rem;
}
@media all and (max-width: 900px) {
  .input-inline__section.section--expirience.section--period {
    grid-column: span 3;
  }
}
@media all and (max-width: 576px) {
  .input-inline__section.section--expirience.section--period {
    grid-column: unset;
  }
}
@media all and (max-width: 576px) {
  .input-inline__section.section--expirience.section--period .section__title {
    display: none;
  }
}
.input-inline__section.section--expirience.section--period .section__subtitle {
  display: flex;
}
@media all and (max-width: 576px) {
  .input-inline__section.section--expirience.section--period .section__subtitle {
    display: none;
  }
}
@media all and (max-width: 900px) {
  .input-inline__section.section--expirience.section--period .section__subtitle::before {
    display: none;
  }
}
.input-inline__section.section--relatives .section__title {
  height: 9rem;
  border-bottom: none;
}
@media all and (max-width: 900px) {
  .input-inline__section.section--relatives .section__title {
    height: 6rem;
  }
}
@media all and (max-width: 576px) {
  .input-inline__section.section--relatives .section__title {
    height: unset;
  }
}
.input-inline__section.section--relatives .section__input {
  padding: 0;
}
.input-inline__section.section--leave .section__title {
  height: 6.4rem;
  border-bottom: none;
}
@media all and (max-width: 900px) {
  .input-inline__section.section--leave .section__title {
    height: 6rem;
  }
}
@media all and (max-width: 576px) {
  .input-inline__section.section--leave .section__title {
    height: unset;
  }
}
.input-inline__section.section--leave .section__input {
  padding: 0;
}
.input-inline__section.section--span3 {
  grid-column: span 3;
}
@media all and (max-width: 576px) {
  .input-inline__section.section--span3 {
    grid-column: unset;
  }
}

.section__del-button {
  position: absolute;
  top: calc(50% - 1rem);
  right: -2.8rem;
  width: 2rem;
  height: 2rem;
  font-size: 0;
  border: none;
  background: transparent;
  opacity: 0.54;
  cursor: pointer;
}
@media all and (max-width: 900px) {
  .section__del-button {
    top: 1rem;
    right: 1rem;
  }
}
.section__del-button::before, .section__del-button::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.2rem;
  background: #58626B;
  position: absolute;
  top: calc(50% - 0.1rem);
}
.section__del-button::before {
  transform: rotate(45deg);
}
.section__del-button::after {
  transform: rotate(-45deg);
}
.wrapper--reviews-page .content-block.block--reviews {
  padding-bottom: 7.1rem;
}
@media all and (max-width: 576px) {
  .wrapper--reviews-page .content-block.block--reviews {
    padding-bottom: 3.1rem;
  }
}
@media all and (max-width: 576px) {
  .wrapper--reviews-page .pagination-block {
    margin-top: 3rem;
  }
}

.content-reviews__reviews-list {
  margin-top: 2.7rem;
  border-right: 1px solid #EBEDEF;
}
@media all and (max-width: 768px) {
  .content-reviews__reviews-list {
    margin-top: 0;
    border: none;
  }
}
.content-reviews__reviews-list .reviews-list__item {
  width: calc(100% + 4rem);
  margin-left: -4rem;
  display: flex;
  justify-content: space-between;
  padding: 3.9rem 5.3rem 5.3rem 4rem;
  border-bottom: 1px solid #EBEDEF;
}
@media all and (max-width: 1310px) {
  .content-reviews__reviews-list .reviews-list__item {
    padding-right: 2rem;
  }
}
@media all and (max-width: 992px) {
  .content-reviews__reviews-list .reviews-list__item {
    flex-direction: column;
  }
}
@media all and (max-width: 768px) {
  .content-reviews__reviews-list .reviews-list__item {
    padding: 2.1rem 2rem 2.3rem;
    margin-left: -2rem;
  }
}
.content-reviews__reviews-list .reviews-list__item:first-of-type {
  padding-top: 2rem;
}
@media all and (max-width: 576px) {
  .content-reviews__reviews-list .reviews-list__item:first-of-type {
    padding-top: 0;
  }
}
.content-reviews__reviews-list .reviews-list__item .item__review-title {
  flex-basis: 33.8rem;
  margin-right: 6rem;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.7rem;
}
@media all and (max-width: 1310px) {
  .content-reviews__reviews-list .reviews-list__item .item__review-title {
    font-size: 2rem;
    line-height: 2.6rem;
    flex-basis: 35%;
  }
}
@media all and (max-width: 992px) {
  .content-reviews__reviews-list .reviews-list__item .item__review-title {
    flex-basis: unset;
    margin-right: unset;
  }
}
.content-reviews__reviews-list .reviews-list__item .item__review-link {
  transition: 0.15s;
}
.content-reviews__reviews-list .reviews-list__item .item__review-link:hover {
  color: #00923F;
}
.content-reviews__reviews-list .reviews-list__item .item__review-link:hover .underline {
  border-bottom: 1px solid #00923F;
}
.content-reviews__reviews-list .reviews-list__item .item__review-info {
  flex: 1;
  max-width: 44.9rem;
  font-size: 1.6rem;
  line-height: 2.8rem;
}
@media all and (max-width: 992px) {
  .content-reviews__reviews-list .reviews-list__item .item__review-info {
    max-width: unset;
    margin-top: 2rem;
    padding-top: 4rem;
  }
}
.content-reviews__reviews-list .reviews-list__item .item__review-info .content__widget.widget--document {
  margin-top: 1.7rem;
}
@media all and (max-width: 576px) {
  .content-reviews__reviews-list .reviews-list__item .item__review-info .content__widget.widget--document {
    margin-top: 1.4rem;
  }
}
.content-reviews__reviews-list .reviews-list__item .item__review-info .content__widget.widget--document .info-document__title {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.8rem;
}
@media all and (max-width: 480px) {
  .content-reviews__reviews-list .reviews-list__item .item__review-info .content__widget.widget--document .info-document__title {
    line-height: 2.6rem;
  }
}
.content-reviews__reviews-list .reviews-list__item .item__review-text {
  position: relative;
}
.content-reviews__reviews-list .reviews-list__item .item__review-text::before {
  content: "";
  display: block;
  width: 3.3rem;
  height: 2.2rem;
  background: url("../img/quotes.svg") center center/auto no-repeat;
  position: absolute;
  top: 0;
  left: -5.4rem;
}
@media all and (max-width: 992px) {
  .content-reviews__reviews-list .reviews-list__item .item__review-text::before {
    top: -4rem;
    left: 0;
  }
}
.content-reviews__reviews-list .reviews-list__item .item__review-author {
  margin-top: 1.8rem;
}
@media all and (max-width: 576px) {
  .content-reviews__reviews-list .reviews-list__item .item__review-author {
    margin-top: 1.4rem;
  }
}
.content-reviews__reviews-list .reviews-list__item .item__review-author-name {
  font-weight: 900;
}

.content-reviews .content-reviews__sidebar-content {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  .content-reviews .content-reviews__sidebar-content {
    margin-top: 2.4rem;
  }
}
@media all and (max-width: 576px) {
  .content-reviews .content-reviews__sidebar-content {
    justify-content: flex-start;
    flex-direction: column;
  }
}
.content-reviews .content-reviews__meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-reviews .content-reviews__date {
  margin-right: 2rem;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #000000;
  opacity: 0.5;
}

.content-reviews__article {
  margin-top: 3.6rem;
}
@media all and (max-width: 576px) {
  .content-reviews__article {
    margin-top: 5.3rem;
  }
}
.content-reviews__article p {
  margin-bottom: 2.7rem;
}
.content-reviews__article p:last-child {
  margin-bottom: 0;
}
.content-reviews__article h3 {
  font-weight: 900;
  font-size: 3.2rem;
  line-height: 4.5rem;
  margin-bottom: 2.4rem;
}
@media all and (max-width: 576px) {
  .content-reviews__article h3 {
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
}
.content-reviews__article .content-reviews__author {
  font-weight: 900;
}
.content-reviews__article .content-reviews__photo {
  margin: 3.1rem 0 4.5rem;
}
@media all and (max-width: 576px) {
  .content-reviews__article .content-reviews__photo {
    margin: 1.5rem 0 3rem;
  }
}
.content-reviews__article .content-reviews__photo .photo__img-wrapper {
  position: relative;
}
@media all and (max-width: 375px) {
  .content-reviews__article .content-reviews__photo .photo__img-wrapper {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.content-reviews__article .content-reviews__photo .photo__img-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.content-reviews__article .content-reviews__photo .photo__gallery-link {
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 3.1rem;
  text-transform: uppercase;
  color: white;
  position: absolute;
  right: 2.2rem;
  bottom: 0.8rem;
  z-index: 2;
}
.content-reviews__article .content-reviews__photo .photo__gallery-link::before {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: calc(50% - 1.2rem);
  left: -3rem;
  background: url("../img/gallery-icon.svg") center center/auto no-repeat;
}
.content-reviews__article .content-reviews__photo .photo__img {
  vertical-align: top;
  width: 100%;
}
.content-reviews__article .content-reviews__photo .photo__caption {
  margin-top: 1.45rem;
  max-width: 44rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #808D94;
}
@media all and (max-width: 576px) {
  .content-reviews__article .content-reviews__photo .photo__caption {
    margin-top: 1.1rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
.wrapper--search-page {
  background: #F7F8F9;
}

.content-block__search-form {
  position: relative;
  margin-top: 5.5rem;
  width: 100%;
}
@media all and (max-width: 768px) {
  .content-block__search-form {
    margin-top: 4rem;
  }
}
@media all and (max-width: 576px) {
  .content-block__search-form {
    margin-top: 2.5rem;
  }
}
@media all and (max-width: 576px) {
  .content-block__search-form {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.content-block__search-form .search-form__input-search {
  width: 100%;
  padding: 2.1rem 8rem 1.7rem 3.1rem;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.7rem;
  color: #000000;
  border: none;
  border-radius: 0.3rem;
  background: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}
@media all and (max-width: 768px) {
  .content-block__search-form .search-form__input-search {
    padding: 1.9rem 8rem 1.7rem 2.5rem;
  }
}
@media all and (max-width: 576px) {
  .content-block__search-form .search-form__input-search {
    font-size: 1.6rem;
    line-height: 1.9rem;
    padding: 1.9rem 6rem 1.7rem 1.6rem;
  }
}
.content-block__search-form .search-form__input-search::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.content-block__search-form .search-form__input-search:focus {
  outline: none;
}
.content-block__search-form .search-form__button-search {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  width: 7rem;
  position: absolute;
  border: none;
  background: transparent url("../img/search-icon.svg") center center/auto no-repeat;
  font-size: 0;
  cursor: pointer;
}
@media all and (max-width: 576px) {
  .content-block__search-form .search-form__button-search {
    width: 5rem;
    background-size: 2rem;
  }
}

.search-result .bread-crumbs {
  margin-top: 1.3rem;
}
@media all and (max-width: 576px) {
  .search-result .bread-crumbs {
    margin-top: 1rem;
  }
}
.search-result__item {
  margin-bottom: 4.5rem;
}
@media all and (max-width: 576px) {
  .search-result__item {
    margin-bottom: 3rem;
  }
}
.search-result__title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.7rem;
  border-bottom: 1px solid #000000;
}
.search-result__title:hover {
  color: #00923F;
  border-bottom-color: #00923F;
}
@media all and (max-width: 768px) {
  .search-result__title {
    font-size: 1.8rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 576px) {
  .search-result__title {
    margin-bottom: 3rem;
  }
}
.search-result .title__link:hover {
  color: inherit;
}
.search-result__preview {
  margin-top: 1.3rem;
}
@media all and (max-width: 576px) {
  .search-result__preview {
    margin-top: 1rem;
  }
}
.select-hidden {
  display: none;
  visibility: hidden;
}

.select {
  flex: 1;
  cursor: pointer;
  position: relative;
  font-family: "Hyundai Sans Head Office", sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #909090;
  width: 100%;
  height: 5.4rem;
}
@media all and (max-width: 576px) {
  .select {
    height: 4.4rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}
.select--changed {
  color: #000000;
}

.select + .select .select-styled {
  border-left: none;
}

.select-styled {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 1.8rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  border: 1px solid #C8CFD6;
  border-radius: 0.4rem;
  z-index: 10;
}
@media all and (max-width: 576px) {
  .select-styled {
    padding-left: 1.4rem;
  }
}
.select-styled:after {
  content: "";
  width: 0;
  height: 0;
  border: 0.5rem solid transparent;
  border-color: #a5a5a5 transparent transparent transparent;
  position: absolute;
  top: 50%;
  right: 2rem;
}
@media all and (max-width: 576px) {
  .select-styled:after {
    right: 1.4rem;
  }
}
.select-styled.select--active:after {
  top: calc(50% - 0.5rem);
  border-color: transparent transparent #a5a5a5 transparent;
}

.select-options {
  width: 100%;
  display: none;
  position: absolute;
  top: calc(100% - 0.1rem);
  right: 0;
  left: 0;
  margin: 0;
  list-style: none;
  background-color: white;
  border-radius: 0.4rem;
  border: 1px solid #d5d5d5;
  z-index: 12;
  padding: 1rem 0;
  color: #000000;
}
.select-options li {
  display: flex;
  height: 3.5rem;
  align-items: center;
  padding-left: 1.8rem;
}
@media all and (max-width: 576px) {
  .select-options li {
    padding-left: 1.4rem;
  }
}
.select-options li:hover {
  background: #F3F3F3;
}
.select-options li:first-of-type {
  display: none;
}
.select-options li[rel=hide] {
  display: none;
}
.services-gallery {
  margin: 5rem 0 0;
}
.services-gallery .slick-arrow {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  z-index: 2;
}
.services-gallery .slick-arrow::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #017DC3 url("../img/arrow-next.svg") center center/auto no-repeat;
  opacity: 1;
}
.services-gallery .slick-arrow.slick-disabled {
  opacity: 0.4;
}
.services-gallery .slick-prev {
  left: 4rem;
}
.services-gallery .slick-prev::before {
  transform: rotate(180deg);
}
.services-gallery .slick-next {
  right: 4rem;
}

.slider-gallery__item {
  text-align: center;
}
@media all and (max-width: 680px) {
  .slider-gallery__item {
    margin-bottom: 2rem;
  }
}
.slider-gallery__item:focus {
  outline: none;
}
.slider-gallery__item-photo {
  max-width: 100%;
  width: 100%;
  height: 71.5rem;
  object-fit: cover;
  vertical-align: top;
}
@media all and (max-width: 1200px) {
  .slider-gallery__item-photo {
    height: 65rem;
  }
}
@media all and (max-width: 992px) {
  .slider-gallery__item-photo {
    height: 50rem;
  }
}
@media all and (max-width: 880px) {
  .slider-gallery__item-photo {
    height: 46rem;
  }
}
@media all and (max-width: 768px) {
  .slider-gallery__item-photo {
    height: 40rem;
  }
}
@media all and (max-width: 680px) {
  .slider-gallery__item-photo {
    height: unset;
  }
}
.slider-gallery__item-photo:focus {
  outline: none;
}

.slider-thumbnails {
  margin: 4rem 9rem 2rem;
}
@media all and (max-width: 992px) {
  .slider-thumbnails {
    margin: 3rem 6rem 0;
  }
}
@media all and (max-width: 680px) {
  .slider-thumbnails {
    display: none;
  }
}
.slider-thumbnails__item {
  text-align: center;
  margin: 1.5rem;
  cursor: pointer;
}
.slider-thumbnails__item:focus {
  outline: none;
}
.slider-thumbnails__item.item--current, .slider-thumbnails__item.slick-current {
  outline: 0.3rem solid #00923F;
}
.slider-thumbnails__item-photo {
  max-width: 15.5rem;
  width: 100%;
  height: 8.8rem;
  object-fit: cover;
}
@media all and (max-width: 1300px) {
  .slider-thumbnails__item-photo {
    max-width: unset;
  }
}
@media all and (max-width: 992px) {
  .slider-thumbnails__item-photo {
    height: 7rem;
  }
}
.slider-thumbnails__item-photo:focus {
  outline: none;
}
.slider-thumbnails .slick-prev {
  left: -9rem;
}
@media all and (max-width: 992px) {
  .slider-thumbnails .slick-prev {
    left: -6rem;
  }
}
.slider-thumbnails .slick-next {
  right: -9rem;
}
@media all and (max-width: 992px) {
  .slider-thumbnails .slick-next {
    right: -6rem;
  }
}
.slider-thumbnails .slick-next.slick-disabled {
  opacity: 1;
}
@charset "UTF-8";
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
.wrapper--services-page .services-block {
  margin: 4.7rem 0 4.3rem;
}
@media all and (max-width: 576px) {
  .wrapper--services-page .services-block {
    margin: 2.5rem 0 0;
  }
}

.services-block {
  margin: 4rem 0 8.5rem;
}
@media all and (max-width: 576px) {
  .services-block {
    margin: 2.5rem 0 2.1rem;
  }
}

.services-block__services-grid {
  margin-top: 3.7rem;
  display: grid;
  grid-template: auto/repeat(2, 1fr);
  grid-gap: 4rem;
}
@media all and (max-width: 768px) {
  .services-block__services-grid {
    grid-gap: 3rem;
  }
}
@media all and (max-width: 640px) {
  .services-block__services-grid {
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 576px) {
  .services-block__services-grid {
    margin-top: 2.5rem;
  }
}

.services-grid__item {
  background: white;
  box-shadow: 0 8rem 10rem rgba(0, 0, 0, 0.06);
}
@media all and (max-width: 576px) {
  .services-grid__item {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}
.services-grid__item .item__service-img {
  width: 100%;
  height: 23.6rem;
  object-fit: cover;
}
@media all and (max-width: 576px) {
  .services-grid__item .item__service-img {
    height: 18rem;
  }
}
.services-grid__item .item__service-info {
  display: flex;
  flex-direction: column;
  padding: 1.8rem 3.7rem 2.7rem;
}
@media all and (max-width: 1200px) {
  .services-grid__item .item__service-info {
    flex-direction: column;
  }
}
@media all and (max-width: 768px) {
  .services-grid__item .item__service-info {
    padding: 1.5rem 2.1rem 2.3rem;
  }
}
.services-grid__item .item__service-info .service-info__text .text__service-title {
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 3.25rem;
  line-height: 4rem;
}
@media all and (max-width: 576px) {
  .services-grid__item .item__service-info .service-info__text .text__service-title {
    font-size: 2.4rem;
    line-height: 3rem;
    max-width: 24rem;
  }
}
.services-grid__item .item__service-info .service-info__text .text__service-link {
  color: #09121C;
  transition: 0.2s;
}
.services-grid__item .item__service-info .service-info__text .text__service-link:hover {
  color: #00923F;
}
.services-grid__item .item__service-info .service-info__text .text-service__desc {
  margin-top: 2.9rem;
}
@media all and (max-width: 576px) {
  .services-grid__item .item__service-info .service-info__text .text-service__desc {
    margin-top: 2.3rem;
  }
}
.services-grid__item .service-info__add-links {
  margin-top: 0.5rem;
}
@media all and (max-width: 576px) {
  .services-grid__item .service-info__add-links {
    display: none;
  }
}
.services-grid__item .service-info__add-links .add-links__link {
  display: block;
  margin-top: 1.5rem;
  font-weight: 700;
  text-decoration: underline;
}

.content-section__service-article p {
  margin-bottom: 2.7rem;
}
.content-section__service-article p:last-child {
  margin-bottom: 0;
}
.content-section__service-article h3 {
  margin: 3.5rem 0 2.6rem;
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 3.8rem;
  line-height: 6.6rem;
}
@media all and (max-width: 992px) {
  .content-section__service-article h3 {
    margin: 3rem 0 2.6rem;
    font-size: 3.2rem;
    line-height: 4rem;
  }
}
@media all and (max-width: 576px) {
  .content-section__service-article h3 {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
}
.content-section__service-article ul {
  margin: 2.5rem 0;
}
.content-section__service-article ul li {
  list-style: none;
  position: relative;
  padding-left: 1.9rem;
}
.content-section__service-article ul li::before {
  content: "•";
  display: inline-block;
  position: absolute;
  top: 0.2rem;
  left: 0;
  font-size: 1.2rem;
  line-height: 3rem;
  margin-right: 1rem;
}
.content-section__service-article ul li + li {
  margin-top: 1.5rem;
}
.content-section__service-article .services__video {
  position: relative;
  margin-top: 4rem;
}
@media all and (max-width: 768px) {
  .content-section__service-article .services__video {
    margin-top: 2.7rem;
  }
}
@media all and (max-width: 375px) {
  .content-section__service-article .services__video {
    max-width: unset;
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.content-section__service-article .services__video .video__img {
  vertical-align: top;
  width: 100%;
}
.content-section__service-article .services__video .video__play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.6rem;
  height: 9.6rem;
  background: linear-gradient(253.53deg, #007DC3 27.33%, #005FA4 140.49%), #017DC3;
  position: absolute;
  top: calc(50% - 4.8rem);
  left: calc(50% - 4.8rem);
  border: none;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
}
@media all and (max-width: 992px) {
  .content-section__service-article .services__video .video__play-button {
    width: 7rem;
    height: 7rem;
    top: calc(50% - 3.5rem);
    left: calc(50% - 3.5rem);
  }
}
@media all and (max-width: 576px) {
  .content-section__service-article .services__video .video__play-button {
    width: 5.5rem;
    height: 5.5rem;
    top: calc(50% - 2.75rem);
    left: calc(50% - 2.75rem);
  }
}
.content-section__service-article .services__video .video__play-button:hover {
  background: #017DC3;
}
.content-section__service-article .services__video .video__play-button::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 1.25rem solid transparent;
  border-left: 1.7rem solid white;
  margin-left: 1.5rem;
}
@media all and (max-width: 992px) {
  .content-section__service-article .services__video .video__play-button::before {
    border: 1rem solid transparent;
    border-left: 1.2rem solid white;
    margin-left: 1.2rem;
  }
}
@media all and (max-width: 576px) {
  .content-section__service-article .services__video .video__play-button::before {
    border: 0.7rem solid transparent;
    border-left: 1rem solid white;
    margin-left: 0.85rem;
  }
}
.content-section__service-article .services__video .video__player {
  width: 100%;
}
.content-section__service-article .services__video .video__player[poster] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.content-section__service-article .services__video .video__player.player--no-controls::-webkit-media-controls {
  display: none;
}
.content-section__service-article .services__process-steps {
  margin-top: 4.8rem;
}
@media all and (max-width: 768px) {
  .content-section__service-article .services__process-steps {
    margin-top: 3.2rem;
  }
}
.content-section__service-article .services__process-steps .list__step {
  margin-bottom: 3.6rem;
  display: flex;
  align-items: center;
}
@media all and (max-width: 992px) {
  .content-section__service-article .services__process-steps .list__step {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
@media all and (max-width: 768px) {
  .content-section__service-article .services__process-steps .list__step {
    margin-bottom: 3.2rem;
  }
}
@media all and (max-width: 576px) {
  .content-section__service-article .services__process-steps .list__step {
    flex-direction: column;
    max-width: 40rem;
    margin: 0 auto 3.2rem;
  }
}
.content-section__service-article .services__process-steps .list__step:last-child {
  margin-bottom: 0;
}
.content-section__service-article .services__process-steps .list__step .step__img {
  max-width: 39.6rem;
  margin-right: 4.8rem;
}
@media all and (max-width: 1200px) {
  .content-section__service-article .services__process-steps .list__step .step__img {
    max-width: 45%;
    margin-right: 2rem;
  }
}
@media all and (max-width: 992px) {
  .content-section__service-article .services__process-steps .list__step .step__img {
    margin-top: 2.1rem;
    order: 2;
  }
}
@media all and (max-width: 576px) {
  .content-section__service-article .services__process-steps .list__step .step__img {
    max-width: 100%;
  }
}
@media all and (max-width: 375px) {
  .content-section__service-article .services__process-steps .list__step .step__img {
    max-width: unset;
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.content-section__service-article .services__process-steps .list__step .step__info {
  max-width: 37.4rem;
}
@media all and (max-width: 992px) {
  .content-section__service-article .services__process-steps .list__step .step__info {
    max-width: 50%;
    order: 3;
  }
}
@media all and (max-width: 576px) {
  .content-section__service-article .services__process-steps .list__step .step__info {
    max-width: 100%;
  }
}
.content-section__service-article .services__process-steps .list__step .step__info .step__title {
  display: inline;
}
@media all and (max-width: 992px) {
  .content-section__service-article .services__process-steps .list__step .step__info .step__title {
    display: none;
  }
}
.content-section__service-article .services__process-steps .list__step .step__title {
  display: none;
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4.7rem;
}
@media all and (max-width: 992px) {
  .content-section__service-article .services__process-steps .list__step .step__title {
    order: 1;
    width: 100%;
    display: inline-block;
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
.content-section__service-article .services__process-steps .list__step .step__desc {
  margin-top: 1.6rem;
}
.content-section__service-article .services__advantages-list {
  margin-top: 3.4rem;
}
@media all and (max-width: 576px) {
  .content-section__service-article .services__advantages-list {
    margin-top: 2rem;
  }
}
.content-section__service-article .services__advantages-list .advantages-list__item {
  position: relative;
  margin-bottom: 2.5rem;
  padding-left: 3.5rem;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3.5rem;
}
@media all and (max-width: 992px) {
  .content-section__service-article .services__advantages-list .advantages-list__item {
    font-size: 1.9rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 576px) {
  .content-section__service-article .services__advantages-list .advantages-list__item {
    padding-left: 3.3rem;
  }
}
.content-section__service-article .services__advantages-list .advantages-list__item::before {
  content: "";
  display: block;
  width: 2rem;
  position: absolute;
  left: 0;
  top: 1.5rem;
  border-bottom: 0.2rem solid #000000;
}
@media all and (max-width: 576px) {
  .content-section__service-article .services__advantages-list .advantages-list__item::before {
    width: 1.7rem;
  }
}

.main-grid__services-types {
  margin-top: 6rem;
}
@media all and (max-width: 992px) {
  .main-grid__services-types {
    margin-top: 4rem;
  }
}
@media all and (max-width: 768px) {
  .main-grid__services-types {
    margin-top: 0;
  }
}
.main-grid__services-types h3 {
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 3.8rem;
  line-height: 6.6rem;
}
@media all and (max-width: 992px) {
  .main-grid__services-types h3 {
    font-size: 3.2rem;
    line-height: 4rem;
  }
}
@media all and (max-width: 576px) {
  .main-grid__services-types h3 {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
}

.services-types__services-grid {
  margin-top: 3.4rem;
  display: grid;
  grid-template: auto/repeat(4, 1fr);
  grid-column-gap: 3.1rem;
  grid-row-gap: 2.2rem;
}
@media all and (max-width: 992px) {
  .services-types__services-grid {
    grid-template: auto/repeat(3, 1fr);
    grid-column-gap: 2.2rem;
  }
}
@media all and (max-width: 768px) {
  .services-types__services-grid {
    grid-template: auto/repeat(2, 1fr);
  }
}
@media all and (max-width: 576px) {
  .services-types__services-grid {
    grid-row-gap: 1rem;
    grid-template: auto/repeat(1, 1fr);
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}
.services-types__services-grid .services-grid__service .service__link {
  display: block;
  min-height: 18.8rem;
  height: 100%;
  padding: 2.8rem 3rem 2.8rem;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 3rem;
  color: #000000;
  background: #F7F7F7;
  border: 1px solid #eeeded;
  transition: 0.1s;
}
@media all and (max-width: 1200px) {
  .services-types__services-grid .services-grid__service .service__link {
    padding: 2rem;
  }
}
@media all and (max-width: 768px) {
  .services-types__services-grid .services-grid__service .service__link {
    min-height: 14.8rem;
  }
}
@media all and (max-width: 768px) {
  .services-types__services-grid .services-grid__service .service__link {
    min-height: unset;
  }
}
.services-types__services-grid .services-grid__service .service__link:hover {
  color: #00923F;
  border: 1px solid #74c898;
}
.services-types__services-grid .services-grid__service .service__link:hover .underline {
  transition: 0.1s;
  border-bottom: 1px solid #00923F;
}
.sidebar__widget {
  margin-bottom: 5.9rem;
}
@media all and (max-width: 768px) {
  .sidebar__widget {
    max-width: 30rem;
    margin-bottom: 4rem;
  }
}
@media all and (max-width: 576px) {
  .sidebar__widget.widget--news {
    display: none;
  }
}
.sidebar__widget.widget--news-filter {
  position: relative;
  padding: 4.1rem 3.4rem 2.1rem;
  background: #F7F7F7;
  border-radius: 0.4rem;
}
@media all and (max-width: 1200px) {
  .sidebar__widget.widget--news-filter {
    padding: 3rem 2rem 1rem;
  }
}
@media all and (max-width: 768px) {
  .sidebar__widget.widget--news-filter {
    padding: 1.4rem 2rem 1.4rem;
    width: 100%;
    max-width: 60rem;
  }
}
.sidebar__widget.widget--news-filter .widget__show-filter-button {
  display: none;
  position: relative;
  width: 100%;
  background: transparent;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  text-align: left;
  border-radius: 0;
  border: none;
  cursor: pointer;
}
.sidebar__widget.widget--news-filter .widget__show-filter-button:focus {
  outline: none;
}
.sidebar__widget.widget--news-filter .widget__show-filter-button::before {
  content: "";
  width: 0;
  height: 0;
  border: 0.5rem solid transparent;
  border-color: #a5a5a5 transparent transparent;
  position: absolute;
  top: 50%;
  right: 0;
}
.sidebar__widget.widget--news-filter .widget__show-filter-button.active::before {
  top: calc(50% - 0.5rem);
  transform: rotate(180deg);
}
@media all and (max-width: 768px) {
  .sidebar__widget.widget--news-filter .widget__show-filter-button {
    display: inline-block;
  }
}
@media all and (max-width: 768px) {
  .sidebar__widget.widget--news-filter .form-news-filter {
    margin-top: 2.2rem;
    display: none;
  }
}
.sidebar__widget.widget--news-filter .form__input-wrapper.wrapper--search::before {
  content: "";
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: calc(50% - 0.85rem);
  right: 2rem;
  background: url("../img/filter-search.svg") center center/auto no-repeat;
}
.sidebar__widget.widget--news-filter .form__input-wrapper .select {
  font-size: 1.5rem;
  line-height: 2rem;
}
.sidebar__widget.widget--news-filter .form__input-wrapper .select-styled {
  border-radius: 0.4rem;
  border: none;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 62, 98, 0.14), 0 0 0.1rem rgba(0, 62, 98, 0.5);
}
.sidebar__widget.widget--news-filter .form__input-wrapper .select-styled:hover {
  box-shadow: 0 0.1rem 0.1rem rgba(4, 36, 53, 0.24), 0 0 0.1rem #053049;
}
.sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline {
  margin-top: 3.6rem;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 480px) {
  .sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline {
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
  }
}
@media all and (max-width: 375px) {
  .sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
.sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline .form__select-wrapper {
  flex: 1;
  max-width: 15.4rem;
}
@media all and (max-width: 1200px) {
  .sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline .form__select-wrapper {
    max-width: 48%;
  }
}
@media all and (max-width: 375px) {
  .sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline .form__select-wrapper {
    flex: unset;
    max-width: 100%;
    width: 100%;
    margin-bottom: 1.1rem;
  }
}
@media all and (max-width: 375px) {
  .sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline .form__select-wrapper:last-child {
    margin-bottom: 0;
  }
}
@media all and (max-width: 375px) {
  .sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline .select + .select {
    margin-top: 1.1rem;
  }
}
.sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline .select-styled {
  font-size: 1.5rem;
  line-height: 2rem;
  border-radius: 0.4rem;
  border: none;
  box-shadow: 0 0.1rem 0.1rem rgba(46, 46, 46, 0.14), 0 0 0.1rem rgba(46, 46, 46, 0.5);
}
.sidebar__widget.widget--news-filter .form__input-wrapper.input-wrapper--inline .select-styled:hover {
  box-shadow: 0 0.1rem 0.1rem rgba(46, 46, 46, 0.24), 0 0 0.1rem #053049;
}
.sidebar__widget.widget--news-filter .form__button-wrapper {
  display: none;
  margin: 3.6rem 0 2rem;
}
@media all and (max-width: 768px) {
  .sidebar__widget.widget--news-filter .form__button-wrapper {
    margin: 3.1rem 0 1.7rem;
  }
}
.sidebar__widget.widget--news-filter .form__clear-filter-button {
  position: relative;
  background: transparent;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0 0 3.3rem;
  width: 15.8rem;
  height: 3.9rem;
  border: 0.1rem solid #00923F;
  border-radius: 0.4rem;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 2.2rem;
  color: #00923F;
  cursor: pointer;
  transition: 0.15s;
}
.sidebar__widget.widget--news-filter .form__clear-filter-button:hover {
  border: 0.1rem solid #00923F;
}
.sidebar__widget.widget--news-filter .form__clear-filter-button:focus {
  outline: none;
}
.sidebar__widget.widget--news-filter .form__clear-filter-button::before {
  content: "";
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: calc(50% - 0.75rem);
  left: 1.3rem;
  background: url("../img/clear-filter.svg") center center/auto no-repeat;
}
.sidebar__widget.widget--news-filter .form__input {
  box-shadow: 0 0.1rem 0.1rem rgba(145, 145, 145, 0.2), 0 0 0.1rem rgba(145, 145, 145, 0.2);
  border: 0.1rem solid transparent;
  transition: 0.15s;
}
.sidebar__widget.widget--news-filter .form__input::placeholder {
  color: #939393;
}
.sidebar__widget.widget--news-filter .form__input.input--search {
  padding-right: 4rem;
}
.sidebar__widget.widget--news-filter .form__input.input--search:focus {
  border: 0.1rem solid #929292;
  box-shadow: 0 0.3rem 0.5rem rgba(137, 146, 157, 0.2), 0 0 0.4rem rgba(137, 146, 157, 0.2);
}
@media all and (max-width: 576px) {
  .sidebar__widget.widget--gallery {
    max-width: unset;
    width: 100%;
    margin-bottom: 0;
  }
}
.sidebar__widget.widget--gallery .photo__img {
  width: 100%;
}
.sidebar__widget.widget--project-info {
  font-size: 1.8rem;
  line-height: 3rem;
  margin-bottom: 5rem;
}
@media all and (max-width: 768px) {
  .sidebar__widget.widget--project-info {
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-bottom: 3rem;
  }
}
.sidebar__widget.widget--project-info .widget__title {
  font-family: "Lato", "Sans Serif";
  font-weight: 900;
  font-size: 2rem;
  line-height: 3.3rem;
  margin-top: 5rem;
}
@media all and (max-width: 768px) {
  .sidebar__widget.widget--project-info .widget__title {
    font-size: 1.8rem;
    line-height: 3rem;
    margin-top: 2rem;
  }
}
.sidebar__widget.widget--project-info .widget__title:first-child {
  margin-top: 0;
}
.sidebar__widget.widget--project-info .project-info__text {
  margin-top: 1.1rem;
}
.sidebar__widget.widget--project-info .project-info__website {
  font-weight: 600;
  border-bottom: 0.1rem solid #000000;
}
.sidebar__widget.widget--project-info .project-info__website:hover {
  color: #00923F;
  border-bottom-color: #00923F;
}
.sidebar__widget.widget--document {
  margin: 0 0 1.7rem;
}
@media all and (max-width: 768px) {
  .sidebar__widget.widget--document {
    margin: 0 0 1.9rem;
    min-width: 100%;
  }
}
.sidebar__widget.widget--document .widget__icon-document {
  margin-right: 1.5rem;
}
.sidebar__widget.widget--document .widget__info-document {
  margin-bottom: 0.3rem;
}
.sidebar__widget.widget--document .info-document__title {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.sidebar__widget.widget--document .info-document__size {
  margin-top: 0.6rem;
  border-bottom: none;
}
.sidebar__widget.widget--contacts-info {
  max-width: 22.7rem;
}
@media all and (max-width: 768px) {
  .sidebar__widget.widget--contacts-info {
    margin-right: auto;
  }
}
.sidebar__widget.widget--links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sidebar__widget.widget--photo {
  position: sticky;
  top: 2rem;
}
.sidebar__widget.widget--photo .widget__gallery-link:last-child .widget__gallery-photo {
  margin-bottom: 0;
}
.sidebar__widget.widget--photo .widget__gallery-photo.selected {
  outline: 0.3rem solid #00923F;
}
.sidebar__widget:last-child {
  margin-bottom: 0;
}
.sidebar__widget .widget__title {
  margin-bottom: 1rem;
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4.9rem;
}
.sidebar__widget .widget__text {
  font-size: 1.6rem;
  line-height: 2.8rem;
}
.sidebar__widget .widget__email-link {
  display: inline-block;
  font-size: 2.2rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 3.4rem;
  border-bottom: 1px solid #000000;
}
@media all and (max-width: 576px) {
  .sidebar__widget .widget__email-link {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 2.5rem;
  }
}
.sidebar__widget .widget__email-link:hover {
  color: #00923F;
  border-bottom: 1px solid #00923F;
}
.sidebar__widget .widget__phone-link {
  font-size: 2.2rem;
  line-height: 3.7rem;
}
@media all and (max-width: 576px) {
  .sidebar__widget .widget__phone-link {
    font-size: 2rem;
    line-height: 3.3rem;
  }
}
.sidebar__widget .widget__photo {
  margin-top: 1rem;
}
.sidebar__widget .widget__photo .photo__img {
  vertical-align: top;
  max-width: 100%;
}
.sidebar__widget .widget__photo .photo__caption {
  margin-top: 1.2rem;
  font-size: 1.4rem;
  line-height: 2.3rem;
  color: #29394B;
}
.sidebar__widget .widget__gallery-photo {
  vertical-align: top;
  margin-bottom: 2rem;
  max-width: 100%;
}
.sidebar__widget .widget__link {
  display: inline-block;
  border-bottom: 1px solid #000000;
  font-weight: 600;
  margin-bottom: 2.3rem;
  line-height: 1.9rem;
}
.sidebar__widget .widget__link:hover {
  color: #00923F;
  border-bottom-color: #00923F;
}
.sidebar__widget .widget__link.link--current {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.sidebar__widget .widget__link.link--current:hover {
  color: #00923F;
  border-bottom-color: #00923F;
}
.sidebar__widget .widget__news-link {
  display: inline-block;
  margin-top: 1.2rem;
  position: relative;
  font-size: 1.6rem;
  line-height: 2rem;
  border-bottom: 1px solid #000000;
  transition: 0.2s;
}
.sidebar__widget .widget__news-link::after {
  content: "";
  display: block;
  width: 2.6rem;
  height: 2.6rem;
  position: absolute;
  top: calc(50% - 1.3rem);
  right: -3.6rem;
  border-radius: 50%;
  background: #00923F url("../img/arrow-all-sidebar.svg") center center no-repeat;
}
.sidebar__widget .widget__news-link:hover {
  color: #00923F;
  border-bottom: 1px solid #00923F;
}
.sidebar__widget .widget__date {
  display: block;
  margin-top: 1.6rem;
  font-size: 1.3rem;
  line-height: 1.9rem;
  color: #09121C;
  opacity: 0.5;
}
.sidebar__widget .widget__news-title {
  display: inline-block;
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 1.9rem;
  line-height: 2.5rem;
}
.sidebar__widget .widget__news-title a {
  transition: 0.2s;
}
.sidebar__widget .widget__news-title:hover a {
  color: #00923F;
}
.sidebar__widget .widget__news-title:hover .underline {
  border-bottom: 1px solid #00923F;
}
.sidebar__widget .widget__news-desc {
  margin: 1.4rem 0 3rem;
  font-size: 1.4rem;
  line-height: 2.3rem;
  color: #29394B;
}
.sorting-block {
  position: relative;
  margin-top: 4.6rem;
}
.sorting-block.sorting--projects, .sorting-block.sorting--gallery {
  padding-top: 9.8rem;
}
@media all and (max-width: 576px) {
  .sorting-block {
    padding-top: 7.3rem;
    margin-top: 2.9rem;
  }
}
@media all and (max-width: 1200px) {
  .sorting-block--main-page {
    padding-top: 0;
  }
}
@media all and (max-width: 576px) {
  .sorting-block--main-page {
    margin-top: 2.6rem;
  }
}
@media all and (max-width: 1200px) {
  .sorting-block--main-page .sorting-block__drop-tag-panel {
    display: none;
  }
}

.sorting-block__drop-tag-panel {
  display: flex;
  width: 100%;
  position: absolute;
  top: 0.2rem;
  left: 0;
  border-bottom: 1px solid #E3E9EC;
  z-index: 5;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media all and (max-width: 1200px) {
  .sorting-block__drop-tag-panel {
    display: block;
    width: 31rem;
    border-bottom: none;
    background: white;
    box-shadow: 0 0.3rem 0.6rem rgba(11, 37, 51, 0.08), 0 0 0.1rem rgba(0, 0, 0, 0.25);
    border-radius: 0.3rem;
  }
  .sorting-block__drop-tag-panel::after {
    content: "";
    display: block;
    width: 1.5rem;
    height: 0.8rem;
    position: absolute;
    top: 2.2rem;
    right: 2rem;
    background: url("../img/drop-down-panel.svg") center center no-repeat;
    background-size: auto;
    transition: 0.15s;
    z-index: -1;
  }
}
@media all and (max-width: 375px) {
  .sorting-block__drop-tag-panel {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.sorting-block__drop-tag-panel::-webkit-scrollbar {
  height: 0;
  width: 0;
  background: rgba(255, 255, 255, 0);
}
.sorting-block__drop-tag-panel.drop-tag-panel--active::after {
  transform: rotate(180deg);
}
.sorting-block__drop-tag-panel .drop-tag-panel__tag {
  display: inline-block;
  margin-left: 2.5rem;
  position: relative;
  background: transparent;
  padding: 1.3rem 0 1.7rem;
  border: none;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  letter-spacing: 0.03em;
  white-space: nowrap;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
}
@media all and (max-width: 1366px) {
  .sorting-block__drop-tag-panel .drop-tag-panel__tag {
    margin-left: 1.5rem;
  }
}
@media all and (max-width: 1200px) {
  .sorting-block__drop-tag-panel .drop-tag-panel__tag {
    display: none;
    width: 100%;
    text-align: left;
    padding: 1.6rem 2rem 1.3rem;
    margin: 0;
  }
}
.sorting-block__drop-tag-panel .drop-tag-panel__tag:disabled {
  color: rgba(0, 0, 0, 0.4);
}
.sorting-block__drop-tag-panel .drop-tag-panel__tag:disabled:hover {
  color: rgba(0, 0, 0, 0.4);
}
.sorting-block__drop-tag-panel .drop-tag-panel__tag:hover {
  color: #00923F;
}
@media all and (max-width: 1200px) {
  .sorting-block__drop-tag-panel .drop-tag-panel__tag.tag--visible {
    display: block;
  }
}
.sorting-block__drop-tag-panel .drop-tag-panel__tag.tag--active {
  color: #00923F;
}
@media all and (max-width: 1200px) {
  .sorting-block__drop-tag-panel .drop-tag-panel__tag.tag--active {
    display: block;
  }
}
.sorting-block__drop-tag-panel .drop-tag-panel__tag.tag--active::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.4rem;
  background: #00923F;
  position: absolute;
  bottom: 0;
}
@media all and (max-width: 1200px) {
  .sorting-block__drop-tag-panel .drop-tag-panel__tag.tag--active::after {
    display: none;
  }
}
.sorting-block__drop-tag-panel .drop-tag-panel__tag:first-of-type {
  margin-left: 0;
}

.drop-tag-panel__button-wrapper {
  width: 9rem;
  height: 4.4rem;
  position: absolute;
  top: 0;
  z-index: 10;
}
@media all and (max-width: 1200px) {
  .drop-tag-panel__button-wrapper {
    display: none;
  }
}
.drop-tag-panel__button-wrapper.wrapper--back {
  background: linear-gradient(90deg, #262626 57%, rgba(38, 38, 38, 0) 100%);
  left: 0;
}
.drop-tag-panel__button-wrapper.wrapper--forw {
  background: linear-gradient(270deg, #262626 57%, rgba(38, 38, 38, 0) 100%);
  right: 0;
}

.drop-tag-panel__button-scroll {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 4.4rem;
  height: 4.4rem;
  border: none;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
  background: #444444 url("../img/sort-scroll-arrow.svg") center center/auto no-repeat;
  z-index: 2;
}
.drop-tag-panel__button-scroll.button-scroll--back {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}
.drop-tag-panel__button-scroll.button-scroll--forw {
  right: 0;
}

.sorting-block__sort-grid {
  display: grid;
  grid-template: auto/repeat(4, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 6rem;
}
@media all and (max-width: 992px) {
  .sorting-block__sort-grid {
    grid-template: auto/repeat(3, 1fr);
  }
}
@media all and (max-width: 766px) {
  .sorting-block__sort-grid {
    grid-template: auto/repeat(2, 1fr);
    grid-row-gap: 4rem;
  }
}
@media all and (max-width: 576px) {
  .sorting-block__sort-grid {
    grid-template: auto/1fr;
  }
}

.sort-grid__item {
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 375px) {
  .sort-grid__item {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
@media all and (max-width: 375px) {
  .sort-grid__item .item__info {
    padding: 0 1rem;
  }
}
.sort-grid__item .item__photo {
  vertical-align: top;
  width: 100%;
  height: 16.4rem;
  object-fit: cover;
}
.sort-grid__item .info__tag, .sort-grid__item .item__tag {
  display: inline-block;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
  background: #00923F;
}
.sort-grid__item .info__date {
  display: inline-block;
  margin-top: 1.7rem;
  font-size: 1.3rem;
  line-height: 1.9rem;
  color: #09121C;
  opacity: 0.5;
}
.sort-grid__item .info__title-link {
  transition: 0.2s;
}
.sort-grid__item .info__title-link:hover {
  color: #00923F;
}
.sort-grid__item .info__title-link:hover .underline {
  border-bottom: 1px solid #00923F;
}
.sort-grid__item .info__title {
  margin-top: 0.5rem;
  color: #09121C;
  font-weight: 700;
  font-size: 1.9rem;
  line-height: 2.5rem;
}
.sort-grid__item .info__desc {
  margin-top: 1.4rem;
  font-size: 1.4rem;
  line-height: 2.3rem;
  color: #333333;
}
@media all and (min-width: 767px) {
  .sort-grid__item.item--double {
    grid-column: span 2;
  }
}
@media all and (max-width: 768px) {
  .sort-grid__item.item--double .underline {
    border-bottom: 0.1rem solid #000000;
  }
}
@media all and (min-width: 767px) {
  .sort-grid__item.item--double .item__photo {
    height: 100%;
    max-height: 39.5rem;
  }
}
@media all and (min-width: 767px) {
  .sort-grid__item.item--double .item__info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.6rem 2.3rem;
    background: rgba(17, 17, 17, 0.87);
  }
}
@media all and (min-width: 767px) {
  .sort-grid__item.item--double .info__tag, .sort-grid__item.item--double .item__tag {
    position: relative;
  }
}
@media all and (min-width: 767px) {
  .sort-grid__item.item--double .info__date {
    margin: 0 0 0 2rem;
    color: white;
    opacity: 1;
  }
}
@media all and (min-width: 767px) {
  .sort-grid__item.item--double .info__title {
    font-family: "Oswald", "Sans Serif";
    font-weight: 500;
    font-size: 3.1rem;
    line-height: 4rem;
    color: white;
  }
  .sort-grid__item.item--double .info__title .info__title-link:hover {
    color: #00a346;
  }
  .sort-grid__item.item--double .info__title .info__title-link:hover .underline {
    border-bottom: 1px solid #00a346;
  }
}
@media all and (min-width: 767px) {
  .sort-grid__item.item--double .info__desc {
    display: none;
  }
}

.sorting-block.sorting--projects .sorting-block__sort-grid {
  grid-row-gap: 3rem;
}
@media all and (max-width: 576px) {
  .sorting-block.sorting--projects .sorting-block__sort-grid {
    grid-row-gap: 1.6rem;
  }
}
.sorting-block.sorting--projects .sort-grid__item {
  max-height: 40.5rem;
  background: white;
}
@media all and (max-width: 1200px) {
  .sorting-block.sorting--projects .sort-grid__item {
    max-height: unset;
  }
}
.sorting-block.sorting--projects .sort-grid__item.item--double .item__tag {
  position: absolute;
}
.sorting-block.sorting--projects .sort-grid__item.item--double .item__photo {
  height: 100%;
  max-height: 39.5rem;
}
@media all and (max-width: 767px) {
  .sorting-block.sorting--projects .sort-grid__item.item--double .item__photo {
    height: 18.5rem;
  }
}
.sorting-block.sorting--projects .sort-grid__item.item--double .item__info {
  max-width: 29rem;
  height: 22.1rem;
  background: white;
  left: unset;
  right: 0;
}
@media all and (max-width: 767px) {
  .sorting-block.sorting--projects .sort-grid__item.item--double .item__info {
    max-width: 100%;
    height: unset;
  }
}
.sorting-block.sorting--projects .sort-grid__item.item--double .item__info.info--left {
  left: 0;
  right: unset;
}
.sorting-block.sorting--projects .sort-grid__item.item--double .item__info .info__title {
  font-family: "Lato", "Sans Serif";
  color: #000000;
}
.sorting-block.sorting--projects .sort-grid__item.item--double .item__info .info__title .info__title-link:hover {
  color: #00923F;
}
.sorting-block.sorting--projects .sort-grid__item.item--double .item__info .info__title .info__title-link:hover .underline {
  border-bottom: 1px solid #00923F;
}
.sorting-block.sorting--projects .sort-grid__item.item--double .item__info .info__date {
  margin: 2.6rem 0 0 0;
  color: rgba(0, 0, 0, 0.5);
}
.sorting-block.sorting--projects .sort-grid__item .item__photo {
  height: 18.5rem;
}
.sorting-block.sorting--projects .sort-grid__item .item__info {
  min-height: 22.1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.6rem 3.5rem 3.2rem;
}
@media all and (max-width: 992px) {
  .sorting-block.sorting--projects .sort-grid__item .item__info {
    padding: 2.6rem 2rem 2.7rem;
  }
}
@media all and (max-width: 767px) {
  .sorting-block.sorting--projects .sort-grid__item .item__info {
    min-height: unset;
  }
}
.sorting-block.sorting--projects .sort-grid__item .info__title {
  font-weight: 600;
  margin-top: 0;
  font-size: 2rem;
  line-height: 3rem;
}
@media all and (max-width: 375px) {
  .sorting-block.sorting--projects .sort-grid__item .info__title {
    max-width: 25rem;
  }
}

.sorting-block.sorting--gallery .sorting-block__sort-grid {
  grid-row-gap: 4.6rem;
}
@media all and (max-width: 576px) {
  .sorting-block.sorting--gallery .sorting-block__sort-grid {
    grid-row-gap: 3.9rem;
  }
}
.sorting-block.sorting--gallery .sorting-block__sort-grid .info__date {
  color: rgba(255, 255, 255, 0.7);
}
.sorting-block.sorting--gallery .sorting-block__sort-grid .info__title {
  font-weight: 500;
  line-height: 2.8rem;
}
.sorting-block.sorting--gallery .sorting-block__sort-grid .info__title .info__title-link {
  color: white;
}
.sorting-block.sorting--gallery .sorting-block__sort-grid .info__title .info__title-link:hover {
  color: #00923F;
}
.sorting-block.sorting--gallery .sorting-block__sort-grid .info__title .info__title-link:hover .underline--white {
  border-bottom: 0.1rem solid #00923F;
}
.structure-grid {
  margin-top: 2.4rem;
  display: grid;
  grid-template: auto/repeat(auto-fill, minmax(24rem, 1fr));
  column-gap: 1.2rem;
  row-gap: 2.4rem;
}
@media all and (max-width: 576px) {
  .structure-grid {
    margin-top: 2rem;
    row-gap: 2rem;
  }
}

.border-structure {
  position: relative;
}
.border-structure:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1rem;
  border-bottom: 0.1rem dashed rgba(209, 218, 227, 0.6);
}

.structure-grid-gm {
  display: grid;
  grid-template: auto/3fr 2fr;
  grid-gap: 1.2rem;
  margin-top: 4.9rem;
}
@media all and (max-width: 980px) {
  .structure-grid-gm {
    grid-template: auto/1fr;
  }
}

.sub-gm {
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
}
.sub-gm__pading-name {
  padding-bottom: 7rem;
  position: relative;
  padding-left: 3.2rem;
}
@media all and (max-width: 980px) {
  .sub-gm__pading-name {
    padding-left: 2rem;
    padding-bottom: 3rem;
  }
}
.sub-gm__pading-name:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1rem;
  border-bottom: 0.1rem dashed rgba(209, 218, 227, 0.6);
}
.sub-gm__padding-position {
  padding-top: 3.2rem;
  padding-left: 3.2rem;
}
@media all and (max-width: 980px) {
  .sub-gm__padding-position {
    padding-left: 2rem;
  }
}

.sub-gm-wrap {
  padding: 3rem 0 2.8rem 0;
}
@media all and (max-width: 980px) {
  .sub-gm-wrap {
    padding: 2.8rem 0.5rem 3.2rem 0;
  }
}

.sub-gm-name {
  font-family: "Oswald", sans-serif, Serif;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  color: #000000;
}

.sub-gm-position {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.8rem;
  color: #000000;
}

.structure-item {
  display: flex;
  flex-direction: column;
  background: white;
  color: #000000;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.08);
  border-radius: 0.2rem;
}
.structure-item.item--gm {
  background: #3F4C59;
  color: white;
  box-shadow: none;
}
@media all and (max-width: 576px) {
  .structure-item.item--gm {
    margin-top: 3.5rem;
  }
}
.structure-item.item--gm .structure-item__department {
  height: auto;
  padding: 0rem 3.2rem 0rem;
  padding-left: 0;
}
@media all and (max-width: 768px) {
  .structure-item.item--gm .structure-item__department {
    padding: 3rem 0rem 4rem;
  }
}
@media all and (max-width: 640px) {
  .structure-item.item--gm .structure-item__department {
    height: 11rem;
  }
}
@media all and (max-width: 520px) {
  .structure-item.item--gm .structure-item__department {
    padding: 0 2rem 3rem;
    height: auto;
  }
}
.structure-item.item--gm .structure-item__info {
  flex-direction: row;
  padding: 3rem 3.2rem;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
}
@media all and (max-width: 768px) {
  .structure-item.item--gm .structure-item__info {
    padding: 3rem 2rem;
  }
}
@media all and (max-width: 640px) {
  .structure-item.item--gm .structure-item__info {
    flex-direction: column;
    padding: 2rem;
  }
}
@media all and (max-width: 375px) {
  .structure-item.item--gm .structure-item__info {
    padding: 2.8rem 2rem 3.2rem;
  }
}
.structure-item.item--gm .structure-item__name {
  color: white;
}
@media all and (max-width: 768px) and (min-width: 641px) {
  .structure-item.item--gm .structure-item__name {
    max-width: 9rem;
  }
}
.structure-item.item--gm .structure-item__contacts {
  margin-top: 0;
  margin-left: 9.7rem;
  color: white;
}
@media all and (max-width: 768px) {
  .structure-item.item--gm .structure-item__contacts {
    margin-left: 0;
  }
}
@media all and (max-width: 640px) {
  .structure-item.item--gm .structure-item__contacts {
    margin-top: 2rem;
  }
}
.structure-item.item--gm .structure-item__link {
  color: white;
}
.structure-item.item--green {
  color: white;
  background: #00923F;
  box-shadow: none;
}
.structure-item.item--green .structure-item__department {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
.structure-item.item--green .structure-item__name {
  color: white;
}
.structure-item.item--green .structure-item__contacts,
.structure-item.item--green .structure-item__link {
  color: white;
}
.structure-item__grid {
  display: grid;
  grid-template: auto/24rem 1fr;
}
@media all and (max-width: 520px) {
  .structure-item__grid {
    grid-template: auto/1fr;
  }
}
.structure-item__photo {
  grid-area: 1/1/3/2;
  padding: 2rem;
  border-right: 0.09rem dashed rgba(255, 255, 255, 0.5);
}
@media all and (max-width: 520px) {
  .structure-item__photo {
    grid-area: unset;
    border-right: none;
  }
}
.structure-item__photo-img {
  width: 100%;
  height: 21.5rem;
  object-fit: cover;
  vertical-align: top;
}
@media all and (max-width: 375px) {
  .structure-item__photo-img {
    height: 15.8rem;
  }
}
.structure-item__department {
  flex: 1;
  padding: 3rem 2rem 4rem;
  font-family: "Oswald", sans-serif, Serif;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
}
@media all and (max-width: 576px) {
  .structure-item__department {
    padding: 2.8rem 2rem 3rem;
  }
}
.structure-item__subdivisions {
  font-family: Lato, sans-serif, serif;
  font-size: 1.5rem;
  line-height: 2.2rem;
}
@media all and (max-width: 520px) {
  .structure-item__subdivisions {
    font-size: 1.6rem;
    line-height: 2.3rem;
  }
}
.structure-item__subdivisions-item:first-child {
  margin-top: 4rem;
}
@media all and (max-width: 576px) {
  .structure-item__subdivisions-item:first-child {
    margin-top: 3rem;
  }
}
.structure-item__subdivisions-item + .structure-item__subdivisions-item {
  margin-top: 1rem;
}
.structure-item__info {
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
}
@media all and (max-width: 768px) {
  .structure-item__info {
    justify-content: space-between;
  }
}
.structure-item__name {
  font-weight: 800;
  font-size: 1.8rem;
  line-height: 2.9rem;
  color: #00923F;
}
.structure-item__contacts {
  margin-top: 3rem;
  color: rgba(0, 0, 0, 0.6);
}
@media all and (max-width: 576px) {
  .structure-item__contacts {
    margin-top: 2rem;
  }
}
.structure-item__contacts-table {
  font-size: 1.5rem;
  line-height: 2rem;
}
.structure-item__contacts-table tr + tr td {
  padding-top: 0.7rem;
}
.structure-item__contacts-table td + td {
  padding-left: 1rem;
}
.structure-item__link {
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.6);
}
.structure-item__link:hover {
  color: inherit;
}
.tank-cleaning__block-title {
  font-family: Oswald, "Sans Serif";
  font-weight: 500;
  width: 100%;
  font-size: 5rem;
  line-height: 6rem;
  text-align: center;
}
@media all and (max-width: 768px) {
  .tank-cleaning__block-title {
    font-size: 3.4rem;
    line-height: 4.6rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__block-title {
    font-size: 2.6rem;
    line-height: 3.5rem;
  }
}
.tank-cleaning__block-desc {
  max-width: 59.4rem;
  margin: 0.9rem auto 0;
  display: block;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #535353;
  text-align: center;
}
@media all and (max-width: 768px) {
  .tank-cleaning__block-desc {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__block-desc {
    margin: 0.7rem auto 0;
  }
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/oswald-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-light.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-semibold.woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-bold.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/lato-heavy.woff2");
  font-weight: 900;
  font-style: normal;
}
body {
  font-family: "Lato", "Sans Serif";
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #000000;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

.page-title, .block-title {
  font-family: "Oswald", "Sans Serif";
  font-weight: 500;
  color: #000000;
}

.page-title {
  font-size: 5.2rem;
  line-height: 7rem;
}
@media all and (max-width: 768px) {
  .page-title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }
}

.block-title {
  font-size: 4.6rem;
  line-height: 6.8rem;
}
@media all and (max-width: 576px) {
  .block-title {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }
}
.wrapper--vendors-page {
  background: #F7F7F7;
}

.content-block.block--single-page.block--vendors {
  padding: 1rem 0 7.4rem;
}
@media all and (max-width: 768px) {
  .content-block.block--single-page.block--vendors {
    padding-bottom: 5rem;
  }
}
@media all and (max-width: 576px) {
  .content-block.block--single-page.block--vendors {
    padding-bottom: 3.4rem;
  }
}

.vendors-content__desc {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.vendors-content__request-list {
  margin-top: 2.6rem;
}
@media all and (max-width: 576px) {
  .vendors-content__request-list {
    margin-top: 1.8rem;
  }
}
.vendors-content__request-list .request-list__item {
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 0.3rem;
  box-shadow: 0 0.1rem 0.1rem rgba(47, 47, 47, 0.16);
}
@media all and (max-width: 480px) {
  .vendors-content__request-list .request-list__item {
    flex-direction: column;
  }
}
@media all and (max-width: 375px) {
  .vendors-content__request-list .request-list__item {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.vendors-content__request-list .request-list__item .item__position-name {
  flex: 1;
  padding: 3.3rem 3.8rem;
  border-right: 1px dashed #d4d4d4;
}
@media all and (max-width: 992px) {
  .vendors-content__request-list .request-list__item .item__position-name {
    padding: 2rem 2.6rem;
  }
}
@media all and (max-width: 480px) {
  .vendors-content__request-list .request-list__item .item__position-name {
    width: 100%;
    padding: 1.8rem 2rem;
    border-right: none;
    border-bottom: 1px dashed #d4d4d4;
  }
}
.vendors-content__request-list .request-list__item .item__position-quantity {
  flex-basis: 23rem;
  display: flex;
  align-items: center;
  padding-left: 4.2rem;
}
@media all and (max-width: 1200px) {
  .vendors-content__request-list .request-list__item .item__position-quantity {
    flex-basis: 14rem;
    padding-left: 3rem;
  }
}
@media all and (max-width: 480px) {
  .vendors-content__request-list .request-list__item .item__position-quantity {
    flex-basis: unset;
    width: 100%;
    padding: 1.7rem 2rem;
    justify-content: flex-start;
    font-weight: 600;
    color: #00923F;
  }
}
.main-grid__social-share .ya-share2__item + .ya-share2__item {
  margin-left: 0.9rem;
}
@media all and (max-width: 576px) {
  .main-grid__social-share .ya-share2__item + .ya-share2__item {
    margin-left: 0.6rem;
  }
}
.main-grid__social-share .ya-share2__badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
}
@media all and (max-width: 576px) {
  .main-grid__social-share .ya-share2__badge {
    width: 3.5rem;
    height: 3.5rem;
  }
}
.main-grid__social-share .ya-share2__badge .ya-share2__icon {
  height: 2.8rem;
  width: 2.8rem;
  background-position: center center;
  background-repeat: no-repeat;
}
@media all and (max-width: 576px) {
  .main-grid__social-share .ya-share2__badge .ya-share2__icon {
    width: 2.2rem;
    height: 2.2rem;
  }
}
.main-grid__social-share .ya-share2__item_service_vkontakte .ya-share2__badge .ya-share2__icon {
  margin-top: 0.1rem;
}
.main-grid__social-share .ya-share2__item_service_odnoklassniki .ya-share2__badge .ya-share2__icon {
  height: 3rem;
  width: 2.8rem;
}
.about-text {
  padding: 10rem 0 4.3rem;
  background: #F7F7F7;
}
@media all and (max-width: 992px) {
  .about-text {
    padding: 7rem 0 2rem;
  }
}
@media all and (max-width: 768px) {
  .about-text {
    padding: 5rem 0 1rem;
  }
}
@media all and (max-width: 576px) {
  .about-text {
    padding: 3.6rem 0 0;
  }
}
.about-text__desc {
  margin: 3.8rem auto 0;
  max-width: 102rem;
  font-size: 2.2rem;
  line-height: 3.5rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .about-text__desc {
    margin-top: 2rem;
    padding: 0 1rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
.detail-work {
  padding: 3.4rem 0 5rem;
  background: #F7F7F7;
}
@media all and (max-width: 576px) {
  .detail-work {
    padding: 3.4rem 0 2.7rem;
  }
}
.detail-work__desc {
  margin: 3.8rem auto 0;
  max-width: 100rem;
  font-size: 2.2rem;
  line-height: 3.5rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .detail-work__desc {
    margin-top: 2rem;
    padding: 0 1rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 576px) {
  .detail-work__desc p {
    margin-top: 1.5rem;
  }
}
.detail-work__grid {
  margin-top: 3.8rem;
  display: flex;
  justify-content: center;
}
@media all and (max-width: 768px) {
  .detail-work__grid {
    flex-direction: column;
    max-width: 64rem;
    margin: 3rem auto 0;
  }
}
@media all and (max-width: 576px) {
  .detail-work__grid {
    margin-top: 2.3rem;
    padding: 0 1rem;
  }
}
.detail-work__grid-item + .detail-work__grid-item {
  margin-left: 2rem;
}
@media all and (max-width: 768px) {
  .detail-work__grid-item + .detail-work__grid-item {
    margin-left: 0;
    margin-top: 2rem;
  }
}
@media all and (max-width: 576px) {
  .detail-work__grid-item + .detail-work__grid-item {
    margin-top: 1rem;
  }
}
.detail-work__grid-item {
  padding: 1rem 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 10.4rem;
  font-size: 2.2rem;
  line-height: 3.5rem;
  text-align: center;
  background: white;
  box-shadow: 0 4rem 3rem -3rem rgba(31, 31, 31, 0.09);
}
@media all and (max-width: 576px) {
  .detail-work__grid-item {
    min-height: 10rem;
    font-size: 1.8rem;
    line-height: 2rem;
  }
}
.detail-work__grid-item-value {
  display: block;
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 3.5rem;
}
@media all and (max-width: 768px) {
  .detail-work__grid-item-value {
    margin-bottom: 0.3rem;
  }
}
.services__header {
  display: flex;
  min-height: 70.3rem;
  flex-direction: column;
  align-items: center;
  padding: 0 0 2.5rem;
}
.services__header--energetics {
  min-height: 67.4rem;
  background: url("../img/bg-energetics.jpg") center center/cover no-repeat;
}
@media all and (max-width: 375px) {
  .services__header--energetics {
    background: url("../img/bg-energetics-m.jpg") center center/cover no-repeat;
  }
}
.services__header .services__order {
  margin-top: 0;
}
@media all and (max-width: 576px) {
  .services__header .services__order .container {
    padding: 0 1rem;
  }
}
.services__header .services__order-inner {
  max-width: 109.6rem;
  margin: 0 auto;
  padding: 3.7rem 2rem 5rem;
  min-height: 31.4rem;
  border-radius: 0.4rem;
}
@media all and (max-width: 576px) {
  .services__header .services__order-inner {
    padding: 2.6rem 3rem 3rem;
  }
}
.services__header .services__order-title {
  line-height: 4rem;
}
@media all and (max-width: 576px) {
  .services__header .services__order-title {
    line-height: unset;
  }
}
.services__header .services__order-form {
  margin-top: 2.7rem;
}
.services__header-title {
  max-width: 125rem;
  padding: 0 1rem;
  margin: 7.5rem auto 0;
  text-align: center;
  font-family: Oswald, "Sans Serif", serif;
  font-weight: 500;
  font-size: 5.8rem;
  line-height: 6rem;
  color: white;
}
@media all and (max-width: 992px) {
  .services__header-title {
    font-size: 4.6rem;
    line-height: 5rem;
  }
}
@media all and (max-width: 768px) {
  .services__header-title {
    margin-top: 5rem;
    font-size: 4rem;
    line-height: 4.6rem;
  }
}
@media all and (max-width: 576px) {
  .services__header-title {
    font-size: 3.6rem;
    line-height: 4.2rem;
  }
}
@media all and (max-width: 375px) {
  .services__header-title {
    margin-top: 3rem;
    font-size: 3.2rem;
    line-height: 4rem;
  }
}
.services__header-desc {
  max-width: 75.9rem;
  margin: 4.2rem 0 5.6rem;
  padding: 0 1rem;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.1rem;
  text-align: center;
  color: white;
}
@media all and (max-width: 768px) {
  .services__header-desc {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
@media all and (max-width: 375px) {
  .services__header-desc {
    max-width: 27.7rem;
    margin: 3rem auto;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.tank-cleaning__order-block, .services__order-block {
  padding: 11rem 0;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .tank-cleaning__order-block, .services__order-block {
    padding: 8rem 0;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-block, .services__order-block {
    padding: 5rem 0;
  }
}
.tank-cleaning__order, .services__order {
  width: 100%;
  position: relative;
}
@media all and (max-width: 375px) {
  .tank-cleaning__order .tank-cleaning__block-desc, .tank-cleaning__order .services__block-desc, .services__order .tank-cleaning__block-desc, .services__order .services__block-desc {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
.tank-cleaning__order-inner, .services__order-inner {
  background: white;
  padding: 6.2rem 2rem 8rem;
  box-shadow: 0 6rem 10rem rgba(31, 31, 31, 0.09);
}
@media all and (max-width: 768px) {
  .tank-cleaning__order-inner, .services__order-inner {
    padding: 6rem 2rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-inner, .services__order-inner {
    padding: 4rem 2rem;
    border-radius: 0.4rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__order-inner, .services__order-inner {
    padding: 2.6rem 2rem 3rem;
  }
}
.tank-cleaning__order-title, .services__order-title {
  max-width: 74rem;
  margin: 0 auto;
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 4.5rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-title, .services__order-title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__order-title, .services__order-title {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
.tank-cleaning__order-form, .services__order-form {
  margin: 4.3rem auto 0;
  max-width: 92rem;
}
@media all and (max-width: 720px) {
  .tank-cleaning__order-form, .services__order-form {
    width: 100%;
    max-width: 46rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__order-form, .services__order-form {
    margin-top: 2.5rem;
  }
}
.tank-cleaning__order-form .form__input-inline, .services__order-form .form__input-inline {
  width: 100%;
}
.tank-cleaning__order-form .form__input-outer.field--invalid::after, .services__order-form .form__input-outer.field--invalid::after {
  display: none;
}
.tank-cleaning__order-form .form__input-outer.field--invalid .field--requared, .services__order-form .form__input-outer.field--invalid .field--requared {
  background: rgba(199, 31, 31, 0.03);
  border: 0.2rem solid #C71F1F;
  color: rgba(199, 31, 31, 0.6);
}
.tank-cleaning__order-form .form__input-outer.field--invalid .field--requared::placeholder, .services__order-form .form__input-outer.field--invalid .field--requared::placeholder {
  color: rgba(199, 31, 31, 0.6);
}
.tank-cleaning__order-form .form__input, .services__order-form .form__input {
  background: #F9F9F9;
  border: 0.1rem solid #D8D8D8;
  font-size: 1.8rem;
  padding: 1.9rem 2.4rem;
  height: 6.4rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-form .form__input, .services__order-form .form__input {
    height: 5.4rem;
    padding: 1.4rem 2.1rem;
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}
.tank-cleaning__order-form .form__input::placeholder, .services__order-form .form__input::placeholder {
  color: #7A7A7A;
}
.tank-cleaning__order-form .form__submit-button, .services__order-form .form__submit-button {
  font-size: 1.8rem;
  line-height: 3rem;
  width: 100%;
  height: 6.4rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-form .form__submit-button, .services__order-form .form__submit-button {
    font-size: 1.6rem;
    line-height: 2.8rem;
    height: 5.4rem;
  }
}
.tank-cleaning__order-form .form__input-wrapper.wrapper--checkbox, .services__order-form .form__input-wrapper.wrapper--checkbox {
  margin-top: 1.5rem;
  margin-bottom: 0;
  text-align: center;
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-form .form__input-wrapper.wrapper--checkbox, .services__order-form .form__input-wrapper.wrapper--checkbox {
    margin-top: 2.5rem;
    padding-right: 0.3rem;
    text-align: left;
  }
}
.projects-table {
  padding: 0 0 12.5rem;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .projects-table {
    padding: 0 0 8rem;
  }
}
@media all and (max-width: 576px) {
  .projects-table {
    padding: 4.5rem 0 5rem;
  }
}
@media all and (max-width: 576px) {
  .projects-table {
    padding: 4.5rem 0 3.7rem;
  }
}
.projects-table__tab-panel {
  margin-top: 2.9rem;
  display: flex;
  justify-content: center;
}
@media all and (max-width: 576px) {
  .projects-table__tab-panel {
    margin-top: 1.6rem;
  }
}
.projects-table__tab-switcher + .projects-table__tab-switcher {
  margin-left: 3.3rem;
}
@media all and (max-width: 576px) {
  .projects-table__tab-switcher + .projects-table__tab-switcher {
    margin-left: 2.5rem;
  }
}
.projects-table__tab-switcher {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 3rem;
  cursor: pointer;
}
@media all and (max-width: 576px) {
  .projects-table__tab-switcher {
    font-size: 1.6rem;
    line-height: 2.7rem;
  }
}
.projects-table__tab-switcher.switcher--active {
  color: #00923F;
  border-bottom: 0.2rem solid #00923F;
}
.projects-table__wrapper {
  position: relative;
  margin-top: 3.5rem;
  width: 100%;
  max-height: 62.6rem;
  overflow: auto;
  border: 0.1rem solid #DFDFDF;
  background: white;
}
@media all and (max-width: 768px) {
  .projects-table__wrapper {
    max-height: 54rem;
  }
}
@media all and (max-width: 576px) {
  .projects-table__wrapper {
    margin-top: 2.5rem;
    max-height: 44.7rem;
  }
}
.projects-table__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0);
  background-color: transparent;
}
.projects-table__wrapper::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.7rem;
  background-color: #F7F7F7;
}
.projects-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  -webkit-box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0);
  width: 0.7rem;
  background-color: #A9A9A9;
}
.projects-table__wrapper.table--lock {
  overflow: hidden;
}
.projects-table__wrapper.table--lock:hover .projects-table__overlay {
  display: block;
}
.projects-table__wrapper.table--lock .projects-table__table {
  margin-top: -5.6rem;
}
.projects-table__wrapper.table--lock .projects-table__unlock-btn {
  display: block;
}
.projects-table__table {
  display: none;
  position: relative;
  background: white;
  font-size: 1.6rem;
  line-height: 2.5rem;
}
@media all and (max-width: 576px) {
  .projects-table__table {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
.projects-table__table.table--active {
  display: block;
}
.projects-table__overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.projects-table__row {
  display: flex;
}
.projects-table__row:last-child .projects-table__cell {
  border-bottom: none;
}
.projects-table__row.row--header {
  font-weight: 700;
}
.projects-table__cell {
  padding: 1.8rem 4.5rem 1.8rem 2rem;
  border-right: 0.1rem solid #DFDFDF;
  border-bottom: 0.1rem solid #DFDFDF;
}
@media all and (max-width: 992px) {
  .projects-table__cell {
    padding: 1.8rem 2rem;
  }
}
@media all and (max-width: 576px) {
  .projects-table__cell {
    padding: 1.1rem 1.5rem;
  }
}
.projects-table__cell:last-child {
  border-right: none;
}
.projects-table__cell.cell--counter {
  padding: 1.8rem 2rem;
  flex: 0 0 8.9rem;
}
@media all and (max-width: 576px) {
  .projects-table__cell.cell--counter {
    padding: 1.1rem 1.3rem;
    flex: 0 0 7rem;
  }
}
.projects-table__cell.cell--object {
  flex: 0 0 30.3rem;
}
@media all and (max-width: 576px) {
  .projects-table__cell.cell--object {
    flex: 0 0 24rem;
  }
}
.projects-table__cell.cell--work {
  flex: 0 0 39.4rem;
}
.projects-table__cell.cell--customer {
  flex: 0 0 26rem;
}
.projects-table__cell.cell--period {
  flex: 0 0 20.1rem;
}
.projects-table__cell p + p {
  margin-top: 1.5rem;
}
@media all and (max-width: 576px) {
  .projects-table__cell p + p {
    margin-top: 1.1rem;
  }
}
.projects-table__unlock-btn {
  display: none;
  position: sticky;
  top: calc(50% - 2.8rem);
  left: calc(50% - 11.7rem);
  padding: 1rem 3.9rem;
  z-index: 2;
}
@media all and (max-width: 576px) {
  .projects-table__unlock-btn {
    font-size: 1.6rem;
    line-height: 2.5rem;
    padding: 1rem 2.5rem;
    left: calc(50% - 9.5rem);
  }
}
.projects-table__unlock-btn.button--invert {
  background: #747474;
}
.projects-table__unlock-btn.button--invert:hover {
  background: #474747;
}
.work-list {
  padding: 5rem 0 12.5rem;
  background: #F7F7F7;
}
@media all and (max-width: 992px) {
  .work-list {
    padding: 4rem 0 8rem;
  }
}
@media all and (max-width: 768px) {
  .work-list {
    padding: 3rem 0 6rem;
  }
}
@media all and (max-width: 576px) {
  .work-list {
    padding: 2.7rem 0 4.1rem;
  }
}
.work-list__grid {
  margin-top: 6rem;
  display: grid;
  grid-template: auto/repeat(2, 1fr);
  column-gap: 3rem;
}
@media all and (max-width: 720px) {
  .work-list__grid {
    margin-top: 4rem;
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 576px) {
  .work-list__grid {
    margin-top: 2.4rem;
  }
}
@media all and (max-width: 720px) {
  .work-list__grid-inner + .work-list__grid-inner {
    margin-top: 1.1rem;
  }
}
.work-list__item {
  margin-bottom: 2.6rem;
  width: 100%;
  display: inline-block;
  padding: 4.1rem 5.4rem 4.3rem;
  background: white;
  box-shadow: 0 4rem 3rem -3rem rgba(31, 31, 31, 0.09);
}
@media all and (max-width: 992px) {
  .work-list__item {
    padding: 3.2rem 3rem 3.6rem;
  }
}
@media all and (max-width: 720px) {
  .work-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .work-list__item {
    padding: 2.6rem 1.9rem 3.3rem;
    margin-bottom: 1.1rem;
  }
}
.work-list__item:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 576px) {
  .work-list__item.item--add-info {
    padding: 2.6rem 2.5rem 3.3rem;
  }
}
.work-list__item.item--add-info .work-list__item-title {
  font-weight: 800;
  max-width: 43rem;
}
.work-list__item.item--add-info .work-list__order-btn {
  margin-top: 3.4rem;
}
@media all and (max-width: 576px) {
  .work-list__item.item--add-info .work-list__order-btn {
    margin-top: 2.3rem;
  }
}
.work-list__item-title {
  display: block;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.5rem;
  color: #000000;
}
@media all and (max-width: 992px) {
  .work-list__item-title {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 576px) {
  .work-list__item-title {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
.work-list__order-btn {
  padding: 0.4rem 2.5rem;
  height: 4.8rem;
  margin-top: 2.2rem;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 4rem;
}
@media all and (max-width: 576px) {
  .work-list__order-btn {
    margin-top: 1.7rem;
  }
}
.work-list__add-works {
  margin-top: 2.6rem;
  padding-left: 1.6rem;
  text-align: left;
}
@media all and (max-width: 992px) {
  .work-list__add-works {
    margin-top: 1.8rem;
  }
}
@media all and (max-width: 576px) {
  .work-list__add-works {
    margin-top: 1.2rem;
  }
}
.work-list__add-works-item + .work-list__add-works-item {
  margin-top: 2.4rem;
}
@media all and (max-width: 576px) {
  .work-list__add-works-item + .work-list__add-works-item {
    margin-top: 1.5rem;
  }
}
@media all and (max-width: 375px) {
  .work-list__add-works-item + .work-list__add-works-item {
    max-width: 20rem;
  }
}
.work-list__add-works-item {
  position: relative;
  padding-left: 2.4rem;
  font-size: 2rem;
  line-height: 2.5rem;
}
@media all and (max-width: 576px) {
  .work-list__add-works-item {
    padding-left: 2rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.work-list__add-works-item::before {
  content: "";
  position: absolute;
  top: 0.9rem;
  left: 0;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #00923F;
}
.tank-cleaning__about {
  padding: 7rem 0;
  background: #F7F7F7;
}
@media all and (max-width: 992px) {
  .tank-cleaning__about {
    padding: 5rem 0;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__about {
    padding: 3rem 0;
  }
}
.tank-cleaning__about .tank-cleaning__block-title {
  text-align: left;
  max-width: 27rem;
}
@media all and (max-width: 992px) {
  .tank-cleaning__about .tank-cleaning__block-title {
    max-width: unset;
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__about .tank-cleaning__block-title {
    text-align: left;
  }
}
.tank-cleaning__about-grid {
  display: grid;
  grid-template: auto/repeat(3, 1fr);
  gap: 3.5rem;
}
@media all and (max-width: 1160px) {
  .tank-cleaning__about-grid {
    grid-template: auto/25rem repeat(2, 1fr);
  }
}
@media all and (max-width: 992px) {
  .tank-cleaning__about-grid {
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__about-grid {
    padding: 0 2rem;
  }
}
.tank-cleaning__about-info {
  grid-area: 1/2/2/4;
  display: grid;
  grid-template: auto/repeat(2, 1fr);
  column-gap: 3.5rem;
  row-gap: 5.3rem;
}
@media all and (max-width: 992px) {
  .tank-cleaning__about-info {
    grid-area: unset;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__about-info {
    row-gap: 3rem;
    grid-template: auto/1fr;
  }
}
.tank-cleaning__about-item-title {
  display: block;
  max-width: 24rem;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 3rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__about-item-title {
    max-width: unset;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__about-item-title {
    font-size: 1.8rem;
  }
}
.tank-cleaning__about-item-desc {
  margin-top: 2rem;
  padding-right: 2.4rem;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__about-item-desc {
    padding-right: unset;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__about-item-desc {
    margin-top: 1.2rem;
  }
}
.tank-cleaning__advantages {
  padding: 6rem 0 12.5rem;
  background: #f7f7f7;
}
@media all and (max-width: 768px) {
  .tank-cleaning__advantages {
    padding: 4rem 0 6rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__advantages {
    padding: 3rem 0 6rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__advantages .tank-cleaning__block-title {
    text-align: left;
    padding: 0 2rem;
  }
}
.tank-cleaning__advantages-grid {
  margin-top: 6.3rem;
  display: grid;
  grid-template: auto/repeat(2, 1fr);
  column-gap: 3rem;
  row-gap: 2.1rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__advantages-grid {
    margin-top: 4rem;
    column-gap: 2rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__advantages-grid {
    margin-top: 2.3rem;
    grid-template: auto/1fr;
    row-gap: 1.1rem;
  }
}
.tank-cleaning__advantages-item {
  margin: 0 auto;
  width: 100%;
  max-width: 61rem;
  padding: 3.8rem 0 4.1rem;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.5rem;
  background: white;
  box-shadow: 0 3rem 3rem -2rem rgba(31, 31, 31, 0.09);
}
@media all and (max-width: 768px) {
  .tank-cleaning__advantages-item {
    padding: 2.2rem 0 1.8rem;
    font-size: 2rem;
    line-height: 3.2rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__advantages-item {
    font-size: 1.8rem;
    line-height: 3rem;
  }
}
.tank-cleaning__advantages-item:last-child:nth-of-type(odd) {
  grid-column: span 2;
}
@media all and (max-width: 576px) {
  .tank-cleaning__advantages-item:last-child:nth-of-type(odd) {
    grid-column: unset;
  }
}
.tank-cleaning__advantages-desc {
  padding: 0 5.3rem;
  border-left: 0.3rem solid #00923F;
}
@media all and (max-width: 768px) {
  .tank-cleaning__advantages-desc {
    padding: 0 3rem 0 2.7rem;
  }
}
.tank-cleaning__anticor-process {
  padding: 9.2rem 0 11rem;
}
@media all and (max-width: 992px) {
  .tank-cleaning__anticor-process {
    padding: 6rem 0 7rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__anticor-process {
    padding: 3.6rem 0 4.5rem;
  }
}
.tank-cleaning__anticor-process .tank-cleaning__block-title {
  max-width: 60rem;
  margin: 0 auto;
}

.anticor-process {
  margin-top: 8rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template: auto/repeat(3, 1fr);
  column-gap: 2.5rem;
  counter-reset: step;
}
@media all and (max-width: 992px) {
  .anticor-process {
    margin-top: 4rem;
    padding-bottom: 3rem;
  }
}
@media all and (max-width: 720px) {
  .anticor-process {
    grid-template: auto/1fr;
    row-gap: 3rem;
  }
}
@media all and (max-width: 576px) {
  .anticor-process {
    padding-bottom: 2rem;
  }
}
.anticor-process__step {
  position: relative;
  padding-left: 8.8rem;
  font-size: 1.9rem;
  line-height: 3rem;
  counter-increment: step;
}
@media all and (max-width: 1260px) {
  .anticor-process__step {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 992px) {
  .anticor-process__step {
    padding-left: 0;
    padding-top: 9.5rem;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 720px) {
  .anticor-process__step {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 375px) {
  .anticor-process__step {
    padding: 9.5rem 1rem 0;
  }
}
.anticor-process__step::before {
  content: counter(step);
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 900;
  font-size: 10rem;
  line-height: 10rem;
  color: #E6E6E6;
}
@media all and (max-width: 992px) {
  .anticor-process__step::before {
    left: 50%;
    transform: translateX(-50%);
  }
}
.tanc-cleaning__building-features {
  padding: 10.2rem 0;
}
@media all and (max-width: 992px) {
  .tanc-cleaning__building-features {
    padding: 6rem 0;
  }
}
@media all and (max-width: 576px) {
  .tanc-cleaning__building-features {
    padding: 2.5rem 0 4.5rem;
  }
}

.building-features {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 992px) {
  .building-features {
    flex-direction: column;
    align-items: center;
  }
}
.building-features .tank-cleaning__block-title {
  text-align: left;
}
.building-features__photo {
  margin-left: 3rem;
  width: 50rem;
  order: 2;
}
@media all and (max-width: 992px) {
  .building-features__photo {
    width: unset;
    max-width: 64rem;
    margin-left: 0;
    order: unset;
  }
}
.building-features__photo img {
  width: 100%;
  vertical-align: top;
}
@media all and (max-width: 992px) {
  .building-features__photo img {
    height: 50rem;
    object-fit: cover;
  }
}
@media all and (max-width: 768px) {
  .building-features__photo img {
    height: unset;
  }
}
.building-features__info {
  max-width: 71.6rem;
  order: 1;
}
@media all and (max-width: 992px) {
  .building-features__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;
    order: unset;
    max-width: 64rem;
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .building-features__info {
    align-items: flex-start;
    text-align: left;
  }
}
@media all and (max-width: 375px) {
  .building-features__info {
    padding: 0 1.3rem;
  }
}
@media all and (max-width: 992px) {
  .building-features__info .tank-cleaning__block-title {
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .building-features__info .tank-cleaning__block-title {
    text-align: left;
  }
}
.building-features__desc {
  margin-top: 4rem;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 576px) {
  .building-features__desc {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.building-features__button {
  margin-top: 3rem;
  padding-right: 4.1rem;
  padding-left: 4.1rem;
}
@media all and (max-width: 576px) {
  .building-features__button {
    margin-top: 2.1rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.building-features__modal-content {
  display: none;
}
.tanc-cleaning__building-finish {
  padding: 9.2rem 0 11rem;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .tanc-cleaning__building-finish {
    padding: 7rem 0 8rem;
  }
}
@media all and (max-width: 768px) {
  .tanc-cleaning__building-finish {
    padding: 5rem 0 6rem;
  }
}
@media all and (max-width: 576px) {
  .tanc-cleaning__building-finish {
    padding: 3.4rem 0 2rem;
  }
}
.tanc-cleaning__building-finish .building-finish {
  margin-top: 5.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media all and (max-width: 1160px) {
  .tanc-cleaning__building-finish .building-finish {
    max-width: 80rem;
    margin: 4.5rem auto 0;
  }
}
@media all and (max-width: 576px) {
  .tanc-cleaning__building-finish .building-finish {
    margin-top: 3rem;
    padding: 0 2rem;
  }
}
.tanc-cleaning__building-finish .building-finish__item {
  position: relative;
  width: 24.5rem;
  padding-top: 9rem;
  font-weight: 800;
  font-size: 2.4rem;
  line-height: 3rem;
}
@media all and (max-width: 1160px) {
  .tanc-cleaning__building-finish .building-finish__item {
    width: 48%;
    margin-bottom: 2.5rem;
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .tanc-cleaning__building-finish .building-finish__item {
    width: 100%;
    font-size: 2rem;
    line-height: 3rem;
  }
}
.tanc-cleaning__building-finish .building-finish__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 7.4rem;
  height: 7.4rem;
  border-radius: 50%;
  background: white url("../img/check-icon-black.svg") center center/auto no-repeat;
  box-shadow: 0 0.8rem 1.5rem rgba(31, 31, 31, 0.15);
}
@media all and (max-width: 1160px) {
  .tanc-cleaning__building-finish .building-finish__item::before {
    left: 50%;
    transform: translateX(-50%);
  }
}
.tanc-cleaning__building-process {
  padding: 9.1rem 0 12.5rem;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .tanc-cleaning__building-process {
    padding: 6rem 0 8rem;
  }
}
@media all and (max-width: 576px) {
  .tanc-cleaning__building-process {
    padding: 3.8rem 0 4rem;
  }
}

.building-steps {
  margin-top: 6.6rem;
  display: grid;
  grid-template: auto/repeat(3, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
  counter-reset: step;
}
@media all and (max-width: 992px) {
  .building-steps {
    margin-top: 3.5rem;
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 576px) {
  .building-steps {
    margin-top: 2.5rem;
    row-gap: 1rem;
  }
}
.building-steps__item {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem 4.5rem 4.5rem;
  background: white;
  box-shadow: 0 4rem 3rem -3rem rgba(31, 31, 31, 0.09);
  counter-increment: step;
}
@media all and (max-width: 1260px) {
  .building-steps__item {
    padding: 1.5rem 3rem 3.5rem;
  }
}
@media all and (max-width: 576px) {
  .building-steps__item {
    padding: 1.5rem 2rem 3.3rem;
  }
}
.building-steps__item::before {
  content: counter(step);
  display: block;
  font-weight: 900;
  font-size: 12rem;
  line-height: 13.2rem;
  color: #e4e4e4;
}
@media all and (max-width: 768px) {
  .building-steps__item::before {
    font-size: 10rem;
    line-height: 10rem;
  }
}
.building-steps__title {
  max-width: 26rem;
  font-weight: 900;
  font-size: 2.8rem;
  line-height: 3.5rem;
}
@media all and (max-width: 1260px) {
  .building-steps__title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 992px) {
  .building-steps__title {
    max-width: unset;
  }
}
.building-steps__desc {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 1260px) {
  .building-steps__desc {
    margin-top: 1.8rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.building-steps__button {
  align-self: flex-start;
  height: 4.2rem;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  margin-top: auto;
}
.building-steps__modal-content {
  display: none;
}
.tanc-cleaning__certification {
  padding: 9.1rem 0 9.6rem;
}
@media all and (max-width: 992px) {
  .tanc-cleaning__certification {
    padding: 6rem 0;
  }
}
@media all and (max-width: 576px) {
  .tanc-cleaning__certification {
    padding: 4.5rem 0;
  }
}
.tanc-cleaning__certification-icon {
  margin: 0 auto 2rem;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  background: #00923F url("../img/certificate-icon.svg") center center/auto no-repeat;
}
@media all and (max-width: 576px) {
  .tanc-cleaning__certification-icon {
    width: 7.5rem;
    height: 7.5rem;
    background: #00923F url("../img/certificate-icon.svg") center center/2.6rem no-repeat;
  }
}
.tanc-cleaning__certification-desc {
  max-width: 100rem;
  margin: 2.5rem auto 0;
  font-size: 2.2rem;
  line-height: 3.5rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .tanc-cleaning__certification-desc {
    padding: 0 1rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin-top: 1.5rem;
  }
}
.tank-cleaning__expirience {
  padding: 8.5rem 0 7rem;
}
@media all and (max-width: 992px) {
  .tank-cleaning__expirience {
    padding: 6rem 0;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__expirience {
    padding: 4rem 0;
  }
}
.tank-cleaning__expirience .tank-cleaning__block-title {
  max-width: 30rem;
  text-align: left;
}
.tank-cleaning__expirience-header {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 992px) {
  .tank-cleaning__expirience-header {
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__expirience-header {
    padding: 0 2rem;
  }
}
.tank-cleaning__expirience-desc {
  flex: 1;
  max-width: 70rem;
}
@media all and (max-width: 992px) {
  .tank-cleaning__expirience-desc {
    margin-top: 1.7rem;
    max-width: unset;
  }
}

.expirience-slider {
  margin-top: 5rem;
  padding: 5.2rem 7.4rem;
  background: #f7f7f7;
  border: 1px solid #CFCFCF;
}
@media all and (max-width: 992px) {
  .expirience-slider {
    margin-top: 3.6rem;
    padding: 3rem 5rem;
  }
}
@media all and (max-width: 576px) {
  .expirience-slider {
    margin-top: 2.7rem;
    padding: 2.3rem 2rem 3rem;
  }
}
.expirience-slider__slide {
  display: none;
  grid-template: 10.4rem 1fr/40rem 1fr;
  column-gap: 2.7rem;
  row-gap: 5.6rem;
}
@media all and (max-width: 1160px) {
  .expirience-slider__slide {
    grid-template: 10.4rem 1fr/32rem 1fr;
  }
}
@media all and (max-width: 992px) {
  .expirience-slider__slide {
    grid-template: 10.4rem 1fr/25rem 1fr;
    column-gap: 2rem;
    row-gap: 2.6rem;
  }
}
@media all and (max-width: 920px) {
  .expirience-slider__slide {
    grid-template: auto/1fr;
    column-gap: 2rem;
    row-gap: 2.6rem;
  }
}
.expirience-slider__slide.slide--active {
  display: grid;
}
.expirience-slider__customer {
  max-width: 30rem;
}
.expirience-slider__customer-name {
  margin-top: 1rem;
  display: block;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 3rem;
}
@media all and (max-width: 992px) {
  .expirience-slider__customer-name {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 576px) {
  .expirience-slider__customer-name {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
.expirience-slider__content {
  grid-area: 1/2/3/3;
}
@media all and (max-width: 920px) {
  .expirience-slider__content {
    grid-area: unset;
  }
}
.expirience-slider__content-list {
  margin-bottom: 3rem;
}
@media all and (max-width: 920px) {
  .expirience-slider__content-list {
    margin-bottom: 1.5rem;
  }
}
.expirience-slider__content-text p {
  margin-bottom: 1.4rem;
}
@media all and (max-width: 920px) {
  .expirience-slider__content-text p {
    margin-bottom: 1rem;
  }
}
.expirience-slider__content-text p:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 920px) {
  .expirience-slider__nav {
    display: flex;
    align-items: center;
  }
}
.expirience-slider__nav-button.button--invert {
  width: 4.4rem;
  font-size: 0;
  background: #00923F url("../img/arrow-next.svg") center center/auto no-repeat;
}
@media all and (max-width: 920px) {
  .expirience-slider__nav-button.button--invert {
    height: 5.4rem;
  }
}
.expirience-slider__nav-button.button--invert:hover {
  background: #0ca24d url("../img/arrow-next.svg") center center/auto no-repeat;
}
.expirience-slider__nav-button.button--invert.nav-button--back {
  transform: scaleX(-1);
}
.expirience-slider__nav-counter {
  margin: 0 2rem;
}

.expirience-gallery {
  max-width: 126rem;
  margin: 5.8rem auto 0;
}
@media all and (max-width: 1460px) {
  .expirience-gallery {
    max-width: calc(100% - 14rem);
  }
}
@media all and (max-width: 992px) {
  .expirience-gallery {
    margin-top: 4rem;
  }
}
@media all and (max-width: 576px) {
  .expirience-gallery {
    max-width: 100%;
    margin-top: 2rem;
  }
}
.expirience-gallery__item {
  padding: 0 1.5rem;
  border-radius: 0.5rem;
}
@media all and (max-width: 768px) {
  .expirience-gallery__item {
    padding: 0 1rem;
  }
}
.expirience-gallery__item:focus {
  outline: none;
}
.expirience-gallery__item-img {
  vertical-align: top;
  width: 100%;
  height: 25rem;
  object-fit: cover;
  border-radius: 0.5rem;
}
@media all and (max-width: 1160px) {
  .expirience-gallery__item-img {
    height: 20rem;
  }
}
@media all and (max-width: 768px) {
  .expirience-gallery__item-img {
    height: 16rem;
  }
}
@media all and (max-width: 576px) {
  .expirience-gallery__item-img {
    height: 20rem;
  }
}
@media all and (max-width: 480px) {
  .expirience-gallery__item-img {
    height: 23rem;
  }
}
.expirience-gallery__item-img:focus {
  outline: none;
}
.expirience-gallery__item-zoom-link:focus {
  outline: none;
}
.expirience-gallery .slick-prev, .expirience-gallery .slick-next {
  width: 4.4rem;
  height: 6.4rem;
  transform: translate(0, -50%);
  z-index: 2;
}
@media all and (max-width: 576px) {
  .expirience-gallery .slick-prev, .expirience-gallery .slick-next {
    height: 5.4rem;
  }
}
.expirience-gallery .slick-prev:hover::before, .expirience-gallery .slick-next:hover::before {
  background: #309C5F url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
}
.expirience-gallery .slick-prev::before, .expirience-gallery .slick-next::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #00923F url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
  border-radius: 0.3rem;
  opacity: 1;
}
.expirience-gallery .slick-prev.slick-disabled::before, .expirience-gallery .slick-next.slick-disabled::before {
  background: #309C5F url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
}
.expirience-gallery .slick-prev {
  left: -6.8rem;
}
@media all and (max-width: 576px) {
  .expirience-gallery .slick-prev {
    left: 0;
  }
}
.expirience-gallery .slick-prev::before {
  transform: rotate(180deg);
}
.expirience-gallery .slick-next {
  right: -6.8rem;
}
@media all and (max-width: 576px) {
  .expirience-gallery .slick-next {
    right: 0;
  }
}
.tank-cleaning__header {
  display: flex;
  min-height: 70.3rem;
  flex-direction: column;
  align-items: center;
  padding: 0 0 2.5rem;
  background: url("../img/bg-tank-cleaning.jpg") center center/cover no-repeat;
}
@media all and (max-width: 480px) {
  .tank-cleaning__header {
    background: url("../img/bg-tank-cleaning-m.jpg") center center/cover no-repeat;
  }
}
.tank-cleaning__header .tank-cleaning__order {
  margin-top: 0;
}
@media all and (max-width: 576px) {
  .tank-cleaning__header .tank-cleaning__order .container {
    padding: 0 1rem;
  }
}
.tank-cleaning__header .tank-cleaning__order-inner {
  max-width: 109.6rem;
  margin: 0 auto;
  padding: 3.7rem 2rem 5rem;
  min-height: 31.4rem;
  border-radius: 0.4rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__header .tank-cleaning__order-inner {
    padding: 2.6rem 3rem 3rem;
  }
}
.tank-cleaning__header .tank-cleaning__order-title {
  line-height: 4rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__header .tank-cleaning__order-title {
    line-height: unset;
  }
}
.tank-cleaning__header .tank-cleaning__order-form {
  margin-top: 2.7rem;
}
.tank-cleaning__header-title {
  max-width: 125rem;
  padding: 0 1rem;
  margin: 7.5rem auto 0;
  text-align: center;
  font-family: Oswald, "Sans Serif", serif;
  font-weight: 500;
  font-size: 5.8rem;
  line-height: 6rem;
  color: white;
}
@media all and (max-width: 992px) {
  .tank-cleaning__header-title {
    font-size: 4.6rem;
    line-height: 5rem;
  }
}
@media all and (max-width: 768px) {
  .tank-cleaning__header-title {
    margin-top: 5rem;
    font-size: 4rem;
    line-height: 4.6rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__header-title {
    font-size: 3.6rem;
    line-height: 4.2rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__header-title {
    margin-top: 3rem;
    font-size: 3.2rem;
    line-height: 4rem;
  }
}
.tank-cleaning__header-title.title--without-desc {
  margin-bottom: 6.5rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__header-title.title--without-desc {
    margin-bottom: 5rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__header-title.title--without-desc {
    margin-bottom: 3.5rem;
  }
}
.tank-cleaning__header-desc {
  max-width: 75.9rem;
  margin: 4.2rem 0 5.6rem;
  padding: 0 1rem;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.1rem;
  text-align: center;
  color: white;
}
@media all and (max-width: 768px) {
  .tank-cleaning__header-desc {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__header-desc {
    max-width: 27.7rem;
    margin: 3rem auto;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.tank-cleaning__header-list {
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3.6rem auto 5.1rem;
  list-style: none;
  max-width: 74rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__header-list {
    align-items: flex-start;
    margin: 3.6rem auto;
  }
}
.tank-cleaning__header-list-item + .tank-cleaning__header-list-item {
  margin-top: 0.9rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__header-list-item + .tank-cleaning__header-list-item {
    margin-top: 1.5rem;
  }
}
.tank-cleaning__header-list-item {
  display: inline-block;
  position: relative;
  padding-left: 3rem;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 3.1rem;
  color: white;
  text-align: center;
}
@media all and (max-width: 768px) {
  .tank-cleaning__header-list-item {
    padding-left: 2.6rem;
    width: 100%;
    text-align: left;
  }
}
.tank-cleaning__header-list-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1.3rem;
  display: block;
  width: 2rem;
  height: 0.3rem;
  background: #00BB51;
}
@media all and (max-width: 768px) {
  .tank-cleaning__header-list-item::before {
    top: 1.2rem;
    width: 1.6rem;
    height: 0.2rem;
  }
}
@media all and (max-width: 768px) {
  .tank-cleaning__header-list-item {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__header-list-item {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.tank-cleaning__last-questions {
  margin: 12rem 0 5.6rem;
}
@media all and (max-width: 1200px) {
  .tank-cleaning__last-questions {
    margin: 9rem 0 4.6rem;
  }
}
@media all and (max-width: 768px) {
  .tank-cleaning__last-questions {
    margin: 6.6rem 0 1.6rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__last-questions {
    margin: 3.6rem 0 0;
  }
}
.tank-cleaning__last-questions-inner {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 1200px) {
  .tank-cleaning__last-questions-inner {
    flex-direction: column;
  }
}
.tank-cleaning__last-questions-info {
  padding-top: 4.3rem;
  max-width: 33.3rem;
}
@media all and (max-width: 1200px) {
  .tank-cleaning__last-questions-info {
    padding-top: 0;
    max-width: 100%;
    margin-bottom: 4rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__last-questions-info {
    margin-bottom: 2.1rem;
  }
}
.tank-cleaning__last-questions-info .tank-cleaning__block-title, .tank-cleaning__last-questions-info .tank-cleaning__block-desc {
  text-align: left;
}
@media all and (max-width: 1200px) {
  .tank-cleaning__last-questions-info .tank-cleaning__block-title, .tank-cleaning__last-questions-info .tank-cleaning__block-desc {
    text-align: center;
  }
}
.tank-cleaning__last-questions-info .tank-cleaning__block-desc {
  margin-top: 1.7rem;
}
.tank-cleaning__last-questions-wrapper {
  width: 100%;
  max-width: 82.3rem;
}
@media all and (max-width: 1200px) {
  .tank-cleaning__last-questions-wrapper {
    max-width: unset;
  }
}
.tank-cleaning__last-questions-video {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}
@media all and (max-width: 375px) {
  .tank-cleaning__last-questions-video {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.tank-cleaning__last-questions-video iframe, .tank-cleaning__last-questions-video img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tank-cleaning__licenses {
  margin: 8.3rem 0 10.6rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__licenses {
    margin: 6.3rem 0;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__licenses {
    margin: 4.3rem 0;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__licenses {
    margin: 3rem 0 4.7rem;
  }
}
.tank-cleaning__licenses-desc {
  max-width: 79rem;
  margin: 3rem auto 0;
  font-size: 1.8rem;
  line-height: 3rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .tank-cleaning__licenses-desc {
    display: none;
  }
}
.tank-cleaning__licenses-slider {
  margin: 5.2rem auto 3.8rem;
}
@media all and (max-width: 375px) {
  .tank-cleaning__licenses-slider {
    margin-top: 2.9rem;
  }
}
.tank-cleaning__licenses-slider .licenses-slider__item {
  margin: 0 1rem;
  cursor: pointer;
}
.tank-cleaning__licenses-slider .licenses-slider__item:focus {
  outline: none;
}
.tank-cleaning__licenses-slider .licenses-slider__item-zoom-link:focus {
  outline: none;
}
.tank-cleaning__licenses-slider .licenses-slider__item-img {
  max-width: 100%;
  border: 0.3rem solid #A6A6A6;
}
@media all and (max-width: 576px) {
  .tank-cleaning__licenses-slider .licenses-slider__item-img {
    border: 0.2rem solid #A6A6A6;
  }
}
.tank-cleaning__licenses-slider .licenses-slider__item-img:focus {
  outline: none;
}
.tank-cleaning__licenses-slider .slick-dots {
  bottom: -3.5rem;
}
.tank-cleaning__licenses-slider .slick-dots li {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0.4rem;
}
.tank-cleaning__licenses-slider .slick-dots li.slick-active button::before {
  background: #728291;
}
.tank-cleaning__licenses-slider .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
}
.tank-cleaning__licenses-slider .slick-dots li button::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #CCD3D8;
  opacity: 1;
}
.tank-cleaning__licenses-label {
  grid-template: auto/repeat(6, minmax(14rem, 20rem));
  display: grid;
  align-items: center;
  box-shadow: 0 2rem 10rem rgba(7, 32, 57, 0.09);
}
@media all and (max-width: 900px) {
  .tank-cleaning__licenses-label {
    margin: 6rem auto 0;
  }
}
@media all and (max-width: 840px) {
  .tank-cleaning__licenses-label {
    max-width: max-content;
    grid-template: auto/repeat(3, minmax(14rem, 18rem));
  }
}
@media all and (max-width: 640px) {
  .tank-cleaning__licenses-label {
    max-width: unset;
    margin: 6rem 1rem 0;
  }
}
@media all and (max-width: 480px) {
  .tank-cleaning__licenses-label {
    max-width: 44rem;
    grid-template: auto/repeat(2, 1fr);
    box-shadow: 0 1rem 3rem rgba(14, 28, 41, 0.07);
  }
}
.tank-cleaning__licenses-label-item {
  position: relative;
  padding: 2rem 1.7rem;
}
.tank-cleaning__licenses-label-item::before {
  content: "";
  display: block;
  width: 0.1rem;
  height: 4.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-left: 0.1rem solid #E3E3E3;
  box-sizing: border-box;
}
.tank-cleaning__licenses-label-item:last-child::before {
  display: none;
}
@media all and (max-width: 840px) and (min-width: 480px) {
  .tank-cleaning__licenses-label-item:nth-of-type(3n)::before {
    display: none;
  }
}
@media all and (max-width: 480px) {
  .tank-cleaning__licenses-label-item:nth-of-type(even)::before {
    display: none;
  }
}
.tank-cleaning__licenses-label-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.tank-cleaning__process-masonry {
  padding: 6.5rem 0 9rem;
  background: #f7f7f7;
}
@media all and (max-width: 768px) {
  .tank-cleaning__process-masonry {
    padding: 4rem 0 5rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__process-masonry {
    padding: 3rem 0 4rem;
  }
}
.tank-cleaning__process-masonry .tank-cleaning__block-title {
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 90rem;
}
.tank-cleaning__process-masonry .tank-cleaning__masonry-desc {
  margin-top: 3rem;
  font-size: 2.2rem;
  line-height: 3.5rem;
  text-align: center;
}
@media all and (max-width: 768px) {
  .tank-cleaning__process-masonry .tank-cleaning__masonry-desc {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__process-masonry .tank-cleaning__masonry-desc {
    margin-top: 1.6rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
.tank-cleaning__process-masonry .tank-cleaning__masonry-title {
  margin-top: 8rem;
  font-weight: 800;
  font-size: 4rem;
  line-height: 4.8rem;
  text-align: center;
}
@media all and (max-width: 768px) {
  .tank-cleaning__process-masonry .tank-cleaning__masonry-title {
    margin-top: 3.4rem;
    font-size: 2.4rem;
    line-height: 3rem;
  }
}

.tank-cleaning__masonry-grid {
  margin-top: 4.5rem;
  column-count: 2;
  column-gap: 3rem;
  column-fill: balance;
  column-fill: balance;
  -moz-column-fill: balance;
  -webkit-column-fill: balance;
}
@media all and (max-width: 720px) {
  .tank-cleaning__masonry-grid {
    column-count: 1;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__masonry-grid {
    margin-top: 2.2rem;
  }
}
.tank-cleaning__masonry-grid-item {
  display: inline-block;
  width: 100%;
  padding: 3.2rem 4.1rem 3.2rem 4.4rem;
  font-size: 2.2rem;
  line-height: 3rem;
  background: white;
  box-shadow: 0 3rem 2rem -2rem rgba(31, 31, 31, 0.09);
  margin-bottom: 1.6rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__masonry-grid-item {
    padding: 1.7rem 2rem 1.8rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 720px) {
  .tank-cleaning__masonry-grid-item {
    margin-bottom: 1rem;
  }
}
.tank-cleaning__order-complex {
  padding: 6.6rem 0 8rem;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .tank-cleaning__order-complex {
    padding: 4rem 0 5rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-complex {
    padding: 3rem 0;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__order-complex {
    padding: 1.2rem 0 2rem;
  }
}
.tank-cleaning__order-complex .tank-cleaning__order-complex-inner {
  padding: 6rem 7.4rem;
  background: white url("../img/bg-order-complex.jpg") right top/contain no-repeat;
  height: 41rem;
}
@media all and (max-width: 1360px) {
  .tank-cleaning__order-complex .tank-cleaning__order-complex-inner {
    padding: 4rem 4rem 5rem;
    height: 36rem;
  }
}
@media all and (max-width: 1200px) {
  .tank-cleaning__order-complex .tank-cleaning__order-complex-inner {
    height: auto;
    background: white url("../img/bg-order-complex-m.jpg") right top/40% no-repeat;
  }
}
@media all and (max-width: 768px) {
  .tank-cleaning__order-complex .tank-cleaning__order-complex-inner {
    border-radius: 0.4rem;
    background: white url("../img/bg-order-complex-m.jpg") right top/300px no-repeat;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-complex .tank-cleaning__order-complex-inner {
    padding: 2.6rem 3rem 3.5rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__order-complex .tank-cleaning__order-complex-inner {
    background: white url("../img/bg-order-complex-m.jpg") right top/250px no-repeat;
  }
}
.tank-cleaning__order-complex .tank-cleaning__block-title {
  text-align: left;
}
@media all and (max-width: 1200px) {
  .tank-cleaning__order-complex .tank-cleaning__block-title {
    max-width: 31rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-complex .tank-cleaning__block-title {
    max-width: 16rem;
  }
}
.tank-cleaning__order-complex .tank-cleaning__order-form {
  max-width: 68rem;
  margin-left: 0;
}
@media all and (max-width: 1200px) {
  .tank-cleaning__order-complex .tank-cleaning__order-form {
    max-width: 49rem;
  }
}
@media all and (max-width: 768px) {
  .tank-cleaning__order-complex .tank-cleaning__order-form {
    margin-top: 11rem;
  }
}
@media all and (max-width: 720px) {
  .tank-cleaning__order-complex .tank-cleaning__order-form {
    max-width: 40rem;
  }
}
.tank-cleaning__order-complex .tank-cleaning__order-form .form__input-wrapper.wrapper--checkbox {
  width: 25rem;
  margin-top: 0;
  margin-left: 0.6rem;
  text-align: left;
}
@media all and (max-width: 720px) {
  .tank-cleaning__order-complex .tank-cleaning__order-form .form__input-wrapper.wrapper--checkbox {
    width: 100%;
  }
}
.tank-cleaning__order-complex .tank-cleaning__order-form .form__input-wrapper.wrapper--checkbox .form__error-msg--privacy {
  top: unset;
  left: -0.5rem;
  bottom: -1.6rem;
  transform: translate(0, 100%);
}
.tank-cleaning__order-complex .tank-cleaning__order-form .form__input-wrapper.wrapper--checkbox .form__error-msg--privacy::before {
  right: unset;
  left: 1rem;
  top: 0;
}
.tank-cleaning__order-complex .form__input-inline.input-inline--complex {
  margin-top: 0.6rem;
  grid-template: auto/23.7rem 1fr;
  align-items: center;
}
@media all and (max-width: 720px) {
  .tank-cleaning__order-complex .form__input-inline.input-inline--complex {
    grid-template: auto/1fr;
  }
}
.tank-cleaning__order-complex .form__input-inline.input-inline--complex .form__label.label--checkbox:before {
  top: 50%;
  transform: translateY(-50%);
}
.tank-cleaning__process-cleaning {
  background: #f7f7f7;
  padding: 9.3rem 0 7.6rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__process-cleaning {
    padding: 5rem 0 6rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__process-cleaning {
    padding: 3.6rem 0 5.1rem;
  }
}

.process-cleaning__grid {
  margin-top: 7.2rem;
  display: grid;
  grid-template: auto/repeat(4, 1fr);
  row-gap: 2.7rem;
}
@media all and (max-width: 992px) {
  .process-cleaning__grid {
    max-width: 64rem;
    margin: 5rem auto 0;
    grid-template: auto/repeat(2, 1fr);
  }
}
@media all and (max-width: 576px) {
  .process-cleaning__grid {
    grid-template: auto/1fr;
    margin: 2.9rem auto 0;
  }
}
.process-cleaning__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.process-cleaning__icon-wrapper {
  margin-bottom: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.4rem;
  height: 9.4rem;
  background: white;
  border-radius: 50%;
  box-shadow: 0 1rem 2rem rgba(31, 31, 31, 0.12);
}
@media all and (max-width: 576px) {
  .process-cleaning__icon-wrapper {
    margin-bottom: 1.3rem;
    min-width: 7.4rem;
    width: 7.4rem;
    height: 7.4rem;
  }
}
.process-cleaning__icon {
  vertical-align: top;
}
@media all and (max-width: 576px) {
  .process-cleaning__icon {
    max-width: 3.6rem;
    max-height: 3.6rem;
  }
}
.process-cleaning__title {
  font-weight: 800;
  font-size: 2.8rem;
  line-height: 3.5rem;
}
@media all and (max-width: 576px) {
  .process-cleaning__title {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
.process-cleaning__desc {
  max-width: 21.8rem;
  margin-top: 1.5rem;
}
@media all and (max-width: 576px) {
  .process-cleaning__desc {
    max-width: 26rem;
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.tank-cleaning__process {
  padding: 7rem 0;
  background: #F7F7F7;
}
@media all and (max-width: 992px) {
  .tank-cleaning__process {
    padding: 7rem 0 3rem;
  }
}
.tank-cleaning__process-grid {
  margin-top: 6.3rem;
  display: grid;
  grid-template: auto/repeat(auto-fit, minmax(46rem, 1fr));
  column-gap: 3rem;
  row-gap: 2.6rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__process-grid {
    margin-top: 4rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__process-grid {
    margin-top: 2.8rem;
    row-gap: 2.1rem;
    grid-template: auto/1fr;
  }
}
.tank-cleaning__process-item {
  padding: 4.1rem 8.7rem 5.2rem 4.4rem;
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0 3rem 3rem -2rem rgba(31, 31, 31, 0.09);
}
@media all and (max-width: 1160px) {
  .tank-cleaning__process-item {
    padding: 3.7rem 5rem 3.8rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__process-item {
    padding: 3.7rem 3rem 3.8rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__process-item {
    padding: 3.7rem 2rem 3.8rem;
  }
}
.tank-cleaning__process-item.process-item--order {
  padding: 3.5rem 5.3rem 2rem 5.3rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media all and (max-width: 576px) {
  .tank-cleaning__process-item.process-item--order {
    padding: 2.9rem 3rem 3.5rem;
  }
}
.tank-cleaning__process-item.process-item--order .tank-cleaning__process-title {
  width: 100%;
  text-align: center;
}
.tank-cleaning__process-item.process-item--order .tank-cleaning__order-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.4rem;
}
.tank-cleaning__process-item.process-item--order .form__submit-button {
  max-width: 28.8rem;
}
.tank-cleaning__process-img-wrapper {
  flex: 1;
  max-width: 14.8rem;
  margin-right: 4.5rem;
}
.tank-cleaning__process-img {
  width: 100%;
  vertical-align: top;
}
.tank-cleaning__process-info {
  flex: 1;
}
.tank-cleaning__process-title {
  display: block;
  font-weight: 800;
  font-size: 2.8rem;
  line-height: 3.5rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__process-title {
    font-size: 2.2rem;
    line-height: 3rem;
    text-align: center;
  }
}
.tank-cleaning__process-desc {
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #333333;
}
@media all and (max-width: 576px) {
  .tank-cleaning__process-desc {
    margin-top: 1.1rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
    text-align: center;
  }
}
.tank-cleaning__process-buttons {
  margin-top: 2rem;
}
@media all and (max-width: 480px) {
  .tank-cleaning__process-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.tank-cleaning__process-button, .tank-cleaning__process-link {
  margin-right: 1.5rem;
  height: 4.8rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #00923F;
}
@media all and (max-width: 480px) {
  .tank-cleaning__process-button, .tank-cleaning__process-link {
    font-size: 1.6rem;
    margin-right: 0;
    margin-bottom: 0.7rem;
    width: 18.9rem;
  }
}
.tank-cleaning__process-button:last-child, .tank-cleaning__process-link:last-child {
  margin-bottom: 0;
}
.tank-cleaning__process-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tank-cleaning__process-link:hover {
  color: #00923F;
}
.tank-cleaning__calc {
  padding: 8.2rem 0 6rem;
  background: #f7f7f7;
  position: relative;
  z-index: 2;
}
@media all and (max-width: 992px) {
  .tank-cleaning__calc {
    padding: 5rem 0 4rem;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__calc {
    padding: 3rem 0 2rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__calc .tank-cleaning__block-desc {
    margin-top: 1.7rem;
  }
}
.tank-cleaning__quiz {
  max-width: 103.5rem;
  margin: 4.8rem auto 0;
  background: white;
  box-shadow: 0 6rem 10rem rgba(32, 32, 32, 0.07);
}
@media all and (max-width: 375px) {
  .tank-cleaning__quiz {
    margin-top: 2.4rem;
  }
}
.tank-cleaning__quiz .quiz__item {
  display: none;
}
.tank-cleaning__quiz .quiz__item.item--current {
  display: block;
}
.tank-cleaning__quiz .quiz__item-header {
  padding: 3.1rem 2rem;
  background: #00923F;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 3.7rem;
  color: white;
  text-align: center;
}
@media all and (max-width: 375px) {
  .tank-cleaning__quiz .quiz__item-header {
    font-size: 2rem;
    line-height: 2.5rem;
    padding: 1.61rem 3.2rem 1.9rem;
  }
}
.tank-cleaning__quiz .quiz__item-question {
  margin-right: 0.5rem;
}
.tank-cleaning__quiz .quiz__item-counter {
  white-space: nowrap;
}
.tank-cleaning__quiz .quiz__item-inputs {
  max-width: 54rem;
  padding: 0 2rem;
  margin: 4.6rem auto 0;
}
@media all and (max-width: 375px) {
  .tank-cleaning__quiz .quiz__item-inputs {
    margin-top: 2.1rem;
  }
}
.tank-cleaning__quiz .quiz__item-inputs.inputs--error .quiz__input {
  background: rgba(199, 31, 31, 0.03);
  border: 0.2rem solid #C71F1F;
}
.tank-cleaning__quiz .quiz__item-inputs.inputs--error .quiz__input::placeholder {
  color: #C71F1F;
}
.tank-cleaning__quiz .quiz__item-inputs.inputs--error .quiz__error {
  display: inline-block;
}

.quiz__input {
  width: 100%;
  padding: 1.7rem 2.4rem;
  font-size: 1.8rem;
  line-height: 3rem;
  background: #F9FBFD;
  border: 0.1rem solid #CDDAE5;
  border-radius: 0.3rem;
}
@media all and (max-width: 576px) {
  .quiz__input {
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding: 1.4rem 2rem 1.2rem;
  }
}
.quiz__input::placeholder {
  color: #697E8D;
}
.quiz__input:focus {
  outline: none;
}

.quiz__radio {
  display: none;
}
.quiz__radio:checked + .quiz__label {
  background: #E7E7E7;
}
.quiz__radio:checked + .quiz__label::before {
  background: #808080;
}

.quiz__input-wrapper {
  margin-bottom: 1.1rem;
}
.quiz__input-wrapper:last-of-type {
  margin-bottom: 0;
}

.quiz__label {
  display: block;
  position: relative;
  width: 100%;
  padding: 1.8rem 2rem 1.6rem 5.3rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3rem;
  background: #F1F1F1;
  border-radius: 0.3rem;
  transition: 0.15s;
  cursor: pointer;
}
@media all and (max-width: 576px) {
  .quiz__label {
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding: 1.4rem 1.6rem 1.2rem 4.4rem;
  }
}
.quiz__label:hover {
  background: #EAEAEA;
}
.quiz__label::before {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 2.2rem;
  left: 2rem;
  border-radius: 50%;
  border: 0.2rem solid #808080;
  box-sizing: border-box;
  transition: 0.15s;
  background: white;
}
@media all and (max-width: 576px) {
  .quiz__label::before {
    top: 1.8rem;
    left: 1.2rem;
  }
}

.quiz__error {
  display: none;
  font-size: 1.3rem;
  line-height: 1.9rem;
  color: #C71F1F;
}

.quiz__nav {
  max-width: 54rem;
  margin: 0 auto;
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-between;
  background: white;
}
@media all and (max-width: 576px) {
  .quiz__nav {
    padding: 2.1rem 2rem 2rem;
  }
}
.quiz__nav-button {
  height: 6.4rem;
  width: 18rem;
  border: 0.2rem solid #00923F;
  transition: opacity 0s;
}
@media all and (max-width: 375px) {
  .quiz__nav-button {
    height: 5.4rem;
    max-width: 12.5rem;
  }
}
.quiz__nav-button.button--hidden {
  cursor: auto;
  opacity: 0;
}
.tank-cleaning__reviews {
  padding: 7rem 0 9rem;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .tank-cleaning__reviews {
    padding: 5rem 0 5rem;
  }
}
@media all and (max-width: 768px) {
  .tank-cleaning__reviews {
    padding: 5rem 0;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__reviews {
    padding: 3rem 0 0;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__reviews .tank-cleaning__block-desc {
    margin-top: 1.7rem;
  }
}
.tank-cleaning__reviews-inner {
  margin-top: 6rem;
}
@media all and (max-width: 640px) {
  .tank-cleaning__reviews-inner {
    margin-top: 8.5rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__reviews-inner {
    padding: 8.1rem 0 0.1rem;
  }
}
.tank-cleaning__reviews-item {
  display: none;
  margin: 0 auto;
  justify-content: space-between;
}
@media all and (max-width: 640px) {
  .tank-cleaning__reviews-item {
    flex-direction: column;
  }
}
.tank-cleaning__reviews-item.item--current {
  display: flex;
}
.tank-cleaning__reviews-item .reviews-item__license {
  position: relative;
  flex: 1;
  max-width: 33.3rem;
  z-index: 2;
}
@media all and (max-width: 640px) {
  .tank-cleaning__reviews-item .reviews-item__license {
    max-width: unset;
    width: 100%;
    padding: 0 7.9rem;
  }
}
.tank-cleaning__reviews-item .reviews-item__license-link:focus {
  outline: none;
}
.tank-cleaning__reviews-item .reviews-item__license-img {
  vertical-align: top;
  max-width: 100%;
  border: 0.3rem solid #A5A5A5;
  box-shadow: 0 4rem 8rem rgba(27, 27, 27, 0.12);
}
@media all and (max-width: 640px) {
  .tank-cleaning__reviews-item .reviews-item__license-img {
    margin-top: -7rem;
    box-shadow: 0 4rem 8rem rgba(27, 27, 27, 0.06);
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__reviews-item .reviews-item__license-img {
    margin-top: -14rem;
    box-shadow: 0 4rem 8rem rgba(27, 27, 27, 0.06);
  }
}
.tank-cleaning__reviews-item .reviews-item__license-img:focus {
  outline: none;
}
.tank-cleaning__reviews-item .reviews-item__text {
  position: relative;
  flex: 1;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 43rem;
  padding: 3rem 10rem 3rem 14rem;
  border: 0.1rem solid #DFDFDF;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 3rem;
  background: white;
}
@media all and (max-width: 640px) {
  .tank-cleaning__reviews-item .reviews-item__text {
    border: none;
  }
}
.tank-cleaning__reviews-item .reviews-item__text::-webkit-scrollbar-track {
  padding-right: 0.5rem;
  -webkit-box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0.3);
  background-color: #eef3f8;
}
.tank-cleaning__reviews-item .reviews-item__text::-webkit-scrollbar {
  width: 0.3rem;
  background-color: #eef3f8;
}
.tank-cleaning__reviews-item .reviews-item__text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0.3);
  background-color: #C3CDD7;
}
@media all and (max-width: 900px) {
  .tank-cleaning__reviews-item .reviews-item__text {
    padding: 3rem 6rem;
    margin-left: 4rem;
  }
}
@media all and (max-width: 768px) {
  .tank-cleaning__reviews-item .reviews-item__text {
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-left: 3rem;
  }
}
@media all and (max-width: 640px) {
  .tank-cleaning__reviews-item .reviews-item__text {
    margin-left: 0;
    margin-top: -9rem;
    padding: 11.5rem 3rem 3rem;
  }
}
.tank-cleaning__reviews-item .reviews-item__text::before {
  content: "";
  display: block;
  width: 4.4rem;
  height: 4.4rem;
  position: absolute;
  top: 5.1rem;
  left: 5.1rem;
  background: url("../img/reviews-quotes.svg") center center/contain no-repeat;
}
@media all and (max-width: 900px) {
  .tank-cleaning__reviews-item .reviews-item__text::before {
    width: 3rem;
    height: 3rem;
    top: 2rem;
    left: 2rem;
  }
}
@media all and (max-width: 640px) {
  .tank-cleaning__reviews-item .reviews-item__text::before {
    width: 7.2rem;
    height: 4.8rem;
    left: 2.8rem;
    top: 1.9rem;
  }
}
.tank-cleaning__reviews-item .reviews-item__text p {
  margin-bottom: 1rem;
}
.tank-cleaning__reviews-item .reviews-item__text p:last-child {
  margin-bottom: 0;
}
.tank-cleaning__reviews-item .reviews-item__text-author {
  margin-top: 1.9rem;
}
.tank-cleaning__reviews-item .reviews-item__text-author .text-selected {
  font-weight: 800;
}
.tank-cleaning__reviews-slider {
  max-width: 111rem;
  margin: 0.3rem auto 0;
}
@media all and (max-width: 1310px) {
  .tank-cleaning__reviews-slider {
    max-width: calc(100% - 14rem);
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__reviews-slider {
    margin-top: 0;
    max-width: calc(100% - 11.7rem);
  }
}
.tank-cleaning__reviews-slider .reviews-slider__item {
  margin: 0 0.6rem;
  padding: 0.6rem 1rem;
  background: white;
  height: 11rem;
  border: 0.1rem solid #DADFE3;
}
@media all and (max-width: 576px) {
  .tank-cleaning__reviews-slider .reviews-slider__item {
    padding: 0 3.1rem;
    height: 9rem;
  }
}
.tank-cleaning__reviews-slider .reviews-slider__item:focus {
  outline: none;
}
.tank-cleaning__reviews-slider .reviews-slider__item:hover {
  cursor: pointer;
}
.tank-cleaning__reviews-slider .reviews-slider__item:hover .reviews-slider__item-img {
  filter: none;
}
.tank-cleaning__reviews-slider .reviews-slider__item.slick-current {
  border-color: transparent;
  box-shadow: 0 1rem 2rem rgba(7, 32, 57, 0.09);
}
.tank-cleaning__reviews-slider .reviews-slider__item.slick-current .reviews-slider__item-img {
  filter: none;
}
.tank-cleaning__reviews-slider .reviews-slider__item-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: grayscale(100%);
  transition: 0.2s;
}
.tank-cleaning__reviews-slider .slick-list {
  padding: 4rem 0;
}
.tank-cleaning__reviews-slider .slick-prev, .tank-cleaning__reviews-slider .slick-next {
  width: 4.4rem;
  height: 6.4rem;
  transform: translate(0, -50%);
  z-index: 2;
}
@media all and (max-width: 576px) {
  .tank-cleaning__reviews-slider .slick-prev, .tank-cleaning__reviews-slider .slick-next {
    height: 5.4rem;
  }
}
.tank-cleaning__reviews-slider .slick-prev:hover::before, .tank-cleaning__reviews-slider .slick-next:hover::before {
  background: #309C5F url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
}
.tank-cleaning__reviews-slider .slick-prev::before, .tank-cleaning__reviews-slider .slick-next::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #00923F url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
  border-radius: 0.3rem;
  opacity: 1;
}
.tank-cleaning__reviews-slider .slick-prev.slick-disabled::before, .tank-cleaning__reviews-slider .slick-next.slick-disabled::before {
  background: #309C5F url("../img/arrow-reviews-slider.svg") center center/auto no-repeat;
}
.tank-cleaning__reviews-slider .slick-prev {
  left: -6.8rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__reviews-slider .slick-prev {
    left: -5.8rem;
  }
}
.tank-cleaning__reviews-slider .slick-prev::before {
  transform: rotate(180deg);
}
.tank-cleaning__reviews-slider .slick-next {
  right: -6.8rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__reviews-slider .slick-next {
    right: -5.8rem;
  }
}
.tank-cleaning__reviews-slider .slick-dots {
  bottom: 0;
}
.tank-cleaning__reviews-slider .slick-dots li {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0.4rem;
}
.tank-cleaning__reviews-slider .slick-dots li.slick-active button::before {
  background: #728291;
}
.tank-cleaning__reviews-slider .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
}
.tank-cleaning__reviews-slider .slick-dots li button::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #CCD3D8;
  opacity: 1;
}
.tank-cleaning__stats-grid {
  display: flex;
  justify-content: space-around;
}
@media all and (max-width: 992px) {
  .tank-cleaning__stats-grid {
    flex-direction: column;
    align-items: center;
  }
}
.tank-cleaning__stats {
  padding: 9rem 0 6rem;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .tank-cleaning__stats {
    padding: 5rem 0 0;
  }
}
.tank-cleaning__stats.stats--full-width .tank-cleaning__stats-grid {
  justify-content: space-between;
}
.tank-cleaning__stats.stats--full-width .stats-item {
  width: 33.3%;
}
@media all and (max-width: 992px) {
  .tank-cleaning__stats.stats--full-width .stats-item {
    width: 35rem;
  }
}
@media all and (max-width: 480px) {
  .tank-cleaning__stats.stats--full-width .stats-item {
    width: 100%;
    padding: 0 5rem 0 2rem;
  }
}
.tank-cleaning__stats.stats--diagnostic {
  background: white;
  padding: 8rem 0;
}
@media all and (max-width: 768px) {
  .tank-cleaning__stats.stats--diagnostic {
    padding: 5.2rem 0;
  }
}
.tank-cleaning__stats.stats--diagnostic .stats-item__img-wrapper {
  border: 0.1rem solid #dfdfdf;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.03);
}
.tank-cleaning__stats .stats-item {
  display: flex;
  align-items: center;
}
@media all and (max-width: 992px) {
  .tank-cleaning__stats .stats-item {
    width: 35rem;
    margin-bottom: 3rem;
  }
}
@media all and (max-width: 480px) {
  .tank-cleaning__stats .stats-item {
    width: 100%;
    padding: 0 3rem;
  }
}
.tank-cleaning__stats .stats-item:last-child {
  margin-bottom: 0;
}
.tank-cleaning__stats .stats-item__img-wrapper {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.4rem;
  height: 9.4rem;
  background: white;
  border-radius: 50%;
  box-shadow: 0 1rem 2rem rgba(31, 31, 31, 0.12);
}
@media all and (max-width: 576px) {
  .tank-cleaning__stats .stats-item__img-wrapper {
    margin-right: 1.7rem;
    min-width: 7.4rem;
    width: 7.4rem;
    height: 7.4rem;
  }
}
.tank-cleaning__stats .stats-item__img-wrapper img {
  vertical-align: top;
}
@media all and (max-width: 576px) {
  .tank-cleaning__stats .stats-item__img-wrapper img {
    max-width: 4rem;
    max-height: 4rem;
  }
}
.tank-cleaning__stats .stats-item__text {
  display: block;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__stats .stats-item__text {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
.tank-cleaning__stats .stats-item__num {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 6.4rem;
  line-height: 7.7rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__stats .stats-item__num {
    font-size: 4.4rem;
    line-height: 6rem;
  }
}
.tank-cleaning__steps {
  padding-bottom: 10.6rem;
  background: #f7f7f7;
}
@media all and (max-width: 576px) {
  .tank-cleaning__steps {
    padding-bottom: 5rem;
  }
}

.tabs-process {
  padding: 5.3rem 10.5rem 6.5rem;
  background: white;
}
@media all and (max-width: 1260px) {
  .tabs-process {
    padding: 4rem 3.5rem 3.5rem;
  }
}
@media all and (max-width: 576px) {
  .tabs-process {
    padding: 3rem 2rem 3.5rem;
  }
}
.tabs-process__tab-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-process__tab-btn + .tabs-process__tab-btn {
  margin-left: 1.4rem;
}
@media all and (max-width: 576px) {
  .tabs-process__tab-btn + .tabs-process__tab-btn {
    margin-left: 1rem;
  }
}
.tabs-process__tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.2rem;
  width: 4.4rem;
  height: 4.4rem;
  font-weight: 800;
  font-size: 2.1rem;
  line-height: 3rem;
  color: #00923F;
  border: 2px solid #00923F;
  border-radius: 50%;
  transition: 0.2s;
}
@media all and (max-width: 576px) {
  .tabs-process__tab-btn {
    width: 3.4rem;
    height: 3.4rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.tabs-process__tab-btn:hover {
  cursor: pointer;
  background: #E4F1EA;
}
.tabs-process__tab-btn.tab-btn--active {
  color: white;
  background: #00923F;
}
.tabs-process__tab {
  display: none;
  margin-top: 2.6rem;
}
@media all and (max-width: 576px) {
  .tabs-process__tab {
    margin-top: 2rem;
  }
}
.tabs-process__tab.tab--active {
  display: block;
}
.tabs-process__step-title {
  display: block;
  max-width: 83.6rem;
  margin: 0 auto;
  font-weight: 800;
  font-size: 4rem;
  line-height: 4.8rem;
  text-align: center;
}
@media all and (max-width: 992px) {
  .tabs-process__step-title {
    font-size: 3rem;
    line-height: 3.6rem;
  }
}
@media all and (max-width: 576px) {
  .tabs-process__step-title {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 375px) {
  .tabs-process__step-title {
    max-width: 24rem;
  }
}
.tabs-process__step-desc {
  max-width: 83.6rem;
  margin: 2.4rem auto 0;
  font-size: 2.2rem;
  line-height: 3.7rem;
  text-align: center;
}
@media all and (max-width: 992px) {
  .tabs-process__step-desc {
    font-size: 2rem;
    line-height: 2.6rem;
    margin-top: 1.6rem;
  }
}
@media all and (max-width: 576px) {
  .tabs-process__step-desc {
    font-size: 1.8rem;
    line-height: 3rem;
  }
}
.tabs-process__step-content {
  margin-top: 4.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 992px) {
  .tabs-process__step-content {
    flex-direction: column;
  }
}
@media all and (max-width: 576px) {
  .tabs-process__step-content {
    margin-top: 2.3rem;
  }
}
.tabs-process__step-img-wrapper {
  max-width: 46.72%;
  margin-right: 7.6rem;
  min-width: 44rem;
}
@media all and (max-width: 1260px) {
  .tabs-process__step-img-wrapper {
    margin-right: 4rem;
  }
}
@media all and (max-width: 992px) {
  .tabs-process__step-img-wrapper {
    margin-right: 0;
    max-width: unset;
    min-width: unset;
  }
}
@media all and (max-width: 375px) {
  .tabs-process__step-img-wrapper {
    width: calc(100% + 4rem);
  }
}
.tabs-process__step-img {
  vertical-align: top;
  width: 100%;
}
.tabs-process__step-adv {
  flex: 1;
}
@media all and (max-width: 992px) {
  .tabs-process__step-adv {
    margin-top: 2.5rem;
  }
}
.tabs-process__step-adv-item + .tabs-process__step-adv-item {
  margin-top: 3rem;
}
@media all and (max-width: 576px) {
  .tabs-process__step-adv-item + .tabs-process__step-adv-item {
    margin-top: 2.6rem;
  }
}
.tabs-process__step-adv-item {
  min-height: 4.4rem;
  position: relative;
  padding-left: 6.4rem;
  font-size: 2rem;
  line-height: 3rem;
}
@media all and (max-width: 576px) {
  .tabs-process__step-adv-item {
    padding-left: 0;
    padding-top: 5.4rem;
    text-align: center;
  }
}
.tabs-process__step-adv-item::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0;
  display: block;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  background: #E4F1EA url("../img/message-send.svg") center center/16px no-repeat;
}
@media all and (max-width: 576px) {
  .tabs-process__step-adv-item::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.tabs-process__btn-wrapper {
  margin-top: 5.6rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .tabs-process__btn-wrapper {
    margin-top: 3.6rem;
  }
}
.tabs-process__order-button {
  padding-left: 3.9rem;
  padding-right: 3.9rem;
}
@media all and (max-width: 576px) {
  .tabs-process__order-button {
    max-width: 24rem;
    white-space: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    padding-left: 2.9rem;
    padding-right: 2.9rem;
  }
}
.tank-diagnostic {
  padding: 9.8rem 0 6.5rem;
  background: #f7f7f7;
}
@media all and (max-width: 992px) {
  .tank-diagnostic {
    padding: 5rem 0 4rem;
  }
}
@media all and (max-width: 576px) {
  .tank-diagnostic {
    padding: 4.7rem 0 3rem;
  }
}
.tank-diagnostic__desc-wrapper {
  max-width: 86.2rem;
  margin: 0 auto;
  font-size: 2.2rem;
  line-height: 3.5rem;
  text-align: center;
}
@media all and (max-width: 768px) {
  .tank-diagnostic__desc-wrapper {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media all and (max-width: 576px) {
  .tank-diagnostic__desc-wrapper {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
.tank-diagnostic__desc + .tank-diagnostic__desc {
  margin-top: 2.5rem;
}
@media all and (max-width: 576px) {
  .tank-diagnostic__desc + .tank-diagnostic__desc {
    margin-top: 2rem;
  }
}
.tank-diagnostic__title {
  margin-top: 8.9rem;
}
@media all and (max-width: 992px) {
  .tank-diagnostic__title {
    margin-top: 7rem;
  }
}
@media all and (max-width: 576px) {
  .tank-diagnostic__title {
    margin-top: 5.9rem;
  }
}
.tank-diagnostic__spec-list {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.tank-diagnostic__spec-item {
  position: relative;
  margin-top: 5.3rem;
  padding: 0 2rem 0 11.8rem;
  display: flex;
  align-items: center;
  width: 41.5rem;
  font-size: 1.8rem;
  line-height: 3rem;
}
@media all and (max-width: 900px) {
  .tank-diagnostic__spec-item {
    width: 50%;
    margin: 4rem 0 0;
  }
}
@media all and (max-width: 640px) {
  .tank-diagnostic__spec-item {
    margin-top: 2.9rem;
    width: 100%;
    padding: 9.1rem 2rem 0;
    justify-content: center;
    text-align: center;
  }
}
.tank-diagnostic__spec-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  display: block;
  width: 7.4rem;
  height: 7.4rem;
  border-radius: 50%;
  background: white url("../img/check-icon-black.svg") center center/auto no-repeat;
  box-shadow: 0 0.8rem 1.5rem rgba(31, 31, 31, 0.15);
}
@media all and (max-width: 640px) {
  .tank-diagnostic__spec-item::before {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.tank-cleaning__tech-cleaning {
  margin-top: 8.2rem;
}
@media all and (max-width: 992px) {
  .tank-cleaning__tech-cleaning {
    margin-top: 5rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-cleaning {
    margin-top: 3.1rem;
  }
}
.tank-cleaning__tech-cleaning .tank-cleaning__block-desc {
  max-width: 49.4rem;
}
@media all and (max-width: 576px) {
  .tank-cleaning__tech-cleaning .tank-cleaning__block-desc {
    display: none;
  }
}
.tank-cleaning__tech-cleaning .tech-cleaning__tabs-block {
  margin-top: 4.7rem;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 1024px) {
  .tank-cleaning__tech-cleaning .tech-cleaning__tabs-block {
    flex-direction: column;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__tech-cleaning .tech-cleaning__tabs-block {
    margin-top: 2.4rem;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__info {
  position: relative;
  max-width: 45.4rem;
  flex: 1;
  box-shadow: 0 6rem 10rem rgba(7, 32, 57, 0.09);
}
@media all and (max-width: 1024px) {
  .tank-cleaning__tech-cleaning .tabs-block__info {
    max-width: unset;
    margin: 1.5rem 2rem 0;
    padding-bottom: 3rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-cleaning .tabs-block__info {
    margin: 1.5rem 1rem 0;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__info::before {
  content: "";
  display: block;
  width: 1.9rem;
  height: 1.9rem;
  position: absolute;
  left: -1.5rem;
  top: 50%;
  background: white;
  transform: rotate(45deg) translateY(-50%);
}
@media all and (max-width: 1024px) {
  .tank-cleaning__tech-cleaning .tabs-block__info::before {
    left: 2.5rem;
    top: -1rem;
    transform: rotate(45deg) translateY(0);
    background: #00923F;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__info-controls {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  background: #00923F;
}
@media all and (max-width: 576px) {
  .tank-cleaning__tech-cleaning .tabs-block__info-controls {
    padding: 1.6rem 1rem;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__info-controls-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.4rem;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  font-weight: 800;
  font-size: 2.1rem;
  line-height: 3.3rem;
  color: white;
  border: none;
  background: #13A652;
  cursor: pointer;
  transition: 0.2s;
}
.tank-cleaning__tech-cleaning .tabs-block__info-controls-button.button--active {
  color: #00923F;
  background: white;
}
.tank-cleaning__tech-cleaning .tabs-block__info-controls-button:last-child {
  margin-right: unset;
}
.tank-cleaning__tech-cleaning .tabs-block__info-controls-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 25%;
  height: 0.8rem;
  background: #2DB969;
}
@media all and (max-width: 1024px) {
  .tank-cleaning__tech-cleaning .tabs-block__info-controls-progress {
    display: none;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__content {
  padding: 1rem 5rem;
  text-align: center;
  font-size: 1.8rem;
  line-height: 3rem;
  overflow-y: auto;
}
@media all and (max-width: 576px) {
  .tank-cleaning__tech-cleaning .tabs-block__content {
    padding: 1rem 3rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-cleaning .tabs-block__content {
    font-size: 1.6rem;
    line-height: 2.5rem;
    padding: 1.6rem 2rem 0;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__content::-webkit-scrollbar-track {
  padding-right: 0.5rem;
  -webkit-box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0.3);
  background-color: #eef3f8;
}
.tank-cleaning__tech-cleaning .tabs-block__content::-webkit-scrollbar {
  width: 0.3rem;
  background-color: #eef3f8;
}
.tank-cleaning__tech-cleaning .tabs-block__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0.3);
  background-color: #C3CDD7;
}
.tank-cleaning__tech-cleaning .tabs-block__content-item {
  display: none;
}
.tank-cleaning__tech-cleaning .tabs-block__content-item.item--current {
  display: block;
}
.tank-cleaning__tech-cleaning .tabs-block__content-title {
  display: inline-block;
  margin-top: 2.5rem;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4.7rem;
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-cleaning .tabs-block__content-title {
    margin-top: 0;
    font-size: 2.2rem;
    line-height: 3.7rem;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__content-desc {
  margin-top: 2.1rem;
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-cleaning .tabs-block__content-desc {
    margin-top: 1rem;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__content-adv {
  margin-top: 2.9rem;
  font-weight: 700;
  padding: 3rem 2.3rem 2.2rem;
  background: #F7F7F7;
  border: 1px solid #D6D6D6;
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-cleaning .tabs-block__content-adv {
    margin-top: 2rem;
    padding: 1.9rem 2rem 1.5rem;
  }
}
.tank-cleaning__tech-cleaning .tabs-block__video-wrapper {
  flex: 1;
}
.tank-cleaning__tech-cleaning .tabs-block__video {
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%;
  display: none;
  position: relative;
}
.tank-cleaning__tech-cleaning .tabs-block__video.video--current {
  display: block;
}
.tank-cleaning__tech-cleaning .tabs-block__video iframe, .tank-cleaning__tech-cleaning .tabs-block__video img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tank-cleaning__btn-wrapper {
  text-align: center;
  margin-top: 3rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__btn-wrapper {
    margin-top: 1.6rem;
  }
}

.tank-cleaning__order-button {
  white-space: unset;
  padding-left: 3.4rem;
  padding-right: 3.4rem;
  height: 6.4rem;
  text-align: center;
}
@media all and (max-width: 576px) {
  .tank-cleaning__order-button {
    padding: 1rem;
    width: 100%;
    line-height: 2.5rem;
    max-width: 30rem;
    height: unset;
    min-height: 5.4rem;
  }
}
.tank-cleaning__tech {
  margin-top: 6.3rem;
}
@media all and (max-width: 768px) {
  .tank-cleaning__tech {
    margin-top: 4rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech {
    margin-top: 3.1rem;
  }
}
.tank-cleaning__tech-inner {
  background: #F7F7F7;
  padding: 8.2rem 2.8rem 10.6rem;
}
@media all and (max-width: 640px) {
  .tank-cleaning__tech-inner {
    padding: 5rem 2.8rem 7rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-inner {
    padding: 3rem 2.8rem 5.6rem;
  }
}
.tank-cleaning__tech-list {
  max-width: 107rem;
  margin: 7.6rem auto 0;
  display: grid;
  grid-template: auto/repeat(2, 1fr);
  grid-column-gap: 5.2rem;
  grid-row-gap: 6.4rem;
}
@media all and (max-width: 900px) {
  .tank-cleaning__tech-list {
    grid-column-gap: 3rem;
    grid-row-gap: 5rem;
  }
}
@media all and (max-width: 640px) {
  .tank-cleaning__tech-list {
    margin-top: 4rem;
    grid-template: auto/1fr;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__tech-list {
    grid-row-gap: 3.8rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-list {
    margin-top: 2.1rem;
  }
}
.tank-cleaning__tech-list-item {
  position: relative;
  padding-left: 8rem;
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 3rem;
}
@media all and (max-width: 900px) {
  .tank-cleaning__tech-list-item {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-list-item {
    padding-left: unset;
    padding-top: 5rem;
    text-align: center;
  }
}
.tank-cleaning__tech-list-item::before {
  content: "";
  display: block;
  width: 5.4rem;
  height: 5.4rem;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: #00923F url("../img/check-tech.svg") center center/auto no-repeat;
}
@media all and (max-width: 375px) {
  .tank-cleaning__tech-list-item::before {
    width: 4.4rem;
    height: 4.4rem;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.tank-cleaning__video-block {
  padding: 8.5rem 0 2rem;
}
@media all and (max-width: 992px) {
  .tank-cleaning__video-block {
    padding: 6rem 0 0;
  }
}
@media all and (max-width: 576px) {
  .tank-cleaning__video-block {
    padding: 4rem 0 0;
  }
}
.tank-cleaning__video-block-inner {
  max-width: 100rem;
  margin: 0 auto;
}

.content__video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.content__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}