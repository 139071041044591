.about-text {
  padding: 10rem 0 4.3rem;
  background: #F7F7F7;

  @media all and (max-width: 992px) {
    padding: 7rem 0 2rem;
  }
  @media all and (max-width: 768px) {
    padding: 5rem 0 1rem;
  }

  @media all and (max-width: 576px) {
    padding: 3.6rem 0 0;
  }


  &__desc {
    margin: 3.8rem auto 0;
    max-width: 102rem;
    font-size: 2.2rem;
    line-height: 3.5rem;
    text-align: center;

    @media all and (max-width: 576px) {
      margin-top: 2rem;
      padding: 0 1rem;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }

}