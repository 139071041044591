@import "../colors";

.tank-cleaning {

  &__calc {
    padding: 8.2rem 0 6rem;
    background: #f7f7f7;
    position: relative;
    z-index: 2;

    @media all and (max-width: 992px) {
      padding: 5rem 0 4rem;
    }
    @media all and (max-width: 576px) {
      padding: 3rem 0 2rem;
    }


    .tank-cleaning__block-desc {
      @media all and (max-width: 375px) {
        margin-top: 1.7rem;
      }
    }
  }

  &__quiz {
    max-width: 103.5rem;
    margin: 4.8rem auto 0;
    background: white;
    box-shadow: 0 6rem 10rem rgba(32, 32, 32, 0.07);

    @media all and (max-width: 375px) {
      margin-top: 2.4rem;
    }

   .quiz__item {
     display: none;

     &.item--current {
       display: block;
     }

      &-header {
        padding: 3.1rem 2rem;
        background: $t-green;
        font-weight: 600;
        font-size: 2.2rem;
        line-height: 3.7rem;
        color: white;
        text-align: center;

        @media all and (max-width: 375px) {
          font-size: 2rem;
          line-height: 2.5rem;
          padding: 1.61rem 3.2rem 1.9rem;
        }
      }

     &-question {
       margin-right: .5rem;
     }

     &-counter {
       white-space: nowrap;
     }

     &-inputs {
       max-width: 54rem;
       padding: 0 2rem;
       margin: 4.6rem auto 0;

       @media all and (max-width: 375px) {
         margin-top: 2.1rem;
       }

       &.inputs--error {
         .quiz__input {
           background: rgba(199, 31, 31, 0.03);
           border: .2rem solid $t-red;

           &::placeholder {
             color: $t-red;
           }
         }
         .quiz__error {
           display: inline-block;
         }
       }
     }
    }
  }

}

.quiz__input {
  width: 100%;
  padding: 1.7rem 2.4rem;
  font-size: 1.8rem;
  line-height: 3rem;
  background: #F9FBFD;
  border: .1rem solid #CDDAE5;
  border-radius: .3rem;

  @media all and (max-width: 576px) {
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding: 1.4rem 2rem 1.2rem;
  }

  &::placeholder {
    color: #697E8D;
  }

  &:focus {
    outline: none;
  }
}

.quiz__radio {
  display: none;

  &:checked + .quiz__label {
    background: #E7E7E7;
    &::before {
      background: #808080;
    }
  }

}

.quiz__input-wrapper {
  margin-bottom: 1.1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.quiz__label {
  display: block;
  position: relative;
  width: 100%;
  padding: 1.8rem 2rem 1.6rem 5.3rem;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3rem;
  background: #F1F1F1;
  border-radius: .3rem;
  transition: .15s;
  cursor: pointer;

  @media all and (max-width: 576px) {
    font-size: 1.6rem;
    line-height: 2.8rem;
    padding: 1.4rem 1.6rem 1.2rem 4.4rem;
  }

  &:hover {
    background: #EAEAEA;
  }

  &::before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 2.2rem;
    left: 2rem;
    border-radius: 50%;
    border: .2rem solid #808080;
    box-sizing: border-box;
    transition: .15s;
    background: white;

    @media all and (max-width: 576px) {
      top: 1.8rem;
      left: 1.2rem;
    }
  }
}

.quiz__error {
  display: none;
  font-size: 1.3rem;
  line-height: 1.9rem;
  color: $t-red;
}

.quiz__nav {
  max-width: 54rem;
  margin: 0 auto;
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-between;
  background: white;

  @media all and (max-width: 576px) {
    padding: 2.1rem 2rem 2rem;
  }

  &-button {
    height: 6.4rem;
    width: 18rem;
    border: .2rem solid $t-green;
    transition: opacity 0s;

    @media all and (max-width: 375px) {
      height: 5.4rem;
      max-width: 12.5rem;
    }

    &.button--hidden {
      cursor: auto;
      opacity: 0;
    }
  }
}