@import "../colors";


.tank-cleaning__process {
  padding: 7rem 0;
  background: #F7F7F7;

  @media all and (max-width: 992px) {
    padding: 7rem 0 3rem;
  }

  &-grid {
    margin-top: 6.3rem;
    display: grid;
    grid-template: auto / repeat(auto-fit, minmax(46rem, 1fr));
    column-gap: 3rem;
    row-gap: 2.6rem;

    @media all and (max-width: 768px) {
      margin-top: 4rem;
    }

    @media all and (max-width: 576px) {
      margin-top: 2.8rem;
      row-gap: 2.1rem;
      grid-template: auto / 1fr;
    }
  }

  &-item {
    padding: 4.1rem 8.7rem 5.2rem 4.4rem;
    display: flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0 3rem 3rem -2rem rgba(31, 31, 31, 0.09);

    @media all and (max-width: 1160px) {
      padding: 3.7rem 5rem 3.8rem;
    }

    @media all and (max-width: 576px) {
      padding: 3.7rem 3rem 3.8rem;
    }

    @media all and (max-width: 375px) {
      padding: 3.7rem 2rem 3.8rem;
    }

    &.process-item--order {
      padding: 3.5rem 5.3rem 2rem 5.3rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @media all and (max-width: 576px) {
        padding: 2.9rem 3rem 3.5rem;
      }

      .tank-cleaning__process-title {
        width: 100%;
        text-align: center;
      }

      .tank-cleaning__order-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2.4rem;
      }

      .form__submit-button {
        max-width: 28.8rem;
      }
    }
  }

  &-img-wrapper {
    flex: 1;
    max-width: 14.8rem;
    margin-right: 4.5rem;
  }

  &-img {
    width: 100%;
    vertical-align: top;
  }

  &-info {
    flex: 1;

  }

  &-title {
    display: block;
    font-weight: 800;
    font-size: 2.8rem;
    line-height: 3.5rem;
    
    @media all and (max-width: 576px) {
      font-size: 2.2rem;
      line-height: 3rem;
      text-align: center;
    }
  }

  &-desc {
    margin-top: 2rem;
    font-size: 1.8rem;
    line-height: 3rem;
    color: #333333;

    @media all and (max-width: 576px) {
      margin-top: 1.1rem;
      font-size: 1.6rem;
      line-height: 2.5rem;
      text-align: center;
    }
  }

  &-buttons {
    margin-top: 2rem;

    @media all and (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-button, &-link {
    margin-right: 1.5rem;
    height: 4.8rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    border-radius: .5rem;
    border: 1px solid $t-green;

    @media all and (max-width: 480px) {
      font-size: 1.6rem;
      margin-right: 0;
      margin-bottom: .7rem;
      width: 18.9rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $t-green;
    }
  }
}