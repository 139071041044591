@import "colors";
@import "fonts";

.wrapper--services-page {
  .services-block {
    margin: 4.7rem 0 4.3rem;
    @media all and (max-width: 576px) {
      margin: 2.5rem 0 0;
    }
  }
}

.services-block {
  margin: 4rem 0 8.5rem;

  @media all and (max-width: 576px) {
    margin: 2.5rem 0 2.1rem;
  }
}


.services-block__services-grid {
  margin-top: 3.7rem;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 4rem;

  @media all and (max-width: 768px) {
    grid-gap: 3rem;
  }
  @media all and (max-width: 640px) {
    grid-template: auto / 1fr;
  }
  @media all and (max-width: 576px) {
    margin-top: 2.5rem;
  }
}


.services-grid__item {
  background: white;
  box-shadow: 0 8rem 10rem rgba(0, 0, 0, 0.06);

  @media all and (max-width: 576px) {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }

  .item__service-img {
    width: 100%;
    height: 23.6rem;
    object-fit: cover;
    @media all and (max-width: 576px) {
      height: 18rem;
    }
  }

  .item__service-info {
    display: flex;
    flex-direction: column;
    padding: 1.8rem 3.7rem 2.7rem;

    @media all and (max-width: 1200px) {
      flex-direction: column;
    }

    @media all and (max-width: 768px) {
      padding: 1.5rem 2.1rem 2.3rem;
    }

    .service-info__text {

      .text__service-title {
        font-family: 'Oswald', 'Sans Serif';
        font-weight: 500;
        font-size: 3.25rem;
        line-height: 4rem;

        @media all and (max-width:576px) {
          font-size: 2.4rem;
          line-height: 3rem;
          max-width: 24rem;
        }
      }

      .text__service-link {
        color: $title-black;
        transition: .2s;

        &:hover {
          color: $t-green;
        }
      }
      .text-service__desc {
        margin-top: 2.9rem;
        @media all and (max-width:576px) {
          margin-top: 2.3rem;
        }
      }
    }
  }

  .service-info__add-links {
    margin-top: .5rem;

    @media all and (max-width: 576px) {
      display: none;
    }
    .add-links__link {
      display: block;
      margin-top: 1.5rem;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.content-section__service-article {

  p {
    margin-bottom: 2.7rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    margin: 3.5rem 0 2.6rem;
    font-family: 'Oswald', 'Sans Serif';
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 6.6rem;

    @media all and (max-width: 992px) {
      margin: 3rem 0 2.6rem;
      font-size: 3.2rem;
      line-height: 4rem;
    }

    @media all and (max-width: 576px) {
      font-size: 2.8rem;
      line-height: 3.5rem;
    }
  }

  ul {
    margin: 2.5rem 0;

    li {
      list-style: none;
      position: relative;
      padding-left: 1.9rem;

      &::before {
        content: '•';
        display: inline-block;
        position: absolute;
        top: .2rem;
        left: 0;
        font-size: 1.2rem;
        line-height: 3rem;
        margin-right: 1rem;
      }
    }
    li + li {
      margin-top: 1.5rem;
    }
  }

  .services__video {
    position: relative;
    margin-top: 4rem;

    @media all and (max-width: 768px) {
      margin-top: 2.7rem;
    }
    @media all and (max-width: 375px) {
      max-width: unset;
      width: calc(100% + 2rem);
      margin-left: -1rem;
    }

    .video__img {
      vertical-align: top;
      width: 100%;
    }

    .video__play-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.6rem;
      height: 9.6rem;
      background: linear-gradient(253.53deg, #007DC3 27.33%, #005FA4 140.49%), #017DC3;
      position: absolute;
      top: calc(50% - 4.8rem);
      left: calc(50% - 4.8rem);
      border: none;
      border-radius: 50%;
      font-size: 0;
      cursor: pointer;

      @media all and (max-width: 992px) {
        width: 7rem;
        height: 7rem;
        top: calc(50% - 3.5rem);
        left: calc(50% - 3.5rem);
      }

      @media all and (max-width: 576px) {
        width: 5.5rem;
        height: 5.5rem;
        top: calc(50% - 2.75rem);
        left: calc(50% - 2.75rem);
      }

      &:hover {
        background: $t-link;
      }

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 1.25rem solid transparent;
        border-left: 1.7rem solid white;
        margin-left: 1.5rem;

        @media all and (max-width: 992px) {
          border: 1rem solid transparent;
          border-left: 1.2rem solid white;
          margin-left: 1.2rem;
        }

        @media all and (max-width: 576px) {
          border: .7rem solid transparent;
          border-left: 1rem solid white;
          margin-left: 0.85rem;
        }
      }
    }

    .video__player {
      width: 100%;

      &[poster]{
        height:100%;
        width:100%;
        object-fit: cover;
      }

      &.player--no-controls {
        &::-webkit-media-controls {
          display: none;
        }
      }
    }
  }

  .services__process-steps {
    margin-top: 4.8rem;

    @media all and (max-width: 768px) {
      margin-top: 3.2rem;
    }

    .list__step {
      margin-bottom: 3.6rem;
      display: flex;
      align-items: center;

      @media all and (max-width: 992px) {
        flex-wrap: wrap;
        align-items: flex-start;
      }
      @media all and (max-width: 768px) {
        margin-bottom: 3.2rem;
      }
      @media all and (max-width: 576px) {
       flex-direction: column;
        max-width: 40rem;
        margin: 0 auto 3.2rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .step__img {
        max-width: 39.6rem;
        margin-right: 4.8rem;

        @media all and (max-width: 1200px) {
          max-width: 45%;
          margin-right: 2rem;
        }
        @media all and (max-width: 992px) {
          margin-top: 2.1rem;
          order: 2;
        }
        @media all and (max-width: 576px) {
          max-width: 100%;
        }
        @media all and (max-width: 375px) {
          max-width: unset;
          width: calc(100% + 2rem);
          margin-left: -1rem;
        }
      }

      .step__info {
        max-width: 37.4rem;

        @media all and (max-width: 992px) {
          max-width: 50%;
          order: 3;
        }
        @media all and (max-width: 576px) {
          max-width: 100%;
        }

        .step__title {
          display: inline;

          @media all and (max-width: 992px) {
            display: none;
          }
        }
      }

      .step__title {
        display: none;
        font-family: 'Oswald', 'Sans Serif';
        font-weight: 500;
        font-size: 2.8rem;
        line-height: 4.7rem;

        @media all and (max-width: 992px) {
          order: 1;
          width: 100%;
          display: inline-block;
          font-size: 2.2rem;
          line-height: 3rem;
        }

      }

      .step__desc {
        margin-top: 1.6rem;
      }

    }

  }

  .services__advantages-list {
    margin-top: 3.4rem;

    @media all and (max-width: 576px) {
      margin-top: 2rem;
    }

    .advantages-list__item {
      position: relative;
      margin-bottom: 2.5rem;
      padding-left: 3.5rem;
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 3.5rem;

      @media all and (max-width: 992px) {
        font-size: 1.9rem;
        line-height: 3rem;
      }
      @media all and (max-width: 576px) {
        padding-left: 3.3rem;
      }

      &::before {
        content: '';
        display: block;
        width: 2rem;
        position: absolute;
        left: 0;
        top: 1.5rem;
        border-bottom: .2rem solid $text-black;

        @media all and (max-width: 576px) {
          width: 1.7rem;
        }
      }
    }
  }

}

.main-grid__services-types {
  margin-top: 6rem;

  @media all and (max-width: 992px) {
    margin-top: 4rem;
  }

  @media all and (max-width: 768px) {
    margin-top: 0;
  }

  h3 {
    font-family: 'Oswald', 'Sans Serif';
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 6.6rem;

    @media all and (max-width: 992px) {
      font-size: 3.2rem;
      line-height: 4rem;
    }

    @media all and (max-width: 576px) {
      font-size: 2.8rem;
      line-height: 3.5rem;
    }
  }
}

.services-types__services-grid {
  margin-top: 3.4rem;
  display: grid;
  grid-template: auto / repeat(4, 1fr);
  grid-column-gap: 3.1rem;
  grid-row-gap: 2.2rem;

  @media all and (max-width: 992px) {
    grid-template: auto / repeat(3, 1fr);
    grid-column-gap: 2.2rem;
  }
  @media all and (max-width: 768px) {
    grid-template: auto / repeat(2, 1fr);
  }
  @media all and (max-width: 576px) {
    grid-row-gap: 1rem;
    grid-template: auto / repeat(1, 1fr);
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }

  .services-grid__service {

    .service__link {
      display: block;
      min-height: 18.8rem;
      height: 100%;
      padding: 2.8rem 3rem 2.8rem;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 3rem;
      color: $text-black;
      background: $t-bg-gray;
      border: 1px solid #eeeded;
      transition: .1s;

      @media all and (max-width: 1200px) {
        padding: 2rem;
      }
      @media all and (max-width: 768px) {
         min-height: 14.8rem;
      }
      @media all and (max-width: 768px) {
         min-height: unset;
      }

      &:hover {
        color: $t-green;
        border: 1px solid #74c898;

        .underline {
          transition: .1s;
          border-bottom: 1px solid $t-green;
        }
      }
    }
  }
}
