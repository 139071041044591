@import "colors";

.content-block {
  padding: 4.5rem 0 5.4rem;

  @media all and (max-width: 576px) {
    margin-top: .5rem;
    padding: 2.2rem 0 4rem;
  }

  &.block--single-page {
    padding: 1rem 0 6.4rem;

    @media all and (max-width: 576px) {
        padding: 0 0 5.4rem;
    }
  }

  &.block--tank-cleaning,
  &.block--tank-diagnostic,
  &.block--tank-repair,
  &.block--tank-anticor,
  &.block--tank-building,
  &.block--energetics {
    padding: 2.2rem 0 0;

    @media all and (max-width: 576px) {
      padding:  1.5rem 0 0;
    }
  }

  &.block--tank-diagnostic,
  &.block--tank-repair,
  &.block--tank-building,
  &.block--tank-anticor {
    .tank-cleaning__reviews {
      @media all and (min-width: 768px) {
        padding-bottom: 9rem;
      }
    }
    .tank-cleaning__about {
      @media all and (min-width: 768px) {
        padding-bottom: 3rem;
      }
    }
    .tank-cleaning__order-block {
      padding-top: 4rem;

      @media all and (min-width: 768px) {
        padding-top: 7rem;
      }
    }
  }

  &.block--tank-cleaning {
    .tank-cleaning__header {
      min-height: 82.3rem;
    }
  }

  &.block--tank-diagnostic {
    .tank-cleaning__header {
      min-height: 67.4rem;
    }
  }

  &.block--tank-repair {
    .tank-cleaning__header {
      min-height: 61.4rem;
    }
  }

  &.block--tank-anticor {
    .tank-cleaning__header {
      min-height: 68.4rem;
    }
  }

  &.block--tank-building {
    .tank-cleaning__header {
      min-height: 85.9rem;
    }
    .tank-cleaning__header-list {
      max-width: unset;
    }
    .tank-cleaning__expirience {
      padding-bottom: 10rem;

      @media all and (max-width: 992px) {
        padding-bottom: 7rem;
      }
      @media all and (max-width: 576px) {
        padding-bottom: 4rem;
      }
    }
  }

  &.block--tank-anticor {
    .tank-cleaning__order-title {
      @media all and (max-width: 375px) {
        max-width: 21rem;
      }
    }
    .tank-cleaning__stats {
      padding-bottom: 5rem;
      @media all and (max-width: 576px) {
        padding: 5rem 0 3rem;
      }
    }
  }

  &.block--energetics {
    .tank-cleaning__header {
      min-height: 68.4rem;
    }
  }

  &.block--projects {
    @media all and (max-width: 576px) {
      padding: 0 0 3.1rem;
    }
  }

  &.block--comment {
    @media all and (max-width: 768px) {
      padding: 0 0 1.2rem;
    }
  }

  &.block--about {
    padding: 3.1rem 0 8.1rem;

    @media all and (max-width: 768px) {
      padding: 2rem 0 5rem;
    }
    @media all and (max-width: 576px) {
      padding: 1.4rem 0 3.1rem;
    }
    .content-block__header {
      max-width: 144rem;
      padding-top: 6.1rem;
      margin: 0 auto;
      height: 43.1rem;
      background: url('../img/about.jpg') center center/cover no-repeat;

      @media all and (max-width: 992px) {
        height: 34rem;
      }
      @media all and (max-width: 768px) {
        height: 28rem;
      }
      @media all and (max-width: 576px) {
        padding-top: 2.1rem;
        height: 20rem;
      }

      @media all and (max-width: 375px) {
        height: 15.7rem;
      }
    }

    .content-block__title {
      margin-top: .9rem;
      color: white;
    }
  }

  .content-block__header-img {
    display: block;
    width: 144rem;
    margin: 4rem auto 0;

    @media all and (max-width: 1440px) {
      width: 100%;
    }

    @media all and (max-width: 576px) {
      margin-top: 2.9rem;
    }

    .header-img__img {
      vertical-align: top;
      min-height: 18rem;
      max-height: 39rem;
      width: 100%;
      object-fit: cover;

      @media all and (max-width: 992px) {
        max-height: 30rem;
      }
      @media all and (max-width: 768px) {
        max-height: 24rem;
      }
      @media all and (max-width: 576px) {
        max-height: 20rem;
      }
    }
  }

  .content-block__main-grid {
    display: grid;
    grid-template: auto / 1fr 28.8rem;
    grid-column-gap: 8.5rem;

    @media all and (max-width: 992px) {
      grid-template: auto / 1fr 24rem;
      grid-column-gap: 4rem;
    }

    @media all and (max-width:768px) {
     grid-row-gap: 4rem;
     grid-template: auto / 1fr;
    }

    @media all and (max-width: 576px) {
     grid-row-gap: 3rem;
    }

    &.main-grid--news-list {
      margin-top: 8.8rem;

      @media all and (max-width: 992px) {
        margin-top: 4.5rem;
      }
      @media all and (max-width: 576px) {
        margin-top: 2.9rem;
      }

      &.main-grid--sidebar-right {
        grid-template: auto / 1fr 39.7rem;
        grid-column-gap: 8.3rem;

        @media all and (max-width: 1200px) {
          grid-template: auto / 1fr 34rem;
          grid-column-gap: 3.3rem;
        }

        @media all and (max-width: 992px) {
          grid-template: auto / 1fr 30rem;
          grid-column-gap: 2.5rem;
        }
        @media all and (max-width: 768px) {
          grid-template: auto / 1fr;
          grid-column-gap: 2.5rem;
        }

        .main-grid__sidebar {

          @media all and (max-width: 768px) {
            grid-column-start: 1;
            grid-row-start: 1;
          }
          @media all and (max-width: 375px) {
            width: calc(100% + 2rem);
            margin-left: -1rem;
          }
        }

        .main-grid__content {
          @media all and (max-width: 768px) {
            grid-column-start: 1;
            grid-row-start: 2;
          }

          .pagination-block {
            margin: 9rem 0 2.2rem;

            @media all and (max-width: 992px) {
              margin: 6rem 0 0;
            }
            @media all and (max-width: 480px) {
              margin: 3.9rem 0 0;
            }
          }
        }
      }
    }

    &.main-grid--news, &.main-grid--projects, &.main-grid--reviews-single  {
      margin-top: 3.7rem;

      @media all and (max-width: 576px) {
        margin-top: 3.2rem;
      }
    }
    &.main-grid--news {
			grid-template: auto / calc(100% - 37.3rem) 28.5rem;

			@media all and (max-width: 768px) {
				grid-template: auto / 100%;
			}
      @media all and (max-width: 480px) {
        margin-top: 2rem;
      }
    }

    &.main-grid--about {
      margin-top: 4.5rem;
      grid-row-gap: 5rem;

      @media all and (max-width: 576px) {
        margin-top: 3.1rem;
      }

      &.main-grid--sidebar-left {

        @media all and (max-width: 992px) {
          grid-template: auto / 26rem 1fr;
        }

        @media all and (max-width: 768px) {
          grid-template: auto / 1fr;
          grid-row-gap: 1.5rem;
        }

        .main-grid__content {
          @media all and (max-width: 768px) {
            order: 2;
          }
        }

        .main-grid__sidebar {
          margin-top: 2rem;

          @media all and (max-width: 768px) {
            margin: 0 auto 0 0;
            order: 1;
          }
        }

        .main-grid__business-direction {
          grid-column-start: span 2;
          order: 3;

          @media all and (max-width: 768px) {
            grid-column-start: span 1;
          }
        }
      }
    }

    &.main-grid--quality {
      margin-top: 8.8rem;

      @media all and (max-width: 768px) {
        margin-top: 5rem;
      }
      @media all and (max-width: 576px) {
        margin-top: 3.7rem;
        grid-row-gap: 1.4rem;
      }

      .main-grid__sidebar {

        @media all and (max-width: 768px) {
          display: none;
        }
      }
    }

    &.main-grid--vendors, &.main-grid--career, &.main-grid--resume, &.main-grid--comment {
      margin-top: 4.5rem;
    }

    &.main-grid--comment {
      @media all and (max-width: 576px) {
        margin-top: 3.2rem;
      }
    }

    &.main-grid--vendors {
      @media all and (max-width: 576px) {
        margin-top: 2.8rem;
      }
    }

    &.main-grid--resume {
      grid-template: auto / 87.7rem 1fr;

      @media all and (max-width: 992px) {
        grid-template: auto / 1fr;
      }
    }

    &.main-grid--search {
      margin-top: 5.2rem;
      grid-row-gap: 0;

      @media all and (max-width: 1024px) {
        grid-template: auto / 1fr;
      }

      @media all and (max-width: 576px) {
        margin-top: 2.4rem;
      }

    }

    &.main-grid--projects, &.main-grid--reviews-single {
      .main-grid__sidebar {
        @media all and (max-width: 768px) {
          display: none;
        }

        &.sidebar--projects, &.sidebar--reviews-single{
          padding-top: 6rem;
        }
      }
    }

    &.main-grid--services {
      margin-top: 5.2rem;

      @media all and (max-width: 768px) {
        margin-top: 2.3rem;
      }

      &.main-grid--sidebar-left {
        @media all and (max-width: 992px) {
          grid-template: auto / 26rem 1fr;
          grid-column-gap: 1rem;
        }
        @media all and (max-width: 768px) {
          grid-template: auto / 1fr;
        }
      }

    }

    &.main-grid--gallery {
      margin-top: 4.1rem;
      color: white;

      @media all and (max-width: 576px) {
        margin-top: 2rem;
      }

      &.main-grid--sidebar-left {
        grid-template: auto / 15.5rem 1fr;
        grid-column-gap: 4.5rem;

        @media all and (max-width: 768px) {
          grid-template: auto / 1fr;
        }

        .main-grid__content {
          max-width: 100%;
        }

        .main-grid__sidebar {

          @media all and (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    &.main-grid--reviews {
      margin-top: 3.8rem;
      grid-template: auto / 1fr 29rem;
      grid-column-gap: 3.1rem;

      @media all and (max-width: 768px) {
        margin-top: 5rem;
        grid-template: auto / 1fr;
      }

      @media all and (max-width: 576px) {
        margin-top: 3.7rem;
      }

      .main-grid__sidebar {
        margin-top: 2.7rem;
        @media all and (max-width: 768px) {
          display: none;
        }

        .sidebar__widget.widget--links {
          margin-top: 2rem;
        }
      }
    }

    &.main-grid--documentation {
      margin-top: 7.1rem;
      grid-template: auto / 1fr 29rem;
      grid-column-gap: 3.1rem;

      @media all and (max-width: 992px) {
        grid-template: auto / 1fr 25rem;
        grid-column-gap: 1.8rem;
      }

      @media all and (max-width: 768px) {
        margin-top: 5rem;
        grid-template: auto / 1fr;
      }

      @media all and (max-width: 480px) {
        margin-top: 3.3rem;
      }

      .main-grid__sidebar {

        @media all and (max-width: 768px) {
          display: none;
        }

        .sidebar__widget.widget--links {
          margin-top: 2rem;
        }
      }
    }

    &.main-grid--sidebar-left {
      grid-template: auto / 34rem 1fr;
      grid-column-gap: 3.2rem;

      .main-grid__content {
        max-width: 82.3rem;
        order: 2;

        @media all and (max-width: 768px) {
          order: 1;
        }
      }

      .main-grid__sidebar {
        order: 1;

        @media all and (max-width: 768px) {
          order: 2;
        }
      }
    }

    .main-grid__content {
      font-size: 1.8rem;
      line-height: 3rem;

      .content-block__article {
        h3 {
          margin-bottom: 2.6rem;
          font-family: 'Oswald', 'Sans Serif';
          font-weight: 500;
          font-size: 3.8rem;
          line-height: 6.6rem;

          @media all and (max-width: 768px) {
           font-size: 3.2rem;
            line-height: 4rem;
          }

          @media all and (max-width: 576px) {
           font-size: 2.8rem;
            line-height: 3.5rem;
          }
        }
        h4 {
          margin-bottom: 2.6rem;
          font-weight: 800;
          font-size: 2.3rem;
          line-height: 3rem;

          @media all and (max-width: 768px) {
            font-size: 2.1rem;
            line-height: 2.6rem;
          }

          @media all and (max-width: 576px) {
            margin-bottom: 2rem;
            font-size: 1.9rem;
            line-height: 2.5rem;
          }
        }
        ul {
          margin: 2.5rem 0;

          li {
            list-style: none;
            position: relative;
            padding-left: 1.9rem;

            &::before {
              content: '•';
              display: inline-block;
              position: absolute;
              top: .2rem;
              left: 0;
              font-size: 1.2rem;
              line-height: 3rem;
              margin-right: 1rem;
            }

            ul {
              margin: 1.8rem 0;
              li {
                &:before {
                  display: none;
                }
              }
            }
          }
          li + li {
            margin-top: 1.5rem;
          }
        }
        p {
          margin-bottom: 2.7rem;
          @media all and (max-width: 576px) {
            font-size: 1.6rem;
            line-height: 2.5rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .content__video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .main-grid__sidebar {

      @media all and (max-width: 768px) {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
      }
    }

    .main-grid__services-types {
      grid-column-start: span 2;
      order: 3;

      @media all and (max-width: 768px) {
        order: 1;
        grid-column-start: unset;
      }
    }
  }
}

.main-grid__social-share {
  margin-top: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media all and (max-width: 768px) {
    margin-top: 1.5rem;
  }
  @media all and (max-width: 576px) {
    margin-top: 2.3rem;
  }

  .social-share__desc {
    margin-right: 1.9rem;
    font-weight: 700;

    @media all and (max-width: 576px) {
      margin-right: .9rem;
    }
  }

  .social-share__social-icon {
    display: flex;

    .social-icon__icon {
      position: relative;
      margin-right: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.4rem;
      height: 4.4rem;
      background: rgba(65, 75, 87, .7);
      border-radius: 50%;

      @media all and (max-width: 576px) {
        height: 3.5rem;
        width: 3.5rem;
        margin-right: .8rem;
      }
      &.icon--z {
      }
      &.icon--rt {
        background: #FFFFFF;
      }
      &.icon--tg {
        background: #FFFFFF;
      }
      &.icon--yt {
        background: #FFFFFF;
      }
      &.icon--vk {
        background: #FFFFFF;
      }
      &.icon--fb {
        background: #3B5998;
      }
      &.icon--tw {
        background: #1DA1F2;
      }
      &.icon--ok{
        background: #EE8208;
        .icon__img {
          max-width: 1.8rem;
        }
      }
    }

    .icon__img {
      max-width: 1.6rem;
      max-height: 2rem;

      @media all and (max-width: 576px) {
        max-width: 1.4rem;
        max-height: 1.8rem;
      }
    }

    .social-icon__link {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      font-size: 0;
      opacity: 0;
      border-radius: 50%;
    }
  }
}

.content__widget, .sidebar__widget {

  &.widget--document {
    margin-top: 2.6rem;
    display: flex;
    align-items: flex-start;
    font-weight: 500;

    @media all and (max-width: 576px) {
      align-items: flex-start;
      margin-top: 1.5rem;
    }

    .widget__icon-document {
      margin-right: 1.9rem;
      max-width: 4rem;
      min-width: 3.5rem;

      .icon-document__icon {
        vertical-align: top;
        max-width: 100%;
      }
    }

    .widget__info-document {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .info-document__title {
      font-weight: 500;
      display: inline-block;
      font-size: 1.8rem;
      line-height: 2rem;

      @media all and (max-width: 576px) {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.5rem;
      }


    }
    .info-document__link {
      &:hover {
        color: $t-green;
        .underline {
          border-bottom: 1px solid $t-green;
        }
      }
    }
    .info-document__size {
      margin-top: .9rem;
      display: inline-block;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #ABABAB;

      @media all and (max-width: 576px) {
        margin-top: .5rem;
        font-size: 1.4rem;
        line-height: 2.1rem;
        border-bottom: none;
      }
    }
  }
}
