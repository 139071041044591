@import "colors";

.sorting-block {
  position: relative;
  margin-top: 4.6rem;

  &.sorting--projects, &.sorting--gallery {
    padding-top: 9.8rem;
  }


  @media all and (max-width: 576px) {
    padding-top: 7.3rem;
    margin-top: 2.9rem;
  }

  &--main-page {
    @media all and (max-width: 1200px) {
      padding-top: 0;
    }
    @media all and (max-width: 576px) {
      margin-top: 2.6rem;
    }


    .sorting-block__drop-tag-panel {
      @media all and (max-width: 1200px) {
        display: none;
      }
    }
  }

}

//=======================TAG PANEL =============================

.sorting-block__drop-tag-panel {
  display: flex;
  width: 100%;
  position: absolute;
  top: .2rem;
  left: 0;
  border-bottom: 1px solid #E3E9EC;
  z-index: 5;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media all and (max-width: 1200px) {
    display: block;
    width: 31rem;
    border-bottom: none;
    background: white;
    box-shadow: 0 .3rem .6rem rgba(11, 37, 51, 0.08), 0 0 .1rem rgba(0, 0, 0, 0.25);
    border-radius: .3rem;

    &::after {
      content: '';
      display: block;
      width: 1.5rem;
      height: .8rem;
      position: absolute;
      top: 2.2rem;
      right: 2rem;
      background: url('../img/drop-down-panel.svg')center center no-repeat;
      background-size: auto;
      transition: .15s;
      z-index: -1;
    }
  }
  @media all and (max-width: 375px) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    background: rgba(255, 255, 255, 0.0);
  }


  &.drop-tag-panel--active {
    &::after {
      transform: rotate(180deg);
    }
  }

 .drop-tag-panel__tag {
    display: inline-block;
    margin-left: 2.5rem;
    position: relative;
    background: transparent;
    padding: 1.3rem 0 1.7rem;
    border: none;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 0.03em;
   white-space: nowrap;
    color: $text-black;
    cursor: pointer;
    transition: .2s;

    @media all and (max-width: 1366px) {
      margin-left: 1.5rem;
    }

    @media all and (max-width: 1200px) {
      display: none;
      width: 100%;
      text-align: left;
      padding: 1.6rem 2rem 1.3rem;
      margin: 0;
    }

    &:disabled {
      color: rgba(0, 0, 0, .4);
      &:hover {
        color: rgba(0, 0, 0, .4);
      }
    }

    &:hover {
      color: $t-green;
    }

   &.tag--visible {
     @media all and (max-width: 1200px) {
       display: block;
     }
   }

    &.tag--active {
      color: $t-green;

      @media all and  (max-width: 1200px) {
        display: block;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: .4rem;
        background: $t-green;
        position: absolute;
        bottom: 0;

        @media all and (max-width: 1200px) {
          display: none;
        }
      }
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.drop-tag-panel__button-wrapper {
  width: 9rem;
  height: 4.4rem;
  position: absolute;
  top: 0;
  z-index: 10;

  @media all and (max-width: 1200px) {
    display: none;
  }

  &.wrapper--back {
    background: linear-gradient(90deg, #262626 57%, rgba(38, 38, 38, 0) 100%);
    left: 0;
  }
  &.wrapper--forw {
    background: linear-gradient(270deg, #262626 57%, rgba(38, 38, 38, 0) 100%);
    right: 0;
  }
}

.drop-tag-panel__button-scroll {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 4.4rem;
  height: 4.4rem;
  border: none;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
  background: #444444 url('../img/sort-scroll-arrow.svg') center center/auto no-repeat;
  z-index: 2;

  &.button-scroll--back {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
  }
  &.button-scroll--forw {
    right: 0;
  }
}

//=======================/TAG PANEL =============================

.sorting-block__sort-grid {
  display: grid;
  grid-template: auto / repeat(4, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 6rem;

  @media all and (max-width: 992px) {
    grid-template: auto / repeat(3, 1fr);
  }
  @media all and (max-width: 766px) {
    grid-template: auto / repeat(2, 1fr);
    grid-row-gap: 4rem;
  }
  @media all and (max-width: 576px) {
    grid-template: auto / 1fr;
  }
}

.sort-grid__item {
  position: relative;
  overflow: hidden;

  @media all and (max-width: 375px) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }

  .item__info {
    @media all and (max-width: 375px) {
      padding: 0 1rem;
    }
  }

  .item__photo {
    vertical-align: top;
    width: 100%;
    height: 16.4rem;
    object-fit: cover;
  }

  .info__tag, .item__tag {
    display: inline-block;
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: white;
    background: $t-green;
  }

  .info__date {
    display: inline-block;
    margin-top: 1.7rem;
    font-size: 1.3rem;
    line-height: 1.9rem;
    color: $title-black;
    opacity: .5;
  }

  .info__title-link {
    transition: .2s;
    &:hover {
      color: $t-green;

      .underline {
        border-bottom: 1px solid $t-green;
      }
    }
  }

  .info__title {
    margin-top: .5rem;
    color: $title-black;
    font-weight: 700;
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
  .info__desc {
    margin-top: 1.4rem;
    font-size: 1.4rem;
    line-height: 2.3rem;
    color: #333333;
  }


  &.item--double {
    @media all and (min-width: 767px) {
      grid-column: span 2;
    }

    .underline {
      @media all and (max-width: 768px) {
        border-bottom: .1rem solid $text-black;
      }
    }


    .item__photo {
      @media all and (min-width: 767px) {
        height: 100%;
        max-height: 39.5rem;
      }
    }

    .item__info {

      @media all and (min-width: 767px) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1.6rem 2.3rem;
        background: rgba(17, 17, 17, .87);
      }
    }

    .info__tag, .item__tag {
      @media all and (min-width: 767px) {
        position: relative;
      }
    }
    .info__date {
      @media all and (min-width: 767px) {
        margin: 0 0 0 2rem;
        color: white;
        opacity: 1;
      }
    }
    .info__title {
      @media all and (min-width: 767px) {
        font-family: 'Oswald', 'Sans Serif';
        font-weight: 500;
        font-size: 3.1rem;
        line-height: 4rem;
        color: white;
        .info__title-link {
          &:hover {
            color: #00a346;

            .underline {
              border-bottom: 1px solid #00a346;
            }
          }
        }
      }

    }
    .info__desc {
      @media all and (min-width: 767px) {
        display: none;
      }
    }

  }
}

.sorting-block {
  &.sorting--projects {

    .sorting-block__sort-grid {
      grid-row-gap: 3rem;

      @media all and (max-width: 576px) {
        grid-row-gap: 1.6rem;
      }
    }

    .sort-grid__item {
      max-height: 40.5rem;
      background: white;

      @media all and (max-width: 1200px) {
        max-height: unset;
      }

      &.item--double {

        .item__tag {
          position: absolute;
        }

        .item__photo {
          height: 100%;
          max-height: 39.5rem;

          @media all and (max-width: 767px) {
            height: 18.5rem;
          }
        }

        .item__info {
          max-width: 29rem;
          height: 22.1rem;
          background: white;
          left: unset;
          right: 0;

          @media all and (max-width: 767px) {
            max-width: 100%;
            height: unset;
          }

          &.info--left {
            left: 0;
            right: unset;
          }

          .info__title {
            font-family: 'Lato', 'Sans Serif';
            color: $text-black;

            .info__title-link {
              &:hover {
                color: $t-green;

                .underline {
                  border-bottom: 1px solid $t-green;
                }
              }
            }
          }

          .info__date {
            margin: 2.6rem 0 0 0;
            color: rgba(0, 0, 0, .5);
          }
        }

      }

      .item__photo {
        height: 18.5rem;
      }

      .item__info {
        min-height: 22.1rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        padding: 2.6rem 3.5rem 3.2rem;

        @media all and (max-width: 992px) {
          padding: 2.6rem 2rem 2.7rem;
        }
        @media all and (max-width: 767px) {
           min-height: unset;
        }
      }

      .info__title {
        font-weight: 600;
        margin-top: 0;
        font-size: 2rem;
        line-height: 3rem;

        @media all and (max-width: 375px) {
          max-width: 25rem;
        }
      }
    }

  }
}

.sorting-block {

  &.sorting--gallery {

    .sorting-block__sort-grid {
      grid-row-gap: 4.6rem;

      @media all and (max-width: 576px) {
        grid-row-gap: 3.9rem;
      }

      .info__date {
        color: rgba(255, 255, 255, .7);
      }

      .info__title {
        font-weight: 500;
        line-height: 2.8rem;

        .info__title-link {
          color: white;

          &:hover {
            color: $t-green;

            .underline--white {
              border-bottom: .1rem solid $t-green;
            }

          }
        }
      }
    }
  }
}


