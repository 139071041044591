@import "colors";

.select-hidden {
  display: none;
  visibility: hidden;
}

.select {
  flex: 1;
  cursor: pointer;
  position: relative;
  font-family: 'Hyundai Sans Head Office', sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #909090;
  width: 100%;
  height: 5.4rem;

  @media all and (max-width: 576px) {
    height: 4.4rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  &--changed {
    color: $text-black;
  }
}

.select + .select {
  .select-styled {
    border-left: none;
  }
}

.select-styled {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 1.8rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  border: 1px solid #C8CFD6;
  border-radius: .4rem;
  z-index: 10;

  @media all and (max-width: 576px) {
    padding-left: 1.4rem;
  }


  &:after {
    content:"";
    width: 0;
    height: 0;
    border: .5rem solid transparent;
    border-color: #a5a5a5 transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 2rem;

    @media all and (max-width: 576px) {
      right: 1.4rem;
    }
  }

  &.select--active {
    &:after {
      top: calc(50% - .5rem);
      border-color: transparent transparent #a5a5a5 transparent;
    }
  }

}

.select-options {
  width: 100%;
  display: none;
  position: absolute;
  top: calc(100% - .1rem);
  right: 0;
  left: 0;
  margin: 0;
  list-style: none;
  background-color: white;
  border-radius: .4rem;
  border: 1px solid #d5d5d5;
  z-index: 12;
  padding: 1rem 0;
  color: $text-black;


  li {
    display: flex;
    height: 3.5rem;
    align-items: center;
    padding-left: 1.8rem;

    @media all and (max-width: 576px) {
      padding-left: 1.4rem;
    }

    &:hover {
      background: #F3F3F3;
    }

    &:first-of-type {
      display: none;
    }

    &[rel="hide"] {
      display: none;
    }
  }
}
