@import "fonts";
@import "colors";

.modal-menu {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: $t-bg-gray;
  z-index: 10;
  color: $text-black;
  transform: translateX(100%);

  &.modal-menu--active {
    opacity: 1;
    transform: translateX(0);
  }

  &-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.modal-menu__header {
  position: relative;
  margin-top: 3.1rem;

  @media all and (max-width: 375px){
    margin-top: 1.4rem;
  }
}

.menu__close-button {
  position: absolute;
  top: 4.3rem;
  right: 9.5rem;
  width: 2rem;
  height: 2rem;
  background: none;
  cursor: pointer;
  border: none;
  font-size: 0;
  z-index: 2;

  @media all and (max-width: 992px) {
    top: 2rem;
    right: 2rem;
  }

  &::before, &::after {
    content: '';
    display: block;
    width: 2.6rem;
    height: .3rem;
    background: $text-black;
    position: absolute;
    top: calc(50% - .1rem);
    left: 0;
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.modal-menu__header {
  display: flex;
  align-items: center;

  .header__logo {
    width: 7.2rem;
    margin-right: .8rem;

    @media all and (max-width: 375px) {
      width: 5.8rem;
    }
  }

  .header__info {
    display: flex;
    flex-direction: column;
    max-width: 16rem;
    margin-bottom: .8rem;

    @media all and (max-width: 375px) {
      max-width: 11.5rem;
      margin-bottom: 1.1rem;
    }
  }


  .info__title {
    font-family: 'Oswald', 'Sans Serif';
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.4rem;

    @media all and (max-width: 375px) {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }


  .info__desc {
    margin-top: .4rem;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5rem;

    @media all and (max-width: 375px) {
      font-size: .9rem;
      line-height: 1.1rem;
      font-weight: 300;
    }
  }
}

.modal-menu__menu-content {

  .menu-content__grid {
    margin-top: 6rem;
    margin-bottom: 8.1rem;
    display: grid;
    grid-template: auto / repeat(4, 1fr);
    grid-gap: 7.8rem;

    @media all and (max-width: 1140px) {
      display: block;
      column-count: 2;
      column-gap: 5rem;
      //grid-gap: 4rem;
      max-width: 62rem;
      margin: 0 auto 3rem;
      //grid-template: auto / repeat(2, 1fr);
    }

    @media all and (max-width: 576px) {
      column-count: 1;
    }
  }

  .grid__menu-section {
    display: none;

    @media all and (max-width: 1140px) {
      margin-top: 4.3rem;
      display: inline-block;
    }
    @media all and (max-width: 576px) {
      display: block;
    }

    .menu-section__list {

      .list__item {
        margin-top: 1.7rem;

        .item__link.link--top {
          display: inline-block;
          font-weight: 700;
          font-size: 2rem;
          line-height: 2rem;
          margin-bottom: 1rem;
          color: $text-black;
        }

        .item__link {
          font-size: 1.6rem;
          line-height: 2.5rem;
          color: #3F4C59;
          padding-bottom: .3rem;
          border-bottom: 1px solid #3F4C59;
          opacity: .9;
          transition: .15s;

          &:hover {
            color: $t-green;
            border-bottom: 1px solid $t-green;
          }
        }
      }
    }
  }

}

.modal-menu__menu-footer {
  margin: auto 0 6.9rem;
  height: 13rem;
  display: flex;
  align-items: center;
  border-top: .1rem solid rgba(0, 0, 0, .1);
  border-bottom: .1rem solid rgba(0, 0, 0, .1);
  transition: .1s;
  opacity: 0;
  @media all and (max-width: 767px) {
    display: none;
  }
}

.menu-footer__contacts-block {
  display: flex;
  justify-content: flex-start;

  .contacts-block__elem + .contacts-block__elem{
    margin-left: 6rem;

    @media all and (max-width: 992px) {
      margin-left: 3rem;
    }
  }

  .elem__title {
    display: inline-block;
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  .elem__phone, .elem__email, .elem__address {
    font-family: 'Oswald', 'Sans Serif';
    margin-top: 1rem;
    display: block;
    font-size: 2rem;
    letter-spacing: 0.01em;

    @media all and (max-width: 992px) {
      font-size: 1.8rem;
    }
  }
}
