@import "fonts";
@import "colors";

@import "css/jquery.fancybox";
@import "css/slick";
@import "css/slick-theme";
@import "css/datepicker.min";
@import "css/intlTelInput";

.iti__flag {background-image: url("../img/flags/flags.png");}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("../img/flags/flags@2x.png");}
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
}

body.no-scroll {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: sticky;
}

button:focus {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;

}

a {
  text-decoration: none;
  color: $t-link-black;
  &:hover {
    color: $t-link-black;
  }
}

ul {
  list-style: none;
}

.main-wrapper {
  &.wrapper--single-page {
    padding-top: .01rem;
  }
}

.container {
  width: 131rem;
  margin: 0 auto;
  padding: 0 3rem;

  @media all and (max-width: 1310px) {
    width: 100%;
  }

  @media all and (max-width: 768px) {
    padding: 0 2rem;
  }

  &--tank-cleaning, &--services {
    width: 131rem;
    margin: 0 auto;

    @media all and (max-width: 1310px) {
      width: 100%;
    }

    @media all and (max-width: 375px) {
      padding: 0 1rem;
    }
  }

  &--tank-cleaning-wide {
    width: 146rem;
    margin: 0 auto;

    @media all and (max-width: 1460px) {
      width: 100%;
    }
    @media all and (max-width: 375px) {
      padding: 0 1rem;
    }
  }
}

.container-tank-cleaning {
  padding: 0;
  width: 144rem;
  margin: 0 auto;

  @media all and (max-width: 1440px) {
    width: 100%;
  }
}

.container

.responsive-img {
  width: 100%;
}

.text-break {
  display: block;
}

.text-nowrap {
  white-space: nowrap;
}

.underline {
  text-decoration: none;
  border-bottom: 1px solid $title-black;

  &.underline--white {
     border-bottom: 1px solid white;
  }
  &.underline--gray {
     border-bottom: 1px solid rgba(0, 0, 0, .6);
  }
  &.underline--blue {
    border-bottom: 1px solid $t-link;
  }
  &.underline--dashed {
    border-bottom: 1px dashed #818181;
  }
}

.no-style-link {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.button {
  background: white;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3rem;
  height: 5.6rem;
  border: none;
  border-radius: .3rem;
  white-space: nowrap;
  cursor: pointer;
  color: $t-green;
  transition: .2s;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $t-green;
  }

  &.button--invert {
    background: $t-green;
    color: white;

    &:hover {
      background: #0CA24D;
    }

    &:disabled {
      color: rgba(255, 255, 255, .5);
      &:hover {
        background: $t-green;
      }
    }
  }
}

.form__input-outer {
  position: relative;
  .iti {
    width: 100%;
  }

  &.field--invalid {

    &::after {
      content: '!';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.8rem;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 2.4rem;
      line-height: 4rem;
      color: white;
      background: $t-red;
      border-radius: 0 .4rem .4rem 0;
    }
    .field--requared {
      border: .2rem solid $t-red;

      & + .calendar-icon {
        border: .1rem solid $t-red
      }
    }
    & + .form__error-msg {
      display: inline-block;
    }

    & + .select-styled {
      border: .1rem solid $t-red;
    }
  }
}

.form__input-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;

  @media all and (max-width: 576px) {
    margin-bottom: 1.6rem;
  }

  &.input-wrapper--radio, &.input-wrapper--checkbox {
    display: flex;
    justify-content: space-between;
    border-radius: .4rem;
    border: .1rem solid #C8CFD6;
    align-self: end;
  }

  &.input-wrapper--radio-gender {
    position: relative;

    &.field--invalid {

      @media all and (max-width: 576px) {
        margin-bottom: 2rem;
      }
      .form__error-msg {
        display: block;
      }
    }

    .form__error-msg {
      position: absolute;
      top: 5.9rem;

      @media all and (max-width: 576px) {
        top: 4.5rem;
      }
    }
  }
  &.input-wrapper--marital-status {
    margin-bottom: 4rem;

    @media all and (max-width: 768px) {
      display: none;
    }
  }

  &.input-wrapper--marital-status-select {
    display: none;

    @media all and (max-width: 768px) {
      display: block;
    }
  }

  &.input-wrapper--driver-category {
    @media all and (max-width: 480px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      border: none;
      border-top: .1rem solid #c8cfd6;
      border-left: .1rem solid #c8cfd6;
    }

    .form__label.label--radio {
      @media all and (max-width: 480px) {
        flex: unset;
        flex-basis: 25%;
        border-bottom: .1rem solid #c8cfd6;
      }

      &:first-of-type {
        @media all and (max-width: 480px) {
          border-radius: .4rem 0 0 0;
        }
      }

      &:last-of-type {
        @media all and (max-width: 480px) {
          border-right: .1rem solid #c8cfd6;
          border-radius: 0 0 .4rem 0;
        }
      }
      &:nth-of-type(4) {
        @media all and (max-width: 480px) {
          border-radius: 0 .4rem 0 0;
        }
      }
      &:nth-of-type(8) {
        @media all and (max-width: 480px) {
          border-radius: 0 0 .4rem 0;
        }
      }
      &:nth-of-type(9) {
        @media all and (max-width: 480px) {
          border-radius: 0 0 0 .4rem;
        }
      }
    }
  }

  &.input-wrapper--inline {
    max-width: 56.4rem;
    display: flex;

    .select:first-of-type {
      .select-styled {
        border-radius: .4rem 0 0 .4rem;
      }
    }
    .select:last-of-type {
      .select-styled {
        border-radius: 0 .4rem .4rem 0;
      }
    }
  }

  .calendar-icon {
    display: block;
    position: absolute;
    width: 3.7rem;
    height: 100%;
    top: 0;
    left: 0;
    background: #F3F3F3 url('../img/calendar.svg') center center/auto no-repeat;
    border-radius: .4rem 0 0 .4rem;
    border: .1rem solid #D4D4D4;
  }
}

.form__label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media all and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .form__label.label--checkbox {
    @media all and (max-width: 640px) {
      margin: .6rem 0 1.5rem;
    }
  }
}

.form__input-inline {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-row-gap: 2rem;

  &.input-inline {
    &--col6 {
      grid-template: auto / repeat(6, 1fr);

      @media all and (max-width: 900px) {
        grid-template: auto / repeat(3, 1fr);
      }
      @media all and (max-width: 576px) {
        grid-template: auto / 1fr;
        grid-gap: 0;
      }
    }

    &--col4 {
      grid-template: auto / repeat(4, 1fr);

      @media all and (max-width: 900px) {
        grid-template: auto / repeat(2, 1fr);
      }
      @media all and (max-width: 480px) {
        grid-template: auto / 1fr;
      }
    }

    &--col3 {
      grid-template: auto / repeat(3, 1fr);

      @media all and (max-width: 576px) {
        grid-template: auto / 1fr;
        grid-gap: 0;
      }
    }
    &--col2 {
      grid-template: auto / repeat(2, 1fr);

      @media all and (max-width: 720px) {
        grid-template: auto / 1fr;
        grid-gap: 0;
      }
    }
    &--gap0 {
      grid-column-gap: unset;
    }
    &--gap1 {
      grid-column-gap: 1.5rem;
    }
    &--gap3 {
      grid-column-gap: 3.6rem;
    }
  }
}

.form__input-section {
  position: relative;
  margin-bottom: 4.1rem;
  background: #efefef;
  padding: 2.5rem 2.9rem 1.4rem;
  border-radius: .4rem;
  border: .1rem solid #C8CFD6;

  @media all and (max-width: 576px) {
    margin-bottom: 2.1rem;
    padding: 1.5rem 2rem .5rem;
  }

  &.input-section--programms {
    padding: 3.5rem 4.8rem;

    @media all and (max-width: 576px) {
      padding: 2rem;
    }

    @media all and (max-width: 576px) {
      padding: 2rem;
    }
  }

  &.input-section--expirience {
    padding: 0 4.3rem 0 0;
    margin-bottom: .5rem;

    @media all and (max-width: 900px) {
      padding: 0;
    }
    @media all and (max-width: 576px) {
      padding: 1.5rem 2rem .5rem;
    }

    .input-inline--col4 {
      grid-template: auto / 26rem repeat(3, 1fr);

      @media all and (max-width: 900px) {
        grid-template: auto / repeat(3, 1fr);
      }
      @media all and (max-width: 576px) {
        grid-template: auto / 1fr;
        grid-gap: 0;
      }
    }

    .section--period {
      .form__input-outer.field--invalid {
        @media all and (max-width: 576px) {
          margin-bottom: .8rem;
        }
      }
      .form__error-msg {
        position: absolute;
        top: 4.3rem;

        @media all and (max-width: 576px) {
          top: 6.8rem;
        }
      }
    }

  }

  &.input-section--relatives, &.input-section--leave {
    padding: 0 4.3rem 0 1.5rem;
    margin-bottom: .5rem;

    @media all and (max-width: 900px) {
      padding: 0 1.5rem;
    }
    @media all and (max-width: 576px) {
      padding: 1.5rem 2rem .5rem;
    }
  }

  &.clone-section {
    margin-top: 1.5rem;
  }
}

.form__label {
  position: relative;
  display: inline-block;
  margin-bottom: .4rem;
  font-size: 1.5rem;
  line-height: 2.2rem;

  &.label--checkbox {
    font-size: 1.3rem;
    line-height: 1.9rem;
    padding-left: 3rem;

    &::before {
      content: '';
      position: absolute;
      top: -.2rem;
      left: 0;
      display: block;
      width: 1.9rem;
      height: 1.9rem;
      border-radius: .3rem;
      border: 1px solid #CDCDCD;
      box-sizing: border-box;
      background: white;

      @media all and (max-width: 480px) {
        top: 0;
        width: 1.9rem;
        height: 1.9rem;
      }
    }
  }

  &.label--radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1.5rem 0;
    border: .1rem solid transparent;
    border-right: .1rem solid #D4D4D4;
    text-align: center;
    color: #949494;
    background: white;

    @media all and (max-width: 576px) {
      padding: 1rem 0;
    }

    &:last-of-type {
      border-right: transparent;
      border-radius: 0 .4rem .4rem 0;
    }
    &:first-of-type {
      border-radius: .4rem 0 0 .4rem;
    }
  }

  &.label--expirience {
    display: none;

    @media all and (max-width: 576px) {
      display: block;
    }
  }

  &.label--upload {
    margin-top: 3.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $t-green;
    font-size: 1.6rem;
    line-height: 3rem;
    padding: 1.5rem 2.5rem;
    height: 5.6rem;
    border: none;
    border-radius: .3rem;
    white-space: nowrap;
    cursor: pointer;
    color: #fff;

    @media all and (max-width: 480px) {
      margin-top: 3.2rem;
    }

    @media all and (max-width: 375px) {
      width: 100%;
    }

    &-resume {
      margin-top: 0;
      font-size: 1.5rem;
      line-height: 2.5rem;
      font-weight: 700;
      min-width: 18rem;
      background: transparent;
      border: .2rem dashed #AAB5BE;
      color: #3F4C59;
    }
  }

  .label__asterisk {
    color: #ff2929;
  }
}

.form__error-msg {
  display: none;
  position: relative;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: $t-red;
  top: -.5rem;

  &--privacy {
    position: absolute;
    top: 50%;
    left: -1.8rem;
    transform: translate(-100%, -50%);
    width: 20.8rem;
    padding: .9rem 1.3rem;
    text-align: left;
    color: white;
    border-radius: .2rem;
    background: $t-red;

    @media all and (max-width: 768px) {
      top: unset;
      left: -.5rem;
      bottom: -1.6rem;
      transform: translate(0, 100%);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -.4rem;
      transform: translateY(-50%) rotate(45deg);
      display: block;
      width: .9rem;
      height: .9rem;
      background: $t-red;

      @media all and (max-width: 768px) {
        right: unset;
        left: 1rem;
        top: 0;
      }
    }
  }
}

.form__input, .form__textarea {
  width: 100%;
  padding: 1.6rem 1.8rem 1.2rem;
  border: .1rem solid #D5D5D5;
  background: white;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: $text-black;
  border-radius: .4rem;

  @media all and (max-width: 576px) {
    padding: .9rem 1.4rem .9rem;
  }

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #929292;
  }

  &:disabled {
    background: #efefef;
    color: #D5D5D5;
  }

  &.field--span3 {
    grid-column: span 3;
  }

  &[type="number"] {
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }
  }

  &.field--date {
    padding-left: 4.5rem;
  }

  &.field--phone {
    color: #929292;

    &:focus {
      color: $text-black !important;
    }
  }
}
.form__textarea {
  min-height: 8.9rem;
  resize: none;
  vertical-align: top;

  @media all and (max-width: 576px) {
    min-height: 7.9rem;
  }
}

.form__checkbox {
  display: none;

  &.checkbox--privacy + .label--checkbox::before{
    border: .2rem solid #c71f1f;
  }
}

.form__checkbox:checked + .label--checkbox::before {
  background: $t-green url('../img/checkbox.svg') center center/auto no-repeat;
  border: none;
}

.form__input-radio, .form__input-checkbox {
  display: none;
}
.form__input-radio:checked + .label--radio, .form__input-checkbox:checked + .label--radio {
  background: #d6f3e2;
  color: $text-black;
  border: .1rem solid $t-green;

  @media all and (max-width: 480px) {
    border-bottom: .1rem solid $t-green !important;
  }

}
.form__upload-wrapper {
  margin-top: 3.6rem;
  display: grid;
  grid-template: auto / 18rem 1fr;
  grid-column-gap: 2.9rem;
  grid-row-gap: 2.1rem;

  @media all and (max-width: 576px) {
    margin-top: 2.4rem;
    grid-template: auto / 1fr;
  }

  .form__error-msg {
    top: unset;

    @media all and (max-width: 480px) {
      width: 100%;
      text-align: center;
    }
  }
}

.form__file-upload {
  display: none;

  &.field--invalid + .label--upload-resume{
    border-color: $t-red;
  }
}

.form__file-list {
  margin-top: 0.8rem;
  display: none;
  grid-column: span 2;

  @media all and (max-width: 576px) {
    margin-top: 0;
    order: 2;
    grid-column: unset;
  }

  &-item {
    position: relative;
    display: flex;
    height: 5.4rem;
    align-items: center;
    padding: 0 3.8rem 0 4.6rem;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #3F4C59;
    background: white;
    border: .1rem dashed #AAB5BE;
    border-right: transparent;
    border-radius: .3rem;

    &::before {
      content: '';
      display: block;
      width: 1.7rem;
      height: 2.2rem;
      position: absolute;
      top: calc(50% - 1.1rem);
      left: 1.8rem;
      background: url('../img/upload-file.svg') center center/auto no-repeat;
    }
  }

  &-del {
    display: block;
    position: absolute;
    right: 0;
    top: -.1rem;
    width: 3.8rem;
    height: calc(100% + .2rem);
    border: none;
    font-size: 0;
    background: $t-red url('../img/del-file-button.svg') center center / auto no-repeat;
    border-radius: 0 .3rem .3rem 0;
    cursor: pointer;
  }
}

.form__file-upload-wrapper {
  @media all and (max-width: 576px) {
    text-align: center;
    order: 3;
  }
}

.form__file-upload-desc {
  font-size: 1.5rem;
  line-height: 2.5rem;

  @media all and (max-width: 576px) {
    order: 1;
  }
}

.form__add-button {
  display: block;
  background: transparent;
  color: $t-green;
  border: none;
  border-bottom: .1rem dashed $t-green;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 2rem;

  &.add-button--job, &.add-button--relative, &.add-button--leave {
    margin-left: auto;
    margin-bottom: 2rem;
  }
}

.form__language-section {
  margin-bottom: 4rem;

  @media all and (max-width: 576px) {
    margin-bottom: 3.2rem;
  }

  .select:first-of-type {
    @media all and (max-width: 480px) {
      flex: .67;
    }
  }
}

.form__checkbox-section {
  display: flex;
  flex-direction: column;


  .checkbox-section__title {
    display: block;
    padding-bottom: .7rem;
    border-bottom: .1rem solid #C8CFD6;
    margin-bottom: 1.4rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: $text-black;
  }

  .label--checkbox {
    padding-left: 2.6rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: $text-black;

    &:before {
      top: .1rem;
      width: 1.9rem;
      height: 1.9rem;
    }

    &:last-of-type {
      margin-top: auto;
    }
  }

  .form__textarea {
    margin-top: 1.1rem;
  }
}

.form__privacy-link {
  //white-space: nowrap;
  border-bottom: .6px solid $t-link-black;
  cursor: pointer;
  transition: .2s;

  &:hover {
    color: $t-green;
    border-bottom: .6px solid $t-green;
  }
}

.grecaptcha-badge {
  display: none;
}
