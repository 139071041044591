@import "../colors";

.tank-cleaning__about {
  padding: 7rem 0;
  background: #F7F7F7;

  @media all and (max-width: 992px) {
    padding: 5rem 0;
  }

  @media all and (max-width: 576px) {
    padding: 3rem 0;
  }

  .tank-cleaning__block-title {
    text-align: left;
    max-width: 27rem;

    @media all and (max-width: 992px) {
      max-width: unset;
      text-align: center;
    }

    @media all and (max-width: 576px) {
      text-align: left;
    }
  }

  &-grid {
    display: grid;
    grid-template: auto / repeat(3, 1fr);
    gap: 3.5rem;

    @media all and (max-width: 1160px) {
      grid-template: auto / 25rem repeat(2, 1fr);
    }
    @media all and (max-width: 992px) {
      grid-template: auto / 1fr;
    }
    @media all and (max-width: 375px) {
      padding: 0 2rem;
    }
  }

  &-info {
    grid-area: 1 / 2 / 2 / 4;
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    column-gap: 3.5rem;
    row-gap: 5.3rem;

    @media all and (max-width: 992px) {
      grid-area: unset;
    }
    @media all and (max-width: 576px) {
      row-gap: 3rem;
      grid-template: auto / 1fr;
    }
  }

  &-item {


    &-title {
      display: block;
      max-width: 24rem;
      font-weight: 900;
      font-size: 2.4rem;
      line-height: 3rem;

      @media all and (max-width: 576px) {
        max-width: unset;
        font-size: 2rem;
        line-height: 2.5rem;
      }

      @media all and (max-width: 375px) {
        font-size: 1.8rem;
      }
    }

    &-desc {
      margin-top: 2rem;
      padding-right: 2.4rem;
      font-size: 1.8rem;
      line-height: 3rem;


      @media all and (max-width: 768px) {
        padding-right: unset;
        font-size: 1.6rem;
        line-height: 2.5rem;
      }

      @media all and (max-width: 576px) {
        margin-top: 1.2rem;
      }
    }

  }
}