@import "colors";
@import "fonts";

.main-screen {
  position: relative;
  padding-top: 3.1rem;
  height: 100vh;
  background: url('../img/bg-main-screen.jpg') center center no-repeat;
  background-size: cover;

  @media all and (max-width: 576px) {
    height: 45rem;
  }
  @media all and (max-width: 375px) {
    padding-top: 1.4rem;
    height: 36.6rem;
    background: url('../img/bg-main-screen-m.jpg') center center no-repeat;
    background-size: cover;
  }

  @media all and (max-height: 768px) {
    height: unset;
  }

  .container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}


.main-screen__main-title {
  flex: 1 0 auto;
  margin-top: 10rem;
  font-family: 'Oswald', 'Sans Serif';
  font-weight: 500;
  font-size: 10rem;
  line-height: 9.5rem;
  color: white;
  max-width: 95rem;

  @media all and (max-height: 900px) {
    margin-top: 5rem;
    font-size: 8rem;
    line-height: 9rem;
    max-width: 72rem;
  }

  @media all and (max-width: 1200px) {
    font-size: 8rem;
    max-width: 72rem;
  }

  @media all and (max-width: 992px) {
    margin-top: 6rem;
    font-size: 6rem;
    line-height: 7rem;
    max-width: 53rem;
  }
  @media all and (max-width: 768px) {
    font-size: 6rem;
    line-height: 7rem;
    max-width: 53rem;
  }
  @media all and (max-width: 576px) {
    margin-bottom: 6rem;
    font-size: 3.9rem;
    line-height: 4.8rem;
    max-width: 36rem;
  }
  @media all and (max-width: 375px) {
    margin-top: 11.5rem;
    font-size: 3.2rem;
    line-height: 3.5rem;
    max-width: 27.5rem;
  }
}


.main-screen__add-info-block {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4rem;

  @media all and (max-width: 576px) {
    display: none;
  }
  @media all and (max-height: 768px) {
    margin-top: 5rem;
  }
}

.add-info-block__scroll-down {
  max-width: 9rem;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: 1024px) {
    display: none;
  }
  @media all and (max-height: 768px) {
    display: none;
  }

  .scroll-down__mouse {
    position: relative;
    display: block;
    width: 3.8rem;
    height: 5.3rem;
    border: .25rem solid white;
    border-radius: 5rem;

    &:before {
      content: '';
      display: block;
      width: .25rem;
      height: 1rem;
      position: absolute;
      top: .7rem;
      left: calc(50% - .1rem);
      background: white;
      animation: 2s wheel infinite;
    }
  }

  @keyframes wheel {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(.5rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  .scroll-down__img {
    width: 3.8rem;
  }
  .scroll-down__text {
    margin-top: 1.1rem;
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.7rem;
    color: white;
  }
}

.add-info-block__item {
  position: relative;
  max-width: 26.6rem;

  @media all and (max-width: 768px) {
    max-width: 22.6rem;
  }

  &::before {
    content: '';
    display: block;
    width: 6.5rem;
    height: 14.8rem;
    position: absolute;
    left: 0;
    top: 0;
    background: $t-green;
    z-index: 1;

    @media all and (max-width: 768px) {
      width: 5.5rem;
    }
  }

  .item__title {
    position: absolute;
    top: 6.4rem;
    right: calc(100% - 3rem);
    font-family: 'Oswald', 'Sans Serif';
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.3rem;
    text-transform: uppercase;
    text-decoration: underline;
    color: white;
    z-index: 2;
  }

  .item__img {
    height: 14.8rem;
    width: 100%;
    object-fit: cover;
  }

  .item__desc {
    padding: 0 3rem;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 1.8rem;
    color: white;
  }

}

.item--articles {
  margin-left: 5.2rem;
  &:before {
    background: $t-blue;
  }
}





