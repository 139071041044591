@import "colors";

.tank-cleaning {

  &__block-title {
    font-family: Oswald,'Sans Serif';
    font-weight: 500;
    width: 100%;
    font-size: 5rem;
    line-height: 6rem;
    text-align: center;

    @media all and (max-width: 768px) {
      font-size: 3.4rem;
      line-height: 4.6rem;
    }

    @media all and (max-width: 576px) {
      font-size: 2.6rem;
      line-height: 3.5rem;
    }
  }

  &__block-desc {
    max-width: 59.4rem;
    margin: .9rem auto 0;
    display: block;
    font-size: 1.8rem;
    line-height: 3rem;
    color: #535353;
    text-align: center;

    @media all and (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
    @media all and (max-width: 576px) {
      margin: .7rem auto 0;
    }
  }

}



