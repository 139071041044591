@import "../colors";

.tank-cleaning {

  &__stats-grid {
    display: flex;
    justify-content: space-around;

    @media all and (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__stats {
    padding: 9rem 0 6rem;
    background: #f7f7f7;

    @media all and (max-width: 992px) {
      padding: 5rem 0 0;
    }

    &.stats--full-width {
      .tank-cleaning__stats-grid {
        justify-content: space-between;
      }

      .stats-item {
        width: 33.3%;

        @media all and (max-width: 992px) {
          width: 35rem;
        }
        @media all and (max-width: 480px) {
          width: 100%;
          padding: 0 5rem 0 2rem;
        }
      }
    }

    &.stats--diagnostic {
      background: white;
      padding: 8rem 0;

      @media all and (max-width: 768px) {
        padding: 5.2rem 0;
      }

      .stats-item__img-wrapper {
        border: .1rem solid #dfdfdf;
        box-shadow: 0 0.5rem 2rem rgba(0,0,0,.03);
      }
    }


    .stats-item {
      display: flex;
      align-items: center;

      @media all and (max-width: 992px) {
        width: 35rem;
        margin-bottom: 3rem;
      }
      @media all and (max-width: 480px) {
        width: 100%;
        padding: 0 3rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__img-wrapper {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.4rem;
        height: 9.4rem;
        background: white;
        border-radius: 50%;
        box-shadow: 0 1rem 2rem rgba(31, 31, 31, 0.12);

        @media all and (max-width: 576px) {
          margin-right: 1.7rem;
          min-width: 7.4rem;
          width: 7.4rem;
          height: 7.4rem;
        }

        img {
          vertical-align: top;
          @media all and (max-width: 576px) {
            max-width: 4rem;
            max-height: 4rem;
          }
        }
      }

      &__text {
        display: block;
        font-size: 1.8rem;
        line-height: 3rem;

        @media all and (max-width: 576px) {
          font-size: 1.6rem;
          line-height: 2.5rem;
        }
      }
      &__num {
        font-family: "Oswald", sans-serif;
        font-weight: 500;
        font-size: 6.4rem;
        line-height: 7.7rem;

        @media all and (max-width: 576px) {
          font-size: 4.4rem;
          line-height: 6rem;
        }
      }
    }
  }

}