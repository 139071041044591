.tank-cleaning__anticor-process {
  padding: 9.2rem 0 11rem;

  @media all and (max-width: 992px) {
    padding: 6rem 0 7rem;
  }

  @media all and (max-width: 576px) {
    padding: 3.6rem 0 4.5rem;
  }

  .tank-cleaning__block-title {
    max-width: 60rem;
    margin: 0 auto;
  }
}

.anticor-process {
  margin-top: 8rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  column-gap: 2.5rem;
  counter-reset: step;

  @media all and (max-width: 992px) {
    margin-top: 4rem;
    padding-bottom: 3rem;
  }

  @media all and (max-width: 720px) {
    grid-template: auto / 1fr;
    row-gap: 3rem;
  }

  @media all and (max-width: 576px) {
    padding-bottom: 2rem;
  }

  &__step {
    position: relative;
    padding-left: 8.8rem;
    font-size: 1.9rem;
    line-height: 3rem;
    counter-increment: step;

    @media all and  (max-width: 1260px) {
      font-size: 1.6rem;
      line-height: 2.5rem;
    }

    @media all and  (max-width: 992px) {
      padding-left: 0;
      padding-top: 9.5rem;
      text-align: center;
      font-size: 1.4rem;
      line-height: 2.5rem;
    }

    @media all and (max-width: 720px) {
      font-size: 1.6rem;
      line-height: 2.5rem;
    }

    @media all and (max-width: 375px) {
      padding: 9.5rem 1rem 0;
    }

    &::before {
      content: counter(step);
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 900;
      font-size: 10rem;
      line-height: 10rem;
      color: #E6E6E6;

      @media all and  (max-width: 992px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}