@import "../colors";

.tank-cleaning {

  &__licenses {
    margin: 8.3rem 0 10.6rem;

    @media all and (max-width: 768px) {
      margin: 6.3rem 0;
    }
    @media all and (max-width: 576px) {
      margin: 4.3rem 0;
    }
    @media all and (max-width: 375px) {
      margin: 3rem 0 4.7rem;
    }

    &-desc {
      max-width: 79rem;
      margin: 3rem auto 0;
      font-size: 1.8rem;
      line-height: 3rem;
      text-align: center;

      @media all and (max-width: 576px) {
        display: none;
      }
    }

    &-slider {
      margin: 5.2rem auto 3.8rem;

      @media all and (max-width: 375px) {
        margin-top: 2.9rem;
      }

      .licenses-slider__item {
        margin: 0 1rem;
        cursor: pointer;


        &:focus {
          outline: none;
        }

        &-zoom-link {
          &:focus {
            outline: none;
          }
        }

        &-img {
          max-width: 100%;
          border: .3rem solid #A6A6A6;

          @media all and (max-width: 576px) {
            border: .2rem solid #A6A6A6;
          }
          &:focus {
            outline: none;
          }
        }


      }

      .slick-dots {
        bottom: -3.5rem;
        li {
          width: .6rem;
          height: .6rem;
          margin: 0.4rem;

          &.slick-active {
            button {
              &::before {
                background: #728291;
              }
            }
          }

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background: #CCD3D8;
              opacity: 1;
            }
          }
        }

      }
    }

    &-label {
      grid-template: auto / repeat(6, minmax(14rem, 20rem));
      display: grid;
      align-items: center;
      box-shadow: 0 2rem 10rem rgba(7, 32, 57, 0.09);

      @media all and (max-width: 900px) {
        margin: 6rem auto 0;
      }

      @media all and (max-width: 840px) {
        max-width: max-content;
        grid-template: auto /repeat(3, minmax(14rem, 18rem));
      }
      @media all and (max-width: 640px) {
        max-width: unset;
        margin: 6rem 1rem 0;
      }
      @media all and (max-width: 480px) {
        max-width: 44rem;
        grid-template: auto /repeat(2, 1fr);
        box-shadow: 0 1rem 3rem rgba(14, 28, 41, 0.07);
      }

      &-item {
        position: relative;
        padding: 2rem 1.7rem;

        &::before {
          content: '';
          display: block;
          width: .1rem;
          height: 4.5rem;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          border-left: .1rem solid #E3E3E3;
          box-sizing: border-box;
        }

        &:last-child {
          &::before {
            display: none;
          }
        }
        &:nth-of-type(3n) {
          &::before {
            @media all and (max-width: 840px) and (min-width: 480px){
              display: none;
            }
          }
        }
        &:nth-of-type(even) {
          &::before {
            @media all and (max-width: 480px) {
              display: none;
            }
          }
        }
      }

      &-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

    }
  }

}