@import "../colors";

.tank-cleaning {

  &__reviews {
    padding: 7rem 0 9rem;
    background: #f7f7f7;


    @media all and (max-width: 992px) {
      padding: 5rem 0 5rem;
    }

    @media all and (max-width: 768px) {
      padding: 5rem 0;
    }

    @media all and (max-width: 576px) {
      padding: 3rem 0 0;
    }

    .tank-cleaning__block-desc {
      @media all and (max-width: 375px) {
        margin-top: 1.7rem;
      }
    }

    &-inner {
      margin-top: 6rem;

      @media all and (max-width: 640px) {
        margin-top: 8.5rem;
      }

      @media all and (max-width: 375px) {
        padding: 8.1rem 0 .1rem;
      }
    }

    &-item {
      display: none;
      margin: 0 auto;
      justify-content: space-between;

      @media all and (max-width: 640px) {
        flex-direction: column;
      }

      &.item--current {
        display: flex;
      }

      .reviews-item__license {
        position: relative;
        flex: 1;
        max-width: 33.3rem;
        z-index: 2;

        @media all and (max-width: 640px) {
          max-width: unset;
          width: 100%;
          padding: 0 7.9rem;
        }

        &-link {
          &:focus {
            outline: none;
          }
        }

        &-img {
          vertical-align: top;
          max-width: 100%;
          border: .3rem solid #A5A5A5;
          box-shadow: 0 4rem 8rem rgba(27, 27, 27, 0.12);

          @media all and (max-width: 640px) {
            margin-top: -7rem;
            box-shadow: 0 4rem 8rem rgba(27, 27, 27, 0.06);
          }
          @media all and (max-width: 375px) {
            margin-top: -14rem;
            box-shadow: 0 4rem 8rem rgba(27, 27, 27, 0.06);
          }

          &:focus {
            outline: none;
          }
        }
      }
      .reviews-item__text {
        position: relative;
        flex: 1;
        margin-left: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 43rem;
        padding: 3rem 10rem 3rem 14rem;
        border: .1rem solid #DFDFDF;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 3rem;
        background: white;

        @media all and (max-width: 640px) {
          border: none;
        }

        &::-webkit-scrollbar-track {
          padding-right: .5rem;
          -webkit-box-shadow: inset 0 0 .4rem rgba(0,0,0,0.3);
          background-color: #eef3f8;
        }

        &::-webkit-scrollbar {
          width: .3rem;
          background-color: #eef3f8;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 .4rem rgba(0,0,0,.3);
          background-color: #C3CDD7;
        }

        @media all and (max-width: 900px) {
          padding: 3rem 6rem;
          margin-left: 4rem;
        }

        @media all and (max-width: 768px) {
          font-size: 1.6rem;
          line-height: 2.5rem;
          margin-left: 3rem;
        }
        @media all and (max-width: 640px) {
          margin-left: 0;
          margin-top: -9rem;
          padding: 11.5rem 3rem 3rem;
        }

        &::before {
          content: '';
          display: block;
          width: 4.4rem;
          height: 4.4rem;
          position: absolute;
          top: 5.1rem;
          left: 5.1rem;
          background: url('../img/reviews-quotes.svg') center center / contain no-repeat;

          @media all and (max-width: 900px) {
            width: 3rem;
            height: 3rem;
            top: 2rem;
            left: 2rem;
          }

          @media all and (max-width: 640px) {
            width: 7.2rem;
            height: 4.8rem;
            left: 2.8rem;
            top: 1.9rem;
          }
        }

        p {
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &-author {
          margin-top: 1.9rem;

          .text-selected {
            font-weight: 800;
          }
        }
      }
    }

    &-slider {
      max-width: 111rem;
      margin: .3rem auto 0;

      @media all and (max-width: 1310px) {
        max-width: calc(100% - 14rem);
      }

      @media all and (max-width: 576px) {
        margin-top: 0;
        max-width: calc(100% - 11.7rem);
      }


      .reviews-slider__item {
        margin: 0 .6rem;
        padding: .6rem 1rem;
        background: white;
        height: 11rem;
        border: .1rem solid #DADFE3;

        @media all and (max-width: 576px) {
          padding: 0 3.1rem;
          height: 9rem;
        }

        &:focus {
          outline: none;
        }
        &:hover {
          cursor: pointer;
          .reviews-slider__item-img {
            filter: none;
          }
        }

        &.slick-current {
          border-color: transparent;
          box-shadow: 0 1rem 2rem rgba(7, 32, 57, 0.09);

          .reviews-slider__item-img {
            filter: none;
          }
        }



        &-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          filter: grayscale(100%);
          transition: .2s;
        }
      }

      .slick-list {
        padding: 4rem 0;
      }

      .slick-prev, .slick-next {
        width: 4.4rem;
        height: 6.4rem;
        transform: translate(0, -50%);
        z-index: 2;
        @media all and (max-width: 576px) {
          height: 5.4rem;
        }

        &:hover {
          &::before {
            background: #309C5F url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;;
          }
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: $t-green url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;
          border-radius: .3rem;
          opacity: 1;
        }

        &.slick-disabled {
          &::before {
            background: #309C5F url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;
          }
        }
      }

      .slick-prev {
        left: -6.8rem;

        @media all and (max-width: 576px) {
          left: -5.8rem;
        }
        &::before {
          transform: rotate(180deg);
        }
      }
      .slick-next {
        right: -6.8rem;
        @media all and (max-width: 576px) {
          right: -5.8rem;
        }
      }

      .slick-dots {
        bottom: 0;
        li {
          width: .6rem;
          height: .6rem;
          margin: 0.4rem;

          &.slick-active {
            button {
              &::before {
                background: #728291;
              }
            }
          }

          button {
            width: 100%;
            height: 100%;
            padding: 0;

            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background: #CCD3D8;
              opacity: 1;
            }
          }
        }

      }
    }
  }

}