@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.woff2');
    font-weight: $weight;
    font-style: $style;
  }
}

//Oswald
@include font-face("Oswald", "../fonts/oswald-light", 300);
@include font-face("Oswald", "../fonts/oswald-regular", 400);
@include font-face("Oswald", "../fonts/oswald-medium", 500);
@include font-face("Oswald", "../fonts/oswald-semibold", 600);
@include font-face("Oswald", "../fonts/oswald-bold", 700);

//Lato
@include font-face("Lato", "../fonts/lato-light", 300);
@include font-face("Lato", "../fonts/lato-regular", 400);
@include font-face("Lato", "../fonts/lato-medium", 500);
@include font-face("Lato", "../fonts/lato-semibold", 600);
@include font-face("Lato", "../fonts/lato-bold", 700);
@include font-face("Lato", "../fonts/lato-heavy", 900);
