@import "../colors";

.tank-cleaning {

  &__last-questions {
    margin: 12rem 0 5.6rem;

    @media all and (max-width: 1200px) {
      margin: 9rem 0 4.6rem;
    }
    @media all and (max-width: 768px) {
      margin: 6.6rem 0 1.6rem;
    }
    @media all and (max-width: 375px) {
      margin: 3.6rem 0 0;
    }

    &-inner {
      display: flex;
      justify-content: space-between;

      @media all and (max-width: 1200px) {
        flex-direction: column;
      }
    }

    &-info  {
      padding-top: 4.3rem;
      max-width: 33.3rem;

      @media all and (max-width: 1200px) {
        padding-top: 0;
        max-width: 100%;
        margin-bottom: 4rem;
      }
      @media all and (max-width: 576px) {
        margin-bottom: 2.1rem;
      }

      .tank-cleaning__block-title, .tank-cleaning__block-desc {
        text-align: left;

        @media all and (max-width: 1200px) {
          text-align: center;
        }
      }

      .tank-cleaning__block-desc {
        margin-top: 1.7rem;
      }
    }

    &-wrapper {
      width: 100%;
      max-width: 82.3rem;

      @media all and (max-width: 1200px) {
        max-width: unset;
      }
    }

    &-video {
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;

      @media all and (max-width: 375px) {
        width: calc(100% + 2rem);
        margin-left: -1rem;
      }


      iframe, img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

}