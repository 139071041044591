@import "fonts";
@import "colors";

.news-block {
  padding: 4.5rem 0 5.4rem;
}



.news-block__all-news {
  margin-top: 6.5rem;
  text-align: center;

  @media all and (max-width: 576px) {
    margin-top: 4.5rem;
  }

  .all-news__link {
    display: inline-block;
    padding-right: 6rem;
    position: relative;
    font-weight: 700;
    font-size: 2rem;
    line-height: 3.2rem;
    color: $title-black;
    transition: .2s;
    &:hover {
      color: $t-green;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 2.5rem);
      display: block;
      width: 5rem;
      height: 5rem;
      background: $t-green url('../img/arrow-next.svg') center center no-repeat;
      background-size: auto;
      border-radius: 50%;
    }
  }
}

.content-section__news-list {

  .news-list__item + .news-list__item {
    margin-top: 5.1rem;

    @media all and (max-width: 480px) {
      margin-top: 3.5rem;
    }
  }

  .news-list__item {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media all and (max-width: 480px) {
      padding-top: 2.6rem;
    }

    &.item--no-photo {
      .news-list__item-photo {
        display: none;
      }
    }

    &-info {
      flex: 1;

      .item-info__meta {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.2rem;
        color: #727272;

        @media all and (max-width: 992px) {
          font-size: 1.4rem;
          line-height: 2rem;
        }
        @media all and (max-width: 480px) {
          position: absolute;
          top: 0;
          left: 0;
        }

        &-date {
          display: inline-block;
          margin-left: 1.5rem;
          font-weight: 400;
        }

        &-tag {
          color: $t-green;
        }
      }


      .item-info__title {
        max-width: 60rem;
        margin-top: .8rem;
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 3rem;
        color: $text-black;

        @media all and (max-width: 992px) {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
        @media all and (max-width: 480px) {
          margin-top: 0;
          font-size: 1.6rem;
          line-height: 2rem;
        }

        &-link {
          &:hover {
            color: $text-black;
            .underline {
              border-bottom: 2px solid rgba(9, 18, 28, .5);
            }
          }

          .underline {
            border-bottom: 2px solid transparent;
            transition: .1s;
          }
        }
      }

      .item-info__desc {
        margin-top: .7rem;
        font-size: 1.5rem;
        line-height: 2.5rem;
        color: #747474;

        @media all and (max-width: 992px) {
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }

    &-photo {
      flex-basis: 16rem;
      width: 16rem;
      height: 16.9rem;
      margin-left: 5.3rem;

      @media all and (max-width: 1200px) {
        margin-left: 3rem;
      }
      @media all and (max-width: 992px) {
        flex-basis: 12rem;
        width: 12rem;
        height: 12.7rem;
        margin-left: 2rem;
      }

      @media all and (max-width: 480px) {
        flex-basis: 7.3rem;
        width: 7.3rem;
        height: 7.7rem;
      }

      .item-photo__link {

      }

      .item-photo__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}

.content-section__no-result {

  @media all and (max-width: 375px) {
    padding: 0 1rem;
  }

  .no-result_text {
    font-size: 2.2rem;
    line-height: 3rem;

    &-query {
      font-weight: 700;
    }

  }
  .no-result__reasons {
    margin-top: 2.6rem;

    &-item {
      position: relative;
      font-size: 1.6rem;
      line-height: 2.8rem;
      padding-left: 2.1rem;

      @media all and (max-width: 375px) {
        margin-bottom: 1.4rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        display: block;
        width: .6rem;
        height: .6rem;
        position: absolute;
        top: 1.1rem;
        left: 0;
        border-radius: 50%;
        background: #000;
      }
    }
  }
}

.content-section__news-article {

  .news-article__meta {
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }

  .news-article__date {
    margin-right: 2rem;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: $text-black;
    opacity: .5;

    @media all and (max-width: 375px) {
      margin-right: 1.5rem;
    }
  }

  .news-article__tag {
    display: inline-block;
    padding: 1.1rem 1.1rem .9rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: $t-green;
    color: white;

    @media all and (max-width: 480px) {
      padding: 0;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.6rem;
      text-transform: none;
      background: transparent;
      color: $t-green;
    }
  }

  .news-article__text {
    margin-top: 3.9rem;

    @media all and (max-width: 480px) {
      margin-top: 2.8rem;
    }

    p {
      margin-bottom: 2.7rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}
