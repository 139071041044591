@import "fonts";
@import "colors";

.footer {
  background: $t-bg-gray;
  padding: 5.9rem 0 3.2rem;

  @media all and (max-width: 576px) {
    padding: 2.7rem 0;
  }
}


.footer__footer-content {
  display: flex;

  @media all and (max-width: 992px) {
    flex-direction: column;
  }
}


.footer-content__subscribe-block {
  display: none;
  color: $text-black;
  max-width: 44.3rem;

  @media all and (max-width: 992px) {
    display: block;
  }
}


.footer-content__nav-grid {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  grid-column-gap: 9rem;

  @media all and (max-width: 1200px) {
    grid-column-gap: 4rem;
  }

  @media all and (max-width: 992px) {
    margin-top: 4rem;
    grid-template: auto / 2fr 2fr 1fr;
  }
  @media all and (max-width: 768px) {
    grid-template: auto / 1fr 1fr;
  }
  @media all and (max-width: 576px) {
    grid-template: auto /1fr;
  }
}


.nav-grid__menu-section:nth-of-type(2) {
  @media all and (max-width: 768px) {
    grid-row: span 2;
  }
  @media all and (max-width: 576px) {
    margin-top: 3.7rem;
  }
}

.nav-grid__menu-section:nth-of-type(3) {
  @media all and (max-width: 768px) {
    margin-top: 3rem;
  }
  @media all and (max-width: 576px) {
    margin-top: 3.7rem;
  }
}

.footer-menu__list {
  .list__item {
    margin-top: 1.8rem;

    &:first-child {
      margin-top: 0;
    }

    .item__link.link--top {
      display: inline-block;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      opacity: 1;

      &:hover {
      opacity: 1;
      }
    }

    .item__link {
      font-size: 1.6rem;
      line-height: 2.5rem;
      color: $text-black;
      padding-bottom: .3rem;
      border-bottom: 1px solid $text-black;
      opacity: .6;
      transition: .15s;

      &:hover {
        opacity: .9;
        color: $t-green;
        border-bottom: 1px solid $t-green;
      }
    }
  }
}

.footer-content__contacts-section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 35.44%;
  color: $text-black;

  @media all and (max-width: 1200px) {
    min-width: 25%;
  }
  @media all and (max-width: 992px) {
    align-items: center;
    text-align: center;
  }
  @media all and (max-width: 576px) {
    align-items: flex-start;
    text-align: left;
  }

}
.contacts-section__subscribe {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 500;
  color: $text-black;

  @media all and (max-width: 992px) {
    display: none;
  }
}

.subscribe__label {
  display: inline-block;
  margin-bottom: 1.9rem;
}

.subscribe__form {
  position: relative;

  .form__input {
    font-family: 'Oswald', 'Sans Serif';
    padding: 1rem 5rem 1.4rem 2.2rem;
    width: 100%;
    background: transparent;
    border: .1rem solid #D0D0D0;
    border-radius: .3rem;
    font-weight: 300;
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: $text-black;

    @media all and (max-width: 1200px) {
      padding: 1rem 4.5rem 1.4rem 1.8rem;
    }

    @media all and (max-width: 576px) {
      font-size: 2.2rem;
      line-height: 3.3rem;
    }
    @media all and (max-width: 375px) {
      width: calc(100% + 2rem);
      margin-left: -1rem;
    }

    &::placeholder {
      color: #969696;
      opacity: .7;
    }

    &:focus {
      outline: none;
    }

  }
  .form__button-subscribe {
    position: absolute;
    bottom: 0;
    right: 0;
    background: transparent url('../img/subscribe-arrow.svg') center center no-repeat;
    background-size: auto;
    border: none;
    font-size: 0;
    width: 7.5rem;
    height: 6rem;
    cursor: pointer;

    @media all and (max-width: 1200px) {
      width: 5.5rem;
    }
  }
}

.contacts-section__contacts-block {
  margin-top: 3.3rem;
  margin-bottom: 2rem;

  @media all and (max-width: 992px) {
    margin-top: 5.2rem;
  }

  .contacts-block__phone {
    font-family: 'Oswald', 'Sans Serif';
    display: block;
    font-size: 3.2rem;
    line-height: 4.7rem;
  }

  .contacts-block__email, .contacts-block__address {
    display: block;
    margin-top: 1rem;

    @media all and (max-width: 576px) {
      margin-top: 1.5rem;
    }
  }

  .address__street {

    @media all and (max-width: 1200px) {
      display: block;
    }
    @media all and (max-width: 992px) {
      display: inline;
    }
    @media all and (max-width: 576px) {
      display: block;
    }
  }
}

.contacts-section__comment-link {
  margin-top: auto;
  flex: 0 0 1;

  @media all and (max-width: 992px) {
    margin-top: 2rem;
  }
  @media all and (max-width: 576px) {
    margin-top: 4.4rem;
  }

  .comment-link__link {
    display: inline-block;
    position: relative;
    color: rgba(0, 0, 0, .6);
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-left: 2.6rem;

    &:hover {
      color: $t-green;

      .underline {
        border-bottom: .1rem solid $t-green;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      top: 0;
      left: -2.6rem;
      background: url('../img/pencil.svg') center center/auto no-repeat;
    }
  }
}

.contacts-section__social-icon {
  margin-top: 3.4rem;
  display: flex;

  @media all and (max-width: 992px) {
    margin-top: 3.8rem;
  }

  .social-icon__icon {
    position: relative;
    margin-right: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;
    transition: .15s;

    &.icon--vk {
      background: #5181B8;
    }
    &.icon--fb {
      background: #3B5998;
    }
    &.icon--tw {
      background: #1DA1F2;
    }
    &.icon--ok {
      background: #EE8208;
    }

  }
  .icon__img {
    max-width: 4.6rem;
    max-height: 3rem;
  }

  .social-icon__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    font-size: 0;
    opacity: 0;
    border-radius: 50%;

  }
}

.footer__copyright {
  margin-top: 7rem;
  display: flex;
  align-items: flex-end;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: rgba(0, 0, 0, .6);


  @media all and (max-width: 992px) {
    margin-top: 5.1rem;
    justify-content: center;
    text-align: center;
  }
  @media all and (max-width: 576px) {
    margin-top: 3.8rem;
    text-align: left;
  }
}


.copyright__text {
  display: flex;
  flex-direction: column;
  width: 49%;
  @media all and (max-width: 992px) {
    max-width: 40rem;
    width: 100%;
  }
  @media all and (max-width: 576px) {
    max-width: unset;
  }
}
.google-recaptcha {
  max-width: 40rem;
  margin-top: 1.5rem;

  &__link {
    color: inherit;
    font-weight: 700;
    border-bottom: .1rem solid #636363;

    &:hover {
      color: inherit;
    }
  }
}


.copyright__find-bug {
  width: 51%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media all and (max-width: 992px) {
    display: none;
  }

  .find-bug__ctrl-key, .find-bug__enter-key {
    padding: .5rem .8rem 2.1rem 2.6rem;
    font-weight: 700;
    color: rgba(0, 0, 0, .8);
    border: .1rem solid #D0D0D0;
    border-radius: .3rem;
    margin-right: 1rem;
  }
  .find-bug__info {
    margin-left: 1rem;
    color: $text-black;
    .info__title {
      display: block;
      font-weight: 700;
      opacity: .8;
    }
    .info__desc {
      opacity: .6;
    }
  }
}

.footer {
  &--gallery {
    background: $t-bg-black;

    .footer-menu__list {

      .list__item {

        .item__link {
          color: white;
          border-bottom: .1rem solid white;
        }
      }
    }

    .footer-content__contacts-section {
      color: white;
    }

    .subscribe__label {
      color: white;
    }

    .subscribe__form {
      .form__input {
        background: #4D4D4D;
        color: white;
        border: none;

        &::placeholder {
          color: #999999;
        }
      }
      .form__button-subscribe {
        background: url(../img/subscribe-arrow-white.svg) center center no-repeat;
      }
    }

    .contacts-section__comment-link {
      .comment-link__link {
        color: rgba(255, 255, 255, .6);

        &:hover {
          opacity: 1;
          color: inherit;

          .underline {
            border-bottom: .1rem solid white;
          }
        }

        &::before {
          background: url(../img/pencil-white.svg) center center no-repeat;
        }
      }
    }

    .contacts-section__social-icon {
      .social-icon__icon {
        background: #4D4D4D;
      }
    }

    .footer__copyright {
      color: rgba(179, 192, 198, .8);
    }
    .copyright__find-bug {
      .find-bug__ctrl-key, .find-bug__enter-key {
        color: #B3C0C6;
        border: .1rem solid #3F4D53;
      }
      .find-bug__info {
        color: rgba(179, 192, 198, .6);

        .info__title {
          color: white;
        }
      }
    }

  }
}


