@import "fonts";
@import "colors";

.main-screen__header, .single-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.2rem;
  color: white;
  width: calc(100% + .8rem);
  margin-left: -.8rem;

  @media all and (max-width: 375px) {
    height: 5.8rem;
  }
}

// ================== SINGLE PAGE ====================//

.single-page__header {
  margin-top: 3.1rem;
  color: $text-black;

  @media all and (max-width: 375px) {
    margin-top: 1.4rem;
  }

  .right-block__first-line {
    .first-line__menu-caller {
      .menu-caller__burger {
        background: $text-black;
        &::before, &::after {
          background: $text-black;
        }
      }
    }
    .first-line__contact {
      .contact__phone-link {
        color: $text-black;
      }
    }

    .search__form {
      .form__search-caller {
        background: url(../img/search-icon.svg) center center/contain no-repeat;

        @media all and (max-width: 375px) {
          background: url(../img/search-icon-m.svg) center center/contain no-repeat;
        }
      }
    }
  }

  .header-menu__navigation {
    .navigation__item {
      .item__link {
        color: $t-link-black;
        border-bottom: 1px solid $t-link-black;
        &:hover {
          color: $t-green;
          border-bottom: 1px solid $t-green;
        }

        &.link--language {
          color: $t-link-black;
          border-bottom: 1px solid $t-link-black;

          &:hover {
            color: $t-green;
            border-bottom-color: $t-green;
          }
        }
      }
      .item__submenu {
        .item__link {
          border-bottom: none;
        }
      }

      &.item--has-submenu {
        &::after {
          background: url(../img/menu-arrow-down-black.svg) center center/cover no-repeat;
        }
      }
    }
  }
}

// ================== SINGLE PAGE ====================//

.header__left-block {
  position: relative;
  display: flex;
  align-items: center;

  .header__home-link {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
  }

  .left-block__logo {
    width: 7.2rem;
    margin-right: .8rem;

    @media all and (max-width: 375px) {
      width: 5.8rem;
    }
  }

  .left-block__info {
    display: flex;
    flex-direction: column;
    max-width: 16rem;
    margin-bottom: .3rem;

    @media all and (max-width: 375px) {
      max-width: 11.5rem;
      margin-bottom: .4rem;
    }
  }


  .info__title {
    font-family: 'Oswald', 'Sans Serif';
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.4rem;
    @media all and (max-width: 375px) {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }
  .info__desc {
    margin-top: .3rem;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5rem;

    @media all and (max-width: 375px) {
      font-size: .9rem;
      line-height: 1.1rem;
      font-weight: 300;
    }
  }
}

.header__right-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.right-block__first-line {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .first-line__contact {
    font-family: 'Oswald', 'Sans Serif';
    font-size: 2.8rem;
    line-height: 4.4rem;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    @media all and (max-width: 576px) {
      display: none;
    }

    .contact__phone-link {
      color: white;
      &:hover {
        color: inherit;
      }
    }
  }
  .search__bg-substrate {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #02141C;
    opacity: 0;
    transition: .7s;
    &.bg-substrate--active {
      display: block;
      opacity: .3;
      z-index: 6;
    }
  }

  .first-line__search {
    position: relative;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 1.8rem 0 2.8rem;

    @media all and (max-width: 375px) {
      margin: 0 1.3rem 0 0;
    }

  }
  .search__form {
    position: relative;

    .form__search-caller {
      position: absolute;
      top: 0;
      right: 0;
      width: 2.4rem;
      height: 2.4rem;
      background: url(../img/search-icon-white.svg) center center no-repeat;
      background-size: contain;
      z-index: 2;
      cursor: pointer;

      @media all and (max-width: 375px) {
        background: url(../img/search-icon-white-m.svg) center center no-repeat;
        background-size: contain;
      }
      &.search-caller--active {
        background: url(../img/search-icon.svg) center center no-repeat;
        z-index: 8;
        @media all and (max-width: 576px) {
          top: -1.5rem;
          left: 4rem;
        }
        @media all and (max-width: 375px) {
          top: .3rem;
          left: 3.5rem;
          background: url(../img/search-icon-m.svg) center center no-repeat;
          background-size: contain;
        }
      }
    }

    .form__input-search {
      position: absolute;
      width: 0;
      padding: 1.5rem 0 1.3rem;
      top: calc(50% - 2.9rem);
      right: -1.5rem;
      font-size: 1.8rem;
      line-height: 3rem;
      background: transparent;
      border: none;
      border-radius: .3rem;
      z-index: 1;
      transition: .2s;

      @media all and (max-width: 576px) {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        border-radius: 0;
        transform: translateY(-5.8rem);
      }

      &.input-search--active {
        background: white;
        padding: 1.5rem 4.4rem 1.3rem 2.6rem;
        width: 48.2rem;
        z-index: 7;

        @media all and (max-width: 576px) {
          width: 100%;
          padding: 1.5rem 4.4rem 1.3rem 2rem;
          transform: translateY(0);
        }
      }
      &::placeholder {
        color: #979EA2;
      }
      &:focus, &:active {
        outline: none;
      }
    }
    .form__button-search {
      border: none;
      width: 0;
      height: 0;
      font-size: 0;
      opacity: 0;
    }
  }

  .first-line__menu-caller {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media all and (max-width: 375px) {
      width: 2rem;
      height: 2rem;
    }

    .menu-caller__burger {
      position: relative;
      display: block;
      width: 2.4rem;
      height: .3rem;
      background: white;

      @media all and (max-width: 375px) {
        width: 1.8rem;
      }

      &::before, &::after {
        content: '';
        display: block;
        width: 2.4rem;
        height: .3rem;
        position: absolute;
        left: 0;
        background: white;

        @media all and (max-width: 375px) {
          width: 1.8rem;
        }
      }
      &::before {
        top: -.9rem;
        @media all and (max-width: 375px) {
          top: -.7rem;
        }
      }
      &::after {
        bottom: -.9rem;
        @media all and (max-width: 375px) {
          bottom: -.7rem;
        }
      }
    }
  }
}

.right-block__second-line {
  display: flex;
  margin-top: .7rem;
  justify-content: flex-end;
}

.header-menu__navigation {
  .navigation__item {
    display: inline-block;
    margin-left: 2.7rem;
    padding-bottom: 1.5rem;

    @media all and (max-width: 1140px) {
      margin-left: 2.2rem;
    }
    @media all and (max-width: 1024px) {
      display: none;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-left: 7.7rem;

      @media all and (max-width: 1100px) {
        margin-left: 4rem;
      }
      @media all and (max-width: 1024px) {
        display: inline-block;
      }
      @media all and (max-width: 375px) {
        padding-bottom: 0;
        margin-left: 5rem;
      }
    }

    &.item--has-submenu {
      position: relative;
      padding-right: 1.8rem;
      &::after {
        content: '';
        display: block;
        width: 1rem;
        height: .7rem;
        position: absolute;
        top: 1rem;
        right: 0;
        background: url('../img/menu-arrow-down.svg') center center no-repeat;
        background-size: cover;
      }

      &:hover .item__submenu {
        display: block;
      }
    }

    .item__link {
      color: white;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
      border-bottom: 1px solid white;

      &:hover {
        color: rgba(255, 255, 255, .7);
        border-bottom: 1px solid rgba(255, 255, 255, .7);
      }

      &.link--language {
        border: none;
        background: transparent;
        color: white;
        font-size: 1.6rem;
        line-height: 2rem;
        border-bottom: 1px solid #fff;
        cursor: pointer;
      }

      &.link--language.link--hidden {
        display: none;
      }

    }

    .item__submenu {
      display: none;
      width: 29.6rem;
      padding: .3rem 1.5rem 2.9rem 2.7rem;
      position: absolute;
      left: -3rem;
      top: 4rem;
      background: white;
      border-radius: .3rem;
      box-shadow: 0 3.5rem 5rem rgba(0, 0, 0, 0.07);
      z-index: 12;

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: -1.2rem;
        left: 4.5rem;
        border: .65rem solid transparent;
        border-bottom: .65rem solid white;

      }

      .submenu__item {
        position: relative;
        padding-right: 2rem;

        &.item--has-submenu {
          &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            top: .5rem;
            right: 1rem;
            border: .5rem solid transparent;
            border-left: .65rem solid $t-link-black;
          }

          &:hover .item__sub-submenu {
            display: block;
          }
        }

        .item__sub-submenu {
          display: none;
          width: 29.2rem;
          padding: .3rem 3.5rem 2.9rem 2.9rem;
          position: absolute;
          left: 100%;
          top: -3rem;
          background: white;
          border-radius: .3rem;
          box-shadow: 0 3.5rem 5rem rgba(0, 0, 0, 0.07);
          z-index: 3;

        }

      }

      .item__link {
        margin-top: 2.3rem;
        display: block;
        font-weight: normal;
        color: $t-link-black;
        text-decoration: underline;

        &:hover {
          color: $t-green;
        }
      }

    }

  }

}


