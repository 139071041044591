@import "../colors";

.tank-cleaning {

  &__tech-cleaning {
    margin-top: 8.2rem;

    @media all and (max-width: 992px) {
      margin-top: 5rem;
    }

    @media all and (max-width: 375px) {
      margin-top: 3.1rem;
    }

    .tank-cleaning__block-desc {
      max-width: 49.4rem;
      @media all and (max-width: 576px) {
        display: none;
      }
    }

    .tech-cleaning__tabs-block {
      margin-top: 4.7rem;
      display: flex;
      justify-content: space-between;

      @media all and (max-width: 1024px) {
        flex-direction: column;
      }
      @media all and (max-width: 576px) {
        margin-top: 2.4rem;
      }
    }

    .tabs-block {
      &__info {
        position: relative;
        max-width: 45.4rem;
        flex: 1;
        box-shadow: 0 6rem 10rem rgba(7, 32, 57, 0.09);

        @media all and (max-width: 1024px) {
          max-width: unset;
          margin: 1.5rem 2rem 0;
          padding-bottom: 3rem;
        }
        @media all and (max-width: 375px) {
          margin: 1.5rem 1rem 0;
        }

        &::before {
          content: '';
          display: block;
          width: 1.9rem;
          height: 1.9rem;
          position: absolute;
          left: -1.5rem;
          top: 50%;
          background: white;
          transform: rotate(45deg) translateY(-50%);

          @media all and (max-width: 1024px) {
            left: 2.5rem;
            top: -1rem;
            transform: rotate(45deg) translateY(0);
            background: $t-green;
          }
        }

        &-controls {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3rem 1rem;
          background: $t-green;

          @media all and (max-width: 576px) {
            padding: 1.6rem 1rem;
          }

          &-button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.4rem;
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 50%;
            font-weight: 800;
            font-size: 2.1rem;
            line-height: 3.3rem;
            color:  white;
            border: none;
            background: #13A652;
            cursor: pointer;
            transition: .2s;

            &.button--active {
              color: $t-green;
              background: white;
            }

            &:last-child {
              margin-right: unset;
            }
          }

          &-progress {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 25%;
            height: .8rem;
            background: #2DB969;

            @media all and (max-width: 1024px) {
              display: none;
            }
          }
        }
      }

      &__content {
        padding: 1rem 5rem;
        text-align: center;
        font-size: 1.8rem;
        line-height: 3rem;
        overflow-y: auto;

        @media all and (max-width: 576px) {
          padding: 1rem 3rem;
        }
        @media all and (max-width: 375px) {
          font-size: 1.6rem;
          line-height: 2.5rem;
          padding: 1.6rem 2rem 0;
        }

        &::-webkit-scrollbar-track {
          padding-right: .5rem;
          -webkit-box-shadow: inset 0 0 .4rem rgba(0,0,0,0.3);
          background-color: #eef3f8;
        }

        &::-webkit-scrollbar {
          width: .3rem;
          background-color: #eef3f8;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 .4rem rgba(0,0,0,.3);
          background-color: #C3CDD7;
        }


        &-item {
          display: none;

          &.item--current {
            display: block;
          }
        }

        &-title {
          display: inline-block;
          margin-top: 2.5rem;
          font-family: Oswald;
          font-style: normal;
          font-weight: 500;
          font-size: 2.8rem;
          line-height: 4.7rem;

          @media all and (max-width: 375px) {
            margin-top: 0;
            font-size: 2.2rem;
            line-height: 3.7rem;
          }
        }
        &-desc {
          margin-top: 2.1rem;

          @media all and (max-width: 375px) {
            margin-top: 1rem;
          }
        }
        &-adv {
          margin-top: 2.9rem;
          font-weight: 700;
          padding: 3rem 2.3rem 2.2rem;
          background: $t-bg-gray;
          border: 1px solid #D6D6D6;

          @media all and (max-width: 375px) {
            margin-top: 2rem;
            padding: 1.9rem 2rem 1.5rem;
          }
        }
      }

      &__video-wrapper {
        flex: 1;
      }

      &__video {
        width: 100%;
        height: 100%;
        padding-bottom: 56.25%;
        display: none;
        position: relative;

        &.video--current {
          display: block;
        }

        iframe, img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}

.tank-cleaning__btn-wrapper {
  text-align: center;
  margin-top: 3rem;

  @media all and (max-width: 768px) {
    margin-top: 1.6rem;
  }
}

.tank-cleaning__order-button {
  white-space: unset;
  padding-left: 3.4rem;
  padding-right: 3.4rem;
  height: 6.4rem;
  text-align: center;

  @media all and (max-width: 576px) {
    padding: 1rem;
    width: 100%;
    line-height: 2.5rem;
    max-width: 30rem;
    height: unset;
    min-height: 5.4rem;
  }
}