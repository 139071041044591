@import "colors";

.structure-grid {
	margin-top: 2.4rem;
	display: grid;
	grid-template: auto / repeat(auto-fill, minmax(24rem, 1fr));
	column-gap: 1.2rem;
	row-gap: 2.4rem;

	@media all and (max-width: 576px) {
		margin-top: 2rem;
		row-gap: 2rem;
	}
}

.border-structure {
	position: relative;

	&:after {
		position: absolute;
		content: '';
		bottom: 0;
		left: 0;
		width: 100%;
		height: .1rem;
		border-bottom: .1rem dashed rgba(209, 218, 227, .6);
	}
}

.structure-grid-gm {
	display: grid;
	grid-template: auto/ 3fr 2fr;
	grid-gap: 1.2rem;
	margin-top: 4.9rem;

	@media all and (max-width: 980px) {
		grid-template: auto/ 1fr;
	}
}

.sub-gm {
	background: #FFFFFF;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
	border-radius: 2px;

	&__pading-name {
		padding-bottom: 7rem;
		position: relative;
		padding-left: 3.2rem;

		@media all and (max-width: 980px) {
			padding-left: 2rem;
			padding-bottom: 3rem;
		}

		&:after {
			position: absolute;
			content: '';
			bottom: 0;
			left: 0;
			width: 100%;
			height: .1rem;
			border-bottom: .1rem dashed rgba(209, 218, 227, 0.6);
		}
	}

	&__padding-position {
		padding-top: 3.2rem;
		padding-left: 3.2rem;

		@media all and (max-width: 980px) {
			padding-left: 2rem;
		}
	}
}

//

.sub-gm-wrap {
	padding: 3rem 0 2.8rem 0;

	@media all and (max-width: 980px) {
		padding: 2.8rem .5rem 3.2rem 0;
	}
}

.sub-gm-name {
	font-family: "Oswald", sans-serif, Serif;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3rem;
	color: #000000;
}

.sub-gm-position {
	font-family: "Lato", sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 1.8rem;
	color: #000000;
}

.structure-item {
	display: flex;
	flex-direction: column;
	background: white;
	color: #000000;
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, .08);
	border-radius: .2rem;

	&.item--gm {

		background: #3F4C59;
		color: white;
		box-shadow: none;

		@media all and (max-width: 576px) {
			margin-top: 3.5rem;
		}

		.structure-item__department {
			height: auto;
			padding: 0rem 3.2rem 0rem;
			// border-bottom-color: rgba(255, 255, 255, 0.5);
			padding-left: 0;

			@media all and (max-width: 768px) {
				padding: 3rem 0rem 4rem;
			}

			@media all and (max-width: 640px) {
				height: 11rem;
			}

			@media all and (max-width: 520px) {
				padding: 0 2rem 3rem;
				height: auto;
			}
		}

		.structure-item__info {
			flex-direction: row;
			padding: 3rem 3.2rem;

			font-family: "Lato",sans-serif;
			font-style: normal;
			font-weight: 800;
			font-size: 18px;

			@media all and (max-width: 768px) {
				padding: 3rem 2rem;
			}

			@media all and (max-width: 640px) {
				flex-direction: column;
				padding: 2rem;
			}

			@media all and (max-width: 375px) {
				padding: 2.8rem 2rem 3.2rem;
			}
		}

		.structure-item__name {
			color: white;

			@media all and (max-width: 768px) and (min-width: 641px) {
				max-width: 9rem;
			}
		}

		.structure-item__contacts {
			margin-top: 0;
			margin-left: 9.7rem;
			color: white;

			@media all and (max-width: 768px) {
				margin-left: 0;
			}

			@media all and (max-width: 640px) {
				margin-top: 2rem;
			}
		}

		.structure-item__link {
			color: white;
		}
	}

	&.item--green {
		color: white;
		background: $t-green;
		box-shadow: none;

		.structure-item__department {
			border-bottom-color: rgba(255, 255, 255, 0.5);
		}

		.structure-item__name {
			color: white;
		}

		.structure-item__contacts,
		.structure-item__link {
			color: white;
		}

	}

	&__grid {
		display: grid;
		grid-template: auto / 24rem 1fr;

		@media all and (max-width: 520px) {
			grid-template: auto / 1fr;
		}
	}

	&__photo {
		grid-area: 1/1/3/2;
		padding: 2rem;
		border-right: .09rem dashed rgba(255, 255, 255, 0.5);

		@media all and (max-width: 520px) {
			grid-area: unset;
			border-right: none;
		}

		&-img {
			width: 100%;
			height: 21.5rem;
			object-fit: cover;
			vertical-align: top;

			@media all and (max-width: 375px) {
				height: 15.8rem;
			}
		}
	}

	&__department {
		flex: 1;
		padding: 3rem 2rem 4rem;
		font-family: "Oswald", sans-serif, Serif;
		font-weight: 500;
		font-size: 2.4rem;
		line-height: 3rem;
		// border-bottom: .09rem dashed rgba(209, 218, 227, 0.6);

		@media all and (max-width: 576px) {
			padding: 2.8rem 2rem 3rem;
		}
	}

	&__subdivisions {
		font-family: Lato, sans-serif, serif;
		font-size: 1.5rem;
		line-height: 2.2rem;

		@media all and (max-width: 520px) {
			font-size: 1.6rem;
			line-height: 2.3rem;
		}

		&-item:first-child {
			margin-top: 4rem;

			@media all and (max-width: 576px) {
				margin-top: 3rem;
			}
		}

		&-item+&-item {
			margin-top: 1rem;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		padding: 3rem 2rem;

		@media all and (max-width: 768px) {
			justify-content: space-between;
		}
	}

	&__name {
		font-weight: 800;
		font-size: 1.8rem;
		line-height: 2.9rem;
		color: $t-green;
	}

	&__contacts {
		margin-top: 3rem;
		color: rgba(0, 0, 0, .6);

		@media all and (max-width: 576px) {
			margin-top: 2rem;
		}
	}

	&__contacts-table {
		font-size: 1.5rem;
		line-height: 2rem;

		tr+tr {
			td {
				padding-top: .7rem;
			}
		}

		td+td {
			padding-left: 1rem;
		}
	}

	&__link {
		white-space: nowrap;
		color: rgba(0, 0, 0, .6);

		&:hover {
			color: inherit;
		}
	}

}