@import "colors";

.services-gallery {
  margin: 5rem 0 0;

  .slick-arrow {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    z-index: 2;

    &::before {
      content: '';
      display: block;width: 100%;
      height: 100%;
      border-radius: 50%;
      background: $t-link url('../img/arrow-next.svg') center center/auto no-repeat;
      opacity: 1;
    }

    &.slick-disabled {
      opacity: .4;
    }
  }

  .slick-prev {
    left: 4rem;
    &::before {
      transform: rotate(180deg);
    }
  }
  .slick-next {
    right: 4rem;
  }
}



.slider-gallery {

  &__item {
    text-align: center;

    @media all and (max-width: 680px) {
      margin-bottom: 2rem;
    }

    &:focus {
      outline: none;
    }

    &-photo {
      max-width: 100%;
      width: 100%;
      height: 71.5rem;
      object-fit: cover;
      vertical-align: top;
      @media all and (max-width: 1200px) {
        height: 65rem;
      }
      @media all and (max-width: 992px) {
        height: 50rem;
      }
      @media all and (max-width: 880px) {
        height: 46rem;
      }
      @media all and (max-width: 768px) {
        height: 40rem;
      }
      @media all and (max-width: 680px) {
        height: unset;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.slider-thumbnails {
  margin: 4rem 9rem 2rem;

  @media all and (max-width: 992px) {
    margin: 3rem 6rem 0;
  }
  @media all and (max-width: 680px) {
    display: none;
  }

  &__item {
    text-align: center;
    margin: 1.5rem;
    cursor: pointer;

    &:focus{
      outline: none;
    }

    &.item--current, &.slick-current {
      outline: .3rem solid $t-green;
    }

    &-photo {
      max-width: 15.5rem;
      width: 100%;
      height: 8.8rem;
      object-fit: cover;

      @media all and (max-width: 1300px) {
        max-width: unset;
      }
      @media all and (max-width: 992px) {
       height: 7rem;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .slick-prev {
    left: -9rem;

    @media all and (max-width: 992px) {
      left: -6rem;
    }
  }
  .slick-next {
    right: -9rem;

    @media all and (max-width: 992px) {
      right: -6rem;
    }

    &.slick-disabled {
      opacity: 1;
    }
  }
}


