@import "fonts";
@import "colors";

body {
  font-family: 'Lato', 'Sans Serif';
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: $text-black;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

.page-title, .block-title {
  font-family: 'Oswald', 'Sans Serif';
  font-weight: 500;
  color: $text-black;
}

.page-title {
  font-size: 5.2rem;
  line-height: 7rem;

  @media all and (max-width: 768px) {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }
}

.block-title {
  font-size: 4.6rem;
  line-height: 6.8rem;
  @media all and (max-width: 576px) {
    font-size: 3.2rem;
    line-height: 3.9rem;
  }
}


