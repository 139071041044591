@import "fonts";
@import "colors";

.geography-block {
  background: $t-bg-black;
  overflow: hidden;
  padding: 4rem 0 4.9rem;

  @media all and (max-width: 576px) {
    padding: 2rem 0 1.9rem;
  }

  .block-title {
    @media all and (max-width: 375px) {
      font-size: 2.6rem;
      line-height: 3.5rem;
    }
  }

  &.block--geography-tc {
    .geography-block__geography-map {
      padding: 0 0 9rem;

      @media all and (max-width: 576px) {
        padding: 0 0 8rem;
      }
    }
    .geography-map__info {
      width: 100%;
      display: block;
      align-items: unset;
      justify-content: unset;
      text-align: center;
      padding-top: 8.2rem ;

      @media all and (max-width: 480px) {
        padding-top: 4rem;
      }

      .info__title {
        @media all and (max-width: 375px) {
          font-weight: 500;
          font-size: 2.6rem;
          line-height: 3.5rem;
        }
      }
    }
  }

  .geography-block__geography-map {
    position: relative;
    @media all and (max-width: 992px) {
      padding-top: 11.7rem;
    }
  }

  .geography-map__img {
    display: block;
    @media all and (max-width: 768px) {
      display: none;
    }
  }
  .geography-map__mobile-img {
    display: none;
    @media all and (max-width: 768px) {
      display: block;
      margin-bottom: 2.4rem;
    }
    @media all and (max-width: 576px) {
      width: calc(100% + 6rem);
      margin-left: -3rem;
      margin-bottom: 1.4rem;
    }
  }

 .geography-map__info {
   position: absolute;
   top: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 4.5rem 0 6.9rem;
   height: 100%;
   width: 100%;
   @media all and (max-width: 576px) {
     padding: 2.1rem 0 2.4rem;
   }

   .info__title {
     color: white;

     &.title--center {
       text-align: center;
     }
   }
   .info__desc {
     font-size: 1.6rem;
     line-height: 2.4rem;
     color: white;
     width: 16.2rem;

     @media all and (max-width: 576px) {
       display: none;
     }

     &-euro {
       font-weight: 700;
     }
   }
  }
}

