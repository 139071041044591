
.tanc-cleaning__building-finish {
  padding: 9.2rem 0 11rem;
  background: #f7f7f7;

  @media all and (max-width: 992px) {
    padding: 7rem 0 8rem;
  }
  @media all and (max-width: 768px) {
    padding: 5rem 0 6rem;
  }

  @media all and (max-width: 576px) {
    padding: 3.4rem 0 2rem;
  }

  .building-finish {
    margin-top: 5.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media all and (max-width: 1160px) {
      max-width: 80rem;
      margin: 4.5rem auto 0;
    }

    @media all and (max-width: 576px) {
      margin-top: 3rem;
      padding: 0 2rem;
    }

    &__item {
      position: relative;
      width: 24.5rem;
      padding-top: 9rem;
      font-weight: 800;
      font-size: 2.4rem;
      line-height: 3rem;

      @media all and (max-width: 1160px) {
        width: 48%;
        margin-bottom: 2.5rem;
        text-align: center;
      }

      @media all and (max-width: 576px) {
        width: 100%;
        font-size: 2rem;
        line-height: 3rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 7.4rem;
        height: 7.4rem;
        border-radius: 50%;
        background: white url("../img/check-icon-black.svg") center center/auto no-repeat;
        box-shadow: 0 0.8rem 1.5rem rgba(31,31,31,.15);

        @media all and (max-width: 1160px) {
          left: 50%;
          transform:  translateX(-50%);
        }
      }
    }
  }
}