@import "../colors";

.tank-cleaning__order-complex {
  padding: 6.6rem 0 8rem;
  background: #f7f7f7;

  @media all and (max-width: 992px) {
    padding: 4rem 0 5rem;
  }

  @media all and (max-width: 576px) {
    padding: 3rem 0;
  }

  @media all and (max-width: 375px) {
    padding: 1.2rem 0 2rem;
  }

  .tank-cleaning__order-complex-inner {
    padding: 6rem 7.4rem;
    background: white url("../img/bg-order-complex.jpg") right top/contain no-repeat;
    height: 41rem;

    @media all and (max-width: 1360px) {
      padding: 4rem 4rem 5rem;
      height: 36rem;
    }

    @media all and (max-width: 1200px) {
      height: auto;
      background: white url("../img/bg-order-complex-m.jpg") right top/40% no-repeat;
    }

    @media all and (max-width: 768px) {
      border-radius: .4rem;
      background: white url("../img/bg-order-complex-m.jpg") right top/300px no-repeat;
    }

    @media all and (max-width: 576px) {
      padding: 2.6rem 3rem 3.5rem;
    }

    @media all and (max-width: 375px) {
      background: white url("../img/bg-order-complex-m.jpg") right top/250px no-repeat;
    }
  }

  .tank-cleaning__block-title {
    text-align: left;

    @media all and (max-width: 1200px) {
      max-width: 31rem;
    }
    @media all and (max-width: 576px) {
      max-width: 16rem;
    }
  }

  .tank-cleaning__order-form {
    max-width: 68rem;
    margin-left: 0;

    @media all and (max-width: 1200px) {
      max-width: 49rem;
    }
    @media all and (max-width: 768px) {
      margin-top: 11rem;
    }

    @media all and (max-width: 720px) {
      max-width: 40rem;
    }

    .form__input-wrapper.wrapper--checkbox {
      width: 25rem;
      margin-top: 0;
      margin-left: .6rem;
      text-align: left;

      @media all and (max-width: 720px) {
        width: 100%;
      }

      .form__error-msg {
        &--privacy {
          top: unset;
          left: -.5rem;
          bottom: -1.6rem;
          transform: translate(0, 100%);

          &::before {
            right: unset;
            left: 1rem;
            top: 0;
          }
        }
      }
    }
  }

  .form__input-inline.input-inline--complex {
    margin-top: .6rem;
    grid-template: auto / 23.7rem 1fr;
    align-items: center;

    @media all and (max-width: 720px) {
      grid-template: auto / 1fr;
    }

    .form__label.label--checkbox {
      &:before {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

}