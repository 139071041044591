
.tank-cleaning__process-masonry {
  padding: 6.5rem 0 9rem;
  background: #f7f7f7;

  @media all and (max-width: 768px) {
    padding: 4rem 0 5rem;
  }

  @media all and (max-width: 576px) {
    padding: 3rem 0 4rem;
  }

  .tank-cleaning__block-title {
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 90rem;
  }

  .tank-cleaning__masonry-desc {
    margin-top: 3rem;
    font-size: 2.2rem;
    line-height: 3.5rem;
    text-align: center;

    @media all and (max-width: 768px) {
      font-size: 2rem;
      line-height: 3rem;
    }

    @media all and (max-width: 576px) {
      margin-top: 1.6rem;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }

  .tank-cleaning__masonry-title {
    margin-top: 8rem;
    font-weight: 800;
    font-size: 4rem;
    line-height: 4.8rem;
    text-align: center;

    @media all and (max-width: 768px) {
      margin-top: 3.4rem;
      font-size: 2.4rem;
      line-height: 3rem;
    }
  }
}

.tank-cleaning__masonry-grid {
  margin-top: 4.5rem;
  column-count: 2;
  column-gap: 3rem;
  column-fill: balance;
  column-fill: balance;
  -moz-column-fill: balance;
  -webkit-column-fill: balance;

  @media all and (max-width: 720px) {
    column-count: 1;
  }
  @media all and (max-width: 576px) {
    margin-top: 2.2rem;
  }

  &-item {
    display: inline-block;
    width: 100%;
    padding: 3.2rem 4.1rem 3.2rem 4.4rem;
    font-size: 2.2rem;
    line-height: 3rem;
    background: white;
    box-shadow: 0 3rem 2rem -2rem rgba(31,31,31,.09);
    margin-bottom: 1.6rem;

    @media all and (max-width: 768px) {
      padding: 1.7rem 2rem 1.8rem;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }

    @media all and (max-width: 720px) {
      margin-bottom: 1rem;
    }

  }
}