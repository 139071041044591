.news-gallery-slider {
	max-width: calc(100% - 10rem);
	margin: 5.8rem auto 0;

	@media all and (max-width: 992px) {
		max-width: 100%;
		margin-top: 4rem;
	}

	@media all and (max-width: 576px) {
		max-width: 100%;
		margin-top: 2rem;
	}

	&__item {
		padding: 0 1rem;
		border-radius: .5rem;

		@media all and (max-width: 768px) {
			padding: 0 1rem;
		}

		&:focus {
			outline: none;
		}
	}

	&__item-img {
		vertical-align: top;
		width: 100%;
		height: 20rem;
		object-fit: cover;
		border-radius: .5rem;

		@media all and (max-width: 1160px) {
			height: 20rem;
		}

		&:focus {
			outline: none;
		}
	}

	&__item-zoom-link {
		&:focus {
			outline: none;
		}
	}

	.slick-prev, .slick-next {
		width: 4.4rem;
		height: 6.4rem;
		transform: translate(0, -50%);
		z-index: 2;

		@media all and (max-width: 576px) {
			height: 5.4rem;
		}

		&:hover {
			&::before {
				background: #309C5F url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;;
			}
		}

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: #00923f url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;
			border-radius: .3rem;
			opacity: 1;
		}

		&.slick-disabled {
			&::before {
				background: #309C5F url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;
			}
		}
	}

	.slick-prev {
		left: -5.8rem;

		@media all and (max-width: 992px) {
			left: 0;
		}

		&::before {
			transform: rotate(180deg);
		}
	}

	.slick-next {
		right: -5.8rem;
		@media all and (max-width: 992px) {
			right: 0;
		}
	}

}