@import "colors";

.wrapper--vendors-page {
  background: $t-bg-gray;
}

.content-block {
  &.block--single-page.block--vendors {
    padding: 1rem 0 7.4rem;

    @media all and (max-width: 768px) {
      padding-bottom: 5rem;
    }

    @media all and (max-width: 576px) {
      padding-bottom: 3.4rem;
    }
  }
}

.vendors-content__desc {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.vendors-content__request-list {
  margin-top: 2.6rem;

  @media all and (max-width: 576px) {
    margin-top: 1.8rem;
  }

  .request-list__item {
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-radius: .3rem;
    box-shadow: 0 .1rem .1rem rgba(47, 47, 47, 0.16);

    @media all and (max-width: 480px) {
      flex-direction: column;
    }
    @media all and (max-width: 375px) {
      width: calc(100% + 2rem);
      margin-left: -1rem;
    }

    .item__position-name {
      flex: 1;
      padding: 3.3rem 3.8rem;
      border-right: 1px dashed #d4d4d4;

      @media all and (max-width: 992px) {
        padding: 2rem 2.6rem;
      }
      @media all and (max-width: 480px) {
        width: 100%;
        padding: 1.8rem 2rem;
        border-right: none;
        border-bottom: 1px dashed #d4d4d4;
      }
    }
    .item__position-quantity {
      flex-basis: 23rem;
      display: flex;
      align-items: center;
      padding-left: 4.2rem;

      @media all and (max-width: 1200px) {
        flex-basis: 14rem;
        padding-left: 3rem;
      }
      @media all and (max-width: 480px) {
        flex-basis: unset;
        width: 100%;
        padding: 1.7rem 2rem;
        justify-content: flex-start;
        font-weight: 600;
        color: $t-green;
      }

    }
  }
}
