@import "colors";

.wrapper--reviews-page {
  .content-block {

    &.block--reviews {
      padding-bottom: 7.1rem;

      @media all and (max-width: 576px) {
        padding-bottom: 3.1rem;
      }
    }
  }

  .pagination-block {
    @media all and (max-width: 576px) {
      margin-top: 3rem;
    }
  }
}

.content-reviews__reviews-list {
  margin-top: 2.7rem;
  border-right: 1px solid #EBEDEF;

  @media all and (max-width: 768px) {
    margin-top: 0;
    border: none;
  }

  .reviews-list__item {
    width: calc(100% + 4rem);
    margin-left: -4rem;
    display: flex;
    justify-content: space-between;
    padding: 3.9rem 5.3rem 5.3rem 4rem;
    border-bottom: 1px solid #EBEDEF;

    @media all and (max-width: 1310px) {
      padding-right: 2rem;
    }

    @media all and (max-width: 992px) {
      flex-direction: column;
    }

    @media all and (max-width: 768px) {
      padding: 2.1rem 2rem 2.3rem;
      margin-left: -2rem;
    }
    @media all and (max-width: 576px) {
    }

    &:first-of-type {
      padding-top: 2rem;

      @media all and (max-width: 576px) {
        padding-top: 0;
      }
    }

    .item__review-title {
      flex-basis: 33.8rem;
      margin-right: 6rem;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3.7rem;

      @media all and (max-width: 1310px) {
        font-size: 2rem;
        line-height: 2.6rem;
        flex-basis: 35%;
      }

      @media all and (max-width: 992px) {
        flex-basis: unset;
        margin-right: unset;
      }
    }

    .item__review-link {
      transition: .15s;
      &:hover {
        color: $t-green;

        .underline {
          border-bottom: 1px solid $t-green;
        }
      }
    }

    .item__review-info {
      flex: 1;
      max-width: 44.9rem;
      font-size: 1.6rem;
      line-height: 2.8rem;

      @media all and (max-width: 992px) {
        max-width: unset;
        margin-top: 2rem;
        padding-top: 4rem;
      }

      .content__widget.widget--document {
        margin-top: 1.7rem;

        @media all and (max-width: 576px) {
          margin-top: 1.4rem;
        }

        .info-document__title {
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2.8rem;

          @media all and (max-width: 480px) {
            line-height: 2.6rem;
          }
        }
      }
    }

    .item__review-text {
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 3.3rem;
        height: 2.2rem;
        background: url('../img/quotes.svg') center center/auto no-repeat;
        position: absolute;
        top: 0;
        left: -5.4rem;

        @media all and (max-width: 992px) {
          top: -4rem;
          left: 0;
        }
      }
    }

    .item__review-author {
      margin-top: 1.8rem;

      @media all and (max-width: 576px) {
        margin-top: 1.4rem;
      }

      &-name {
        font-weight: 900;
      }
    }
  }


}

.content-reviews {

  .content-reviews__sidebar-content {
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 768px) {
      margin-top: 2.4rem;
    }

    @media all and (max-width: 576px) {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
  .content-reviews__meta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .content-reviews__date {
    margin-right: 2rem;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: $text-black;
    opacity: .5;
  }
}

.content-reviews__article {
  margin-top: 3.6rem;

  @media all and (max-width: 576px) {
    margin-top: 5.3rem;
  }

  p {
    margin-bottom: 2.7rem;
   &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    font-weight: 900;
    font-size: 3.2rem;
    line-height: 4.5rem;
    margin-bottom: 2.4rem;

     @media all and (max-width: 576px) {
      font-size: 2.4rem;
      line-height: 3.4rem;
    }
  }
  .content-reviews__author {
    font-weight: 900;
  }

  .content-reviews__photo {
    margin: 3.1rem 0 4.5rem;

    @media all and (max-width: 576px) {
      margin: 1.5rem 0 3rem;
    }

    .photo__img-wrapper {
      position: relative;
      @media all and (max-width: 375px) {
        width: calc(100% + 2rem);
        margin-left: -1rem;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
      }
    }

    .photo__gallery-link {
      font-weight: 900;
      font-size: 1.8rem;
      line-height: 3.1rem;
      text-transform: uppercase;
      color: white;
      position: absolute;
      right: 2.2rem;
      bottom: .8rem;
      z-index: 2;

      &::before {
        content: '';
        display: block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: calc(50% - 1.2rem);
        left: - 3rem;
        background: url('../img/gallery-icon.svg') center center/auto no-repeat;
      }
    }

    .photo__img {
      vertical-align: top;
      width: 100%;
    }
    .photo__caption {
      margin-top: 1.45rem;
      max-width: 44rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.5rem;
      color: $t-text-gray;

      @media all and (max-width: 576px) {
        margin-top: 1.1rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }

  }
}


