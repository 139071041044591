@import "colors";

.additional-info {
  padding: 4.3rem 0 5.4rem;
  background: #00923F;
  color: white;

  @media all and (max-width: 576px) {
    padding: 1.9rem 0 3.1rem;
  }


  &__content {
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__text-info {
    max-width: 69.2rem;
    margin-right: 2rem;
    @media all and (max-width: 576px) {
      margin-right: unset;
    }
  }

  &__title {
    display: inline-block;
    font-family: 'Oswald', 'Sans Serif';
    font-weight: 500;
    font-size: 3.8rem;
    line-height: 6.6rem;

    @media all and (max-width: 768px) {
      font-size: 2.8rem;
      line-height: 3.5rem;
    }
  }
  &__desc {
    margin-top: 1.6rem;
    font-size: 1.8rem;
    line-height: 3rem;

    @media all and (max-width: 768px) {
      margin-top: 2.2rem;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }

  &__consult-button {
    position: relative;
    margin-top: 3.8rem;
    padding: 0 4.6rem 0 2rem;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 3rem;
    color: $t-green;
    box-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.1);

    &::after {
      content: '';
      display: block;
      width: 1rem;
      height: 1.5rem;
      position: absolute;
      top: calc(50% - .75rem);
      right: 2rem;
      background: url('../img/arrow-add-info.svg') center center/contain no-repeat;
    }
  }

  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 4.6rem 0 2rem;
    background: white;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 3rem;
    color: $t-green;
    height: 5.6rem;
    margin-top: 3.8rem;
    border-radius: .3rem;
    box-shadow: 0 .4rem .4rem rgba(0, 0, 0, 0.1);

    &:hover {
      color: $t-green;
    }

    @media all and (max-width: 768px) {
      margin-top: 2.5rem;
    }

    &.link-career {
      width: 16.7rem;
      color: #007D36;
    }

    &::after {
      content: '';
      display: block;
      width: 1rem;
      height: 1.5rem;
      position: absolute;
      top: calc(50% - .75rem);
      right: 2rem;
      background: url('../img/arrow-add-info.svg') center center/contain no-repeat;
    }
  }

  &__buttons-block {
    display: flex;
    align-items: flex-end;

    @media all and (max-width: 992px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__resume-block {
    display: flex;
    margin-left: 2rem;
    align-items: center;

    @media all and (max-width: 992px) {
      margin-top: 1.8rem;
      margin-left: 0;
   }

    @media all and (max-width: 640px) {
      flex-direction: column;
    }
  }

  &__resume-button {

    &.button {
      height: 5.6rem;
      border: .2rem solid white;
      background: transparent;
      font-weight: 600;
      color: white;
      width: 21.2rem;
    }
  }

  &__resume-formats {
    display: inline;
    margin: 0 0 0 2rem;
    width: 18rem;

    @media all and (max-width: 640px) {
      margin: 1rem 0 0 0;
      width: 19rem;
    }
  }

  &__contacts {
    width: 28.8rem;

    @media all and (max-width: 576px) {
      margin-top: 2.5rem;
    }

    .contacts__phone-link {
      display: block;
      font-family: 'Oswald', 'Sans Serif';
      font-weight: 500;
      font-size: 2.8rem;
      line-height: 4.9rem;

      @media all and (max-width: 768px) {
        font-size: 2rem;
        line-height: 3.5rem;
      }
    }

    .contacts__text {
      max-width: 22rem;
      margin-top: .7em;
      line-height: 2.8rem;

      @media all and (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
      @media all and (max-width: 576px) {
        display: none;
      }
    }
  }

  &__subscribe {
    margin-top: 4rem;

    @media all and (max-width: 420px) {
      margin-top: 3rem;
    }

    .subscribe__form {
      max-width: 56.5rem;

      .form__input {
        padding: 1.4rem 16rem 1.4rem 3rem;
        background: rgba(8, 183, 84, .7);
        font-family: 'Lato', 'Sans Serif';
        color: white;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 3rem;
        height: 5.5rem;
        border: none;

        @media all and (max-width: 420px) {
          width: 100%;
          margin-left: 0;
          padding: 1.4rem 2rem 1.4rem;
        }

        &::placeholder {
          color: $text-transparent;
          opacity: 1;
        }
      }

      .form__button-subscribe {
        width: 15rem;
        height: 5.5rem;
        background: white;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 3rem;
        color: $t-green;
        border-radius: .3rem;

        @media all and (max-width: 420px) {
          position: relative;
          margin-top: 1rem
        }
      }
    }
  }

}
