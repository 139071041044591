@import "../colors";

.projects-table {
  padding: 0 0 12.5rem;
  background: #f7f7f7;

  @media all and (max-width: 992px) {
    padding: 0 0 8rem;
  }

  @media all and (max-width: 576px) {
    padding: 4.5rem 0 5rem;
  }

  @media all and (max-width: 576px) {
    padding: 4.5rem 0 3.7rem;
  }

  &__tab-panel {
    margin-top: 2.9rem;
    display: flex;
    justify-content: center;

    @media all and (max-width: 576px) {
      margin-top: 1.6rem;
    }
  }

  &__tab-switcher + &__tab-switcher {
    margin-left: 3.3rem;
    @media all and (max-width: 576px) {
      margin-left: 2.5rem;
    }
  }

  &__tab-switcher {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 3rem;
    cursor: pointer;

    @media all and (max-width: 576px) {
      font-size: 1.6rem;
      line-height: 2.7rem;
    }

    &.switcher--active {
      color: $t-green;
      border-bottom: .2rem solid $t-green;
    }
  }

  &__wrapper {
    position: relative;
    margin-top: 3.5rem;
    width: 100%;
    max-height: 62.6rem;
    overflow: auto;
    border: .1rem solid #DFDFDF;
    background: white;

    @media all and (max-width: 768px) {
      max-height: 54rem;
    }

    @media all and (max-width: 576px) {
      margin-top: 2.5rem;
      max-height: 44.7rem;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 .4rem rgba(0,0,0,0.0);
      background-color: transparent;
      }

    &::-webkit-scrollbar {
      width: .7rem;
      height: .7rem;
      background-color: #F7F7F7;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      -webkit-box-shadow: inset 0 0 .4rem rgba(0,0,0,.0);
      width: .7rem;
      background-color: #A9A9A9;
    }


    &.table--lock {
      overflow: hidden;

      &:hover {
        .projects-table__overlay {
          display: block;
        }
      }

      .projects-table__table {
        margin-top: -5.6rem;
      }

      .projects-table__unlock-btn {
        display: block;
      }
    }
  }

  &__table {
    display: none;
    position: relative;
    background: white;
    font-size: 1.6rem;
    line-height: 2.5rem;

    @media all and (max-width: 576px) {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    &.table--active {
      display: block;
    }
  }

  &__overlay {
    display: none;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background: rgba(0, 0, 0, .1);
    z-index: 1;
  }

  &__row {
    display: flex;

    &:last-child {
      .projects-table__cell {
        border-bottom: none;
      }
    }

    &.row--header {
      font-weight: 700;
    }
  }

  &__cell {
    padding: 1.8rem 4.5rem 1.8rem 2rem;
    border-right: .1rem solid #DFDFDF;
    border-bottom: .1rem solid #DFDFDF;

    @media all and (max-width: 992px) {
      padding: 1.8rem 2rem;
    }

    @media all and (max-width: 576px) {
      padding: 1.1rem 1.5rem;
    }

    &:last-child {
      border-right: none;
    }

    &.cell--counter {
      padding: 1.8rem 2rem;
      flex: 0 0 8.9rem;

      @media all and (max-width: 576px) {
        padding: 1.1rem 1.3rem;
        flex: 0 0 7rem;
      }
    }
    &.cell--object {
      flex: 0 0 30.3rem;

      @media all and (max-width: 576px) {
        flex: 0 0 24rem;
      }
    }
    &.cell--work {
      flex: 0 0 39.4rem;
    }
    &.cell--customer {
      flex: 0 0 26rem;
    }
    &.cell--period {
      flex: 0 0 20.1rem;
    }

    p + p {
      margin-top: 1.5rem;

      @media all and (max-width: 576px) {
        margin-top: 1.1rem;
      }
    }
  }

  &__unlock-btn {
    display: none;
    position: sticky;
    top: calc(50% - 2.8rem);
    left: calc(50% - 11.7rem);
    padding: 1rem 3.9rem;
    z-index: 2;

    @media all and (max-width: 576px) {
      font-size: 1.6rem;
      line-height: 2.5rem;
      padding: 1rem 2.5rem;
      left: calc(50% - 9.5rem);
    }

    &.button--invert {
      background: #747474;
      &:hover {
        background: #474747;
      }
    }
  }
}