@import "fonts";
@import "colors";

.wrapper--projects-page {
  background: $t-bg-gray;
}

.our-projects-block {
  position: relative;
  padding: 7.9rem 0 2rem;
  overflow: hidden;

  @media all and (max-width: 768px) {
    padding: 2.2rem 0 2rem;
  }
  @media all and (max-width: 576px) {
    padding: 2.2rem 0 2rem;
  }

  .our-projects-block__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .our-projects-block__all-projects-link {
      color: $text-black;
      font-weight: 700;
      @media all and (max-width: 767px) {
        display: none;
      }
    }
  }

  .our-projects-block__title {
    position: relative;
    z-index: 2;
  }
}


.our-projects-block__sort-projects {
  position: relative;
  z-index: 2;
}

.sort-projects__tag-panel {
  margin-top: 2.4rem;

  @media all and (max-width: 992px) {
    max-width: 83rem;
    display: flex;
    justify-content: space-between;
  }

  @media all and (max-width: 767px) {
    display: none;
  }

  .tag-panel__tag-projects {
    display: inline-flex;
    align-items: center;

    height: 3.5rem;
    padding: 0 1.3rem 0 1.1rem;
    background: #D1D1D1;
    margin-right: 1.3rem;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    border: none;
    color: $text-black;
    cursor: pointer;
    transition: .2s;

    @media all and (max-width: 992px) {
      font-size: 1.2rem;
      margin-right: 0;
    }

    &:hover {
      background: #C1C1C1;
    }

    &:disabled {
      color: rgba(55, 55, 55, .4);
      &:hover {
        background: #D1D1D1;
      }
    }

    &.tag-projects--active {
      color: white;
      background: #141414;
    }
  }
}

.sort-projects__slider-projects {
  margin-top: 1rem;

  .slick-prev, .slick-next {
    width: 5.6rem;
    height: 5.6rem;
    top: 17rem;
    transition: .2s;
    z-index: 2;

    &:hover {
      &:before {
        background: #DEDEDE url('../img/slider-arrow.svg') center center no-repeat;
      }
    }

    &::before {
      content: '';
      display: block;
      width: 5.6rem;
      height: 5.6rem;
      background: #E8E8E8 url('../img/slider-arrow.svg') center center no-repeat;
      border-radius: 50%;
      opacity: 1;
    }

    &.slick-disabled {
      opacity: 0;
    }
  }

  .slick-prev {
    left: -8.6rem;
    @media all and (max-width: 1440px) {
      left: -2.8rem;
    }

    &:before {
      transform: rotate(180deg);
    }
  }
  .slick-next {
    right: -8.6rem;
    @media all and (max-width: 1440px) {
      right: -2.8rem;
    }
  }

  .slick-list {

    @media all and (max-width: 1366px) {
      margin-left: 0;
    }
    @media all and (max-width: 1024px) {
      margin-right: 0;
      margin-left: -1.5rem;
    }
    @media all and (max-width: 767px) {
      margin-top: 3.2rem;
    }
    @media all and (max-width: 640px) {
      margin-left: 0;
    }
    @media all and (max-width: 576px) {
      width: calc(100% + 4rem);
      margin-left: -2rem;
    }


  }

  .slick-slide {
    &:focus {
      outline: none;
    }
  }

  .slider-projects__slick-slide {
    width: 29rem;
    margin: 0 1.5rem;
    font-weight: 700;
    color: $text-black;

    @media all and (max-width: 1024px) {
      width: unset;
      margin-right: 0;
    }
    @media all and (max-width: 640px) {
      width: unset;
      margin: 0;
    }

    *:focus {
      outline: none;
    }


    .slick-slide__img {
      width: 100%;
      height: 34rem;
      object-fit: cover;
      outline-offset: -1rem;
      transition: .25s;

      @media all and (max-width: 992px) {
        height: 30rem;
      }
      @media all and (max-width: 375px) {
        height: 21.2rem;
      }
    }


    .slick-slide__meta {
      display: flex;
      margin-top: 2.2rem;
      align-items: center;

      @media all and (max-width: 576px) {
       padding: 0 2rem;
      }
      .meta__date-project {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #84888D;
      }

      .meta__tag-project {
        margin-right: 2.2rem;
        display: inline-block;
        padding: .8rem 1.2rem;
        background: $t-green;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: white;
      }

    }

    .slick-slide__title {
      font-weight: 700;
      margin-top: 2.1rem;
      font-size: 2.2rem;
      line-height: 2.9rem;

      @media all and (max-width: 640px) {
        font-size: 2.4rem;
      }
      @media all and (max-width: 576px) {
       padding: 0 2rem;
      }

      .title_link  {
        transition: .15s;
        &:hover {
          color: $t-green;
        }
      }

    }


    &.last-project {
      width: 61rem;

      @media all and (max-width: 1024px) {
        width: unset;
      }

      .slick-slide__title {
        margin-top: 1.8rem;
        font-size: 3.4rem;
        line-height: 4.1rem;

         @media all and (max-width: 1024px) {
          margin-top: 2.1rem;
          font-size: 2.2rem;
          line-height: 2.9rem;
        }
        @media all and (max-width: 640px) {
          font-size: 2.4rem;
        }
      }
    }

  }

  .slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    height: .3rem;
    background: rgba(57, 77, 100, .6);
    border-radius: 2rem;
    transition: .25s;

    @media all and (max-width: 768px) {
      bottom: -5rem;
      background: transparent;
      height: 1rem;
    }
    @media all and (max-width: 576px) {
      bottom: -4.7rem;
    }

    li {
      display: block;
      width: 30rem;
      height: 100%;
      margin: 0;

      @media all and (max-width: 768px) {
        width: 1rem;
        height: 1rem;
        margin: 0 .5rem;
      }

      button {
        width: 100%;
        height: 100%;
        padding: 0;
        transition: .25s;
        border-radius: 2rem;

        &::before {
          display: none;
        }
        @media all and (max-width: 768px) {
          height: 1rem;
          background: #6C7381;
          border-radius: 50%;
        }
      }
    }
    .slick-active {
      button {
        background: #5A6F88;
        @media all and (max-width: 768px) {
          background: $t-green;
        }
      }
    }
  }
}
.our-projects-block__link-wrapper {
  margin-top: 10rem;
  text-align: center;
  display: none;

  @media all and (max-width: 767px){
    display: block;
  }
}

.our-projects-block__all-projects-link {
  display: inline-block;
  position: relative;
  border-radius: 0;
  color: $text-black;
  padding-right: 6.2rem;

  &:hover {
    color: $text-black;
    &::after {
      box-shadow: 0 0 0 .8rem rgba(0, 160, 69, .5);
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 2.3rem);
    right: 0;
    display: block;
    width: 4.6rem;
    height: 4.6rem;
    background: #00A045 url('../img/arrow-next.svg') center center no-repeat;
    background-size: auto;
    border-radius: 50%;
    box-shadow: 0 0 0 .5rem rgba(0, 160, 69, .5);
    cursor: pointer;
    transition: .25s;
  }

  &:focus {
    outline: none;
  }

}


.content-section__projects-article {

  .projects-article__sidebar-content {
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 768px) {
      margin-top: 2.4rem;
    }

    @media all and (max-width: 576px) {
      justify-content: flex-start;
      flex-direction: column;
    }

    .sidebar__widget {
      max-width: 28.8rem;
      margin-right: 2rem;
      margin-bottom: 3rem;

      .project-info__text {
        margin-bottom: 0;
      }
    }

  }
  .projects-article__meta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .projects-article__date {
    margin-right: 2rem;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: $text-black;
    opacity: .5;
  }

  .projects-article__text {
    margin-top: 3.7rem;
    @media all and (max-width: 576px) {
      margin-top: 2.7rem;
    }

    p {
      margin-bottom: 2.7rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
    h3 {
      font-weight: 900;
      font-size: 3.2rem;
      line-height: 4.5rem;
      margin-bottom: 2.4rem;

      @media all and (max-width: 576px) {
        font-size: 2.4rem;
        line-height: 3.4rem;
      }
    }
  }

  .projects-article__photo {
    margin: 3.1rem 0 4.5rem;

    @media all and (max-width: 576px) {
      margin: 1.5rem 0 3rem;
    }

    .photo__img-wrapper {
      position: relative;

      @media all and (max-width: 375px) {
        width: calc(100% + 2rem);
        margin-left: -1rem;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
      }
    }

    .photo__gallery-link {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      font-size: 0;
      opacity: 0;
      z-index: 4;

      &.link--launcher {
        display: block;
      }


    }

    .photo__gallery-rem {
      font-weight: 900;
      font-size: 1.8rem;
      line-height: 3.1rem;
      text-transform: uppercase;
      color: white;
      position: absolute;
      right: 2.2rem;
      bottom: .8rem;
      z-index: 3;

      &::before {
        content: '';
        display: block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: calc(50% - 1.2rem);
        left: - 3rem;
        background: url('../img/gallery-icon.svg') center center/auto no-repeat;
      }
    }

    .photo__img {
      display: none;
      vertical-align: top;
      width: 100%;

      &.img--launcher {
        display: unset;
      }
    }
    .photo__caption {
      margin-top: 1.45rem;
      max-width: 44rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.5rem;
      color: $t-text-gray;

      @media all and (max-width: 576px) {
        margin-top: 1.1rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }

  }
}


