.tank-diagnostic {
  padding: 9.8rem 0 6.5rem;
  background: #f7f7f7;

  @media all and (max-width: 992px) {
    padding: 5rem 0 4rem;
  }

  @media all and (max-width: 576px) {
    padding: 4.7rem 0 3rem;
  }

  &__desc-wrapper {
    max-width: 86.2rem;
    margin: 0 auto;
    font-size: 2.2rem;
    line-height: 3.5rem;
    text-align: center;

    @media all and (max-width: 768px) {
      font-size: 2rem;
      line-height: 3rem;
    }

    @media all and (max-width: 576px) {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }

  &__desc + &__desc {
    margin-top: 2.5rem;

    @media all and (max-width: 576px) {
      margin-top: 2rem;
    }
  }

  &__title {
    margin-top: 8.9rem;

    @media all and (max-width: 992px) {
      margin-top: 7rem;
    }

    @media all and (max-width: 576px) {
      margin-top: 5.9rem;
    }
  }

  &__spec-list {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media all and (max-width: 768px) {

    }
  }

  &__spec-item {
    position: relative;
    margin-top: 5.3rem;
    padding: 0 2rem 0 11.8rem;
    display: flex;
    align-items: center;
    width: 41.5rem;
    font-size: 1.8rem;
    line-height: 3rem;

    @media all and (max-width: 900px) {
      width: 50%;
      margin: 4rem 0 0;
    }

    @media all and (max-width: 640px) {
      margin-top: 2.9rem;
      width: 100%;
      padding: 9.1rem 2rem 0;
      justify-content: center;
      text-align: center;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 2rem;
      transform: translateY(-50%);
      display: block;
      width: 7.4rem;
      height: 7.4rem;
      border-radius: 50%;
      background: white url('../img/check-icon-black.svg') center center/auto no-repeat;
      box-shadow: 0 .8rem 1.5rem rgba(31, 31, 31, 0.15);

      @media all and (max-width: 640px) {
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}