@import "../colors";

.tank-cleaning__expirience {
  padding: 8.5rem 0 7rem;

  @media all and (max-width: 992px) {
    padding: 6rem 0;
  }

  @media all and (max-width: 576px) {
    padding: 4rem 0;
  }


  .tank-cleaning__block-title {
    max-width: 30rem;
    text-align: left;
  }

  &-header {
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 992px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    @media all and (max-width: 576px) {
      padding: 0 2rem;
    }
  }

  &-desc {
    flex: 1;
    max-width: 70rem;
    @media all and (max-width: 992px) {
      margin-top: 1.7rem;
      max-width: unset;
    }
  }
}

.expirience-slider {
  margin-top: 5rem;
  padding: 5.2rem 7.4rem;
  background: #f7f7f7;
  border: 1px solid #CFCFCF;

  @media all and (max-width: 992px) {
    margin-top: 3.6rem;
    padding: 3rem 5rem;
  }

  @media all and (max-width: 576px) {
    margin-top: 2.7rem;
    padding: 2.3rem 2rem 3rem;
  }

  &__slide {
    display: none;
    grid-template: 10.4rem 1fr / 40rem 1fr;
    column-gap: 2.7rem;
    row-gap: 5.6rem;

    @media all and (max-width: 1160px) {
      grid-template: 10.4rem 1fr / 32rem 1fr;
    }

    @media all and (max-width: 992px) {
      grid-template: 10.4rem 1fr / 25rem 1fr;
      column-gap: 2rem;
      row-gap: 2.6rem;
    }

    @media all and (max-width: 920px) {
      grid-template: auto / 1fr;
      column-gap: 2rem;
      row-gap: 2.6rem;
    }

    &.slide--active {
      display: grid;
    }
  }

  &__customer {
    max-width: 30rem;
  }

  &__customer-name {
    margin-top: 1rem;
    display: block;
    font-weight: 900;
    font-size: 2.4rem;
    line-height: 3rem;

    @media all and (max-width: 992px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    @media all and (max-width: 576px) {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }

  &__content {
    grid-area: 1 / 2 /3 /3;

    @media all and (max-width: 920px) {
      grid-area: unset;
    }
  }

  &__content-list {
    margin-bottom: 3rem;

    @media all and (max-width: 920px) {
      margin-bottom: 1.5rem;
    }
  }

  &__content-text {
    p {
      margin-bottom: 1.4rem;

      @media all and (max-width: 920px) {
        margin-bottom: 1rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__nav {
    @media all and (max-width: 920px) {
      display: flex;
      align-items: center;
    }
  }

  &__nav-button.button--invert {
    width: 4.4rem;
    font-size: 0;
    background: $t-green url("../img/arrow-next.svg") center center/auto no-repeat;

    @media all and (max-width: 920px) {
      height: 5.4rem;
    }

    &:hover {
      background: #0ca24d url("../img/arrow-next.svg") center center/auto no-repeat;
    }
    &.nav-button--back {
      transform: scaleX(-1);
    }
  }

  &__nav-counter {
    margin: 0 2rem;
  }
}

.expirience-gallery {
  max-width: 126rem;
  margin: 5.8rem auto 0;

  @media all and (max-width: 1460px) {
    max-width: calc(100% - 14rem);
  }

  @media all and (max-width: 992px) {
    margin-top: 4rem;
  }

  @media all and (max-width: 576px) {
    max-width: 100%;
    margin-top: 2rem;
  }

  &__item {
    padding: 0 1.5rem;
    border-radius: .5rem;

    @media all and (max-width: 768px) {
      padding: 0 1rem;
    }

    &:focus {
      outline: none;
    }
  }

  &__item-img {
    vertical-align: top;
    width: 100%;
    height: 25rem;
    object-fit: cover;
    border-radius: .5rem;

    @media all and (max-width: 1160px) {
      height: 20rem;
    }
    @media all and (max-width: 768px) {
      height: 16rem;
    }
    @media all and (max-width: 576px) {
      height: 20rem;
    }
    @media all and (max-width: 480px) {
      height: 23rem;
    }

    &:focus {
      outline: none;
    }
  }

  &__item-zoom-link {
    &:focus {
      outline: none;
    }
  }

  .slick-prev, .slick-next {
        width: 4.4rem;
        height: 6.4rem;
        transform: translate(0, -50%);
        z-index: 2;

        @media all and (max-width: 576px) {
          height: 5.4rem;
        }

        &:hover {
          &::before {
            background: #309C5F url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;;
          }
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: $t-green url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;
          border-radius: .3rem;
          opacity: 1;
        }

        &.slick-disabled {
          &::before {
            background: #309C5F url('../img/arrow-reviews-slider.svg') center center / auto no-repeat;
          }
        }
      }

  .slick-prev {
        left: -6.8rem;

        @media all and (max-width: 576px) {
          left: 0;
        }
        &::before {
          transform: rotate(180deg);
        }
      }
  .slick-next {
        right: -6.8rem;
        @media all and (max-width: 576px) {
          right: 0;
        }
      }

}