@import "colors";

.wrapper--resume-page {
  background: $t-bg-gray;
}

.content-block {
  &.block--single-page.block--resume {
    padding: 1rem 0 7.7rem;

    @media all and (max-width: 768px) {
      padding-bottom: 2rem;
    }

    @media all and (max-width: 576px) {
      padding-bottom: .4rem;
    }
  }
}
.resume-content__desc {
  font-size: 1.6rem;
  line-height: 2.8rem;
}
.resume-content__desc + .resume-content__desc {
  margin-top: 2.6rem;
}

.resume-form {
  margin-top: 5.6rem;

  @media all and (max-width: 576px) {
    margin-top: 3rem;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media all and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__employee-photo {
    position: relative;
    flex-basis: 24.3rem;
    min-height: 30rem;
    margin-right: 2.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.4rem 1.6rem 1.5rem;
    border-radius: .6rem;
    border: 2px dashed #CBCBCB;
    overflow: hidden;

    @media all and (max-width: 576px) {
      flex-basis: unset;
      width: 28rem;
      margin: 0 auto;
    }

    &-wrapper {
      width: 16.5rem;
      height: 16.5rem;
      border-radius: 50%;
      overflow: hidden;
      border: .3rem solid #BEBEBE;
      text-align: center;
    }

    &-img {
      margin-top: 1.8rem;
    }

    &-button {
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin-top: auto;
      width: 100%;
      z-index: 3;
    }
  }

  &__photo-uploaded {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__employee-full-name {
    flex: 1;

    @media all and (max-width: 576px) {
      margin-top: 2rem;
      width: 100%;
    }
  }

}

.form__submit-button-wrapper {
  margin-top: 3.6rem;
  margin-bottom: 3rem;
  @media all and (max-width: 576px) {
    margin: 2.5rem 0 2.5rem;
    text-align: center;
  }
}

.form__submit-button {
  width: 18rem;
  font-size: 1.5rem;
  line-height: 2.5rem;

  @media all and (max-width: 375px) {
    width: 100%;
  }
}

.section__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11.6rem;
  text-align: center;

  @media all and (max-width: 900px) {
    height: 7.6rem;
  }
  @media all and (max-width: 576px) {
    height: unset;
    text-align: left;
    justify-content: flex-start;
  }
}

.section__subtitle {
  position: relative;
  display: flex;
  align-items: center;
  height: 5.5rem;
  border-bottom: .1rem solid #C8CFD6;
  border-top: .1rem solid #C8CFD6;

  @media all and (max-width: 900px) {
    display: none;
  }

    &::before {
      content: '';
      display: block;
      width: 4.3rem;
      height: 100%;
      position: absolute;
      top: -.1rem;
      right: -4.3rem;
      border-top: .1rem solid #C8CFD6;
      border-bottom: .1rem solid #C8CFD6;
    }

  &-text {
    flex: 1;
    text-align: center;
  }
}

.section__input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10.8rem;
  padding: 1rem .8rem;

  @media all and (max-width: 900px) {
    min-height: unset;
    margin-bottom: 1rem;
  }

  @media all and (max-width: 576px) {
    flex-direction: column;
    padding: 0;
    margin-bottom: 1.5rem;
  }



  &.clone-section {
    padding: 0 .8rem 1rem;
    min-height: 9.9rem;
  }

  .form__input-wrapper + .form__input-wrapper {
    margin-left: 1.6rem;

    @media all and (max-width: 576px) {
      margin-left: 0;
      margin-top: 1.5rem;
    }
  }

  .form__input-wrapper {
    margin-bottom: 0;

    .calendar-icon {
      width: 3.1rem;
    }

   .form__input {
      height: 4.1rem;
      font-size: 1.3rem;
      padding: 1.5rem 1rem 1.4rem;

      &.field--date {
       padding: 1.5rem 1rem 1.4rem 3.5rem;
      }
    }
    .form__textarea {
      font-size: 1.4rem;
      padding: 1rem;
    }
  }
}


.input-inline__section {
  font-size: 1.5rem;
  line-height: 2.5rem;

  &.section--expirience.section--period {
    @media all and (max-width: 900px) {
      grid-column: span 3;
    }
    @media all and (max-width: 576px) {
      grid-column: unset;
    }

    .section__title {
      @media all and (max-width: 576px) {
        display: none;
      }
    }

    .section__subtitle {
      display: flex;

      @media all and (max-width: 576px) {
        display: none;
      }

      &::before {
        @media all and (max-width: 900px) {
          display: none;
        }
      }
    }
  }

  &.section--relatives {
    .section__title {
      height: 9rem;
      border-bottom: none;

      @media all and (max-width: 900px) {
        height: 6rem;
      }
      @media all and (max-width: 576px) {
        height: unset;
      }
    }
    .section__input {
      padding: 0;
    }
  }

  &.section--leave {
    .section__title {
      height: 6.4rem;
      border-bottom: none;

      @media all and (max-width: 900px) {
        height: 6rem;
      }
      @media all and (max-width: 576px) {
        height: unset;
      }
    }
    .section__input {
      padding: 0;
    }
  }

  &.section--span3 {
    grid-column: span 3;

    @media all and (max-width: 576px) {
      grid-column: unset;
    }
  }
}

.section__del-button {
  position: absolute;
  top: calc(50% - 1rem);
  right: -2.8rem;
  width: 2rem;
  height: 2rem;
  font-size: 0;
  border: none;
  background: transparent;
  opacity: .54;
  cursor: pointer;

  @media all and (max-width: 900px) {
    top: 1rem;
    right: 1rem;
  }

  &::before, &::after {
    content: '';
    display: block;
    width: 100%;
    height: .2rem;
    background: #58626B;
    position: absolute;
    top: calc(50% - .1rem);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

