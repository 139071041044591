@import 'colors';

.wrapper--career-page {
	background: $t-bg-gray;
}

.content-block {
	&.block--single-page.block--career {
		padding: 1rem 0 7.7rem;

		@media all and (max-width: 768px) {
			padding-bottom: 2rem;
		}

		@media all and (max-width: 576px) {
			padding-bottom: 0.4rem;
		}
	}
}

.career-content__desc {
	font-size: 1.6rem;
	line-height: 2.8rem;
}

.career-content__vacancy-list {
	margin-top: 2.9rem;

	@media all and (max-width: 576px) {
		margin-top: 2rem;
	}
	@media all and (max-width: 375px) {
		width: calc(100% + 2rem);
		margin-left: -1rem;
	}
}

.dropdawn-menu__vacancy-item {
	position: relative;
	margin-bottom: 1.2rem;
	background: white;
	box-shadow: 0 0.1rem 0.1rem rgba(41, 41, 41, 0.16);

	&.item--active {
		box-shadow: 0 2rem 5rem rgba(41, 41, 41, 0.05);
		&::after {
			transform: rotate(180deg);
		}
	}

	&:hover {
		box-shadow: 0 2rem 5rem rgba(0, 55, 84, 0.05);
	}

	&:last-child {
		margin-bottom: 0;
	}

	&::after {
		content: '';
		display: block;
		width: 1.7rem;
		height: 1.1rem;
		position: absolute;
		top: 3.6rem;
		right: 3.6rem;
		background: url('../img/arrow-dropdown-vacancy.svg') center center/auto
			no-repeat;
		transition: 0.15s;
	}

	.vacancy-item__header {
		padding: 2.7rem 6rem 2rem 3.8rem;
		display: flex;
		flex-direction: column;
		cursor: pointer;

		@media all and (max-width: 576px) {
			padding: 1.7rem 5.5rem 1.5rem 2rem;
		}

		.vacancy-item__name {
			font-weight: 600;
			color: $t-green;
		}

		.vacancy-item__city {
			display: inline-block;
			margin-top: 0.7rem;
			font-size: 1.4rem;
			line-height: 2.3rem;
			color: rgba(0, 0, 0, 0.5);

			@media all and (max-width: 576px) {
				margin-top: 0.5rem;
			}
		}

		.vacancy-item__salary {
			display: inline-block;
			margin-top: 1.1rem;
			font-weight: 500;

			@media all and (max-width: 576px) {
				margin-top: 0.7rem;
			}
		}
	}

	.vacancy-item__vacancy-info {
		display: none;
		padding: 3.1rem 3.8rem;
		border-top: 1px solid #e9e9e9;
		font-size: 1.6rem;
		line-height: 2.7rem;

		@media all and (max-width: 576px) {
			line-height: 2.5rem;
			padding: 2.5rem 2.3rem 1.5rem;
		}

		.vacancy-info__title {
			font-weight: 900;
			font-size: 1.8rem;
			line-height: 3rem;

			@media all and (max-width: 576px) {
				font-size: 1.6rem;
				line-height: 2.7rem;
			}
		}

		.vacancy-info__add-info {
			margin-top: 4.4rem;

			@media all and (max-width: 576px) {
				margin-top: 3rem;
			}
		}

		.vacancy-info__list {
			margin: 1.7rem 0 3.3rem;

			@media all and (max-width: 576px) {
				margin: 1rem 0 3rem;
			}

			.list__item {
				position: relative;
				padding-left: 3rem;
				margin-bottom: 0.8rem;

				&::before {
					content: '';
					display: block;
					width: 1.5rem;
					border-bottom: 0.2rem solid $text-black;
					position: absolute;
					top: 1.2rem;
					left: 0;
				}
			}
		}
	}
}

//welder edits
.welder-flex-class {
	display: flex;
}

.career-welder {
	width: 100%;
	height: 100%;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.5);

	.welder-content {
		width: 55.7rem;
		height: 27.4rem;
		background-color: white;
		margin: auto;
		color: black;
		position: relative;
		padding: 3rem 4.5rem 4rem 4.5rem;

		.welder-exit {
			position: absolute;
			right: 2rem;
			top: 2rem;
			font-size: 2.4rem;
			font-weight: 300;
			color: #89929d;
			cursor: pointer;
		}

		h1 {
			font-weight: 700;
			font-size: 2.4rem;
			line-height: 4rem;
			margin-bottom: 1.9rem;
		}

		h1 + span {
			font-weight: 400;
			font-size: 1.9rem;
			line-height: 2.8rem;
			margin-bottom: 2.7rem;
			display: inline-block;
		}

		span + div {
			display: flex;
			justify-content: space-between;
		}

		span + div > a {
			width: 20.6rem;
			height: 5.4rem;
			background: #00923f;
			border-radius: 3px;
			color: white;
			text-align: center;
			font-weight: 700;
			font-size: 16px;
			line-height: 2.4rem;
			border: none;
			cursor: pointer;
			padding-top: 1.5rem;
		}

		div > a + a {
			width: 20.6rem;
			height: 5.4rem;
			border-radius: 3px;
			color: white;
			text-align: center;
			font-weight: 700;
			font-size: 16px;
			line-height: 2.4rem;
			cursor: pointer;
			background-color: white;
			color: black;
			border: 1.5px solid #00923f;
			border-radius: 3px;
		}
	}
}

@media screen and (max-width: 557px) {
	//welder edits
	.career-welder {
		.welder-content {
			width: 98%;
			height: auto;
			padding: 2rem 3.5rem 3rem 3.5rem;

			.welder-exit {
				right: 1rem;
				top: 1rem;
				font-size: 1.6rem;
			}

			span + div {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				align-items: center;
			}

			span + div > a {
				margin-bottom: 1rem;
			}
		}
	}
}
