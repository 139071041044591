@import "colors";

.wrapper--about-page {

  .bread-crumbs {
    margin-top: 0;
    .bread-crumbs__link {
      color: white;
      border-bottom: 1px solid white;
    }
  }
}

.business-direction__title {
  font-family: 'Oswald', 'Sans Serif';
  font-weight: 500;
  font-size: 3.8rem;
  line-height: 6.6rem;

  @media all and (max-width: 768px) {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
}

.business-direction__grid {
  margin-top: 3.4rem;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 3.5rem;

  @media all and (max-width: 768px) {
    grid-gap: 2rem;
  }
  @media all and (max-width: 768px) {
    grid-template: auto / 1fr;
  }
  @media all and (max-width: 375px) {
    grid-gap: 1.8rem;
  }
}

.business-direction__item {
  padding: 3.7rem 4.1rem 4.7rem;
  color: white;
  border-radius: .3rem;
  box-shadow: 0 8rem 10rem rgba(0, 0, 0, 0.08);

  @media all and (max-width: 768px) {
    padding: 3rem;
  }
  @media all and (max-width: 375px) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    padding: 1.5rem 2rem;
  }

  &.item--project {
    background: linear-gradient(199.48deg, #00A346 -0.33%, #00873A 103.3%);
  }
  &.item--building {
    background: linear-gradient(196.5deg, #007CC7 -6.23%, #0072B8 101.4%);
  }

  &.item--with-img {
    padding: 0 0 2.7rem 0;
    color: $text-black;

    .item__title {
      margin-top: 2.1rem;
      padding: 0 4rem;

      @media all and (max-width: 768px) {
        padding: 0 3rem;
      }
      @media all and (max-width: 576px) {
         padding: 0 2rem;
        margin-top: 1.4rem;
      }
    }
    .item__desc {
      margin-top: 1.7rem;
      padding: 0 4rem;

      @media all and (max-width: 768px) {
        padding: 0 3rem;
      }
      @media all and (max-width: 576px) {
        padding: 0 2rem;
      }
    }
  }

  .item__img  {
    vertical-align: top;
    width: 100%;
    min-height: 18rem;
    object-fit: cover;
  }

  .item__title {
    font-family: 'Oswald', 'Sans Serif';
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 4rem;

    @media all and (max-width: 768px) {
      font-size: 2.2rem;
      line-height: 3rem;
    }
  }

  .item__desc {
    margin-top: 2rem;
  }
}
