
.tanc-cleaning__building-process {
  padding: 9.1rem 0 12.5rem;
  background: #f7f7f7;

  @media all and (max-width: 992px) {
    padding: 6rem 0 8rem;
  }

  @media all and (max-width: 576px) {
    padding: 3.8rem 0 4rem;
  }
}

.building-steps {
  margin-top: 6.6rem;
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
  counter-reset: step;

  @media all and (max-width: 992px) {
    margin-top: 3.5rem;
    grid-template: auto / 1fr;
  }

  @media all and (max-width: 576px) {
    margin-top: 2.5rem;
    row-gap: 1rem;
  }


  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2rem 4.5rem 4.5rem;
    background: white;
    box-shadow: 0 4rem 3rem -3rem rgba(31,31,31,.09);
    counter-increment: step;

    @media all and (max-width: 1260px) {
      padding: 1.5rem 3rem 3.5rem;
    }

    @media all and (max-width: 576px) {
      padding: 1.5rem 2rem 3.3rem;
    }

    &::before {
      content: counter(step);
      display: block;
      font-weight: 900;
      font-size: 12rem;
      line-height: 13.2rem;
      color: #e4e4e4;

      @media all and (max-width: 768px) {
        font-size: 10rem;
        line-height: 10rem;
      }
    }
  }

  &__title {
    max-width: 26rem;
    font-weight: 900;
    font-size: 2.8rem;
    line-height: 3.5rem;

    @media all and (max-width: 1260px) {
      font-size: 2.4rem;
      line-height: 3rem;
    }

    @media all and (max-width: 992px) {
      max-width: unset;
    }
  }

  &__desc {
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-size: 1.8rem;
    line-height: 3rem;

    @media all and (max-width: 1260px) {
      margin-top: 1.8rem;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }

  &__button {
    align-self: flex-start;
    height: 4.2rem;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    margin-top: auto;
  }

  &__modal-content {
    display: none;
  }

}