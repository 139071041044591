@import "colors";

.modal {
  overflow: unset;
  display: none;
  padding: 3rem 3rem 4.1rem 4.1rem;

  @media all and (max-width: 576px) {
    padding: 3rem 2rem;
  }

  @media all and (max-width: 375px) {
    padding: 2rem;
    vertical-align: top;
  }

  &__title {
    display: inline-block;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 4rem;
    color: $text-black;

    @media all and (max-width: 576px) {
      font-size: 2.2rem;
      line-height: 3rem;
    }

    @media all and (max-width: 375px) {
      max-width: 24.3rem;
      margin: 0 auto;
    }
  }

  &__content {
    margin-top: 1.9rem;

    &-list {

      &-item + &-item {
        margin-top: 1rem;
      }
      &-item {
        position: relative;
        padding-left: 1.5rem;

        &::before {
          content: '';
          display: block;
          width: .4rem;
          height: .4rem;
          border-radius: 50%;
          position: absolute;
          top: 1rem;
          left: 0;
          background: #000;
        }
      }
    }

    &-title {
      font-weight: 700;
      margin: 3rem 0 1.7rem;
      display: inline-block;
      font-size: 1.8rem;
      line-height: 3.1rem;
    }

    &-requirements {
      display: none;
    }

  }

  &__download-photo-button {
    display: block;
    padding: 1.5rem 2.5rem;
    margin-top: 3.6rem;
  }

  &__all-requirements {
    position: relative;
    margin-top: 2.6rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $t-green;
    background: transparent;
    border: none;
    border-bottom: .1rem dashed $t-green;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 1rem;
      height: .6rem;
      background: url('../img/show-more-arrow.svg') center center / auto no-repeat;
      position: absolute;
      top: .8rem;
      right: -1.75rem;
    }

    &.active {
      &::before {
        transform: rotate(180deg);
      }
    }
  }

  .fancybox-close-small {
    width: 3rem;
    height: 3rem;
    top: 1.7rem;
    right: 1.7rem;
    padding: 0;
    opacity: 1;

    @media all and (max-width: 576px) {
      top: 1.25rem;
      right: 1.25rem;
    }

    path {
      fill: #89929D;
    }
  }

  //========== other modals =============

  &--typo {
    width: 62.5rem;
    padding: 3rem 4.5rem 4.5rem;

    @media all and (max-width: 576px) {
      padding: 2rem;
    }

    .modal__typo-form {
      margin-top: 4rem;
      font-size: 1.5rem;
      line-height: 2.5rem;

      @media all and (max-width: 576px) {
        margin-top: 3rem;
      }
    }

    .form__sent-text-wrapper {
      margin: 1.5rem 0 2rem 0;
      background: $t-bg-light-gray;
      padding: 2rem 2rem 3rem;

      @media all and (max-width: 576px) {
        margin: .5rem 0 1.5rem 0;
        padding: 2rem;
      }
    }

    .form__send-text {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.5rem;

      .typo {
        color: $t-red;
      }
    }

    .typo-form__submit-button-wrapper {
      margin-top: 4.5rem;

      @media all and (max-width: 576px) {
        margin-top: 3rem;
      }
    }
    .typo-form__submit-button {
      min-width: 18rem;

      @media all and (max-width: 375px) {
        width: 100%;
      }
    }



  }

  &--attach-resume {
    width: 62rem;
    padding: 3rem 4.5rem 2.5rem;

    @media all and (max-width: 576px) {
      padding: 2rem 2rem 0;
    }

    .resume-form {
      margin-top: 4rem;
      @media all and (max-width: 576px) {
        margin-top: 3rem;
      }
    }

    .input-wrapper--phone {
      max-width: 26.7rem;

      @media all and (max-width: 576px) {
        max-width: unset;
      }
    }

    .form__upload-wrapper {
      margin-top: 4.5rem;

      @media all and (max-width: 576px) {
        margin-top: 3rem;
      }
    }

    .form__submit-button-wrapper {
      margin: 4rem 0 2rem;
      @media all and (max-width: 576px) {
        margin: 2rem 0 1rem;
      }
    }
    .form__submit-button {
      font-size: 1.6rem;
      line-height: 2.7rem;
    }

    .form__label.label--upload-resume {
      border-color: $t-green;
      color: $t-green;
    }
  }

  &--thanks {
    vertical-align: middle;
    width: unset;
    padding: 4rem 6rem 4.4rem;
    border-radius: .4rem;

    @media all and (max-width: 576px) {
      padding: 3.6rem 3rem 4.3rem;
    }
    @media all and (max-width: 375px) {
      width: 100%;
    }

    .modal__content {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .modal__thanks-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 5.6rem;
      height: 5.6rem;
      background: #D6F3E2;
      border-radius: 50%;
    }

    .modal__thanks-img {
      vertical-align: top;
      max-width: 100%;
    }

    .modal__thank-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.modal__thank-text {
        display: none;

        &.thank-text--subscribe {
          .modal__desc {
            display: none;
          }
        }
      }

    }

    .modal__title {
      margin: 2rem 0 1.4rem;
      font-weight: 700;
      display: block;
      font-size: 2rem;
      line-height: 3rem;
      text-align: center;

      @media all and (max-width: 576px) {
        font-size: 1.9rem;
        line-height: 3rem;
      }
    }

    .modal__desc {
      width: 25.5rem;
      margin: 0 auto;
      font-size: 1.6rem;
      line-height: 2.5rem;
      text-align: center;

      @media all and (max-width: 576px) {
        max-width: 24.1rem;
        font-size: 1.5rem;
        line-height: 2.5rem;
      }
    }
  }

  &--photo {
    width: 55.7rem;
    vertical-align: top;
    margin-top: 26.7rem;

    @media all and (max-width: 576px) {
      margin-top: 12.1rem;
    }

    .modal__title {
      @media all and (max-width: 375px) {
        padding-right: 5rem;
      }
    }

    .form__error-msg {
      top: 0;
    }
  }

  &--privacy {
    max-width: 90rem;
    padding: 4.7rem 9.2rem 1.6rem 5.6rem;

    @media all and (max-width: 768px) {
      padding: 3.1rem 6rem 1.6rem 3.1rem;
    }
    @media all and (max-width: 480px) {
      padding: 3.1rem 2rem 1.6rem 2rem;
    }

    .modal__title {
      font-size: 3.2rem;
      line-height: 5.1rem;

      @media all and (max-width: 768px) {
        font-size: 2.8rem;
        line-height: 3.6rem;
      }

      @media all and (max-width: 576px) {
        font-size: 2.4rem;
        line-height: 3rem;
      }
    }

    .modal__content {
      margin-top: 2.5rem;

      &.content--privacy {
        font-size: 1.8rem;
        line-height: 3rem;

        @media all and (max-width: 768px) {
          font-size: 1.6rem;
          line-height: 2.5rem;
        }

        p + p {
          margin-top: 3rem;

          @media all and (max-width: 480px) {
            margin-top: 2.5rem;
          }
        }
      }
    }

    .modal__privacy-text {
      margin-top: 2.5rem;
      color: $text-black;

      p + p {
        margin-top: 3rem;

      }
    }

    .fancybox-close-small {
      top: 2.1rem;
      right: 2.1rem;
      width: 4rem;
      height: 4rem;
      padding: 0;

      @media all and (max-width: 768px) {
        top: 1.1rem;
        right: 1.1rem;
        width: 3.6rem;
        height: 3.6rem;
      }

      path {
        fill: #000;
      }
    }
  }

  &--order {
   .modal__title {
     display: block;
     text-align: center;
   }
 }

  &--consult, &--order {
    vertical-align: middle;
    max-width: 44rem;
    padding: 4.1rem 4.5rem;
    border-radius: .4rem;

    @media all and (max-width: 576px) {
      padding: 2rem 2rem .9rem;
    }

    .modal__desc {
      display: block;
      margin-top: 1.3rem;
      text-align: center;
      color: #3F4C59;
    }

    .consult-form {
      margin-top: 3rem;
      @media all and (max-width: 576px) {
         margin-top: 2.5rem;
      }

      &__submit-button-wrapper {
        margin: 3.7rem 0 2.9rem;
        @media all and (max-width: 576px) {
          margin: 3.1rem 0 2rem;
        }
      }

      .form__submit-button {
        font-size: 1.6rem;
        line-height: 2.7rem;
        width: 100%;
      }
    }
  }

  &--step, &--read-more {
    padding: 0;
    max-width: 125rem;
    border-radius: .4rem;

    .modal-step-content {
      display: flex;
      justify-content: space-between;

      @media all and (max-width: 992px) {
        flex-direction: column;
      }

      &__text {
        font-size: 1.9rem;
        line-height: 3rem;
        padding: 4.8rem 5.8rem 5.2rem;

        @media all and (max-width: 576px) {
          padding: 4.5rem 2rem 3.4rem;
          font-size: 1.6rem;
          line-height: 2.5rem;
        }

        p + p {
          margin-top: 1.6rem;

          @media all and (max-width: 576px) {
            margin-top: 1.2rem;
          }
        }

        h4 {
          margin: 4.9rem 0 2.6rem;
          font-weight: 900;
          font-size: 2.8rem;
          line-height: 3.5rem;


          @media all and (max-width: 576px) {
            margin: 3.5rem 0 2rem;
            font-size: 2.4rem;
            line-height: 3rem;
          }
        }

      }

      &__feedback {
        flex: 0 0 46.6rem;
        border-radius: 0 .4rem .4rem 0;
        padding: 4.3rem 5.8rem 2.5rem;
        background: #f7f7f7;

        @media all and (max-width: 992px) {
          flex: unset;
        }

        @media all and (max-width: 576px) {
          padding: 3.2rem 3rem 4rem;
        }
      }

      &__title {
        display: block;
        width: 100%;
        font-weight: 800;
        font-size: 3.2rem;
        line-height: 4rem;

        @media all and (max-width: 992px) {
          text-align: center;
          font-size: 2.8rem;
          line-height: 3.2rem;
        }

        @media all and (max-width: 768px) {
          font-size: 2.4rem;
          line-height: 3rem;
        }

        @media all and (max-width: 576px) {
          font-size: 1.8rem;
          line-height: 2.5rem;
          text-align: left;
        }
      }

      &__form {
        margin: 2.7rem auto 0;
        max-width: 46rem;

        .form__input {
          height: 6.4rem;
          font-size: 1.8rem;
          line-height: 3rem;

          @media all and (max-width: 576px) {
            font-size: 1.6rem;
            line-height: 2.8rem;
            height: 5.4rem;
          }


        }

        .form__input-outer {
          &.field--invalid .field--requared {
            background: rgba(199,31,31,.03);
            border: .2rem solid #c71f1f;

            &::placeholder {
              color: rgba(199, 31, 31, 0.6);
            }
          }
          &::after {
            display: none;
          }
        }
      }

      .consult-form__submit-button-wrapper {
        text-align: center;
        margin-bottom: 2rem;
      }

      .form__submit-button {
        width: 100%;
        max-width: 40.6rem;
      }
    }

    .fancybox-close-small {

      @media all and (min-width: 1300px) {
        top: .4rem;
        right: -3.2rem;
      }

      path {
        @media all and (min-width: 1360px) {
          fill: #ffffff;
        }
      }
    }
  }

  &__scroll-top {
    position: sticky;
    bottom: 1.6rem;
    height: 5rem;
    text-align: right;

    &-button {
      position: relative;
      right: -7.6rem;
      bottom: 0;
      width: 5rem;
      height: 5rem;
      border: .2rem solid #000;
      border-radius: 50%;
      font-size: 0;
      background: white url('../img/scroll-top-arrow.svg') center center / auto no-repeat;
      cursor: pointer;

      @media all and (max-width: 768px) {
        right: -4.4rem;
        height: 4.2rem;
        width: 4.2rem;
      }
      @media all and (max-width: 480px) {
        right: -.9rem;
      }
    }
  }
}

.fancybox-container {

  .fancybox-slide {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

  }

  .fancybox-bg {
     background: rgba(2, 20, 28, .3);
   }

  .fancybox-slide--html {
    padding: 1rem;
  }
}

.fancybox-content {
  .fancybox-close-small {
    color: #000000;
  }
}

.fancybox-navigation {

  .fancybox-button {
    display: block;
    padding: 0;
    opacity: 1;
    background: transparent;
    width: 4.8rem;
    height: 4.8rem;
    top: 50%;
    transform: translateY(-50%);

    @media all and (max-width: 576px) {
      width: 3.5rem;
      height: 3.5rem;
    }

    &:disabled {
      opacity: .4;
    }

    &.fancybox-button--arrow_left {
       left: 1rem;
      div {
        transform: rotate(180deg);
      }
    }
    &.fancybox-button--arrow_right {
       right: 1rem;
    }

    div {
      width: 4.8rem;
      height: 4.8rem;
      padding: 0;
      background: $t-green url(../img/arrow-next.svg) center center/auto no-repeat;
      border-radius: 50%;

      @media all and (max-width: 576px) {
        width: 3.5rem;
        height: 3.5rem;
        background: $t-green url(../img/arrow-next.svg) center center/25% no-repeat;
      }

      svg {
        display: none;
      }
    }
  }

}

.error-widget {
  display: none;
  width: 35.3rem;
  height: 10rem;
  position: fixed;
  right: 3.2rem;
  bottom: 3.2rem;
  padding: 2.5rem 7rem 2.5rem 3.4rem;
  background: white;
  font-size: 1.5rem;
  line-height: 2.5rem;
  border-left: .3rem solid #cc1212;
  box-shadow: 0 2rem 5rem rgba(0, 55, 84, 0.07);
  z-index: 10;

  &__close {
    position: absolute;
    top: 2.1rem;
    right: 2.1rem;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &::before, &::after {
      content: '';
      display: block;
      width: 2.1rem;
      height: .2rem;
      background: #89929D;
      position: absolute;
      top: calc(50% - .1rem);
      left: calc(50% - 1rem);
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}


