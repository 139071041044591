@import "../colors";

.work-list {
  padding: 5rem 0 12.5rem;
  background: #F7F7F7;

  @media all and (max-width: 992px) {
    padding: 4rem 0 8rem;
  }

  @media all and (max-width: 768px) {
    padding: 3rem 0 6rem;
  }

  @media all and (max-width: 576px) {
    padding: 2.7rem 0 4.1rem;
  }

  &__grid {
    margin-top: 6rem;
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    column-gap: 3rem;

    @media all and (max-width: 720px) {
      margin-top: 4rem;
      grid-template: auto / 1fr;
    }

    @media all and (max-width: 576px) {
      margin-top: 2.4rem;
    }
  }

  &__grid-inner + &__grid-inner{
    @media all and (max-width: 720px) {
      margin-top: 1.1rem;
    }
  }

  &__item {
    margin-bottom: 2.6rem;
    width: 100%;
    display: inline-block;
    padding: 4.1rem 5.4rem 4.3rem;
    background: white;
    box-shadow: 0 4rem 3rem -3rem rgba(31, 31, 31, 0.09);

    @media all and (max-width: 992px) {
      padding: 3.2rem 3rem 3.6rem;
    }

    @media all and (max-width: 720px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @media all and (max-width: 576px) {
      padding: 2.6rem 1.9rem 3.3rem;
      margin-bottom: 1.1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.item--add-info {

      @media all and (max-width: 576px) {
        padding: 2.6rem 2.5rem 3.3rem;
      }
      .work-list__item-title {
        font-weight: 800;
        max-width: 43rem;
      }
      .work-list__order-btn {
        margin-top: 3.4rem;

        @media all and (max-width: 576px) {
          margin-top: 2.3rem;
        }
      }
    }
  }

  &__item-title {
    display: block;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.5rem;
    color: #000000;

    @media all and (max-width: 992px) {
      font-size: 2.2rem;
      line-height: 3rem;
    }

    @media all and (max-width: 576px) {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  }

  &__order-btn {
    padding: .4rem 2.5rem;
    height: 4.8rem;
    margin-top: 2.2rem;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 4rem;

    @media all and (max-width: 576px) {
      margin-top: 1.7rem;
    }
  }

  &__add-works {
    margin-top: 2.6rem;
    padding-left: 1.6rem;
    text-align: left;

    @media all and (max-width: 992px) {
      margin-top: 1.8rem;
    }

    @media all and (max-width: 576px) {
      margin-top: 1.2rem;
    }
  }

  &__add-works-item + &__add-works-item {
    margin-top: 2.4rem;

    @media all and (max-width: 576px) {
      margin-top: 1.5rem;
    }
    @media all and (max-width: 375px) {
      max-width: 20rem;
    }
  }

  &__add-works-item {
    position: relative;
    padding-left: 2.4rem;
    font-size: 2rem;
    line-height: 2.5rem;

    @media all and (max-width: 576px) {
      padding-left: 2rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: .9rem;
      left: 0;
      display: block;
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
      background: $t-green;
    }
  }
}