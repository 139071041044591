@import "../colors";

.tank-cleaning, .services {

  &__order-block {
    padding: 11rem 0;
    background: #f7f7f7;

    @media all and (max-width: 992px) {
      padding: 8rem 0;
    }

    @media all and (max-width: 576px) {
      padding: 5rem 0;
    }
  }

  &__order {
    width: 100%;
    position: relative;

    @media all and (max-width: 576px) {

    }

    .tank-cleaning__block-desc, .services__block-desc {
      @media all and (max-width: 375px) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }

    &-inner {
      background: white;
      padding: 6.2rem 2rem 8rem;
      box-shadow: 0 6rem 10rem rgba(31, 31, 31, 0.09);

      @media all and (max-width: 768px) {
        padding: 6rem 2rem;
      }

      @media all and (max-width: 576px) {
        padding: 4rem 2rem;
        border-radius: .4rem;
      }

      @media all and (max-width: 375px) {
        padding: 2.6rem 2rem 3rem;
      }

    }

    &-title {
      max-width: 74rem;
      margin: 0 auto;
      font-weight: 800;
      font-size: 3.2rem;
      line-height: 4.5rem;
      text-align: center;

      @media all and (max-width: 576px) {
        font-size: 2.4rem;
        line-height: 3rem;
      }

      @media all and (max-width: 375px) {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
    }

    &-form {
      margin: 4.3rem auto 0;
      max-width: 92rem;

      @media all and (max-width: 720px) {
        width: 100%;
        max-width: 46rem;
      }

      @media all and (max-width: 375px) {
        margin-top: 2.5rem;
      }

      .form__input-inline {
        width: 100%;
      }

      .form__input-outer.field--invalid {
        &::after {
          display: none;
        }

        .field--requared {
          background: rgba(199, 31, 31, 0.03);
          border: .2rem solid #C71F1F;
          color: rgba(199, 31, 31, 0.6);

          &::placeholder {
            color: rgba(199, 31, 31, 0.6);
          }
        }
      }

      .form__input {
        background: #F9F9F9;
        border: .1rem solid #D8D8D8;
        font-size: 1.8rem;
        padding: 1.9rem 2.4rem;
        height: 6.4rem;

        @media all and (max-width: 576px) {
          height: 5.4rem;
          padding: 1.4rem 2.1rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
        }

        &::placeholder {
          color: #7A7A7A;
        }
      }

      .form__submit-button {
        font-size: 1.8rem;
        line-height: 3rem;
        width: 100%;
        height: 6.4rem;

        @media all and (max-width: 576px) {
          font-size: 1.6rem;
          line-height: 2.8rem;
          height: 5.4rem;
        }
      }

      .form__input-wrapper.wrapper--checkbox {
        margin-top: 1.5rem;
        margin-bottom: 0;
        text-align: center;

        @media all and (max-width: 576px) {
          margin-top: 2.5rem;
          padding-right: .3rem;
          text-align: left;
        }
      }
    }
  }

}