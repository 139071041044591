@import "../colors";

.tank-cleaning__process-cleaning {
  background: #f7f7f7;
  padding: 9.3rem 0 7.6rem;

  @media all and (max-width: 768px) {
    padding: 5rem 0 6rem;
  }

  @media all and (max-width: 576px) {
    padding: 3.6rem 0 5.1rem;
  }
}

.process-cleaning {

  &__grid {
    margin-top: 7.2rem;
    display: grid;
    grid-template: auto / repeat(4, 1fr);
    row-gap: 2.7rem;

    @media all and (max-width: 992px) {
      max-width: 64rem;
      margin: 5rem auto 0;
      grid-template: auto / repeat(2, 1fr);
    }

    @media all and (max-width: 576px) {
      grid-template: auto / 1fr;
      margin: 2.9rem auto 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__icon-wrapper {
    margin-bottom: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.4rem;
    height: 9.4rem;
    background: white;
    border-radius: 50%;
    box-shadow: 0 1rem 2rem rgba(31, 31, 31, 0.12);

    @media all and (max-width: 576px) {
      margin-bottom: 1.3rem;
      min-width: 7.4rem;
      width: 7.4rem;
      height: 7.4rem;
    }
  }

  &__icon {
    vertical-align: top;

    @media all and (max-width: 576px) {
      max-width: 3.6rem;
      max-height: 3.6rem;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 2.8rem;
    line-height: 3.5rem;

    @media all and (max-width: 576px) {
      font-size: 2.2rem;
      line-height: 3rem;
    }
  }

  &__desc {
    max-width: 21.8rem;
    margin-top: 1.5rem;

    @media all and (max-width: 576px) {
      max-width: 26rem;
      margin-top: 1rem;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }
}