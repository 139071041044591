@import "colors";

.wrapper--gallery-page {
  background: #262626;

  .main-screen__header {
    margin-top: 3.1rem;
  }

  .bread-crumbs {
    .bread-crumbs__link {
      color: #C7C7C7;
      border-bottom: 1px solid #C7C7C7;

      &:hover {
        color: #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
      }
    }
  }

  .page-title {
    color: white;
  }

  .sorting-block__drop-tag-panel {
    overflow-x: scroll;
    border-bottom: 1px solid rgba(255, 255, 255, .15);

    @media all and (max-width: 1200px) {
      overflow: unset;
      background: #5E5E5E;
      border-bottom: none;
    }

    &::after {
      background: url('../img/drop-down-panel-white.svg') center center/auto no-repeat;
    }

    .drop-tag-panel__tag {
      color: white;

      &:hover {
        color: $t-green;
      }

      &.tag--active {
        color: $t-green;

        @media all and (max-width: 1200px) {
          color: white;
        }
        &::after {
          background: $t-green;
        }
      }
    }
  }

}

.content-block {
  &.block--single-page.block--gallery {
    padding: 1rem 0 8.4rem;

    @media all and (max-width: 768px) {
      padding-bottom: 5rem;
    }

    @media all and (max-width: 576px) {
      padding-bottom: 3.2rem;
    }
  }
}

.content__gallery {
  .gallery__photo {
    max-width: 100%;
    vertical-align: top;
    margin-bottom: 2.9rem;

    @media all and (max-width: 768px) {
      width: 100%;
    }

    @media all and (max-width: 576px) {
      margin-bottom: 1rem;
    }
    @media all and (max-width: 375px) {
      max-width: unset;
      width: calc(100% + 2rem);
      margin-left: -1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
