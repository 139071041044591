@import "colors";

.pagination-block {
  margin-top: 6.7rem;

  @media all and (max-width: 576px) {
    margin-top: 4.6rem;
  }
}

.pagination-block__pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-list__item {
  .item--pagination-link {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    color: $text-black;

    @media all and (max-width: 576px) {
     width: 3.8rem;
    height: 3.8rem;
    }

    &:hover {
      color: $text-black;
    }
  }

  &.item--prev, &.item--next {

    @media all and (max-width: 576px) {
      display: none;
    }
    .item--pagination-link {
      margin: 0 2rem;
      width: 10rem;
      border-radius: 3rem;
      background: #F3F4F6;
      transition: .15s;
    }
  }

  &.item--current-page {
    .item--pagination-link {
      background: $t-green;
      color: white;

      &:hover {
        color: white;
      }
    }
  }
  &:hover {
    .item--pagination-link {
      color: $t-green;
    }
  }
}

.pagination-block {
  &.pagination--in-content {
    .pagination-list__item {
      &.item--prev, &.item--next {

        @media all and (max-width: 992px) {
          display: none;
        }
        @media all and (max-width: 768px) {
          display: block;
        }

        @media all and (max-width: 576px) {
          display: none;
        }
      }
    }
  }
}

.pagination-block {
  &.pagination--white-btn {

    .pagination-list__item {

      &.item--prev, &.item--next {

        .item--pagination-link {
          background: white;
          box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
