@import "colors";

.wrapper--comment-page {
  background: $t-bg-light-gray;
}

.comment-form {
  max-width: 59rem;

  .form__textarea {
    min-height: 14.9rem;

    @media all and (max-width: 576px) {
      max-height: 10.4rem;
    }
  }

  .form__submit-button-wrapper {
    margin-bottom: 0;
  }
}
