@import "../colors";

.tanc-cleaning__certification {
  padding: 9.1rem 0 9.6rem;

  @media all and (max-width: 992px) {
    padding: 6rem 0;
  }

  @media all and (max-width: 576px) {
    padding: 4.5rem 0;
  }

  &-icon {
    margin: 0 auto 2rem;
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    background: $t-green url("../img/certificate-icon.svg") center center/auto no-repeat;

    @media all and (max-width: 576px) {
      width: 7.5rem;
      height: 7.5rem;
      background: $t-green url("../img/certificate-icon.svg") center center/2.6rem no-repeat;
    }
  }

  &-desc {
    max-width: 100rem;
    margin: 2.5rem auto 0;
    font-size: 2.2rem;
    line-height: 3.5rem;
    text-align: center;

    @media all and (max-width: 576px) {
      padding: 0 1rem;
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin-top: 1.5rem;
    }
  }
}