@import "colors";


#map {
  height: 52rem;

  @media all and (max-width: 768px) {
    height: 45rem;
  }
  @media all and (max-width: 576px) {
    height: 35rem;
  }
  @media all and (max-width: 375px) {
    height: 22.5rem;
  }
}

.ymaps-block {
  position: relative;
  margin-top: 4rem;



  &__float-contacts {
    width: 45.7rem;
    height: 34.6rem;
    padding: 11.5rem 1.2rem 3.3rem;
    position: absolute;
    top: calc(50% - 17.3rem);
    right: calc(50% - 62.7rem);
    font-size: 1.8rem;
    line-height: 3rem;
    background: white;
    border-radius: .6rem;
    box-shadow: 0 3.5rem 5rem rgba(0, 0, 0, 0.1);

    @media all and (max-width: 1310px) {
      right: 3rem;
    }

    @media all and (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.5rem;
      width: 39.7rem;
      height: 30.6rem;
      top: calc(50% - 15.3rem);
    }

    @media all and (max-width: 640px) {
      position: relative;
      min-height: 32rem;
      top: 0;
      right: 0;
      width: 100%;
      height: unset;
      padding: 10.4rem 1.2rem 2rem;
    }
    @media all and (max-width: 375px) {
      min-height: 33.9rem;
    }

    .float-contacts__offices {
      position: absolute;
      top: 1.4rem;
      left: 1.2rem;
      right: 1.2rem;

      @media all and (max-width: 640px) {
        top: 2.1rem;
      }
    }

    .float-contacts__offices-list {
      position: relative;
      width: 100%;
      max-height: 31.8rem;
      overflow-y: auto;
      margin-bottom: 4rem;
      box-shadow: 0 .2rem .6rem rgba(0, 0, 0, 0.2);
      background: white;
      z-index: 5;

      @media all and (max-width: 768px) {
        max-height: 27.8rem;
      }

      @media all and (max-width: 640px) {
        max-height: 28.3rem;
      }
      @media all and (max-width: 375px) {
        max-height: 30.5rem;
      }

      &::-webkit-scrollbar-track {
        width: 1rem;
      }

      &::-webkit-scrollbar {
        margin-right: .5rem;
        width: .4rem;
      }

      &::-webkit-scrollbar-thumb {
        width: .4rem;
        background: #C3C8CA;
        border-radius: .2rem;
      }


      &.list--active {

        &::after {
          transform: rotate(180deg);
          right: 2.3rem;
          top: 2.35rem;

          @media all and (max-width: 576px) {
            top: 2.1rem;
            right: 2rem;
          }
        }

        .offices-list__item {
          padding: 1.3rem 2.8rem 1.1rem;
          border-top: 1px solid #EBEDEF;
          display: block;

          &.item--current {
            padding: 1.3rem 2.8rem 1.1rem;
          }

          &:first-child {
            font-weight: 500;
            color: rgba(0, 0, 0, .5);
            padding: 1.9rem 4rem 1.6rem 2.8rem;

            @media all and (max-width: 576px) {
              padding: 1.5rem 4rem 1.3rem 1.7rem;
            }
          }
        }
      }

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: .65rem solid transparent;
        border-top: .65rem solid $text-black;
        position: absolute;
        top: 3rem;
        right: 2.6rem;
        transition: .15s;
        z-index: 2;

        @media all and (max-width: 576px) {
          top: 2.6rem;
          right: 1.9rem;
          border: .4rem solid transparent;
          border-top: .4rem solid $text-black;
        }
      }


      .offices-list__item {
        font-weight: 600;
        cursor: pointer;
        display: none;

        &.item--current {
          display: block;
          padding: 1.9rem 4rem 1.6rem 2.8rem;

          @media all and (max-width: 576px) {
            padding: 1.5rem 4rem 1.3rem 1.7rem;
          }
        }

      }
    }
  }
}

.office-item__title {
  display: none;
}

.office-item__contact {
  margin-top: 1.2rem;
  display: flex;

  @media all and (max-width: 640px) {
    margin-top: 1.9rem;
  }

  &:first-of-type {
      margin-top: 0;
  }

  .contact__title {
    flex-basis: 11.3rem;
    padding-left: 2.8rem;
    margin-right: 1.2rem;
    color: rgba(0, 0, 0, .5);

    @media all and (max-width: 375px) {
      flex-basis: 10.3rem;
      padding-left: 1.8rem;
    }
  }

  .contact__value {
    flex: 1;

    .phone-link {
      white-space: nowrap;
    }

    .email__link {
      border-bottom: 1px solid $t-link-black;
    }
  }
}

.branch-offices__grid {
  margin-top: 6.4rem;
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  grid-column-gap: 6.4rem;
  grid-row-gap: 4.9rem;
  font-size: 1.8rem;
  line-height: 3rem;

  @media all and (max-width: 1200px) {
    grid-column-gap: 4.4rem;
  }

  @media all and (max-width: 992px) {
    grid-template: auto / repeat(2, 1fr);
  }

  @media all and (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  @media all and (max-width: 576px) {
    grid-template: auto / 1fr;
  }

  .office-item__title {
    display: inline-block;
    font-weight: 900;
    font-size: 2.8rem;
    line-height: 3.9rem;
    margin-bottom: 3rem;

    @media all and (max-width: 992px) {
      font-size: 2.1rem;
      line-height: 2.9rem;
    }
    @media all and (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }

  .office-item__contact {

    @media all and (max-width: 640px) {
      margin-top: 1.4rem;
    }

    &:first-of-type {
      margin-top: 0;
    }

    .contact__title {
      flex-basis: 8.5rem;
      padding-left: 0;
      margin-right: .9rem;
    }
  }
}


